import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@nextui-org/react";
import { motion, AnimatePresence } from "framer-motion";

/**
 * HeaderBanner Component - Displays a loyalty program banner with accordion-like functionality
 * @param {Object} props
 * @param {string} props.title - The title of the banner
 * @param {string} props.heading - The heading text
 * @param {Array<{title: string, content: string}>} props.accordionItems - Array of accordion items
 * @param {string} props.imageSrc - URL of the image to display
 * @param {Object} props.ctaConfig - Configuration for call-to-action buttons
 * @param {Object} props.ctaConfig.primaryButton - Primary button configuration
 * @param {string} props.ctaConfig.primaryButton.text - Primary button text
 * @param {string} props.ctaConfig.primaryButton.href - Primary button URL
 * @param {Object} props.ctaConfig.secondaryButton - Secondary button configuration
 * @param {string} props.ctaConfig.secondaryButton.text - Secondary button text
 * @param {string} props.ctaConfig.secondaryButton.href - Secondary button URL
 */
export default function HeaderBanner({
  title = "Vernostný program pre registrovaných užívateľov",
  heading = "",
  accordionItems = [],
  imageSrc = "/loyalty-program.jpg",
  ctaConfig = {
    primaryButton: { text: "Registrácia zdarma", href: "/register" },
    secondaryButton: {
      text: "Viac informácií",
      href: "/blog#vyhody-cashbacku",
    },
  },
}) {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState(null);

  // Toggle accordion item
  const toggleItem = (index) => {
    setActiveItem(activeItem === index ? null : index);
  };

  // Animation variants for container
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        when: "beforeChildren",
      },
    },
    exit: {
      opacity: 0,
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
        when: "afterChildren",
      },
    },
  };

  // Animation variants for items
  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        ease: "easeOut",
      },
    },
    exit: {
      opacity: 0,
      y: -10,
      transition: {
        duration: 0.2,
        ease: "easeIn",
      },
    },
  };

  // Animation variants for content
  const contentVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        ease: "easeOut",
        delay: 0.1,
      },
    },
    exit: {
      opacity: 0,
      y: 10,
      transition: {
        duration: 0.3,
        ease: "easeIn",
      },
    },
  };

  // Plus icon component
  const PlusIcon = () => (
    <div className="min-w-[24px] w-6 h-6 rounded-full bg-blue flex items-center justify-center mr-3 flex-shrink-0 mt-0.5">
      <svg
        width="14"
        height="14"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="text-white"
      >
        <line
          x1="12"
          y1="5"
          x2="12"
          y2="19"
          stroke="currentColor"
          strokeWidth="2.5"
          strokeLinecap="round"
        />
        <line
          x1="5"
          y1="12"
          x2="19"
          y2="12"
          stroke="currentColor"
          strokeWidth="2.5"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );

  return (
    <div className="w-full  relative">
      {/* Large screen layout - original side-by-side design */}
      <div className="hidden lg:block relative overflow-visible mt-24">
        {/* Enhanced animated gradient background */}
        <div className="absolute inset-0 z-0">
          {/* Base gradient - fixed and stable */}
          <div className="absolute inset-0 bg-gradient-to-r from-white to-primary"></div>

          {/* Floating blurred circles */}
          <div className="absolute inset-0 overflow-hidden">
            {/* White circles - Moved more toward center with faster, more visible motion */}
            <motion.div
              className="absolute h-[500px] w-[500px] rounded-full bg-white/20 blur-2xl right-[60%] top-1/6"
              animate={{
                x: [0, 120, -80, 0],
                y: [0, -100, 60, 0],
              }}
              transition={{
                duration: 10,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
              }}
            ></motion.div>

            <motion.div
              className="absolute h-[550px] w-[550px] rounded-full bg-white/15 blur-3xl right-[55%] top-1/3"
              animate={{
                x: [0, -140, 100, 0],
                y: [0, 120, -80, 0],
              }}
              transition={{
                duration: 12,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 0.5,
              }}
            ></motion.div>

            <motion.div
              className="absolute h-[480px] w-[480px] rounded-full bg-white/20 blur-2xl right-[45%] bottom-1/4"
              animate={{
                x: [0, 160, -120, 0],
                y: [0, -140, 100, 0],
              }}
              transition={{
                duration: 11,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 1,
              }}
            ></motion.div>

            <motion.div
              className="absolute h-[420px] w-[420px] rounded-full bg-white/15 blur-3xl right-[40%] top-1/12"
              animate={{
                x: [0, -180, 140, 0],
                y: [0, 160, -120, 0],
              }}
              transition={{
                duration: 13,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 1.5,
              }}
            ></motion.div>

            {/* Additional white circles */}
            <motion.div
              className="absolute h-[580px] w-[580px] rounded-full bg-white/18 blur-3xl right-[25%] top-1/3"
              animate={{
                x: [0, 150, -130, 0],
                y: [0, -130, 110, 0],
              }}
              transition={{
                duration: 12,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 0.9,
              }}
            ></motion.div>

            <motion.div
              className="absolute h-[520px] w-[520px] rounded-full bg-white/22 blur-2xl right-[20%] bottom-1/5"
              animate={{
                x: [0, -170, 140, 0],
                y: [0, 150, -120, 0],
              }}
              transition={{
                duration: 14,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 1.7,
              }}
            ></motion.div>

            <motion.div
              className="absolute h-[490px] w-[490px] rounded-full bg-white/20 blur-3xl right-[15%] top-1/5"
              animate={{
                x: [0, 190, -150, 0],
                y: [0, -170, 130, 0],
              }}
              transition={{
                duration: 11,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 0.4,
              }}
            ></motion.div>

            {/* Pink circles - Moved more toward center with faster, more visible motion */}
            <motion.div
              className="absolute h-[520px] w-[520px] rounded-full bg-pink/10 blur-3xl right-[50%] top-1/3"
              animate={{
                x: [0, 180, -140, 0],
                y: [0, -160, 120, 0],
              }}
              transition={{
                duration: 14,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 0.7,
              }}
            ></motion.div>

            <motion.div
              className="absolute h-[480px] w-[480px] rounded-full bg-pink/15 blur-2xl right-[35%] center"
              animate={{
                x: [0, -200, 160, 0],
                y: [0, 180, -140, 0],
              }}
              transition={{
                duration: 12,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 1.2,
              }}
            ></motion.div>

            <motion.div
              className="absolute h-[500px] w-[500px] rounded-full bg-pink/10 blur-3xl right-[40%] bottom-1/3"
              animate={{
                x: [0, 220, -180, 0],
                y: [0, -200, 160, 0],
              }}
              transition={{
                duration: 15,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 0.3,
              }}
            ></motion.div>

            {/* Primary color circles - Moved more toward center with faster, more visible motion */}
            <motion.div
              className="absolute h-[600px] w-[600px] rounded-full bg-primary/10 blur-3xl right-[45%] bottom-1/5"
              animate={{
                x: [0, 160, -200, 0],
                y: [0, -140, 180, 0],
              }}
              transition={{
                duration: 16,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 0.2,
              }}
            ></motion.div>

            <motion.div
              className="absolute h-[450px] w-[450px] rounded-full bg-primary/12 blur-2xl right-[50%] top-1/4"
              animate={{
                x: [0, -180, 140, 0],
                y: [0, 200, -160, 0],
              }}
              transition={{
                duration: 14,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 0.8,
              }}
            ></motion.div>

            {/* Additional blending circles with different colors - Moved more toward center with faster, more visible motion */}
            <motion.div
              className="absolute h-[520px] w-[520px] rounded-full bg-blue/8 blur-3xl right-[40%] top-1/5"
              animate={{
                x: [0, 140, -120, 0],
                y: [0, -120, 100, 0],
              }}
              transition={{
                duration: 18,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 0.4,
              }}
            ></motion.div>

            <motion.div
              className="absolute h-[580px] w-[580px] rounded-full bg-purple/8 blur-3xl right-[30%] bottom-1/4"
              animate={{
                x: [0, -160, 120, 0],
                y: [0, 140, -120, 0],
              }}
              transition={{
                duration: 20,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 1.1,
              }}
            ></motion.div>

            {/* Additional pink circles for the right side with faster, more visible motion */}
            <motion.div
              className="absolute h-[550px] w-[550px] rounded-full bg-pink/25 blur-3xl right-0 mr-20 top-1/4"
              animate={{
                x: [0, -60, 50, 0],
                y: [0, 70, -50, 0],
              }}
              transition={{
                duration: 14,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 0.6,
              }}
            ></motion.div>

            <motion.div
              className="absolute h-[480px] w-[480px] rounded-full bg-pink/20 blur-2xl right-0 mr-40 bottom-1/3"
              animate={{
                x: [0, 70, -60, 0],
                y: [0, -60, 50, 0],
              }}
              transition={{
                duration: 16,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 1.3,
              }}
            ></motion.div>

            <motion.div
              className="absolute h-[520px] w-[520px] rounded-full bg-pink/18 blur-3xl right-0 mr-10 center"
              animate={{
                x: [0, -50, 40, 0],
                y: [0, 60, -50, 0],
              }}
              transition={{
                duration: 18,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 0.9,
              }}
            ></motion.div>
          </div>
        </div>

        <div className="max-w-screen-2xl mx-auto relative z-10 ">
          <div className="flex flex-row justify-between items-stretch min-h-[400px] pt-8 px-4">
            {/* Text Section */}
            <div className="w-2/3 flex flex-col">
              <motion.h2
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-3xl font-bold text-blue mb-6"
              >
                {t(title)}
              </motion.h2>

              {/* Fixed height container for accordion items */}
              <div className=" max-w-3xl overflow-hidden relative">
                <AnimatePresence mode="wait">
                  {activeItem === null ? (
                    // Show all accordion items when none is active
                    <motion.div
                      key="accordion-list"
                      variants={containerVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      className="space-y-0"
                    >
                      {accordionItems.map((item, index) => (
                        <motion.div
                          key={index}
                          variants={itemVariants}
                          className="flex items-start cursor-pointer hover:bg-blue-50 p-2 rounded-lg transition-colors"
                          onClick={() => toggleItem(index)}
                          whileHover={{
                            scale: 1,
                            transition: { duration: 0.2 },
                          }}
                        >
                          <PlusIcon />
                          <span className="text-darkblue font-semibold">
                            {t(item.title)}
                          </span>
                        </motion.div>
                      ))}
                    </motion.div>
                  ) : (
                    // Show only the active item with its content
                    <motion.div
                      key="accordion-content"
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      className="h-full"
                    >
                      <motion.div
                        variants={itemVariants}
                        className="flex items-start cursor-pointer hover:bg-blue-50 p-2 rounded-lg transition-colors mb-4"
                      >
                        <div
                          className="min-w-[24px] w-6 h-6 rounded-full bg-blue flex items-center justify-center mr-3 cursor-pointer flex-shrink-0 mt-0.5"
                          onClick={() => toggleItem(null)}
                        >
                          <motion.div
                            className="text-white rotate-45"
                            whileHover={{ rotate: 90 }}
                            transition={{ duration: 0.2 }}
                          >
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <line
                                x1="12"
                                y1="5"
                                x2="12"
                                y2="19"
                                stroke="currentColor"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                              />
                              <line
                                x1="5"
                                y1="12"
                                x2="19"
                                y2="12"
                                stroke="currentColor"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                              />
                            </svg>
                          </motion.div>
                        </div>
                        <span className="text-darkblue font-semibold">
                          {t(accordionItems[activeItem].title)}
                        </span>
                      </motion.div>

                      {/* Scrollable content area */}
                      <motion.div
                        variants={contentVariants}
                        className="overflow-y-auto h-[230px] pr-4 text-darkblue"
                      >
                        {t(accordionItems[activeItem].content)}
                      </motion.div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              {/* CTA Buttons */}
              <div className="flex flex-row gap-4 mt-2 pb-8">
                <Button
                  as="a"
                  href={ctaConfig.primaryButton.href}
                  className="bg-blue text-white rounded-3xl font-bold py-3 px-8"
                >
                  {t(ctaConfig.primaryButton.text)} &rarr;
                </Button>
                <Button
                  as="a"
                  href={ctaConfig.secondaryButton.href}
                  className="bg-pink text-white border-none rounded-3xl font-bold py-3 px-8"
                >
                  {t(ctaConfig.secondaryButton.text)}
                </Button>
              </div>
            </div>

            {/* Image Section */}
            <div className="w-1/3 flex justify-end items-end relative overflow-visible ">
              <motion.img
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.7, ease: "easeOut" }}
                src={imageSrc}
                alt={t("Loyalty program")}
                className="absolute bottom-0 -left-20 h-[120%] w-auto max-w-xl object-cover"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Mobile and medium screen layout - banner at top, accordion below */}
      <div className="lg:hidden mt-24 overflow-visible">
        {/* Banner Section with Title and Image */}
        <div className="relative overflow-visible border border-gray-200 rounded-lg mb-6 ">
          {/* Enhanced animated gradient background */}
          <div className="absolute inset-0 z-0">
            {/* Base gradient - fixed and stable */}
            <div className="absolute inset-0 bg-gradient-to-r from-white to-primary"></div>

            {/* Floating blurred circles - simplified for mobile */}
            <div className="absolute inset-0 overflow-hidden">
              {/* Include a subset of the animated circles for mobile */}
              <motion.div
                className="absolute h-[500px] w-[500px] rounded-full bg-white/20 blur-2xl right-[60%] top-1/6"
                animate={{
                  x: [0, 120, -80, 0],
                  y: [0, -100, 60, 0],
                }}
                transition={{
                  duration: 10,
                  repeat: Infinity,
                  repeatType: "loop",
                  ease: "easeInOut",
                }}
              ></motion.div>

              {/* Add a few more essential circles for mobile */}
              <motion.div
                className="absolute h-[550px] w-[550px] rounded-full bg-pink/25 blur-3xl right-0 mr-20 top-1/4"
                animate={{
                  x: [0, -60, 50, 0],
                  y: [0, 70, -50, 0],
                }}
                transition={{
                  duration: 14,
                  repeat: Infinity,
                  repeatType: "loop",
                  ease: "easeInOut",
                  delay: 0.6,
                }}
              ></motion.div>
            </div>
          </div>

          <div className="max-w-screen-2xl mx-auto relative z-10 overflow-visible ">
            <div className="flex flex-row justify-between items-center relative ml-4 overflow-visible">
              {/* Title Section */}
              <div className="w-3/4 pr-4 overflow-visible">
                <motion.h2
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  className="text-xl sm:text-2xl font-bold text-darkblue "
                >
                  {t(title)}
                </motion.h2>
              </div>

              {/* Image Section - responsive approach */}
              <div className="w-1/4 relative flex justify-end overflow-visible">
                <div className="aspect-[3/4] h-auto relative w-full overflow-visible">
                  <motion.img
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.7, ease: "easeOut" }}
                    src={imageSrc}
                    alt={t("Loyalty program")}
                    className="absolute bottom-0 -right-20 h-[120%] max-w-xl object-cover overflow-visible"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Accordion Items Section */}
        <div className="max-w-screen-2xl mx-auto px-4">
          <div className="space-y-0 mb-6">
            {activeItem === null ? (
              // Show all accordion items when none is active
              accordionItems.map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  className="flex items-start cursor-pointer hover:bg-blue-50 p-2 rounded-lg transition-colors"
                  onClick={() => toggleItem(index)}
                  whileHover={{
                    scale: 1.02,
                    transition: { duration: 0.2 },
                  }}
                >
                  <PlusIcon />
                  <span className="text-darkblue font-semibold">
                    {t(item.title)}
                  </span>
                </motion.div>
              ))
            ) : (
              // Show only the active item with its content
              <div>
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="flex items-start cursor-pointer hover:bg-blue-50 p-2 rounded-lg transition-colors mb-4"
                >
                  <div
                    className="min-w-[24px] w-6 h-6 rounded-full bg-blue flex items-center justify-center mr-3 cursor-pointer flex-shrink-0 mt-0.5"
                    onClick={() => toggleItem(null)}
                  >
                    <motion.div
                      className="text-white rotate-45"
                      whileHover={{ rotate: 90 }}
                      transition={{ duration: 0.2 }}
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line
                          x1="12"
                          y1="5"
                          x2="12"
                          y2="19"
                          stroke="currentColor"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                        />
                        <line
                          x1="5"
                          y1="12"
                          x2="19"
                          y2="12"
                          stroke="currentColor"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </motion.div>
                  </div>
                  <span className="text-darkblue font-semibold">
                    {t(accordionItems[activeItem].title)}
                  </span>
                </motion.div>

                {/* Scrollable content area */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4, ease: "easeOut", delay: 0.1 }}
                  className="overflow-y-auto max-h-[230px] pr-4 text-darkblue ml-9"
                >
                  {t(accordionItems[activeItem].content)}
                </motion.div>
              </div>
            )}
          </div>

          {/* CTA Buttons */}
          <div className="flex flex-col sm:flex-row gap-4 mt-8">
            <Button
              as="a"
              href={ctaConfig.primaryButton.href}
              className="bg-blue text-white rounded-3xl font-bold py-3 px-8"
            >
              {t(ctaConfig.primaryButton.text)} &rarr;
            </Button>
            <Button
              as="a"
              href={ctaConfig.secondaryButton.href}
              className="bg-pink text-white border-none rounded-3xl font-bold py-3 px-8"
            >
              {t(ctaConfig.secondaryButton.text)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
