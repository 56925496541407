import React from "react";
import { Box, Typography, Paper, Divider, Grid } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { formatPrice } from "../../utils/formatters";
import { useTranslation } from "react-i18next";

const IBAN_EUR = "SK49 8330 0000 0020 0303 3982";
const IBAN_CZK = "SK30 8330 0000 0023 0303 3984";

const PaymentConfirmation = ({ orderData, total }) => {
  const { t } = useTranslation();

  if (!orderData) return null;

  const calculateTotalWithDelivery = () => {
    const orderTotal = parseFloat(orderData.total_amount);
    const deliveryPrice = orderData.shipping_provider
      ? parseFloat(orderData.shipping_provider.price)
      : 0;
    const threshold = orderData.shipping_provider
      ? parseFloat(orderData.shipping_provider.treshold)
      : 0;

    return orderTotal < threshold ? orderTotal + deliveryPrice : orderTotal;
  };

  const total_amount = calculateTotalWithDelivery();
  const order_number = orderData.order_number;

  // Get locale from localStorage, default to 'sk'
  const locale = localStorage.getItem("locale")?.toLowerCase() || "sk";
  // Select IBAN based on locale
  const selectedIBAN = locale === "cs" || locale === "cz" ? IBAN_CZK : IBAN_EUR;
  // Select currency based on locale
  const currency = locale === "cs" || locale === "cz" ? "CZK" : "EUR";

  return (
    <Box className="max-w-screen-xl mx-auto px-4 py-8">
      <Paper sx={{ p: 4, maxWidth: 800, mx: "auto" }}>
        <Box display="flex" alignItems="center" gap={2} mb={4}>
          <CheckCircleIcon color="success" sx={{ fontSize: 40 }} />
          <Typography variant="h5">
            {t("Vaša objednávka č.")} {order_number} {t("čaká na zaplatenie")}
          </Typography>
        </Box>

        <Typography variant="h6" gutterBottom>
          {t("Platobné údaje")}
        </Typography>
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} sm={4}>
            <Typography color="text.secondary">{t("Suma")}:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>
              {formatPrice(total.toFixed(2))} {currency}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography color="text.secondary">
              {t("Číslo účtu v tvare IBAN")}:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>{selectedIBAN}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography color="text.secondary">
              {t("Variabilný symbol")}:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>{order_number}</Typography>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom>
          {t("Údaje spoločnosti")}
        </Typography>
        <Box sx={{ mb: 4 }}>
          <Typography>Bilionbuy International s.r.o.</Typography>
          <Typography>Farbiarska 53/29</Typography>
          <Typography>064 01 Stará Ľubovňa</Typography>
          <Typography>Slovenská republika</Typography>
          <Typography>IČO: 56190999</Typography>
          <Typography>DIČ: 2122237392</Typography>
          <Typography>IČ DPH: SK2122237392</Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Typography color="error" sx={{ mb: 4 }}>
          {t(
            "V prípade neuhradenia tejto objednávky do 3 dní, sa táto objednávka automaticky stornuje."
          )}
        </Typography>

        {/* <Typography variant="h6" gutterBottom>
          Garancia spokojnosti
        </Typography> */}
        {/* <Typography>
          Vaša spokojnosť je pre nás prvoradá, preto, ak nedodržíme dobu
          dodania, získate od nás ako ospravedlnenie 5 EUR bonus na ďalší nákup
        </Typography> */}
      </Paper>
    </Box>
  );
};

export default PaymentConfirmation;
