import React from "react";
import { NextUIProvider } from "@nextui-org/react";
import { BrowserRouter, Routes, Route, Router } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Licencing from "./pages/Licencing";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ViewMore from "./pages/ViewMore";
import ProductView from "./pages/ProductView";
import ProductComparison from "./pages/ProductComparison";
import AffiliateLanding from "./pages/AffiliateLanding";
import CookieConsent from "./components/CookieConsent";
import MyAccount from "./pages/MyAccount";
import Wishlist from "./pages/Wishlist";
import TestPage from "./pages/TestPage";
import Dashboard from "./pages/Dashboard";
import MyClicks from "./pages/MyClicks";
import PrivateRoute from "./utils/PrivateRoute";
import { SpeedInsights } from "@vercel/speed-insights/react";
import MyZone from "./pages/MyZone";
import Category from "./pages/Category";
import BlogAdmin from "./pages/BlogAdmin";
import Blogs from "./pages/Blogs";
import Blog from "./pages/Blog";
import TermsAndConditions from "./pages/TermsAndConditions";
import AuthCheck from "./services/AuthCheck";
import Maintanance from "./pages/Maintanance";
import Sentry from "./pages/Sentry";
import { Analytics } from "@vercel/analytics/react";
import ViewShop from "./pages/ViewShop";
import ViewManufacturer from "./pages/ViewManufacturer";
import ViewMoreProducts from "./pages/ViewMoreProducts";
import Shops from "./pages/Shops";
import TravelView from "./pages/TravelView";
import Popup from "./components/Popup";
import LanguageModal from "./components/LanguageModal";
import { NavbarProvider } from "./components/NavbarContext";
import { HelmetProvider } from "react-helmet-async";
import { LeaveModalProvider } from "./components/LeaveModalContext";
import LeaveModal from "./components/LeaveModal";
import BonusModal from "./components/BonusModal";
import { BonusProvider } from "./components/BonusContext";
import RegisterNewShop from "./pages/RegisterNewShop";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import ReferralCodeHandler from "./components/ReferralCodeHandler";
// import { WebviewProvider } from "./providers/WebviewProvider";
import { AdultContentProvider } from "./providers/AdultContentProvider";
import ResetPasswordPage from "./pages/ResetPassword";
import PartnerZone from "./pages/PartnerZone";
import Wallet from "./pages/Wallet";
import Cart from "./pages/Cart";
import { SnackbarProvider } from "notistack";
import PaymentSuccessPage from "./pages/PaymentSuccessPage";
import { CartProvider } from "./contexts/CartContext";
import BrowseProducts from "./pages/BrowseProducts";
import { AdminProvider } from "./contexts/AdminContext";
import PaymentCancelPage from "./pages/PaymentCancelPage";
import Marketplace from "./pages/Marketplace";
import LoyaltyProgram from "./pages/LoyaltyProgram";
import GrowingCashback from "./pages/GrowingCashback";
import RewardsFromEarnings from "./pages/RewardsFromEarnings";
import LanguageInitializer from "./components/LanguageInitializer";

export default function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.documentElement.lang = i18n.language; // Set the lang attribute
  }, [i18n.language]);

  return (
    <HelmetProvider>
      {/* <WebviewProvider> */}
      <NextUIProvider>
        <LeaveModalProvider>
          <AdultContentProvider>
            <NavbarProvider>
              <SnackbarProvider maxSnack={3}>
                <CartProvider>
                  <AdminProvider>
                    <BrowserRouter>
                      <LanguageInitializer />
                      <SpeedInsights />
                      <Analytics />
                      <ReferralCodeHandler>
                        <CookieConsent />
                        <AuthCheck>
                          <Routes>
                            <Route path="/" element={<Layout />}>
                              {/* <Route index element={<Maintanance />} /> */}
                              <Route index element={<Home />} />
                              <Route path="about" element={<About />} />
                              <Route path="shops" element={<Shops />} />
                              <Route path="sentry" element={<Sentry />} />
                              <Route path="cart" element={<Cart />} />
                              <Route
                                path="privacy-policy"
                                element={<PrivacyPolicy />}
                              />
                              <Route path="licensing" element={<Licencing />} />
                              <Route path="contact" element={<Contact />} />
                              <Route path="login" element={<Login />} />
                              <Route path="register" element={<Register />} />
                              <Route
                                path="reset-password/:token"
                                element={<ResetPasswordPage />}
                              />
                              <Route path="view/:id" element={<ViewMore />} />
                              <Route
                                path="category/:id"
                                element={<Category />}
                              />
                              <Route
                                path="register-shop"
                                element={<RegisterNewShop />}
                              />
                              <Route
                                path="view-product/:slug"
                                element={<ProductView />}
                              />
                              <Route
                                path="view-travel/:id"
                                element={<TravelView />}
                              />
                              <Route
                                path="view-food/:id"
                                element={<ProductView />}
                              />
                              <Route
                                path="view-more-products/:id"
                                element={<ViewMoreProducts />}
                              />
                              <Route
                                path="view-shop/:id"
                                element={<ViewShop />}
                              />
                              <Route
                                path="view-manufacturer/:id"
                                element={<ViewManufacturer />}
                              />
                              <Route
                                path="compare/:id"
                                element={<ProductComparison />}
                              />
                              <Route path="test-page" element={<TestPage />} />
                              <Route
                                path="affiliate"
                                element={<AffiliateLanding />}
                              />
                              <Route path="blog" element={<Blogs />} />
                              <Route path="blog/:id" element={<Blog />} />
                              <Route
                                path="terms-and-conditions"
                                element={<TermsAndConditions />}
                              />
                              <Route path="*" element={<NoPage />} />

                              <Route
                                path="my-account"
                                element={
                                  <PrivateRoute element={<MyAccount />} />
                                }
                              />
                              <Route
                                path="wishlist"
                                element={
                                  <PrivateRoute element={<Wishlist />} />
                                }
                              />
                              <Route
                                path="my-clicks"
                                element={
                                  <PrivateRoute element={<MyClicks />} />
                                }
                              />
                              <Route
                                path="my-zone"
                                element={<PrivateRoute element={<MyZone />} />}
                              />
                              <Route
                                path="partner-zone"
                                element={
                                  <PrivateRoute element={<PartnerZone />} />
                                }
                              />
                              <Route
                                path="dashboard"
                                element={
                                  <PrivateRoute element={<Dashboard />} />
                                }
                              />
                              <Route
                                path="blog-admin"
                                element={
                                  <PrivateRoute element={<BlogAdmin />} />
                                }
                              />
                              <Route
                                path="wallet"
                                element={<PrivateRoute element={<Wallet />} />}
                              />
                              <Route
                                path="payment/success"
                                element={<PaymentSuccessPage />}
                              />
                              <Route
                                path="payment/cancel"
                                element={<PaymentCancelPage />}
                              />
                              {/* <Route
                                path="browse-products/:id"
                                element={<BrowseProducts />}
                              />
                              <Route
                                path="browse-products/"
                                element={<BrowseProducts />}
                              /> */}
                              <Route
                                path="loyalty-program"
                                element={<LoyaltyProgram />}
                              />
                              <Route
                                path="marketplace"
                                element={<Marketplace />}
                              />
                              <Route
                                path="growing-cashback"
                                element={<GrowingCashback />}
                              />
                              <Route
                                path="rewards-from-earnings"
                                element={<RewardsFromEarnings />}
                              />
                            </Route>
                          </Routes>
                        </AuthCheck>
                      </ReferralCodeHandler>
                    </BrowserRouter>
                  </AdminProvider>
                </CartProvider>
              </SnackbarProvider>
            </NavbarProvider>
            <LeaveModal />
            {/* <BonusModal /> */}
            {/* </BonusProvider> */}
          </AdultContentProvider>
        </LeaveModalProvider>
      </NextUIProvider>
      {/* </WebviewProvider> */}
    </HelmetProvider>
  );
}

export const routes = (
  <Router>
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/shops" element={<Shops />} />
      <Route path="/sentry" element={<Sentry />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/licensing" element={<Licencing />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
      <Route path="/view/:id" element={<ViewMore />} />
      <Route path="/category/:id" element={<Category />} />
      <Route path="/view-product/:slug" element={<ProductView />} />
      <Route path="/view-travel/:id" element={<TravelView />} />
      <Route path="/view-food/:id" element={<ProductView />} />
      <Route path="/view-more-products/:id" element={<ViewMoreProducts />} />
      <Route path="/view-shop/:id" element={<ViewShop />} />
      <Route path="/view-manufacturer/:id" element={<ViewManufacturer />} />
      <Route path="/affiliate" element={<AffiliateLanding />} />
      <Route path="/blog" element={<Blogs />} />
      <Route path="/blog/:id" element={<Blog />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/my-zone" element={<MyZone />} />
      <Route path="/partner-zone" element={<PartnerZone />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/wallet" element={<Wallet />} />
      <Route path="/payment/success" element={<PaymentSuccessPage />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/browse-products" element={<BrowseProducts />} />
    </Routes>
  </Router>
);
