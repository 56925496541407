import React from "react";
import { Box, Typography, Paper, Grid, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatPrice } from "../../utils/formatters";

const OrderReview = ({
  cartTotals,
  deliveryDetails,
  selectedDelivery,
  selectedPayment,
  deliveryOptions,
  deliveryPrice,
  cart,
  calculateTotal,
  currency,
  t,
}) => {
  if (!cartTotals || !deliveryDetails) {
    return null;
  }

  const total_vat = cartTotals?.total_vat ?? 0;
  const total_amount = cartTotals?.total_amount ?? 0;
  const subtotal = total_amount - total_vat;

  const getDeliveryPrice = () => {
    if (!selectedDelivery || !deliveryOptions.length) return 0;

    const selectedOption = deliveryOptions.find(
      (opt) => opt.name === selectedDelivery
    );
    if (selectedOption) {
      return selectedOption.price;
    }
    return 0;
  };

  return (
    <Box className="max-w-screen-xl mx-auto px-4">
      <Typography variant="h5" gutterBottom>
        {t("Kontrola objednávky")}
      </Typography>

      {/* Products section */}
      <Typography variant="h6" gutterBottom>
        {t("Objednávané produkty")}
      </Typography>
      <Paper sx={{ mb: 4 }}>
        {cart.map((item) => (
          <Box
            key={item.id}
            sx={{
              p: 2,
              borderBottom: "1px solid #eee",
              "&:last-child": { borderBottom: "none" },
            }}
          >
            <Box display="flex" gap={2}>
              <img
                src={item.image}
                alt={item.name}
                style={{ width: 80, height: 80, objectFit: "contain" }}
              />
              <Box flex={1}>
                <Typography variant="h6">{item.name}</Typography>
                <Box display="flex" gap={2} mt={1}>
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      {t("Body")}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color="primary"
                    >
                      {(item.points * item.quantity).toFixed(3)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      {t("Cashback")}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color="success.main"
                    >
                      {formatPrice(item.cashback * item.quantity)} {currency}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box textAlign="right">
                <Typography variant="body2" color="text.secondary">
                  {item.quantity} {t("ks")} × {formatPrice(item.price)}{" "}
                  {currency}
                </Typography>
                <Typography variant="h6" color="primary">
                  {formatPrice(item.price * item.quantity)} {currency}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Paper>

      {/* Delivery Details */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          {t("Dodacie údaje")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="text.secondary">
              {t("Meno a priezvisko")}
            </Typography>
            <Typography>
              {deliveryDetails.firstName} {deliveryDetails.lastName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="text.secondary">
              {t("Kontakt")}
            </Typography>
            <Typography>{deliveryDetails.email}</Typography>
            <Typography>{deliveryDetails.phone}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="text.secondary">
              {t("Adresa doručenia")}
            </Typography>
            <Typography>{deliveryDetails.street}</Typography>
            <Typography>
              {deliveryDetails.postalCode} {deliveryDetails.city}
            </Typography>
          </Grid>
          {deliveryDetails.isCompany && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="text.secondary">
                {t("Fakturačné údaje")}
              </Typography>
              <Typography>{deliveryDetails.companyName}</Typography>
              <Typography>
                {t("IČO")}: {deliveryDetails.ico}
              </Typography>
              <Typography>
                {t("DIČ")}: {deliveryDetails.dic}
              </Typography>
              {deliveryDetails.ic_dph && (
                <Typography>
                  {t("IČ DPH")}: {deliveryDetails.ic_dph}
                </Typography>
              )}
            </Grid>
          )}

          {/* Additional Message */}
          {deliveryDetails.additional_message && (
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ mt: 2 }}
              >
                {t("Poznámka k objednávke")}
              </Typography>
              <Typography style={{ whiteSpace: "pre-wrap" }}>
                {deliveryDetails.additional_message}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>

      {/* Payment and Delivery Method */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          {t("Spôsob doručenia a platby")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="text.secondary">
              {t("Spôsob doručenia")}
            </Typography>
            <Typography>{selectedDelivery}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="text.secondary">
              {t("Spôsob platby")}
            </Typography>
            <Typography>
              {selectedPayment === "card"
                ? "Platba kartou"
                : selectedPayment === "transfer"
                  ? "Bankový prevod"
                  : selectedPayment}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {/* Summary section */}
      <Paper sx={{ p: 3, mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          {t("Súhrn objednávky")}
        </Typography>
        <Box>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography>{t("Suma bez DPH:")}</Typography>
            <Typography>
              {formatPrice(cartTotals?.total_amount - cartTotals?.total_vat)}{" "}
              {currency}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography>{t("DPH")}:</Typography>
            <Typography>
              {formatPrice(cartTotals?.total_vat)} {currency}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography>{t("Doprava s DPH")}:</Typography>
            <Typography>
              {formatPrice(getDeliveryPrice())} {currency}
            </Typography>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">{t("Celkom na úhradu")}:</Typography>
            <Typography variant="h6" color="primary">
              {formatPrice(calculateTotal())} {currency}
            </Typography>
          </Box>
          <Box bgcolor="grey.100" p={2} borderRadius={1} mt={2}>
            <Typography variant="subtitle2" gutterBottom>
              {t("Z tejto objednávky získate:")}
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Typography>{t("Body:")}</Typography>
              <Typography color="primary" fontWeight="bold">
                {cart
                  .reduce((sum, item) => sum + item.points * item.quantity, 0)
                  .toFixed(3)}{" "}
                {t("body")}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography>{t("Cashback:")}</Typography>
              <Typography color="success.main" fontWeight="bold">
                {formatPrice(
                  cart.reduce(
                    (sum, item) => sum + item.cashback * item.quantity,
                    0
                  )
                )}{" "}
                {currency} {t("cashback")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default OrderReview;
