import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Button,
  Chip,
  CircularProgress,
  Typography,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Tooltip,
} from "@mui/material";
import {
  Visibility as VisibilityIcon,
  Search as SearchIcon,
  Refresh as RefreshIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import api, { cancelRequest } from "../../services/api";
import { toast } from "react-toastify";
import AdminCommissionsDetails from "./AdminCommissionsDetails";
import AdminCommissionsCreate from "./AdminCommissionsCreate";
import { debounce } from "lodash";
import axios from "axios";

// Function to format date from YYYY-MM-DD to DD.MM.YYYY
function formatDate(dateString) {
  if (!dateString) return "";
  const [year, month, day] = dateString.split("-");
  return `${day}.${month}.${year}`;
}

const headCells = [
  { id: "status", label: "Status", sortable: false },
  { id: "uzavierkaNr", label: "Číslo uzávierky", sortable: false },
  { id: "period", label: "Obdobie", sortable: false },
  { id: "userEmail", label: "Email používateľa", sortable: false },
  { id: "userName", label: "Meno používateľa", sortable: false },
  { id: "userId", label: "ID používateľa", sortable: false },
  { id: "totalPoints", label: "Celkové body", sortable: false, numeric: true },
  {
    id: "totalRewardClaim",
    label: "Celková odmena (€)",
    sortable: false,
    numeric: true,
  },
  { id: "actions", label: "Akcie", sortable: false },
];

export default function AdminCommissionsTable() {
  const [uzavierky, setUzavierky] = useState([]);
  const [filteredUzavierky, setFilteredUzavierky] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [inputText, setInputText] = useState(""); // Local state for immediate UI feedback
  const [isSearching, setIsSearching] = useState(false); // State to track if search is in progress
  const [statusFilter, setStatusFilter] = useState("all");
  const [monthFilter, setMonthFilter] = useState("all");
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedUzavierkaId, setSelectedUzavierkaId] = useState(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  // Request tracking refs
  const lastRequestUrlRef = useRef(null);
  const lastRequestIdRef = useRef(0);

  // Create a debounced version of setFilterText that only triggers after 500ms of inactivity
  const debouncedSetFilterText = useCallback(
    debounce((value) => {
      // Cancel any in-flight requests
      if (lastRequestUrlRef.current) {
        cancelRequest(lastRequestUrlRef.current);
      }

      setFilterText(value);
      setPage(0); // Reset to first page when changing search
      setIsSearching(false); // End searching state
    }, 500),
    []
  );

  // Handle filter text change with debounce
  const handleFilterChange = (event) => {
    const value = event.target.value;
    setInputText(value); // Update local state immediately for UI feedback
    setIsSearching(true); // Start searching state
    debouncedSetFilterText(value);
  };

  // Fetch uzavierky data
  const fetchUzavierky = async (pageNumber = 1) => {
    setLoading(true);

    // Ensure pageNumber is a number
    const pageNum =
      typeof pageNumber === "number"
        ? pageNumber
        : parseInt(pageNumber, 10) || 1;

    // Cancel previous request if it exists
    if (lastRequestUrlRef.current) {
      console.log(`Canceling previous request: ${lastRequestUrlRef.current}`);
      cancelRequest(lastRequestUrlRef.current);
    }

    // Base API endpoint
    const baseUrl = `/admin-commission-lists/`;
    lastRequestUrlRef.current = baseUrl;

    // Generate a unique request ID
    const requestId = ++lastRequestIdRef.current;
    console.log(
      `Starting request ${requestId} to ${baseUrl} with page=${pageNum}`
    );

    try {
      const response = await api.get(baseUrl, {
        params: {
          page: pageNum,
          page_size: rowsPerPage,
          ...(monthFilter !== "all" && { month: monthFilter }),
          ...(filterText && { search: filterText }),
          ...(statusFilter !== "all" && { status: statusFilter }),
          _requestId: requestId, // Add unique ID to help with debugging
        },
      });

      // Only process the response if this is still the latest request
      if (requestId === lastRequestIdRef.current) {
        // Check if response has the expected structure
        const data = response.data;
        console.log("API Response:", data);

        if (data && typeof data === "object") {
          // Handle the case where results is present (normal pagination response)
          if (Array.isArray(data.results)) {
            setUzavierky(data.results);
            setFilteredUzavierky(data.results);
            setTotalCount(data.count || 0);
            setNextPage(data.next);
            setPreviousPage(data.previous);
            setError(null); // Clear any previous errors
          }
          // Handle case where the API returns a single object or other valid structure
          else if (Object.keys(data).length > 0) {
            // If it's a single item without pagination
            const results = Array.isArray(data) ? data : [data];
            setUzavierky(results);
            setFilteredUzavierky(results);
            setTotalCount(results.length);
            setNextPage(null);
            setPreviousPage(null);
            setError(null); // Clear any previous errors
          }
          // Invalid response structure
          else {
            console.error("Unexpected response structure:", data);
            setError("Neplatná odpoveď zo servera. Prosím, skúste to znova.");
            setUzavierky([]);
            setFilteredUzavierky([]);
            setTotalCount(0);
            setNextPage(null);
            setPreviousPage(null);
          }
        } else {
          console.error("Invalid response data:", data);
          setError("Neplatná odpoveď zo servera. Prosím, skúste to znova.");
          setUzavierky([]);
          setFilteredUzavierky([]);
          setTotalCount(0);
          setNextPage(null);
          setPreviousPage(null);
        }

        setLoading(false);
      } else {
        console.log(
          `Ignoring response for request ${requestId} as it's not the latest`
        );
      }
    } catch (err) {
      // Don't show error if request was canceled
      if (axios.isCancel(err)) {
        console.log("Request canceled:", err.message);
        return;
      }

      // Only process errors if this is still the latest request
      if (requestId === lastRequestIdRef.current) {
        console.error("Chyba pri načítaní uzávierok:", err);
        setError("Nastala chyba pri načítaní dát. Prosím, skúste to znova.");
        setLoading(false);
        toast.error("Nepodarilo sa načítať uzávierky. Skúste to znova neskôr.");
        setUzavierky([]);
        setFilteredUzavierky([]);
        setTotalCount(0);
        setNextPage(null);
        setPreviousPage(null);
      }
    }
  };

  useEffect(() => {
    fetchUzavierky(page + 1); // Convert to 1-based page number for API
  }, [page, rowsPerPage, monthFilter, filterText, statusFilter]);

  // When uzavierky changes (after fetch), just apply current filters without resetting page
  useEffect(() => {
    if (uzavierky.length > 0) {
      // Since filtering is now handled by the backend, we just pass the data through
      setFilteredUzavierky(uzavierky);
    }
  }, [uzavierky]);

  // Cancel any pending requests when component unmounts
  useEffect(() => {
    return () => {
      // Cancel any in-flight requests when unmounting
      if (lastRequestUrlRef.current) {
        console.log(`Cleanup canceling request: ${lastRequestUrlRef.current}`);
        cancelRequest(lastRequestUrlRef.current);
      }
    };
  }, []);

  const handleChangePage = (event, newPage) => {
    // Cancel any in-flight requests
    if (lastRequestUrlRef.current) {
      cancelRequest(lastRequestUrlRef.current);
    }

    // Ensure newPage is a number
    const pageNum = parseInt(newPage, 10) || 0;
    setPage(pageNum);

    // Don't reset filtered results when changing page
  };

  const handleChangeRowsPerPage = (event) => {
    // Cancel any in-flight requests
    if (lastRequestUrlRef.current) {
      cancelRequest(lastRequestUrlRef.current);
    }

    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page when changing rows per page
  };

  const handleStatusFilterChange = (event) => {
    // Cancel any in-flight requests
    if (lastRequestUrlRef.current) {
      cancelRequest(lastRequestUrlRef.current);
    }

    setStatusFilter(event.target.value);
    setPage(0); // Reset to first page when changing status filter
  };

  const handleMonthFilterChange = (event) => {
    // Cancel any in-flight requests
    if (lastRequestUrlRef.current) {
      cancelRequest(lastRequestUrlRef.current);
    }

    setMonthFilter(event.target.value);
    setPage(0); // Reset to first page when changing month filter
  };

  const handleViewDetails = (id) => {
    setSelectedUzavierkaId(id);
    setDetailsOpen(true);
  };

  const handleCloseDetails = () => {
    setDetailsOpen(false);
    setSelectedUzavierkaId(null);
  };

  // Refresh functionality
  const handleRefresh = () => {
    // Cancel any in-flight requests
    if (lastRequestUrlRef.current) {
      cancelRequest(lastRequestUrlRef.current);
    }

    fetchUzavierky();
    toast.info("Obnovujem zoznam uzávierok...");
  };

  // Create new uzávierka
  const handleCreateNew = () => {
    setCreateDialogOpen(true);
  };

  const handleCreateDialogClose = () => {
    setCreateDialogOpen(false);
  };

  const handleCreateSuccess = () => {
    fetchUzavierky(page + 1);
  };

  // Render the table
  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Správa uzávierok
        </Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Tooltip title="Obnoviť">
            <IconButton onClick={handleRefresh} color="default" size="small">
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleCreateNew}
            size="small"
            disabled
          >
            Nová uzávierka
          </Button>
        </Box>
      </Box>

      {/* Filters */}
      <Paper
        elevation={0}
        sx={{
          p: 2,
          mb: 2,
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 2,
          borderRadius: 1,
          border: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: 250,
            position: "relative",
          }}
        >
          <SearchIcon sx={{ color: "action.active", mr: 1 }} />
          <TextField
            size="small"
            placeholder="Vyhľadávanie..."
            variant="outlined"
            fullWidth
            value={inputText}
            onChange={handleFilterChange}
            InputProps={{
              endAdornment: isSearching && (
                <CircularProgress color="inherit" size={20} sx={{ mr: 1 }} />
              ),
            }}
          />
        </Box>
        <FormControl sx={{ minWidth: 200 }} size="small">
          <InputLabel id="status-filter-label">Status</InputLabel>
          <Select
            labelId="status-filter-label"
            id="status-filter"
            value={statusFilter}
            label="Status"
            onChange={handleStatusFilterChange}
          >
            <MenuItem value="all">Všetky statusy</MenuItem>
            <MenuItem value="created">Vytvorená</MenuItem>
            <MenuItem value="published">Započítaná</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 200 }} size="small">
          <InputLabel id="month-filter-label">Mesiac</InputLabel>
          <Select
            labelId="month-filter-label"
            id="month-filter"
            value={monthFilter}
            label="Mesiac"
            onChange={handleMonthFilterChange}
          >
            <MenuItem value="all">Všetky mesiace</MenuItem>
            <MenuItem value="01">Január</MenuItem>
            <MenuItem value="02">Február</MenuItem>
            <MenuItem value="03">Marec</MenuItem>
            <MenuItem value="04">Apríl</MenuItem>
            <MenuItem value="05">Máj</MenuItem>
            <MenuItem value="06">Jún</MenuItem>
            <MenuItem value="07">Júl</MenuItem>
            <MenuItem value="08">August</MenuItem>
            <MenuItem value="09">September</MenuItem>
            <MenuItem value="10">Október</MenuItem>
            <MenuItem value="11">November</MenuItem>
            <MenuItem value="12">December</MenuItem>
          </Select>
        </FormControl>
      </Paper>

      {/* Table */}
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          borderRadius: 1,
          border: (theme) => `1px solid ${theme.palette.divider}`,
          overflow: "hidden",
        }}
      >
        <TableContainer sx={{ maxHeight: "calc(100vh - 300px)" }}>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Box sx={{ p: 3, textAlign: "center", color: "error.main" }}>
              <Typography>{error}</Typography>
              <Button
                variant="contained"
                onClick={fetchUzavierky}
                sx={{ mt: 2 }}
              >
                Skúsiť znova
              </Button>
            </Box>
          ) : (
            <Table stickyHeader aria-label="uzávierky tabuľka">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? "right" : "left"}
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                        fontWeight: 600,
                      }}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : error ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <Typography color="error">{error}</Typography>
                      <Button
                        variant="contained"
                        onClick={fetchUzavierky}
                        sx={{ mt: 2 }}
                      >
                        Skúsiť znova
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : filteredUzavierky.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <Typography>Žiadne uzávierky neboli nájdené</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredUzavierky.map((row) => (
                    <TableRow hover key={row.id}>
                      <TableCell>
                        <Chip
                          label={
                            row.status === "published"
                              ? "Započítaná"
                              : row.status === "closed"
                                ? "Uzavretá"
                                : "Vytvorená"
                          }
                          color={
                            row.status === "published"
                              ? "success"
                              : row.status === "closed"
                                ? "warning"
                                : "default"
                          }
                          size="small"
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell>{row.uzavierkaNr}</TableCell>
                      <TableCell>
                        {formatDate(
                          row.startDate ? row.startDate.split("T")[0] : ""
                        )}{" "}
                        -{" "}
                        {formatDate(
                          row.endDate ? row.endDate.split("T")[0] : ""
                        )}
                      </TableCell>
                      <TableCell>{row.userEmail}</TableCell>
                      <TableCell>{row.userName}</TableCell>
                      <TableCell>{row.userId}</TableCell>
                      <TableCell align="right">{row.totalPoints}</TableCell>
                      <TableCell align="right">
                        {row.totalRewardClaim} €
                      </TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={() => handleViewDetails(row.id)}
                          aria-label="zobraziť detaily"
                          color="primary"
                        >
                          <VisibilityIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <TablePagination
          component="div"
          count={totalCount}
          page={parseInt(page, 10) || 0}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />
      </Paper>

      {/* Details Dialog */}
      <AdminCommissionsDetails
        open={detailsOpen}
        onClose={handleCloseDetails}
        uzavierkaId={selectedUzavierkaId}
      />

      {/* Create Dialog */}
      <AdminCommissionsCreate
        open={createDialogOpen}
        onClose={handleCreateDialogClose}
        onSuccess={handleCreateSuccess}
      />
    </Box>
  );
}
