import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";
import { Select, SelectItem } from "@nextui-org/react";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import api from "../services/api";

const countries = [
  {
    key: "sk",
    label: "Slovakia",
    icon: <ReactCountryFlag countryCode="SK" />,
    locale: "sk",
  },
  {
    key: "cz",
    label: "Czech Republic",
    icon: <ReactCountryFlag countryCode="CZ" />,
    locale: "cs",
  },
  // {
  //   key: "pl",
  //   label: "Poland",
  //   icon: <ReactCountryFlag countryCode="PL" />,
  //   locale: "pl",
  // },
  // {
  //   key: "hu",
  //   label: "Hungary",
  //   icon: <ReactCountryFlag countryCode="HU" />,
  //   locale: "hu",
  // },
  // {
  //   key: "ro",
  //   label: "Romania",
  //   icon: <ReactCountryFlag countryCode="RO" />,
  //   locale: "ro",
  // },
  // {
  //   key: "bg",
  //   label: "Bulgaria",
  //   icon: <ReactCountryFlag countryCode="BG" />,
  //   locale: "bg",
  // },
  // {
  //   key: "at",
  //   label: "Austria",
  //   icon: <ReactCountryFlag countryCode="AT" />,
  //   locale: "de-AT",
  // },
  // {
  //   key: "de",
  //   label: "Germany",
  //   icon: <ReactCountryFlag countryCode="DE" />,
  //   locale: "de",
  // },
  // {
  //   key: "it",
  //   label: "Italy",
  //   icon: <ReactCountryFlag countryCode="IT" />,
  //   locale: "it",
  // },
  // {
  //   key: "fr",
  //   label: "France",
  //   icon: <ReactCountryFlag countryCode="FR" />,
  //   locale: "fr",
  // },
  // {
  //   key: "es",
  //   label: "Spain",
  //   icon: <ReactCountryFlag countryCode="ES" />,
  //   locale: "es",
  // },
  // {
  //   key: "hr",
  //   label: "Croatia",
  //   icon: <ReactCountryFlag countryCode="HR" />,
  //   locale: "hr",
  // },
  // {
  //   key: "rs",
  //   label: "Serbia",
  //   icon: <ReactCountryFlag countryCode="RS" />,
  //   locale: "sr",
  // },
  // {
  //   key: "si",
  //   label: "Slovenia",
  //   icon: <ReactCountryFlag countryCode="SI" />,
  //   locale: "sl",
  // },
  // {
  //   key: "nl",
  //   label: "Netherlands",
  //   icon: <ReactCountryFlag countryCode="NL" />,
  //   locale: "nl",
  // },
  // {
  //   key: "be",
  //   label: "Belgium",
  //   icon: <ReactCountryFlag countryCode="BE" />,
  //   locale: "nl-BE",
  // },
  // {
  //   key: "pt",
  //   label: "Portugal",
  //   icon: <ReactCountryFlag countryCode="PT" />,
  //   locale: "pt",
  // },
  // {
  //   key: "gr",
  //   label: "Greece",
  //   icon: <ReactCountryFlag countryCode="GR" />,
  //   locale: "el",
  // },
  // {
  //   key: "se",
  //   label: "Sweden",
  //   icon: <ReactCountryFlag countryCode="SE" />,
  //   locale: "sv",
  // },
  // {
  //   key: "dk",
  //   label: "Denmark",
  //   icon: <ReactCountryFlag countryCode="DK" />,
  //   locale: "da",
  // },
  // {
  //   key: "fi",
  //   label: "Finland",
  //   icon: <ReactCountryFlag countryCode="FI" />,
  //   locale: "fi",
  // },
  // {
  //   key: "no",
  //   label: "Norway",
  //   icon: <ReactCountryFlag countryCode="NO" />,
  //   locale: "no",
  // },
  // {
  //   key: "ch",
  //   label: "Switzerland",
  //   icon: <ReactCountryFlag countryCode="CH" />,
  //   locale: "de-CH",
  // },
  // {
  //   key: "ie",
  //   label: "Ireland",
  //   icon: <ReactCountryFlag countryCode="IE" />,
  //   locale: "en-IE",
  // },
  // {
  //   key: "uk",
  //   label: "United Kingdom",
  //   icon: <ReactCountryFlag countryCode="GB" />,
  //   locale: "en-GB",
  // },
  // {
  //   key: "cn",
  //   label: "China",
  //   icon: <ReactCountryFlag countryCode="CN" />,
  //   locale: "zh",
  // },
  // {
  //   key: "ca",
  //   label: "Canada",
  //   icon: <ReactCountryFlag countryCode="CA" />,
  //   locale: "en-CA",
  // },
  // {
  //   key: "us",
  //   label: "USA",
  //   icon: <ReactCountryFlag countryCode="US" />,
  //   locale: "en-US",
  // },
];

const languages = [
  {
    key: "sk",
    label: "Slovak",
    icon: <ReactCountryFlag countryCode="SK" />,
  },
  {
    key: "cs",
    label: "Czech",
    icon: <ReactCountryFlag countryCode="CZ" />,
  },
  {
    key: "pl",
    label: "Polish",
    icon: <ReactCountryFlag countryCode="PL" />,
  },
  {
    key: "de",
    label: "Deutsch",
    icon: <ReactCountryFlag countryCode="DE" />,
  },
  {
    key: "en",
    label: "English",
    icon: <ReactCountryFlag countryCode="GB" />,
  },
  {
    key: "hu",
    label: "Magyar",
    icon: <ReactCountryFlag countryCode="HU" />,
  },
];

export default function LanguageModal({ openModal, onClose }) {
  const [isOpen, setIsOpen] = useState(openModal);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [countryError, setCountryError] = useState("");
  const [languageError, setLanguageError] = useState("");
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const locale = localStorage.getItem("locale");

  const findCountryByLocale = (locale) => {
    const country = countries.find((c) => c.locale === locale);
    return country ? country.key : null;
  };

  const changeLanguage = (lng) => {
    if (typeof lng === "string") {
      console.log(`Changing language to: ${lng}`);
      i18n.changeLanguage(lng);
    } else {
      console.error("Invalid language code:", lng);
    }
  };

  useEffect(() => {
    if (locale) {
      const countryKey = findCountryByLocale(locale);
      setSelectedCountry(countryKey);
    }
    setSelectedLanguage(i18n.language);
  }, [locale, i18n.language]);

  const handleCountryChange = (keys) => {
    const selectedKey = Array.from(keys)[0];
    setSelectedCountry(selectedKey);
    setCountryError(""); // Clear error on change

    const selectedCountryObj = countries.find(
      (country) => country.key === selectedKey
    );
    if (selectedCountryObj) {
      // Only set locale, do not change language
      localStorage.setItem("locale", selectedCountryObj.locale);
    }
  };

  const handleLanguageChange = (keys) => {
    const selectedKey = Array.from(keys)[0];
    setSelectedLanguage(selectedKey);
    setLanguageError(""); // Clear error on change

    // Only update language, not locale
    localStorage.setItem("i18nextLng", selectedKey);
  };

  const handleChooseClick = () => {
    let valid = true;

    if (!selectedCountry) {
      setCountryError(t("country_required"));
      valid = false;
    }

    if (!selectedLanguage) {
      setLanguageError(t("language_required"));
      valid = false;
    }

    if (valid) {
      // Clear cart items when locale changes using the api service
      api
        .post("/eshop/cart/clear_items/")
        .then(() => {
          // Only apply language change, locale is already set in handleCountryChange
          if (selectedLanguage !== i18n.language) {
            changeLanguage(selectedLanguage);
          }
          setIsOpen(false);
          onClose();
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error clearing cart items:", error);
          // Continue with language change even if cart clearing fails
          if (selectedLanguage !== i18n.language) {
            changeLanguage(selectedLanguage);
          }
          setIsOpen(false);
          onClose();
          window.location.reload();
        });
    }
  };

  useEffect(() => {
    setIsOpen(openModal);
  }, [openModal]);

  const renderSelectedCountry = (items) => {
    return items.map((item) => {
      const selected = countries.find((c) => c.key === item.key);
      return (
        <div key={selected.key} className="flex items-center gap-2">
          {selected.icon}
          <span>{selected.label}</span>
        </div>
      );
    });
  };

  const renderSelectedLanguage = (items) => {
    return items.map((item) => {
      const selected = languages.find((l) => l.key === item.key);
      return (
        <div key={selected.key} className="flex items-center gap-2">
          {selected.icon}
          <span>{selected.label}</span>
        </div>
      );
    });
  };

  return (
    <Modal
      backdrop="opaque"
      isOpen={isOpen}
      onClose={() => {}}
      closeButton={false}
      preventClose
      motionProps={{
        variants: {
          enter: {
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.3,
              ease: "easeOut",
            },
          },
          exit: {
            y: -20,
            opacity: 0,
            transition: {
              duration: 0.2,
              ease: "easeIn",
            },
          },
        },
      }}
    >
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          {t("choose_locale")}
        </ModalHeader>
        <ModalBody>
          <Select
            label={t("choose_country")}
            className="w-full"
            variant="bordered"
            selectionMode="single"
            selectedKeys={selectedCountry ? [selectedCountry] : []}
            onSelectionChange={handleCountryChange}
            placeholder="Select a country"
            renderValue={renderSelectedCountry}
          >
            {countries.map((country) => (
              <SelectItem key={country.key} value={country.key}>
                <div className="flex items-center gap-2">
                  {country.icon}
                  {country.label}
                </div>
              </SelectItem>
            ))}
          </Select>
          {countryError && <p className="text-red-500">{countryError}</p>}

          <Select
            label={t("choose_language")}
            className="w-full"
            variant="bordered"
            selectionMode="single"
            selectedKeys={selectedLanguage ? [selectedLanguage] : []}
            onSelectionChange={handleLanguageChange}
            placeholder="Select a language"
            renderValue={renderSelectedLanguage}
          >
            {languages.map((language) => (
              <SelectItem key={language.key} value={language.key}>
                <div className="flex items-center gap-2">
                  {language.icon}
                  {language.label}
                </div>
              </SelectItem>
            ))}
          </Select>
          {languageError && <p className="text-red-500">{languageError}</p>}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" variant="solid" onPress={handleChooseClick}>
            {t("choose")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
