/**
 * Formats a number as a price with proper thousands separators
 * @param {number} value - The price to format
 * @param {string} [currency] - Optional currency symbol to append
 * @param {number} [decimals=2] - Number of decimal places
 * @return {string} - Formatted price string
 */
export function formatPrice(value, currency = "", decimals = 2) {
  if (value === null || value === undefined) return "";

  // Convert to number if it's a string
  const numValue = typeof value === "string" ? parseFloat(value) : value;

  // Check if it's a valid number
  if (isNaN(numValue)) return "";

  // Format with fixed decimal places and replace dot with comma
  const withDecimals = numValue.toFixed(decimals).replace(".", ",");

  // Add thousands separators (spaces)
  const parts = withDecimals.split(",");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  // Check if decimal part is all zeros and remove if true
  if (parts[1] && parts[1].replace(/0/g, "") === "") {
    // Just use the integer part without the decimal separator
    return currency ? `${parts[0]} ${currency}` : parts[0];
  }

  // Join back with comma and add currency if provided
  const formatted = parts.join(",");
  return currency ? `${formatted} ${currency}` : formatted;
}

export function getDeliveryText(deliveryDate) {
  if (!deliveryDate && deliveryDate !== 0) {
    return "info v obchode";
  }

  const date = parseInt(deliveryDate);

  if (date === 0) {
    return "skladom";
  } else if (date >= 1 && date <= 3) {
    return "do 3 dní";
  } else if (date >= 4 && date <= 7) {
    return "do týždňa";
  } else if (date >= 8 && date <= 14) {
    return "do 2 týždňov";
  } else if (date >= 15 && date <= 30) {
    return "do mesiaca";
  } else if (date >= 31) {
    return "viac ako mesiac";
  } else {
    return "info v obchode";
  }
}
