/* global google */

import React, { useEffect } from "react";
import api from "../services/api";

const GoogleSignIn = ({ onSignInSuccess, onSignInFailure }) => {
  // Add function to check for webview
  const isWebView = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return (
      userAgent.includes("instagram") ||
      userAgent.includes("fb_iab") || // Facebook in-app browser
      userAgent.includes("messenger") ||
      userAgent.includes("wkwebview") || // iOS WebView
      userAgent.includes("electron") ||
      /.*fbav.*/.test(userAgent) // Another way Facebook app appears
    );
  };

  useEffect(() => {
    // If it's a webview, don't initialize Google Sign-In
    if (isWebView()) {
      return;
    }

    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = () => {
      google.accounts.id.initialize({
        client_id:
          "593791051-jhbs8jdfg74ooivv186tad408nv6dirv.apps.googleusercontent.com",
        callback: onSignInResponse,
      });
      google.accounts.id.renderButton(
        document.getElementById("googleSignInButton"),
        { theme: "outline", size: "large" }
      );
      google.accounts.id.prompt();
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // If it's a webview, don't render the component
  if (isWebView()) {
    return null;
  }

  const onSignInResponse = (response) => {
    const id_token = response.credential;
    api
      .post("google-sign-in/", {
        token: id_token,
        referralCode: localStorage.getItem("referralCode"),
      })
      .then((response) => {
        const data = response.data;
        localStorage.setItem("jwt", data.token);
        localStorage.setItem("user_image", data.img_url);
        localStorage.setItem("user_permissions", data.user_permissions);
        localStorage.setItem("username", data.username);

        // Dispatch login event after setting localStorage items
        window.dispatchEvent(new Event("user-login"));

        if (onSignInSuccess) {
          onSignInSuccess(data); // Call the parent-provided success callback
        }
      })
      .catch((error) => {
        console.error("Error during sign-in:", error);
        if (onSignInFailure) {
          onSignInFailure(error); // Call the parent-provided failure callback
        }
      });
  };

  // const handleReferal = () => {
  //   const queryParams = new URLSearchParams(window.location.search);
  //   const ref = queryParams.get("ref");

  //   if (ref) {
  //     localStorage.setItem("referralCode", ref);
  //     console.log("Referral: ", ref);
  //   }
  // };

  // useEffect(() => {
  //   handleReferal();
  // }, []);

  return (
    <div
      id="googleSignInButton"
      style={{ marginTop: "20px", textAlign: "center" }}
    >
      {/* Button is rendered by Google's script */}
    </div>
  );
};

export default GoogleSignIn;
