import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Chip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  CircularProgress,
  Skeleton,
  TablePagination,
} from "@mui/material";
import {
  Edit as EditIcon,
  Visibility as ViewIcon,
  LocalShipping as ShippingIcon,
  Done as DoneIcon,
  Search as SearchIcon,
  Payment as PaymentIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Save as SaveIcon,
  Close as CloseIcon,
  Upload as UploadIcon,
  Receipt as ReceiptIcon,
} from "@mui/icons-material";
import api from "../../../services/api";
import { ALL_COUNTRIES } from "../../../data/countries";
import debounce from "lodash/debounce";

import { formatPrice } from "../../../utils/formatters";

const STATUS_COLORS = {
  pending: "warning",
  paid: "info",
  invoiced: "secondary",
  shipped: "primary",
  delivered: "success",
  cancelled: "error",
};

const STATUS_LABELS = {
  pending: "Čaká na platbu",
  paid: "Zaplatené",
  invoiced: "Faktúra odoslaná",
  shipped: "Odoslané",
  delivered: "Doručené",
  cancelled: "Zrušené",
};

const PAYMENT_METHOD_LABELS = {
  transfer: "Bankový prevod",
  card: "Platobná karta",
};

const countryFlags = {
  SK: "🇸🇰",
  CZ: "🇨🇿",
  all: "🌍",
};

const CARRIER_OPTIONS = [
  { value: "DPD", label: "DPD" },
  { value: "Osobný odber", label: "Osobný odber" },
  { value: "Bilionbuy", label: "Bilionbuy" },
];

const renderCarrierWithCountry = (option) => {
  const flag = countryFlags[option.country] || countryFlags.all;
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <span>{flag}</span>
      <span>{option.name}</span>
    </Box>
  );
};

const TableSkeleton = () => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Číslo objednávky</TableCell>
          <TableCell>Dátum</TableCell>
          <TableCell>Zákazník</TableCell>
          <TableCell>Suma</TableCell>
          <TableCell>Stav</TableCell>
          <TableCell>Tracking číslo</TableCell>
          <TableCell>Akcie</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {[...Array(5)].map((_, index) => (
          <TableRow key={index}>
            <TableCell>
              <Skeleton width={100} />
            </TableCell>
            <TableCell>
              <Skeleton width={80} />
            </TableCell>
            <TableCell>
              <Skeleton width={150} />
            </TableCell>
            <TableCell>
              <Skeleton width={70} />
            </TableCell>
            <TableCell>
              <Skeleton
                width={100}
                height={32}
                variant="rectangular"
                sx={{ borderRadius: 1 }}
              />
            </TableCell>
            <TableCell>
              <Skeleton width={120} />
            </TableCell>
            <TableCell>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton variant="circular" width={30} height={30} />
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default function EshopOrdersTable() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [filters, setFilters] = useState({
    orderNumber: "",
    customerName: "",
    status: "all",
  });
  const [shippingDialogOpen, setShippingDialogOpen] = useState(false);
  const [selectedOrderForShipping, setSelectedOrderForShipping] =
    useState(null);
  const [shippingData, setShippingData] = useState({
    shipments: [{ provider: "", tracking_number: "", estimated_delivery: "" }],
  });
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isEditingTracking, setIsEditingTracking] = useState(false);
  const [editedTrackingData, setEditedTrackingData] = useState({
    shipments: [{ provider: "", tracking_number: "", estimated_delivery: "" }],
  });
  const [deliveryConfirmationOpen, setDeliveryConfirmationOpen] =
    useState(false);
  const [orderToMarkDelivered, setOrderToMarkDelivered] = useState(null);
  const [invoiceUploadDialogOpen, setInvoiceUploadDialogOpen] = useState(false);
  const [selectedOrderForInvoice, setSelectedOrderForInvoice] = useState(null);
  const [selectedInvoiceFile, setSelectedInvoiceFile] = useState(null);
  const [uploadingInvoice, setUploadingInvoice] = useState(false);
  const [invoiceUploadError, setInvoiceUploadError] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState({
    action: "",
    orderId: null,
    orderNumber: "",
  });

  const debouncedCustomerFilter = useCallback(
    debounce((value) => {
      setFilters((prev) => ({
        ...prev,
        customerName: value,
      }));
    }, 500),
    []
  );

  const [customerNameInput, setCustomerNameInput] = useState("");

  useEffect(() => {
    setCustomerNameInput(filters.customerName);
  }, [filters.customerName]);

  useEffect(() => {
    return () => {
      debouncedCustomerFilter.cancel();
    };
  }, [debouncedCustomerFilter]);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams();
      queryParams.append("page", page + 1);
      queryParams.append("page_size", rowsPerPage);

      if (filters.orderNumber) {
        queryParams.append("order_number", filters.orderNumber);
      }
      if (filters.customerName) {
        queryParams.append("customer_name", filters.customerName);
      }
      if (filters.status && filters.status !== "all") {
        queryParams.append("status", filters.status);
      }

      const response = await api.get(
        `/eshop/orders/?${queryParams.toString()}`
      );
      setOrders(response.data.results || []);
      setTotalCount(response.data.count || 0);
    } catch (error) {
      console.error("Failed to fetch orders:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDeliveryOptions = async () => {
    try {
      const response = await api.get("eshop/delivery-options/");
      if (response.data && Array.isArray(response.data.results)) {
        setDeliveryOptions(response.data.results);
      } else if (Array.isArray(response.data)) {
        setDeliveryOptions(response.data);
      }
    } catch (error) {
      console.error("Error fetching delivery options:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
    fetchDeliveryOptions();
  }, [filters, page, rowsPerPage]);

  const handleStatusChange = async (orderId, newStatus) => {
    const order = orders.find((o) => o.id === orderId);

    if (newStatus === "mark_shipped") {
      setSelectedOrderForShipping(order);
      setShippingData({
        shipments: order.tracking_number || [
          { provider: "", tracking_number: "", estimated_delivery: "" },
        ],
      });
      setShippingDialogOpen(true);
    } else if (newStatus === "mark_delivered") {
      setOrderToMarkDelivered(order);
      setDeliveryConfirmationOpen(true);
    } else if (newStatus === "mark_paid") {
      setConfirmAction({
        action: "mark_paid",
        orderId: orderId,
        orderNumber: order.order_number,
      });
      setConfirmDialogOpen(true);
    } else {
      setConfirmAction({
        action: newStatus,
        orderId: orderId,
        orderNumber: order.order_number,
      });
      setConfirmDialogOpen(true);
    }
  };

  const handleShippingSubmit = async () => {
    // This function is now unused as we're using the callback approach
    try {
      await api.post(
        `/eshop/orders/${selectedOrderForShipping.id}/mark_shipped/`,
        { shipments: shippingData.shipments }
      );
      setShippingDialogOpen(false);
      setSelectedOrderForShipping(null);
      setShippingData({
        shipments: [
          { provider: "", tracking_number: "", estimated_delivery: "" },
        ],
      });
      fetchOrders();
    } catch (error) {
      console.error("Failed to mark order as shipped:", error);
    }
  };

  const handleViewDetails = (order) => {
    setSelectedOrder(order);
    setDialogOpen(true);
  };

  const handleFilterChange = (field) => (event) => {
    setFilters((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setIsEditingTracking(false);
    setEditedTrackingData({
      shipments: [
        { provider: "", tracking_number: "", estimated_delivery: "" },
      ],
    });
  };

  const handleInvoiceUpload = (order) => {
    setSelectedOrderForInvoice(order);
    setSelectedInvoiceFile(null);
    setInvoiceUploadError("");
    setInvoiceUploadDialogOpen(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedInvoiceFile(file);
      setInvoiceUploadError("");
    } else {
      setSelectedInvoiceFile(null);
      setInvoiceUploadError("Prosím vyberte PDF súbor");
    }
  };

  const handleInvoiceSubmit = async () => {
    if (!selectedInvoiceFile || !selectedOrderForInvoice) return;

    setUploadingInvoice(true);
    setInvoiceUploadError("");

    try {
      const formData = new FormData();
      formData.append("file", selectedInvoiceFile);
      formData.append("order_id", selectedOrderForInvoice.id);

      await api.post("/eshop/upload-invoice/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setInvoiceUploadDialogOpen(false);
      setSelectedOrderForInvoice(null);
      setSelectedInvoiceFile(null);
      fetchOrders();
    } catch (error) {
      console.error("Failed to upload invoice:", error);
      setInvoiceUploadError(
        error.response?.data?.message || "Nastala chyba pri nahrávaní faktúry"
      );
    } finally {
      setUploadingInvoice(false);
    }
  };

  const handleConfirmAction = async () => {
    try {
      if (confirmAction.callback) {
        const result = await confirmAction.callback();
        if (result === false) return;
      } else {
        await api.post(
          `/eshop/orders/${confirmAction.orderId}/${confirmAction.action}/`
        );
        fetchOrders();
      }
    } catch (error) {
      console.error(`Failed to perform action ${confirmAction.action}:`, error);
    } finally {
      setConfirmDialogOpen(false);
    }
  };

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Číslo objednávky"
              variant="outlined"
              size="small"
              value={filters.orderNumber}
              onChange={handleFilterChange("orderNumber")}
              InputProps={{
                startAdornment: (
                  <SearchIcon sx={{ color: "action.active", mr: 1 }} />
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Zákazník"
              variant="outlined"
              size="small"
              value={customerNameInput}
              onChange={(e) => {
                const value = e.target.value;
                setCustomerNameInput(value);
                debouncedCustomerFilter(value);
              }}
              InputProps={{
                startAdornment: (
                  <SearchIcon sx={{ color: "action.active", mr: 1 }} />
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Stav objednávky</InputLabel>
              <Select
                value={filters.status}
                label="Stav objednávky"
                onChange={handleFilterChange("status")}
              >
                <MenuItem value="all">Všetky stavy</MenuItem>
                {Object.entries(STATUS_LABELS).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {loading ? (
        <>
          <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-end" }}>
            <Skeleton width={250} height={40} />
          </Box>
          <TableSkeleton />
          <Box sx={{ mt: 2 }}>
            <Skeleton width="100%" height={52} />
          </Box>
        </>
      ) : orders.length > 0 ? (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Číslo objednávky</TableCell>
                  <TableCell>Dátum</TableCell>
                  <TableCell>Zákazník</TableCell>
                  <TableCell>Suma</TableCell>
                  <TableCell>Stav</TableCell>
                  <TableCell>Tracking číslo</TableCell>
                  <TableCell>Akcie</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.order_number}</TableCell>
                    <TableCell>
                      {new Date(order.created_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {order.shipping_first_name
                        ? `${order.shipping_first_name} ${order.shipping_last_name}`
                        : order.shipping_name}
                      <br />
                      {order.shipping_email}
                    </TableCell>
                    <TableCell>
                      {formatPrice(order.total_amount)} {order.currency}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={STATUS_LABELS[order.status]}
                        color={STATUS_COLORS[order.status]}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      {order.tracking_number
                        ? order.tracking_number.map((tracking, index) => (
                            <Chip
                              key={index}
                              label={`${tracking.provider}: ${tracking.tracking_number}`}
                              size="small"
                              variant="outlined"
                              sx={{ mr: 1, mb: 1 }}
                            />
                          ))
                        : "-"}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => handleViewDetails(order)}
                      >
                        <ViewIcon />
                      </IconButton>
                      {order.status === "pending" && (
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleStatusChange(order.id, "mark_paid")
                          }
                        >
                          <PaymentIcon />
                        </IconButton>
                      )}
                      {order.status === "paid" && (
                        <IconButton
                          size="small"
                          onClick={() => handleInvoiceUpload(order)}
                        >
                          <ReceiptIcon />
                        </IconButton>
                      )}
                      {order.status === "invoiced" && (
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleStatusChange(order.id, "mark_shipped")
                          }
                        >
                          <ShippingIcon />
                        </IconButton>
                      )}
                      {order.status === "shipped" && (
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleStatusChange(order.id, "mark_delivered")
                          }
                        >
                          <DoneIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 20, 50]}
            labelRowsPerPage="Počet na stránku"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} z ${count !== -1 ? count : `viac ako ${to}`}`
            }
          />
        </Paper>
      ) : (
        <Box sx={{ textAlign: "center", my: 3 }}>
          <Typography color="text.secondary">
            Neboli nájdené žiadne objednávky
          </Typography>
        </Box>
      )}

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        {selectedOrder && (
          <>
            <DialogTitle>
              {loading ? (
                <Skeleton width={200} />
              ) : (
                `Objednávka #${selectedOrder.order_number}`
              )}
            </DialogTitle>
            <DialogContent>
              {loading ? (
                <Box sx={{ mt: 2 }}>
                  <Skeleton height={30} width={150} />
                  <Skeleton height={100} />
                  <Skeleton height={30} width={150} sx={{ mt: 2 }} />
                  <Skeleton height={100} />
                  <Skeleton height={30} width={150} sx={{ mt: 2 }} />
                  <Skeleton height={200} />
                </Box>
              ) : (
                <>
                  <Typography variant="h6">Dodacie údaje</Typography>
                  <Typography>
                    {selectedOrder.shipping_name
                      ? selectedOrder.shipping_name
                      : `${selectedOrder.shipping_first_name} ${selectedOrder.shipping_last_name}`}
                    <br />
                    {selectedOrder.shipping_address}
                    <br />
                    {selectedOrder.shipping_postal_code}{" "}
                    {selectedOrder.shipping_city}
                    <br />
                    {
                      ALL_COUNTRIES.find(
                        (country) =>
                          country.code === selectedOrder.shipping_country
                      )?.name
                    }
                    <br />
                    {selectedOrder.shipping_email}
                    <br />
                    {selectedOrder.shipping_phone}
                  </Typography>

                  {selectedOrder.company_name && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="h6">Firemné údaje</Typography>
                      <Typography>
                        {selectedOrder.company_name}
                        <br />
                        IČO: {selectedOrder.company_id}
                        <br />
                        DIČ: {selectedOrder.tax_id}
                        {selectedOrder.vat_id && (
                          <>
                            <br />
                            IČ DPH: {selectedOrder.vat_id}
                          </>
                        )}
                      </Typography>
                    </Box>
                  )}

                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6">Položky</Typography>

                    {/* BilionBuy Products */}
                    {selectedOrder.items?.some((item) => !item.is_bigbuy) && (
                      <>
                        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                          Produkty z nášho skladu
                        </Typography>
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Produkt</TableCell>
                                <TableCell align="right">Množstvo</TableCell>
                                <TableCell align="right">Cena</TableCell>
                                <TableCell align="right">Spolu</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedOrder.items
                                ?.filter((item) => !item.is_bigbuy)
                                .map((item) => (
                                  <TableRow key={item.id}>
                                    <TableCell>{item.product_name}</TableCell>
                                    <TableCell align="right">
                                      {item.quantity}
                                    </TableCell>
                                    <TableCell align="right">
                                      {formatPrice(item.converted_price)}{" "}
                                      {item.currency}
                                    </TableCell>
                                    <TableCell align="right">
                                      {formatPrice(
                                        item.converted_price * item.quantity
                                      )}{" "}
                                      {item.currency}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )}

                    {/* BigBuy Products */}
                    {selectedOrder.items?.some((item) => item.is_bigbuy) && (
                      <>
                        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                          Produkty od dodávateľa BigBuy
                        </Typography>
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Produkt</TableCell>
                                <TableCell align="right">Množstvo</TableCell>
                                <TableCell align="right">Cena</TableCell>
                                <TableCell align="right">Spolu</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedOrder.items
                                ?.filter((item) => item.is_bigbuy)
                                .map((item) => (
                                  <TableRow key={item.id}>
                                    <TableCell>{item.product_name}</TableCell>
                                    <TableCell align="right">
                                      {item.quantity}
                                    </TableCell>
                                    <TableCell align="right">
                                      {formatPrice(item.price)} {item.currency}
                                    </TableCell>
                                    <TableCell align="right">
                                      {formatPrice(item.price * item.quantity)}{" "}
                                      {item.currency}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )}
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6">Súhrn</Typography>
                    <Typography>
                      Suma bez DPH:{" "}
                      {formatPrice(
                        parseFloat(selectedOrder.total_amount) -
                          parseFloat(selectedOrder.vat_amount)
                      )}{" "}
                      {selectedOrder.currency}
                      <br />
                      Poštovné: {formatPrice(selectedOrder.shipping_price)}{" "}
                      {selectedOrder.currency}
                      <br />
                      DPH: {formatPrice(selectedOrder.vat_amount)}{" "}
                      {selectedOrder.currency}
                      <br />
                      Celkom s DPH:{" "}
                      {formatPrice(
                        parseFloat(selectedOrder.total_amount) +
                          parseFloat(selectedOrder.shipping_price)
                      )}{" "}
                      {selectedOrder.currency}
                    </Typography>
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6">Platba</Typography>
                    <Typography>
                      Platobná metóda:{" "}
                      {PAYMENT_METHOD_LABELS[selectedOrder.payment_method] ||
                        selectedOrder.payment_method}
                      <br />
                      Stav platby:{" "}
                      {selectedOrder.payment_received
                        ? "Zaplatené"
                        : "Nezaplatené"}
                    </Typography>
                  </Box>

                  {selectedOrder.additional_message && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="h6">
                        Poznámka k objednávke
                      </Typography>
                      <Typography>
                        {selectedOrder.additional_message}
                      </Typography>
                    </Box>
                  )}

                  <Box sx={{ mt: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      <Typography variant="h6">Tracking Information</Typography>
                      {isEditingTracking ? (
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Button
                            startIcon={<CloseIcon />}
                            onClick={() => {
                              setIsEditingTracking(false);
                              setEditedTrackingData({
                                shipments: selectedOrder.tracking_number || [
                                  {
                                    provider: "",
                                    tracking_number: "",
                                    estimated_delivery: "",
                                  },
                                ],
                              });
                            }}
                          >
                            Zrušiť
                          </Button>
                          <Button
                            startIcon={<SaveIcon />}
                            variant="contained"
                            onClick={async () => {
                              setConfirmAction({
                                action: "update_tracking",
                                orderId: selectedOrder.id,
                                orderNumber: selectedOrder.order_number,
                                callback: async () => {
                                  try {
                                    await api.post(
                                      `/eshop/orders/${selectedOrder.id}/change_tracking_info/`,
                                      {
                                        shipments: editedTrackingData.shipments,
                                      }
                                    );
                                    fetchOrders();
                                    setIsEditingTracking(false);
                                  } catch (error) {
                                    console.error(
                                      "Failed to update tracking information:",
                                      error
                                    );
                                  }
                                },
                              });
                              setConfirmDialogOpen(true);
                            }}
                            disabled={editedTrackingData.shipments.some(
                              (shipment) =>
                                !shipment.tracking_number || !shipment.provider
                            )}
                          >
                            Uložiť
                          </Button>
                        </Box>
                      ) : (
                        <Button
                          startIcon={<EditIcon />}
                          onClick={() => {
                            setEditedTrackingData({
                              shipments: selectedOrder.tracking_number || [
                                {
                                  provider: "",
                                  tracking_number: "",
                                  estimated_delivery: "",
                                },
                              ],
                            });
                            setIsEditingTracking(true);
                          }}
                        >
                          Upraviť
                        </Button>
                      )}
                    </Box>

                    {isEditingTracking ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                        }}
                      >
                        {editedTrackingData.shipments.map((shipment, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              gap: 2,
                              alignItems: "flex-start",
                              pb: 2,
                              borderBottom:
                                index !==
                                editedTrackingData.shipments.length - 1
                                  ? "1px solid #e0e0e0"
                                  : "none",
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ pt: 1, minWidth: "80px" }}
                            >
                              Zásielka {index + 1}
                            </Typography>
                            <Box
                              sx={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                              }}
                            >
                              <TextField
                                fullWidth
                                label="Dopravca"
                                value={shipment.provider}
                                onChange={(e) => {
                                  const newShipments = [
                                    ...editedTrackingData.shipments,
                                  ];
                                  newShipments[index].provider = e.target.value;
                                  setEditedTrackingData({
                                    shipments: newShipments,
                                  });
                                }}
                              />
                              <TextField
                                fullWidth
                                label="Tracking číslo"
                                value={shipment.tracking_number}
                                onChange={(e) => {
                                  const newShipments = [
                                    ...editedTrackingData.shipments,
                                  ];
                                  newShipments[index].tracking_number =
                                    e.target.value;
                                  setEditedTrackingData({
                                    shipments: newShipments,
                                  });
                                }}
                              />
                              <TextField
                                fullWidth
                                label="Odhadovaný dátum doručenia (dd-mm-yyyy)"
                                placeholder="dd-mm-yyyy"
                                value={shipment.estimated_delivery || ""}
                                onChange={(e) => {
                                  const newShipments = [
                                    ...editedTrackingData.shipments,
                                  ];
                                  newShipments[index].estimated_delivery =
                                    e.target.value;
                                  setEditedTrackingData({
                                    shipments: newShipments,
                                  });
                                }}
                                inputProps={{
                                  pattern: "\\d{2}-\\d{2}-\\d{4}",
                                  maxLength: 10,
                                }}
                              />
                              {index > 0 && (
                                <IconButton
                                  onClick={() => {
                                    const newShipments =
                                      editedTrackingData.shipments.filter(
                                        (_, i) => i !== index
                                      );
                                    setEditedTrackingData({
                                      shipments: newShipments,
                                    });
                                  }}
                                  color="error"
                                  sx={{ alignSelf: "flex-end" }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                        ))}
                        <Button
                          startIcon={<AddIcon />}
                          onClick={() =>
                            setEditedTrackingData((prev) => ({
                              shipments: [
                                ...prev.shipments,
                                {
                                  provider: "",
                                  tracking_number: "",
                                  estimated_delivery: "",
                                },
                              ],
                            }))
                          }
                          sx={{ alignSelf: "flex-start", mt: 1 }}
                        >
                          Pridať ďalšiu zásielku
                        </Button>
                      </Box>
                    ) : selectedOrder.tracking_number ? (
                      selectedOrder.tracking_number.map((tracking, index) => (
                        <Box key={index} sx={{ mb: 1 }}>
                          <Typography>
                            Dopravca: {tracking.provider}
                            <br />
                            Tracking číslo: {tracking.tracking_number}
                            {tracking.estimated_delivery && (
                              <>
                                <br />
                                Odhadovaný dátum doručenia:{" "}
                                {tracking.estimated_delivery}
                              </>
                            )}
                          </Typography>
                        </Box>
                      ))
                    ) : (
                      <Typography color="text.secondary">
                        Žiadne tracking informácie
                      </Typography>
                    )}
                  </Box>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Zavrieť</Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <Dialog
        open={shippingDialogOpen}
        onClose={() => setShippingDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Označiť ako odoslané</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            {shippingData.shipments.map((shipment, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: 2,
                  alignItems: "flex-start",
                  pb: 2,
                  borderBottom:
                    index !== shippingData.shipments.length - 1
                      ? "1px solid #e0e0e0"
                      : "none",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ pt: 1, minWidth: "80px" }}
                >
                  Zásielka {index + 1}
                </Typography>
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Dopravca</InputLabel>
                    <Select
                      value={shipment.provider}
                      label="Dopravca"
                      onChange={(e) => {
                        const newShipments = [...shippingData.shipments];
                        newShipments[index].provider = e.target.value;
                        // Reset tracking number and delivery date based on provider
                        if (e.target.value === "Osobný odber") {
                          newShipments[index].tracking_number = "";
                          newShipments[index].estimated_delivery = "";
                        } else if (e.target.value === "Bilionbuy") {
                          newShipments[index].tracking_number = "";
                        }
                        setShippingData({ shipments: newShipments });
                      }}
                    >
                      {CARRIER_OPTIONS.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {shipment.provider === "DPD" && (
                    <>
                      <TextField
                        fullWidth
                        label="Tracking číslo"
                        value={shipment.tracking_number}
                        onChange={(e) => {
                          const newShipments = [...shippingData.shipments];
                          newShipments[index].tracking_number = e.target.value;
                          setShippingData({ shipments: newShipments });
                        }}
                        required
                      />
                      <TextField
                        fullWidth
                        label="Odhadovaný dátum doručenia (dd-mm-yyyy)"
                        placeholder="dd-mm-yyyy"
                        value={shipment.estimated_delivery || ""}
                        onChange={(e) => {
                          const newShipments = [...shippingData.shipments];
                          newShipments[index].estimated_delivery =
                            e.target.value;
                          setShippingData({ shipments: newShipments });
                        }}
                        inputProps={{
                          pattern: "\\d{2}-\\d{2}-\\d{4}",
                          maxLength: 10,
                        }}
                      />
                    </>
                  )}

                  {shipment.provider === "Bilionbuy" && (
                    <TextField
                      fullWidth
                      label="Odhadovaný dátum doručenia (dd-mm-yyyy)"
                      placeholder="dd-mm-yyyy"
                      value={shipment.estimated_delivery || ""}
                      onChange={(e) => {
                        const newShipments = [...shippingData.shipments];
                        newShipments[index].estimated_delivery = e.target.value;
                        setShippingData({ shipments: newShipments });
                      }}
                      inputProps={{
                        pattern: "\\d{2}-\\d{2}-\\d{4}",
                        maxLength: 10,
                      }}
                      required
                    />
                  )}

                  {index > 0 && (
                    <IconButton
                      onClick={() => {
                        const newShipments = shippingData.shipments.filter(
                          (_, i) => i !== index
                        );
                        setShippingData({ shipments: newShipments });
                      }}
                      color="error"
                      sx={{ alignSelf: "flex-end" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
            ))}
            <Button
              startIcon={<AddIcon />}
              onClick={() =>
                setShippingData((prev) => ({
                  shipments: [
                    ...prev.shipments,
                    {
                      provider: "",
                      tracking_number: "",
                      estimated_delivery: "",
                    },
                  ],
                }))
              }
              sx={{ alignSelf: "flex-start", mt: 1 }}
            >
              Pridať ďalšiu zásielku
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShippingDialogOpen(false)}>Zrušiť</Button>
          <Button
            onClick={() => {
              setConfirmAction({
                action: "mark_shipped_confirm",
                orderId: selectedOrderForShipping.id,
                orderNumber: selectedOrderForShipping.order_number,
                callback: async () => {
                  try {
                    await api.post(
                      `/eshop/orders/${selectedOrderForShipping.id}/mark_shipped/`,
                      { shipments: shippingData.shipments }
                    );
                    setShippingDialogOpen(false);
                    setSelectedOrderForShipping(null);
                    setShippingData({
                      shipments: [
                        {
                          provider: "",
                          tracking_number: "",
                          estimated_delivery: "",
                        },
                      ],
                    });
                    fetchOrders();
                  } catch (error) {
                    console.error("Failed to mark order as shipped:", error);
                  }
                },
              });
              setConfirmDialogOpen(true);
            }}
            variant="contained"
            color="primary"
            disabled={shippingData.shipments.some(
              (shipment) =>
                !shipment.provider ||
                (shipment.provider === "DPD" && !shipment.tracking_number) ||
                (shipment.provider === "Bilionbuy" &&
                  !shipment.estimated_delivery)
            )}
          >
            Potvrdiť
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deliveryConfirmationOpen}
        onClose={() => {
          setDeliveryConfirmationOpen(false);
          setOrderToMarkDelivered(null);
        }}
      >
        <DialogTitle>Potvrdiť doručenie</DialogTitle>
        <DialogContent>
          <Typography>
            Naozaj chcete označiť objednávku #
            {orderToMarkDelivered?.order_number} ako doručenú?
          </Typography>
          {orderToMarkDelivered?.tracking_number && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">
                Tracking informácie:
              </Typography>
              {orderToMarkDelivered.tracking_number.map((tracking, index) => (
                <Typography key={index} color="text.secondary">
                  {tracking.provider}: {tracking.tracking_number}
                  {tracking.estimated_delivery && (
                    <>
                      <br />
                      Odhadovaný dátum doručenia: {tracking.estimated_delivery}
                    </>
                  )}
                </Typography>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeliveryConfirmationOpen(false);
              setOrderToMarkDelivered(null);
            }}
          >
            Zrušiť
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setConfirmAction({
                action: "mark_delivered",
                orderId: orderToMarkDelivered.id,
                orderNumber: orderToMarkDelivered.order_number,
                callback: async () => {
                  try {
                    await api.post(
                      `/eshop/orders/${orderToMarkDelivered.id}/mark_delivered/`
                    );
                    fetchOrders();
                    setDeliveryConfirmationOpen(false);
                    setOrderToMarkDelivered(null);
                  } catch (error) {
                    console.error("Failed to mark order as delivered:", error);
                  }
                },
              });
              setConfirmDialogOpen(true);
            }}
          >
            Potvrdiť doručenie
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={invoiceUploadDialogOpen}
        onClose={() => {
          setInvoiceUploadDialogOpen(false);
          setSelectedOrderForInvoice(null);
          setSelectedInvoiceFile(null);
          setInvoiceUploadError("");
        }}
      >
        <DialogTitle>Nahrať faktúru</DialogTitle>
        <DialogContent>
          {selectedOrderForInvoice && (
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle1" gutterBottom>
                Objednávka #{selectedOrderForInvoice.order_number}
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {selectedOrderForInvoice.shipping_first_name
                  ? `${selectedOrderForInvoice.shipping_first_name} ${selectedOrderForInvoice.shipping_last_name}`
                  : selectedOrderForInvoice.shipping_name}
                {" - "}
                {formatPrice(selectedOrderForInvoice.total_amount)}{" "}
                {selectedOrderForInvoice.currency}
              </Typography>

              <Box
                sx={{
                  mt: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={<UploadIcon />}
                  sx={{ mb: 2 }}
                >
                  Vybrať PDF súbor
                  <input
                    type="file"
                    accept="application/pdf"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>

                {selectedInvoiceFile && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Vybraný súbor: {selectedInvoiceFile.name}
                  </Typography>
                )}

                {invoiceUploadError && (
                  <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                    {invoiceUploadError}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setInvoiceUploadDialogOpen(false);
              setSelectedOrderForInvoice(null);
              setSelectedInvoiceFile(null);
            }}
          >
            Zrušiť
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (!selectedInvoiceFile || uploadingInvoice) return;

              setConfirmAction({
                action: "upload_invoice",
                orderId: selectedOrderForInvoice.id,
                orderNumber: selectedOrderForInvoice.order_number,
                callback: async () => {
                  setUploadingInvoice(true);
                  setInvoiceUploadError("");

                  try {
                    const formData = new FormData();
                    formData.append("file", selectedInvoiceFile);
                    formData.append("order_id", selectedOrderForInvoice.id);

                    await api.post("/eshop/upload-invoice/", formData, {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    });

                    setInvoiceUploadDialogOpen(false);
                    setSelectedOrderForInvoice(null);
                    setSelectedInvoiceFile(null);
                    fetchOrders();
                  } catch (error) {
                    console.error("Failed to upload invoice:", error);
                    setInvoiceUploadError(
                      error.response?.data?.message ||
                        "Nastala chyba pri nahrávaní faktúry"
                    );
                    // Keep the dialog open in case of error
                    return false; // Return false to prevent closing the confirmation dialog
                  } finally {
                    setUploadingInvoice(false);
                  }
                },
              });
              setConfirmDialogOpen(true);
            }}
            disabled={!selectedInvoiceFile || uploadingInvoice}
            startIcon={
              uploadingInvoice ? <CircularProgress size={20} /> : <UploadIcon />
            }
          >
            {uploadingInvoice ? "Nahrávanie..." : "Nahrať faktúru"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Potvrdiť akciu</DialogTitle>
        <DialogContent>
          <Typography>
            {confirmAction.action === "mark_paid" && (
              <>
                Naozaj chcete označiť objednávku #{confirmAction.orderNumber}{" "}
                ako zaplatenú?
              </>
            )}
            {confirmAction.action === "mark_invoiced" && (
              <>
                Naozaj chcete označiť objednávku #{confirmAction.orderNumber}{" "}
                ako fakturovanú?
              </>
            )}
            {confirmAction.action === "mark_cancelled" && (
              <>Naozaj chcete zrušiť objednávku #{confirmAction.orderNumber}?</>
            )}
            {confirmAction.action === "update_tracking" && (
              <>
                Naozaj chcete aktualizovať informácie o doručení pre objednávku
                #{confirmAction.orderNumber}?
              </>
            )}
            {confirmAction.action === "upload_invoice" && (
              <>
                Naozaj chcete nahrať faktúru pre objednávku #
                {confirmAction.orderNumber}?
              </>
            )}
            {confirmAction.action === "mark_delivered" && (
              <>
                Naozaj chcete označiť objednávku #{confirmAction.orderNumber}{" "}
                ako doručenú?
              </>
            )}
            {confirmAction.action === "mark_shipped_confirm" && (
              <>
                Naozaj chcete označiť objednávku #{confirmAction.orderNumber}{" "}
                ako odoslanú?
              </>
            )}
            {![
              "mark_paid",
              "mark_invoiced",
              "mark_cancelled",
              "update_tracking",
              "upload_invoice",
              "mark_delivered",
              "mark_shipped_confirm",
            ].includes(confirmAction.action) && (
              <>
                Naozaj chcete vykonať akciu "{confirmAction.action}" pre
                objednávku #{confirmAction.orderNumber}?
              </>
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Zrušiť</Button>
          <Button variant="contained" onClick={handleConfirmAction}>
            Potvrdiť
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
