import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
  Divider,
  Tooltip,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { toast } from "react-toastify";
import api from "../../../services/api";
import InfoIcon from "@mui/icons-material/Info";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto",
};

const initialOfferState = {
  stock: null,
  stock_count: "",
  delivery_date: "",
  delivery: null,
  gift: null,
  price_vat: "",
  price_novat: "",
  extended_warranty: null,
  special_service: null,
  sales_voucher: null,
  cashback: "",
  visibility: true,
  margin: "",
  margin_price: "",
  suggested_price: "",
  suggested_retail_price: "",
  shipping_cost: "",
  lowest_comparison_price: "",
  gross_profit: "",
  gross_profit_percentage: "",
  commision_groups: {},
};

const BilionBuyOfferModal = ({ isOpen, onClose, product, onUpdate }) => {
  const [editedOffer, setEditedOffer] = useState(initialOfferState);
  const [loading, setLoading] = useState(false);
  const [vatRate, setVatRate] = useState(null);
  const [deliveryPrice, setDeliveryPrice] = useState(null);
  const [vatSettings, setVatSettings] = useState([]);
  const [selectedVat, setSelectedVat] = useState(null);
  const [isExpanded, setIsExpanded] = useState({});
  const [commisionGroups, setcommisionGroups] = useState({
    OC: "",
    DC: "",
    SC: "",
  });

  const fetchDeliveryPrice = useCallback(async (productId, country) => {
    try {
      const response = await api.get("/bigbuy/get-delivery-price/", {
        params: {
          product: productId,
          country: country,
        },
      });
      setDeliveryPrice(response.data.price);
      setEditedOffer((prev) => ({
        ...prev,
        shipping_cost: response.data.price,
      }));
    } catch (error) {
      console.error("Error fetching delivery price:", error);
      setDeliveryPrice(null);
    }
  }, []);

  useEffect(() => {
    const fetchOfferData = async () => {
      if (isOpen && product?.id) {
        try {
          setLoading(true);
          const response = await api.get(`bilionbuy-offer/${product.id}/`);
          const offerData = response.data;

          setVatSettings(offerData.all_vat_settings);

          const vatId = offerData.vat;
          const currentVat = offerData.all_vat_settings.find(
            (vat) => vat.id === vatId
          );
          setSelectedVat(currentVat);

          if (currentVat?.vat_rate) {
            setVatRate(parseFloat(currentVat.vat_rate));
          }

          const initialData = {
            price_vat: offerData.price_vat || "",
            price_novat: offerData.price_novat || "",
            stock: offerData.stock !== undefined ? offerData.stock : null,
            stock_count: offerData.stock_count || "",
            delivery_date: offerData.delivery_date || "",
            delivery: offerData.delivery || null,
            gift: offerData.gift || null,
            extended_warranty: offerData.extended_warranty || null,
            special_service: offerData.special_service || null,
            sales_voucher: offerData.sales_voucher || null,
            cashback: offerData.cashback || "",
            visibility: offerData.visibility ?? true,
            margin: offerData.margin || "",
            margin_price: offerData.margin_price || "",
            suggested_price: offerData.suggested_price || "",
            suggested_retail_price: offerData.suggested_retail_price || "",
            shipping_cost: "",
            lowest_comparison_price: offerData.lowest_price || "",
            gross_profit: offerData.gross_profit || "",
            gross_profit_percentage: "",
            commision_groups: offerData.commision_groups || {},
          };

          if (initialData.margin_price && initialData.margin) {
            initialData.gross_profit = calculateGrossProfit(
              initialData.margin_price,
              initialData.margin
            );

            const priceNoVat =
              parseFloat(initialData.margin_price) +
              parseFloat(initialData.gross_profit);
            initialData.price_novat = priceNoVat.toFixed(2);

            if (currentVat?.vat_rate) {
              initialData.price_vat = (
                priceNoVat *
                (1 + parseFloat(currentVat.vat_rate) / 100)
              ).toFixed(2);
            }

            initialData.gross_profit_percentage =
              calculateGrossProfitPercentage(initialData.margin);
          }

          setEditedOffer(initialData);

          if (offerData.product_id && offerData.product_language) {
            await fetchDeliveryPrice(
              offerData.product_id,
              offerData.product_language
            );
          }

          const expandedState = {};
          Object.keys(offerData.commision_groups || {}).forEach((_, index) => {
            expandedState[index] = false;
          });
          setIsExpanded(expandedState);

          if (offerData.commision_groups) {
            setcommisionGroups({
              OC: offerData.commision_groups.OC || "",
              DC: offerData.commision_groups.DC || "",
              SC: offerData.commision_groups.SC || "",
            });
          } else {
            setcommisionGroups({
              OC: "",
              DC: "",
              SC: "",
            });
          }
        } catch (error) {
          console.error("Error fetching offer data:", error);
          toast.error("Failed to load offer data");
          onClose();
        } finally {
          setLoading(false);
        }
      } else if (isOpen && product?.product?.id) {
        setLoading(true);
        try {
          const response = await api.get("vat-settings/");
          console.log("VAT settings for new offer:", response.data);
          setVatSettings(response.data || []);

          const primaryVat =
            response.data.find((vat) => vat.primary) || response.data[0];
          if (primaryVat) {
            setSelectedVat(primaryVat);
            setVatRate(parseFloat(primaryVat.vat_rate));
            console.log("Selected default VAT:", primaryVat);
          }

          setEditedOffer(initialOfferState);
          setDeliveryPrice(null);

          setIsExpanded({});

          setcommisionGroups({
            OC: "",
            DC: "",
            SC: "",
          });
        } catch (error) {
          console.error("Error fetching VAT settings for new offer:", error);
          toast.error("Failed to load VAT settings");
        } finally {
          setLoading(false);
        }
      } else if (isOpen) {
        setEditedOffer(initialOfferState);
        setVatRate(null);
        setDeliveryPrice(null);
        setIsExpanded({});

        setcommisionGroups({
          OC: "",
          DC: "",
          SC: "",
        });
      }
    };

    fetchOfferData();
  }, [isOpen, product, fetchDeliveryPrice]);

  const calculatePriceWithVat = (priceNoVat) => {
    if (!priceNoVat || vatRate === null) return "";
    const price = parseFloat(priceNoVat);
    return (price * (1 + vatRate / 100)).toFixed(2);
  };

  const calculateGrossProfit = (marginPrice, margin) => {
    if (!marginPrice || !margin) return "";
    const wholesale = parseFloat(marginPrice);
    const marginPercent = parseFloat(margin);
    return ((wholesale * marginPercent) / 100).toFixed(2);
  };

  const calculateMargin = (marginPrice, grossProfit) => {
    if (!marginPrice || !grossProfit) return "";
    const wholesale = parseFloat(marginPrice);
    const profit = parseFloat(grossProfit);
    return ((profit * 100) / wholesale).toFixed(2);
  };

  const calculateGrossProfitPercentage = (margin) => {
    if (!margin) return "";
    const marginDecimal = parseFloat(margin) / 100;
    return ((marginDecimal / (1 + marginDecimal)) * 100).toFixed(2);
  };

  const handleAddCommissionGroup = () => {
    const newGroupId = `new_${Date.now()}`;
    const groupCount = Object.keys(editedOffer.commision_groups).length;

    setEditedOffer((prev) => ({
      ...prev,
      commision_groups: {
        ...prev.commision_groups,
        [newGroupId]: {
          name: "",
          coefficient: "",
          type: "Kategória",
          OC: "",
          DC: "",
          SC: "",
        },
      },
    }));

    setIsExpanded((prev) => ({
      ...prev,
      [groupCount]: false,
    }));
  };

  const toggleExpand = (index) => {
    setIsExpanded((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleRemoveCommissionGroup = (groupId) => {
    setEditedOffer((prev) => {
      const updatedGroups = { ...prev.commision_groups };
      delete updatedGroups[groupId];
      return {
        ...prev,
        commision_groups: updatedGroups,
      };
    });
  };

  const handleCommissionGroupChange = (field, value) => {
    setcommisionGroups((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    const newEditedOffer = { ...editedOffer, [name]: value };

    if (name === "margin_price") {
      if (newEditedOffer.margin) {
        newEditedOffer.gross_profit = calculateGrossProfit(
          value,
          newEditedOffer.margin
        );
        const priceNoVat =
          parseFloat(value) + parseFloat(newEditedOffer.gross_profit);
        newEditedOffer.price_novat = priceNoVat.toFixed(2);
        newEditedOffer.price_vat = calculatePriceWithVat(priceNoVat);
        newEditedOffer.gross_profit_percentage = calculateGrossProfitPercentage(
          newEditedOffer.margin
        );
      }
    } else if (name === "margin") {
      if (newEditedOffer.margin_price) {
        newEditedOffer.gross_profit = calculateGrossProfit(
          newEditedOffer.margin_price,
          value
        );
        const priceNoVat =
          parseFloat(newEditedOffer.margin_price) +
          parseFloat(newEditedOffer.gross_profit);
        newEditedOffer.price_novat = priceNoVat.toFixed(2);
        newEditedOffer.price_vat = calculatePriceWithVat(priceNoVat);
        newEditedOffer.gross_profit_percentage =
          calculateGrossProfitPercentage(value);
      }
    } else if (name === "gross_profit") {
      if (newEditedOffer.margin_price) {
        const newMargin = calculateMargin(newEditedOffer.margin_price, value);
        newEditedOffer.margin = newMargin;
        const priceNoVat =
          parseFloat(newEditedOffer.margin_price) + parseFloat(value);
        newEditedOffer.price_novat = priceNoVat.toFixed(2);
        newEditedOffer.price_vat = calculatePriceWithVat(priceNoVat);
        newEditedOffer.gross_profit_percentage =
          calculateGrossProfitPercentage(newMargin);
      }
    } else if (name === "gross_profit_percentage") {
      if (newEditedOffer.margin_price) {
        const grossProfitDecimal = parseFloat(value) / 100;
        const margin = (
          (grossProfitDecimal / (1 - grossProfitDecimal)) *
          100
        ).toFixed(2);
        newEditedOffer.margin = margin;

        const grossProfit = calculateGrossProfit(
          newEditedOffer.margin_price,
          margin
        );
        newEditedOffer.gross_profit = grossProfit;

        const priceNoVat =
          parseFloat(newEditedOffer.margin_price) + parseFloat(grossProfit);
        newEditedOffer.price_novat = priceNoVat.toFixed(2);
        newEditedOffer.price_vat = calculatePriceWithVat(priceNoVat);
      }
    } else if (name === "price_vat") {
      if (newEditedOffer.margin_price && vatRate !== null) {
        const priceNoVat = (parseFloat(value) / (1 + vatRate / 100)).toFixed(2);
        newEditedOffer.price_novat = priceNoVat;

        const grossProfit = (
          parseFloat(priceNoVat) - parseFloat(newEditedOffer.margin_price)
        ).toFixed(2);
        newEditedOffer.gross_profit = grossProfit;

        const newMargin = calculateMargin(
          newEditedOffer.margin_price,
          grossProfit
        );
        newEditedOffer.margin = newMargin;

        newEditedOffer.gross_profit_percentage =
          calculateGrossProfitPercentage(newMargin);
      }
    }

    setEditedOffer(newEditedOffer);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const payload = {
        price_vat: editedOffer.price_vat || null,
        price_novat: editedOffer.price_novat || null,
        visibility: editedOffer.visibility,
        stock: editedOffer.stock,
        stock_count:
          editedOffer.stock_count === "" ? null : editedOffer.stock_count,
        delivery_date: editedOffer.delivery_date || null,
        delivery: editedOffer.delivery,
        gift: editedOffer.gift || null,
        extended_warranty: editedOffer.extended_warranty,
        special_service: editedOffer.special_service || null,
        sales_voucher: editedOffer.sales_voucher,
        cashback: editedOffer.cashback || null,
        margin: editedOffer.margin || null,
        margin_price: editedOffer.margin_price || null,
        suggested_price: editedOffer.suggested_price || null,
        suggested_retail_price: editedOffer.suggested_retail_price || null,
        shipping_cost: editedOffer.shipping_cost || null,
        gross_profit: editedOffer.gross_profit || null,
        commision_groups: commisionGroups,
        vat: selectedVat?.id || null,
      };

      console.log("Payload being sent:", payload);

      if (product.id) {
        await api.patch(`/bilionbuy-offer/${product.id}/update/`, payload);
        toast.success("Ponuka obchodu bola úspešne aktualizovaná");
      } else {
        await api.post(
          `/products/${product.product.id}/bilionbuy-offer/`,
          payload
        );
        toast.success("Ponuka obchodu bola úspešne pridaná");
      }

      onUpdate();
      onClose();
    } catch (error) {
      if (error.response?.status === 400 && error.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        console.error("Error saving offer:", error);
        toast.error("Nepodarilo sa uložiť ponuku obchodu");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCashbackChange = (e) => {
    let value = e.target.value.replace(",", ".");
    if (value === "" || /^\d*\.?\d*$/.test(value)) {
      setEditedOffer((prev) => ({
        ...prev,
        cashback: value,
      }));
    }
  };

  const handleVatChange = (e) => {
    const vatId = e.target.value;
    const selected = vatSettings.find((vat) => vat.id === vatId) || null;
    setSelectedVat(selected);

    if (selected?.vat_rate) {
      const newVatRate = parseFloat(selected.vat_rate);
      setVatRate(newVatRate);

      if (editedOffer.margin_price && editedOffer.margin) {
        const grossProfit = calculateGrossProfit(
          editedOffer.margin_price,
          editedOffer.margin
        );

        const priceNoVat =
          parseFloat(editedOffer.margin_price) + parseFloat(grossProfit);

        setEditedOffer((prev) => ({
          ...prev,
          gross_profit: grossProfit,
          price_novat: priceNoVat.toFixed(2),
          price_vat: (priceNoVat * (1 + newVatRate / 100)).toFixed(2),
          gross_profit_percentage: calculateGrossProfitPercentage(
            editedOffer.margin
          ),
        }));
      }
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
          Detaily ponuky obchodu
        </Typography>

        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Cenotvorba
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Veľkoobchodná cena"
                  name="margin_price"
                  value={editedOffer.margin_price}
                  onChange={handlePriceChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                    startAdornment: (
                      <Tooltip title="Cena, za ktorú produkt nakupujeme">
                        <IconButton size="small">
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Marža"
                  name="margin"
                  value={editedOffer.margin}
                  onChange={handlePriceChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Cena s DPH"
                  name="price_vat"
                  value={editedOffer.price_vat}
                  onChange={handlePriceChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="vat-select-label">DPH</InputLabel>
                  <Select
                    labelId="vat-select-label"
                    value={selectedVat?.id || ""}
                    label="DPH"
                    onChange={handleVatChange}
                  >
                    <MenuItem value="">
                      <em>Žiadna</em>
                    </MenuItem>
                    {vatSettings.map((vat) => (
                      <MenuItem key={vat.id} value={vat.id}>
                        {`${vat.vat_rate} % - ${vat.country} - ${vat.type}`}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    Vyberte sadzbu DPH pre produkt
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Náklady na dopravu"
                  name="shipping_cost"
                  value={deliveryPrice !== null ? `${deliveryPrice}` : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                    startAdornment:
                      deliveryPrice === null ? (
                        <CircularProgress size={20} />
                      ) : null,
                  }}
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Hrubý zisk"
                  name="gross_profit"
                  value={editedOffer.gross_profit}
                  onChange={handlePriceChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Hrubý zisk (%)"
                  name="gross_profit_percentage"
                  value={editedOffer.gross_profit_percentage}
                  onChange={handlePriceChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Najnižšia cena v porovnávači"
                  name="lowest_comparison_price"
                  value={editedOffer.lowest_comparison_price}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Cena v obchodoch"
                  name="suggested_price"
                  value={editedOffer.suggested_price}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Odporúčaná maloobchodná cena"
                  name="suggested_retail_price"
                  value={editedOffer.suggested_retail_price}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Stav skladu</InputLabel>
                  <Select
                    value={
                      editedOffer.stock === null
                        ? "null"
                        : editedOffer.stock === true
                          ? "true"
                          : "false"
                    }
                    label="Stav skladu"
                    onChange={(e) => {
                      console.log("Selected value:", e.target.value);
                      const newValue =
                        e.target.value === "true"
                          ? true
                          : e.target.value === "false"
                            ? false
                            : null;
                      console.log("Converted value:", newValue);
                      setEditedOffer((prev) => {
                        const newState = { ...prev, stock: newValue };
                        console.log("New state:", newState);
                        return newState;
                      });
                    }}
                  >
                    <MenuItem value="null">Nešpecifikované</MenuItem>
                    <MenuItem value="true">Na sklade</MenuItem>
                    <MenuItem value="false">Vypredané</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Počet kusov"
                  name="stock_count"
                  type="number"
                  inputProps={{ min: 0, step: 1 }}
                  value={editedOffer.stock_count}
                  onChange={(e) =>
                    setEditedOffer((prev) => ({
                      ...prev,
                      stock_count: e.target.value
                        ? Math.floor(Number(e.target.value))
                        : "",
                    }))
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Dodacia lehota</InputLabel>
                  <Select
                    value={editedOffer.delivery_date}
                    label="Dodacia lehota"
                    onChange={(e) =>
                      setEditedOffer((prev) => ({
                        ...prev,
                        delivery_date: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="">Info v obchode</MenuItem>
                    <MenuItem value="0">Skladom</MenuItem>
                    <MenuItem value="3">Do 3 dní</MenuItem>
                    <MenuItem value="7">Do týždňa</MenuItem>
                    <MenuItem value="14">Do 2 týždňov</MenuItem>
                    <MenuItem value="30">Do mesiaca</MenuItem>
                    <MenuItem value="31">Viac ako mesiac</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Darček</InputLabel>
                  <Select
                    value={editedOffer.gift}
                    label="Darček"
                    onChange={(e) =>
                      setEditedOffer((prev) => ({
                        ...prev,
                        gift: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value={null}>Nešpecifikované</MenuItem>
                    <MenuItem value={1}>Áno</MenuItem>
                    <MenuItem value={0}>Nie</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Špeciálna služba</InputLabel>
                  <Select
                    value={editedOffer.special_service}
                    label="Špeciálna služba"
                    onChange={(e) =>
                      setEditedOffer((prev) => ({
                        ...prev,
                        special_service: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value={null}>Nešpecifikované</MenuItem>
                    <MenuItem value={1}>Áno</MenuItem>
                    <MenuItem value={0}>Nie</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={editedOffer.visibility}
                      onChange={(e) =>
                        setEditedOffer((prev) => ({
                          ...prev,
                          visibility: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Viditeľná ponuka"
                />
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Koeficienty
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ p: 2, bgcolor: "#f5f5f5", borderRadius: 1, mb: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} mb={2}>
                      <TextField
                        fullWidth
                        label="Cashback"
                        name="cashback"
                        value={editedOffer.cashback}
                        onChange={handleCashbackChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Divider sx={{ my: 1 }} />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        size="small"
                        label="OC koeficient"
                        value={commisionGroups.OC}
                        onChange={(e) => {
                          const value = e.target.value.replace(",", ".");
                          if (value === "" || /^\d*\.?\d*$/.test(value)) {
                            handleCommissionGroupChange("OC", value);
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        size="small"
                        label="DC koeficient"
                        value={commisionGroups.DC}
                        onChange={(e) => {
                          const value = e.target.value.replace(",", ".");
                          if (value === "" || /^\d*\.?\d*$/.test(value)) {
                            handleCommissionGroupChange("DC", value);
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        size="small"
                        label="SC koeficient"
                        value={commisionGroups.SC}
                        onChange={(e) => {
                          const value = e.target.value.replace(",", ".");
                          if (value === "" || /^\d*\.?\d*$/.test(value)) {
                            handleCommissionGroupChange("SC", value);
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>

            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button onClick={onClose} sx={{ mr: 1 }}>
                Zrušiť
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Uložiť"}
              </Button>
            </Box>
          </form>
        )}
      </Box>
    </Modal>
  );
};

export default BilionBuyOfferModal;
