import React, { useState, useEffect, useCallback } from "react";
import { ToastContainer } from "react-toastify";
import CategoryMappings from "../components/admin/CategoryMappings";
import AdminCategories from "../components/admin/category/AdminCategories";
import api from "../services/api";
import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  CssBaseline,
  Avatar,
  Collapse,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  ShoppingCart as ProductsIcon,
  Category as CategoriesIcon,
  CloudUpload as UploadIcon,
  Receipt as TransactionsIcon,
  Article as BlogIcon,
  Store as StoreIcon,
  Email as EmailIcon,
  MenuBook as MenuBookIcon,
  SupportAgent as TicketsIcon,
  ShoppingBasket as ShoppingBasketIcon,
  ExpandLess,
  ExpandMore,
  Search as SearchIcon,
  LocalShipping as ShippingIcon,
  ImportExport as ImportIcon,
  Settings as SettingsIcon,
  Receipt as ReceiptIcon,
} from "@mui/icons-material";
import { Pagination } from "@mui/material";

import AdminStats from "../components/AdminStats";
import AdminTable from "../components/AdminTable";
import UserTable from "../components/admin/UserTable";
import ProductsTable from "../components/admin/ProductsTable";
import ProductMerge from "../components/admin/ProductMerge";
import CategoriesTable from "../components/admin/CategoriesTable";
import UploadCSV from "../components/admin/UploadCSV";
import TransactionsTable from "../components/admin/TransactionsTable";
import BlogsTable from "../components/BlogsTable";
import ProfileComponent from "../components/ProfileComponent";
import { logo_url } from "../data/common";
import AdminEmailEditor from "../components/admin/AdminEmailEditor";
import VariantsDuplicatesSuggestions from "../components/admin/VariantsDuplicatesSuggestions";
import ProductsMainTable from "../components/admin/ProductsTable/ProductsMainTable";
import CategoryAssignmentTable from "../components/admin/CategoryAssignmentTable";
import LandingOffersTable from "../components/admin/LandingOffers/LandingOffersTable";
import { OfferTypesTable } from "../components/admin/LandingOffers/OfferTypesTable";
import WalletTransactionsAdmin from "../components/admin/WalletTransactionsAdmin";
import ProductsLinkedShopsTable from "../components/admin/ProductsLinkedShopsTable";
import ThesaurusTable from "../components/admin/ThesaurusTable";
import TicketsTable from "../components/admin/TicketsTable";
import EshopProductImport from "../components/admin/eshop/EshopProductImport";
import EshopOrdersTable from "../components/admin/eshop/EshopOrdersTable";
import EshopProductsLinkedShopsTable from "../components/admin/eshop/EshopProductsLinkedShopsTable";
import BilionBuyProductsTable from "../components/admin/eshop/BilionBuyProductsTable";
import DeliveryOptionsTable from "../components/admin/eshop/DeliveryOptionsTable";
import BigBuyProductSearch from "../components/BigBuyProductSearch";
import VATSettingsTable from "../components/admin/eshop/VATSettingsTable";
import AdminCommissionsTable from "../components/admin/AdminCommissionsTable";

const drawerWidth = 240;
const miniDrawerWidth = 65;

const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  padding: 0,
  paddingTop: theme.spacing(12),
  display: "flex",
  backgroundColor: theme.palette.background.default,
  transition: theme.transitions.create(["padding"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const NestedListItem = styled(ListItem)(({ theme, level = 0, open }) => ({
  paddingLeft: !open ? theme.spacing(2) : theme.spacing(2 + 2 * level),
  whiteSpace: "nowrap",
  justifyContent: !open ? "center" : "flex-start",
  "& .MuiListItemText-primary": {
    fontSize: level > 0 ? "0.875rem" : "1rem",
    fontWeight: level > 0 ? 400 : 500,
    opacity: open ? 1 : 0,
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  "& .MuiListItemIcon-root": {
    minWidth: !open ? 0 : level > 0 ? "40px" : "48px",
    marginRight: !open ? 0 : theme.spacing(2),
    justifyContent: "center",
  },
  paddingTop: level > 0 ? theme.spacing(0.5) : theme.spacing(1),
  paddingBottom: level > 0 ? theme.spacing(0.5) : theme.spacing(1),
}));

// Define menu structure before component
const menuStructure = [
  { id: "dashboard", text: "Prehľad", icon: <DashboardIcon /> },
  { id: "users", text: "Používatelia", icon: <PeopleIcon /> },
  {
    id: "products",
    text: "Produkty",
    icon: <ProductsIcon />,
    subItems: [
      { id: "products-main", text: "Produkty a parametre" },
      { id: "offer-types", text: "Správa typov ponúk" },
      { id: "landing-offers", text: "Ponuky na úvodnej stránke" },
      { id: "products-table", text: "Tabuľka produktov" },
      { id: "products-merge", text: "Zlúčenie produktov" },
      { id: "products-suggestions", text: "Návrhy" },
      { id: "products-shops", text: "Správa produktov obchodov" },
    ],
  },
  {
    id: "categories",
    text: "Kategórie",
    icon: <CategoriesIcon />,
    subItems: [
      { id: "admin-categories", text: "Správa kategórií" },
      { id: "categories-table", text: "Tabuľka kategórií" },
      { id: "categories-assignment", text: "Priradenie kategórií" },
      { id: "categories-mapping", text: "Mapovanie kategórií" },
    ],
  },
  {
    id: "uploads",
    text: "Nahrávania",
    icon: <UploadIcon />,
    subItems: [
      { id: "clicks-import", text: "Import kliknutí" },
      { id: "transactions-import", text: "Import transakcií" },
    ],
  },
  {
    id: "transactions",
    text: "Transakcie",
    icon: <TransactionsIcon />,
    subItems: [
      { id: "transactions-table", text: "Tabuľka transakcií" },
      { id: "wallet-transactions", text: "Transakcie peňaženky" },
      { id: "uzavierky", text: "Uzávierky" },
    ],
  },
  { id: "blog", text: "Správa blogu", icon: <BlogIcon /> },
  { id: "shops", text: "Obchody", icon: <StoreIcon /> },
  { id: "emails", text: "E-maily", icon: <EmailIcon /> },
  { id: "thesaurus", text: "Slovník", icon: <MenuBookIcon /> },
  { id: "tickets", text: "Reklamácie", icon: <TicketsIcon /> },
  {
    id: "eshop",
    text: "E-SHOP",
    icon: <ShoppingBasketIcon />,
    subItems: [
      {
        id: "bigbuy-search",
        text: "Vyhľadávanie BIGBUY",
        icon: <SearchIcon />,
      },
      {
        id: "product-import",
        text: "Import produktov",
        icon: <ImportIcon />,
      },
      {
        id: "bilionbuy-products",
        text: "Produkty BilionBuy",
        icon: <ProductsIcon />,
      },
      { id: "orders", text: "Správa objednávok", icon: <ReceiptIcon /> },
      { id: "delivery", text: "Správa doručenia", icon: <ShippingIcon /> },
      { id: "vat", text: "Nastavenia DPH", icon: <SettingsIcon /> },
    ],
  },
];

// Utility function to find parent menu of an item
const findParentMenu = (itemId) => {
  for (const item of menuStructure) {
    if (item.subItems?.some((subItem) => subItem.id === itemId)) {
      return item.id;
    }
  }
  return null;
};

// Utility function to get initial expanded state
const getInitialExpandedState = (hash) => {
  const expanded = {};
  const parentId = findParentMenu(hash);
  if (parentId) {
    expanded[parentId] = true;
  }
  return expanded;
};

export default function Dashboard() {
  const [open, setOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState(() => {
    const hash = window.location.hash.slice(1);
    return hash || "dashboard";
  });

  const [expandedMenus, setExpandedMenus] = useState(() => {
    const hash = window.location.hash.slice(1);
    return getInitialExpandedState(hash);
  });

  // Check API URL on component mount
  useEffect(() => {
    console.log("Dashboard mounted - API baseURL:", api.defaults.baseURL);
  }, []);

  // Update URL hash when selected item changes
  useEffect(() => {
    window.location.hash = selectedItem;
  }, [selectedItem]);

  // Listen for hash changes
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1);
      if (hash) {
        setSelectedItem(hash);
        const parentId = findParentMenu(hash);
        if (parentId) {
          setExpandedMenus((prev) => ({
            ...prev,
            [parentId]: true,
          }));
        }
      }
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleMenuClick = (itemId) => {
    setSelectedItem(itemId);
  };

  const handleMenuExpand = (menuId) => {
    setExpandedMenus((prev) => ({
      ...prev,
      [menuId]: !prev[menuId],
    }));
  };

  const renderMenuItem = (item, level = 0) => {
    const hasSubItems = item.subItems && item.subItems.length > 0;

    return (
      <React.Fragment key={item.id}>
        <NestedListItem
          button
          level={level}
          open={open}
          selected={selectedItem === item.id}
          onClick={() => {
            if (!open) {
              setOpen(true);
            } else if (hasSubItems) {
              handleMenuExpand(item.id);
            } else {
              handleMenuClick(item.id);
            }
          }}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
          {hasSubItems &&
            open &&
            (expandedMenus[item.id] ? <ExpandLess /> : <ExpandMore />)}
        </NestedListItem>
        {hasSubItems && open && (
          <Collapse in={expandedMenus[item.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.subItems.map((subItem) =>
                renderMenuItem(subItem, level + 1)
              )}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    );
  };

  const renderContent = () => {
    switch (selectedItem) {
      case "dashboard":
        return (
          <Box>
            <Typography variant="h6">Dashboard Overview</Typography>
            {/* <AdminStats /> */}
          </Box>
        );
      case "users":
        return <UserTable />;
      case "products-main":
        return <ProductsMainTable />;
      case "offer-types":
        return <OfferTypesTable />;
      case "landing-offers":
        return <LandingOffersTable />;
      case "products-table":
        return <ProductsTable />;
      case "products-merge":
        return <ProductMerge />;
      case "products-suggestions":
        return <VariantsDuplicatesSuggestions />;
      case "products-shops":
        return <ProductsLinkedShopsTable />;
      case "admin-categories":
        return <AdminCategories />;
      case "categories-table":
        return <CategoriesTable />;
      case "categories-assignment":
        return <CategoryAssignmentTable />;
      case "categories-mapping":
        return <CategoryMappings />;
      case "blog":
        return <BlogsTable />;
      case "shops":
        return <AdminTable />;
      case "emails":
        return <AdminEmailEditor />;
      case "thesaurus":
        return <ThesaurusTable />;
      case "tickets":
        return <TicketsTable />;
      case "bigbuy-search":
        return <BigBuyProductSearch />;
      case "product-import":
        return <EshopProductImport />;
      case "bilionbuy-products":
        return <BilionBuyProductsTable />;
      case "orders":
        return <EshopOrdersTable />;
      case "delivery":
        return <DeliveryOptionsTable />;
      case "vat":
        return <VATSettingsTable />;
      case "transactions-table":
        return <TransactionsTable />;
      case "wallet-transactions":
        return <WalletTransactionsAdmin />;
      case "uzavierky":
        return <AdminCommissionsTable />;
      default:
        return <Typography>Select an item from the menu</Typography>;
    }
  };

  return (
    <>
      <ToastContainer />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: "100%",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <IconButton
              color="inherit"
              aria-label="otvoriť menu"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              component="a"
              href="/"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <img
                src="https://bbuy.fra1.cdn.digitaloceanspaces.com/img/bilionbuy_white_logo.png"
                alt="BBLogo"
                style={{ maxHeight: "40px" }}
              />
            </Box>
            <ProfileComponent />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: open ? drawerWidth : miniDrawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: open ? drawerWidth : miniDrawerWidth,
              boxSizing: "border-box",
              top: ["64px", "64px", "64px"],
              height: "calc(100% - 64px)",
              overflowX: "hidden",
              transition: (theme) =>
                theme.transitions.create("width", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
            },
          }}
          open={open}
        >
          <List>{menuStructure.map((item) => renderMenuItem(item))}</List>
        </Drawer>
        <Main>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              px: 2, // Equal padding on both sides
              "& .MuiPaper-root": {
                boxShadow: "none",
                borderRadius: 0,
                width: "100%",
                backgroundColor: "transparent",
              },
              "& .MuiTableContainer-root": {
                width: "100%",
                borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
                borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                backgroundColor: "#fff",
              },
            }}
          >
            {renderContent()}
          </Box>
        </Main>
      </Box>
    </>
  );
}
