import { Image, Button, Link, Skeleton } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import Translate from "./TranslationAPI";
import { withAdultContent } from "./hoc/withAdultContent";
import { useAdultContent } from "../providers/AdultContentProvider";
import { formatPrice } from "../utils/formatters";
const FALLBACK_IMAGE =
  "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?v=1530129081";

const SeasonalCard = ({ product: rawProduct, isLoading }) => {
  const { t } = useTranslation();

  // Transform the product data to a consistent format
  const transformProduct = (rawProduct) => {
    if (!rawProduct) return null;

    // Handle seasonal offers format (with shop information)
    if (rawProduct.shop_name && rawProduct.product) {
      return {
        id: rawProduct.product.id || "",
        name: rawProduct.product.product_name || "",
        slug: rawProduct.product.slug || "",
        price: rawProduct.offer_price || "",
        imageUrl: rawProduct.product.product_image_url || FALLBACK_IMAGE,
        cashback: rawProduct.shop_max_cashback || null,
        product_cashback: rawProduct.product_cashback || null,
        drc: null,
        ratingValue: 0,
        ratingCount: 0,
        deliveryDate: "",
        finalUrl: rawProduct.final_url || "",
        shopName: rawProduct.shop_name || "",
        shopLogo: rawProduct.shop_logo_url || "",
        discount: rawProduct.discount || null,
        type: rawProduct.type || "",
      };
    }

    // Handle detailed product information format
    if (rawProduct.product_name || rawProduct.original_name) {
      return {
        id: rawProduct.id || "",
        name: rawProduct.product_name || rawProduct.original_name || "",
        slug: rawProduct.slug || "",
        price: rawProduct.product_price_min
          ? `${formatPrice(rawProduct.product_price_min)} ${
              rawProduct.currency
            }`
          : "",
        imageUrl: rawProduct.product_image_url || FALLBACK_IMAGE,
        cashback: null,
        drc: null,
        ratingValue: rawProduct.rating || 0,
        ratingCount: 0,
        deliveryDate: "",
        finalUrl: "",
        manufacturer: rawProduct.product_manufacturer || "",
        category: rawProduct.product_category || "",
        description:
          rawProduct.product_description ||
          rawProduct.original_description ||
          "",
      };
    }

    // Default format (fallback)
    return {
      id: rawProduct.id || "",
      name: rawProduct.name || rawProduct.title || "",
      slug: rawProduct.slug || rawProduct.id || "",
      price: rawProduct.price || rawProduct.formattedPrice || "",
      imageUrl: rawProduct.imageUrl || rawProduct.image || FALLBACK_IMAGE,
      cashback: rawProduct.cashback || rawProduct.cashbackPercentage || null,
      drc: rawProduct.drc || null,
      ratingValue: rawProduct.ratingValue || rawProduct.rating || 0,
      ratingCount: rawProduct.ratingCount || rawProduct.reviewCount || 0,
      deliveryDate:
        rawProduct.deliveryDate || rawProduct.estimatedDelivery || "",
      finalUrl: rawProduct.finalUrl || rawProduct.url || "",
    };
  };

  const product = transformProduct(rawProduct);

  // Function to clean HTML tags and truncate text
  const cleanAndTruncateText = (html, maxLength = 38) => {
    if (html == null) return "";
    const cleanText = html.replace(/<[^>]*>/g, "");
    return cleanText.length > maxLength
      ? cleanText.substring(0, maxLength) + "..."
      : cleanText;
  };

  if (isLoading) {
    return (
      <div className="px-3 mb-4 flex justify-center items-center">
        <div className="relative rounded-lg flex flex-col gap-2 transition duration-300 w-full max-w-[280px] h-[420px]">
          <div className="relative h-[200px]">
            <Skeleton className="absolute inset-0 rounded-xl" />
          </div>

          {/* Price and cashback skeleton */}
          <div className="flex flex-col gap-1 mt-1">
            <Skeleton className="h-5 w-24" />
            <Skeleton className="h-4 w-36" />
          </div>

          {/* Loyalty program skeleton */}
          <div className="flex flex-row items-center gap-2 mt-1">
            <Skeleton className="h-5 w-5 rounded-full" />
            <Skeleton className="h-4 w-44" />
          </div>

          {/* Product title skeleton */}
          <Skeleton className="h-5 w-full mt-1" />
          <Skeleton className="h-5 w-4/5" />

          {/* Delivery info skeleton */}
          <Skeleton className="h-4 w-32 mt-1" />

          {/* Rating skeleton */}
          <div className="flex flex-row items-center gap-1 mt-1">
            <Skeleton className="h-4 w-24" />
            <Skeleton className="h-4 w-10" />
          </div>
        </div>
      </div>
    );
  }

  if (!product) return null;

  // Get rating data
  const ratingCount = product.ratingCount || 0;
  const ratingValue = product.ratingValue || 0;

  // Format estimated delivery date (only if available)
  const deliveryDate = product.deliveryDate || "";

  return (
    <a href={"/view-product/" + product.slug}>
      <div className="px-3 mb-4">
        <div className="relative rounded-lg flex flex-col transition duration-300 w-full max-w-[280px] h-[420px]">
          {/* Product Image - Fixed height */}
          <div className="rounded-lg overflow-hidden h-[200px] bg-white">
            <img
              src={product.imageUrl}
              alt={product.name}
              className="w-full h-full object-cover bg-white"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = FALLBACK_IMAGE;
              }}
            />
          </div>

          {/* Content Container - Fixed spacing */}
          <div className="flex flex-col justify-between h-[220px] py-2">
            <div className="space-y-2">
              {/* Shop Info - Only show if available */}
              {product.shopName && (
                <div className="flex items-center gap-2">
                  {product.shopLogo && (
                    <img
                      src={product.shopLogo}
                      alt={product.shopName}
                      className="h-5 w-auto object-contain"
                    />
                  )}
                </div>
              )}

              {/* Price and Cashback */}
              <div className="flex flex-row items-center gap-2">
                <span className="font-bold text-lg">{product.price}</span>
                <div className="flex flex-row items-center gap-1">
                  <span className="text-red-500 text-xs font-semibold">
                    {product.product_cashback
                      ? `${formatPrice(product.product_cashback)} % cashback`
                      : null}
                  </span>
                  <span className="text-blue-500 text-xs font-semibold">
                    {product.drc ? `${product.drc}% drc` : null}
                  </span>
                </div>
              </div>

              {/* Discount - Only show if available */}
              {product.discount && (
                <div className="text-red-500 text-xs font-semibold">
                  {t("zľava")}: {product.discount}
                </div>
              )}

              {/* Loyalty Program */}
              <div className="flex flex-row items-center gap-2">
                <div className="bg-red-500 text-white p-1 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-3"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                  </svg>
                </div>
                <span className="text-red-500 text-xs font-semibold">
                  {t("výhodne body do vernostného programu")}
                </span>
              </div>

              {/* Product Title - Fixed height with line clamp */}
              <h3 className="font-bold text-gray-800 text-base h-[46px] line-clamp-2">
                {cleanAndTruncateText(product.name, 70)}
              </h3>

              {/* Manufacturer & Category - Only show if available */}
              {/* {product.manufacturer && (
                <div className="text-xs text-gray-600">
                  {product.manufacturer}
                  {product.category && (
                    <span className="ml-1 text-gray-400">
                      • {product.category.split("|")[0].trim()}
                    </span>
                  )}
                </div>
              )} */}

              {/* Delivery Info - Only show if delivery date exists */}
              {deliveryDate && (
                <div className="text-green-500 font-semibold text-xs">
                  {t("doručenie")} {deliveryDate}
                </div>
              )}

              {/* Rating */}
              <div className="flex flex-row items-center gap-2">
                <div className="flex">
                  {[...Array(5)].map((_, i) => {
                    // Calculate if this star should be full, partial, or empty
                    const starValue = i + 1;
                    const difference = ratingValue - i;

                    // Full star (difference >= 1)
                    if (difference >= 1) {
                      return (
                        <svg
                          key={i}
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 text-yellow-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      );
                    }
                    // Partial star (0 < difference < 1)
                    else if (difference > 0) {
                      return (
                        <div key={i} className="relative h-4 w-4">
                          {/* Gray background star */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute h-4 w-4 text-gray-300"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>
                          {/* Partial yellow star with clip-path */}
                          <div
                            className="absolute h-4 overflow-hidden"
                            style={{ width: `${difference * 100}%` }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4 text-yellow-400"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>
                          </div>
                        </div>
                      );
                    }
                    // Empty star (difference <= 0)
                    else {
                      return (
                        <svg
                          key={i}
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 text-gray-300"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      );
                    }
                  })}
                </div>
                <span className="text-gray-600 font-semibold">
                  {ratingCount > 0 ? ratingCount : null}
                </span>
              </div>
            </div>

            {/* Action Buttons - Fixed at bottom */}
            {/* <div className="w-full flex flex-row justify-between gap-2">
              {product.finalUrl ? (
                <Button
                  className="bg-primary text-white rounded-sm flex-1"
                  as={Link}
                  href={product.finalUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="sm"
                >
                  {t("Do obchodu")}
                </Button>
              ) : (
                <Button
                  className="bg-primary text-white rounded-sm flex-1"
                  as={Link}
                  href={`/view-product/${product.slug}`}
                  size="sm"
                >
                  {t("Zobraziť detail")}
                </Button>
              )}

              <Button
                className="bg-green text-white rounded-sm flex-1"
                as={Link}
                href={`/view-product/${product.slug}`}
                size="sm"
              >
                {product.manufacturer ? t("Porovnať ceny") : t("Porovnaj")}
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </a>
  );
};

export default withAdultContent(SeasonalCard);
