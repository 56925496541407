import React from 'react';
import BasicNavbar from '../components/BasicNavbar';
import Footer from '../components/Footer';
import TextImageComponent from '../components/TextImageComponent';
import HeaderBanner from '../components/HeaderBanner';
import HowItWorks from '../components/HowItWorks';
import FAQ from '../components/FAQ';
import WhatPeopleSay from '../components/WhatPeopleSay';
import ContactUs from '../components/ContactUs';
import BlogLanding from '../components/BlogLanding';
import YouCouldSeeUs from '../components/YouCouldSeeUs';
import InPageRegistration from '../components/InPageRegistration';

export default function Marketplace() {
  return (
    <div className='overflow-x-clip'>
      <BasicNavbar />

      <HeaderBanner
        imageSrc='https://bbuy.fra1.cdn.digitaloceanspaces.com/img/man3.png'
        alt='Growing Cashback'
        title='Rastúci cashback je tu! Výhodnejší ako bežný cashback'
        accordionItems={[
          {
            title: 'Výhodnejší oproti štandardnému cashbacku',
            content:
              'Štandardný cashback predstavuje hodnotu peňazí späť z každého vlastného nákupu. Štandardný cashback nezískate za ľudí, ktorým ste odporúčali využívanie cashbacku. Naopak, hodnota rastúceho cashbacku sa vám zvyšuje na základe histórie vlastných nákupov, ale aj histórie nákupov vašej siete ľudí, ktorým ste rastúci cashback odporučili.',
          },
          {
            title: 'Vždy lacnejší nákup o rastúci cashback',
            content:
              'Nákup, z ktorého získate peniaze späť napríklad vo forme rastúceho cashbacku je pre vás vždy lacnejší ako keby ste nákup uskutočnili bez rastúceho cashbacku. Je to z dôvodu, že získate peniaze z tohto nákupu späť a o túto hodnotu bude pre vás nákup s rastúcim cashbackom vždy lacnejší.',
          },
          {
            title: 'Rastúci cashback je fixný cashback z nákupu a odmena z vernostného programu',
            content:
              'Rastúci cashback je zložený z fixného cashbacku za nákup a z odmien za body získané vo vernostnom programe.\n\nCashback z vlastného nákupu: 2% zo sumy bez DPH.\n\nOdmena z vernostného programu za vlastný nákup:\n- 0,5 EUR za 1 bod nákupu pri štarte vo vernostnom programe\n- 2,5 EUR za 1 bod nákupu pri dosiahnutej 2. pozícii vo vernostnom programe\n- 3,5 EUR za 1 bod nákupu pri dosiahnutej 3. pozícii vo vernostnom programe\n- 4 EUR za 1 bod nákupu pri dosiahnutej 4. pozícii vo vernostnom programe\n- následné zvyšovanie podľa vernostného programu.\n\nRastúci cashback by takto stúpal pri dosahovaní jednotlivých pozícií.',
          },
          {
            title: 'Rastie na základe histórie vlastných nákupov',
            content:
              'Každý užívateľ zbiera vo vernostnom programe body. Každý nákup má svoju bodovú hodnotu v závislosti od výšky odmeny poskytnutej partnerom. Vo vernostnom programe sa získavajú body aj za vlastné nákupy. Takto získané body sa vám spočítavajú. Vernostný program umožňuje získať vyšší rastúci cashback podľa tabuľky vernostného programu.',
          },
          {
            title: 'Rastie na základe histórie nákupov vašej siete',
            content:
              'Každý užívateľ zbiera vo vernostnom programe body. Každý nákup má svoju bodovú hodnotu v závislosti od výšky odmeny poskytnutej partnerom. Vo vernostnom programe sa získavajú body aj za nákupy siete. Do vašej siete patria všetci užívatelia zaregistrovaní vo vašej sieti smerom pod vami bez obmedzenia počtu línií. Takto získané body sa vám spočítavajú. Vernostný program umožňuje získať vyšší rastúci cashback podľa tabuľky vernostného programu.',
          },
          {
            title: 'Vypláca sa podľa tabuľky vernostného programu',
            content:
              'Vernostný program odmeňuje užívateľov podľa histórie vlastných nákupov a nákupov siete. Vernostný program umožňuje vyššie odmeny podľa tabuľky vernostného programu, v ktorej sú odstupňované výšky odmien podľa historicky získaných bodov. Historicky získané body - odmena za 1 bod: 0 bodov - 0,5 EUR 100 bodov - 2,5 EUR 300 bodov - 3,5 EUR 1.000 bodov - 4 EUR 5.000 bodov - 4,5 EUR 25.000 bodov - 5 EUR 100.000 bodov - 5,5 EUR 200.000 bodov - 6 EUR Vernostný program umožňuje získať užívateľovi vyššie odmeny, v prípade, ak v danom mesiaci získa vyššiu odmenu (eurovú hodnotu) za body získane v danom mesiaci, a to podľa tabuľky vernostného programu, v ktorej sú odstupňované výšky odmien podľa získaných bodov v danom mesiaci. Získané body v mesiaci - odmena za 1 bod: 0 bodov - 0,5 EUR 10 bodov - 2,5 EUR 30 bodov - 3,5 EUR 100 bodov - 4 EUR 500 bodov - 4,5 EUR 2.500 bodov - 5 EUR 10.000 bodov - 5,5 EUR 20.000 bodov - 6 EUR 50.000 bodov - 6,5 EUR 100.000 bodov - 7 EUR 200.000 bodov - 7,5 EUR 500.000 bodov - 8 EUR',
          },
        ]}
      />

      <HowItWorks
        title='Ako funguje rastúci cashback?'
        subtitle='Ako funguje vernostný program?'
        steps={[
          {
            number: '1',
            titleKey: 'Lacnejší nákupo rastúci cashback',
            descriptionKey:
              'Získate peniaze z nákupu späť a o túto hodnotu bude pre vás nákup s rastúcim cashbackom vždy lacnejší',
            bgColor: 'bg-[#4A90E2]',
          },
          {
            number: '2',
            titleKey: 'Zvyšuje sa vlastnými nákupmi',
            descriptionKey:
              'Finančná hodnota rastúceho cashbacku rastie na základe toho, čím  viac nákupov ste uskutočnili, čím ste získali viac bodov za nákupy',
            bgColor: 'bg-[#E57697]',
          },
          {
            number: '3',
            titleKey: 'Zvyšuje sanákupmi siete',
            descriptionKey:
              'Finančná hodnota rastúceho cashbacku rastie na základe toho, čím  viac nákupov vaša sieť uskutočnila, čím ste získali viac bodov za nákupy siete',
            bgColor: 'bg-[#A6CF4A]',
          },
          {
            number: '4',
            titleKey: 'Pripísanie odmienz rastúceho cashbacku',
            descriptionKey:
              'Za každý nákup získava užívateľ rastúci cashback, ktorý sa pripisuje do e-peňaženky',
            bgColor: 'bg-[#FB6238]',
          },
          {
            number: '5',
            titleKey: 'Vyplatenie odmienna bankový účet',
            descriptionKey:
              'O vyplatenie odmien je možné požiadať v e-peňaženke. Pred vyplatením si musíte upraviť profil a zadať údaje potrebné na vyplatenie odmeny',
            bgColor: 'bg-[#B8CC2E]',
          },
        ]}
      />

      <TextImageComponent
        imagePosition='left' // or "right"
        heading='Nová filozofiu nakupovania. Predstavujeme vám samospotrebiteľské nákupy.'
        fulltext='Predstavte si nakupovanie, ktoré vám nielenže šetrí čas, ale aj zarába peniaze. Tento inovatívny prístup mení bežné výdavky za potraviny, drogériu, oblečenie či elektroniku na múdru investíciu. Každý nákup prináša možnosť zisku – už to nie je len míňanie, ale cesta k finančnej stabilite. Kľúčovou výhodou je rastúci cashback, ktorý vám vracia časť peňazí späť. Zmeňte každodenné nakupovanie na inteligentný nástroj na efektívne hospodárenie s financiami.'
        imageSrc='https://bbuy.fra1.cdn.digitaloceanspaces.com/img/nova-filiozofia.png'
        imageAlt='Illustration of how cashback works'
        auth={false}
        ctaConfig={{
          primaryButton: {
            text: 'Registrácia zdarma',
            href: '/register',
          },
          secondaryButton: {
            text: 'Viac informácií',
            href: '/blog#vyhody-cashbacku/',
          },
        }}
      />

      <TextImageComponent
        imagePosition='right' // or "right"
        heading='Zmeňte nakupovanie na stroj na zarábanie peňazí. Predstavujeme vám zvyšovanie rastúceho cashbacku vďaka nákupom siete.'
        fulltext='Zvyšovanie odmien z vernostného programu môžete ľahko ovplyvniť rozširovaním svojej siete a ich nákupmi. Okrem cashbacku zohráva kľúčovú úlohu aj  odmena z vernostného programu, ktorá sa odvíja od hodnoty získaných bodov z vašich vlastných nákupov a nákupov siete. Čím viac bodov nazbierate, tým vyššia je vaša odmena, čo vás motivuje nakupovať múdro a strategicky. Každý bod vás približuje k finančným cieľom, takže tento jednoduchý, no efektívny systém vám pomáha stabilne zvyšovať príjem a budovať finančnú istotu.'
        imageSrc='https://bbuy.fra1.cdn.digitaloceanspaces.com/img/zmente-nakupovanie.png'
        imageAlt='Illustration of how cashback works'
        auth={false}
        ctaConfig={{
          primaryButton: {
            text: 'Registrácia zdarma',
            href: '/register',
          },
          secondaryButton: {
            text: 'Viac informácií',
            href: '/blog#vyhody-cashbacku/',
          },
        }}
      />
      <FAQ
        title='Často kladené otázky'
        questions={[
          {
            question: 'Čo je to cashback?',
            answer:
              'Cashback predstavuje hodnotu peňazí, ktoré užívateľ získa späť z každého nákupu.',
          },
          {
            question: 'Aký je rozdiel medzi rastúcim cashbackom a cashbackom?',
            answer:
              'Štandardný cashback predstavuje hodnotu peňazí späť z každého vlastného nákupu. Štandardný cashback nezískate za ľudí, ktorým ste odporúčali využívanie cashbacku. Naopak, hodnota rastúceho cashback sa vám zvyšuje na základe histórie vlastných nákupov, ale aj histórie nákupov vašej siete ľudí, ktorým ste rastúci cashback odporučili.',
          },
          {
            question: 'Kedy sa vypláca rastúci cashback?',
            answer:
              'Ak je nákup zrealizovaný cez nákupný košík Bilionbuy, rastúci cashback sa vypláca do 20 dní od doručenia tovaru. Ak je nákup realizovaný cez preklik do partnerského obchodu, rastúci cashback sa vypláca rôzne podľa partnerov, od 60 do 180 dní.',
          },
          {
            question: 'Ako si môžem zvyšovať rastúci cashback?',
            answer:
              'Rastúci cashback sa zvyšuje dosiahnutím bodových hodnôt za vlastné nákupy a nákupy siete. Najlepší spôsob ako si zvyšovať rastúci cashback je ten, že väčšinu nákupov budete nakupovať cez Bilionbuy a partnerov, a že budete budovať vašu sieť užívateľov, ktorí budú tiež nakupovať na Bilionbuy. Body z nákupov siete sa vám započítavajú automaticky od ľudí, ktorí sú zaregistrovaní pod vami vo vašej siete, a to do hĺbky bez obmedzenia počtu línií. Body z nákupov siete teda získate vtedy, ak zaregistrujete užívateľov vo vašej sieti, ktorí nakupujú na Bilionbuy. Vernostný program odmeňuje užívateľov podľa histórie vlastných nákupov a nákupov siete. Vernostný program umožňuje vyššie odmeny podľa tabuľky vernostného programu, v ktorej sú odstupňované výšky odmien podľa historicky získaných bodov. Historicky získané body - odmena za 1 bod: 0 bodov - 0,5 EUR 100 bodov - 2,5 EUR 300 bodov - 3,5 EUR 1.000 bodov - 4 EUR 5.000 bodov - 4,5 EUR 25.000 bodov - 5 EUR 100.000 bodov - 5,5 EUR 200.000 bodov - 6 EUR Vernostný program umožňuje získať užívateľovi vyššie odmeny, v prípade, ak v danom mesiaci získa vyššiu odmenu (eurovú hodnotu) za body získane v danom mesiaci, a to podľa tabuľky vernostného programu, v ktorej sú odstupňované výšky odmien podľa získaných bodov v danom mesiaci. Získané body v mesiaci - odmena za 1 bod: 0 bodov - 0,5 EUR 10 bodov - 2,5 EUR 30 bodov - 3,5 EUR 100 bodov - 4 EUR 500 bodov - 4,5 EUR 2.500 bodov - 5 EUR 10.000 bodov - 5,5 EUR 20.000 bodov - 6 EUR 50.000 bodov - 6,5 EUR 100.000 bodov - 7 EUR 200.000 bodov - 7,5 EUR 500.000 bodov - 8 EUR',
          },
          {
            question:
              'Ako je možné, že rastie cashback, keď mám pri každom produkte vždy rovnakú hodnotu % vyplácaného cashback?',
            answer:
              'Uvedená hodnota % cashbacku sa týka fixného cashbacku. K tomuto fixnému cashbacku však užívateľ na Bilionbuy získava ďalšiu formu cashbacku a to odmeny z vernostného programu v závislosti od toho na ktorej pozícii vernostného programu sa nachádza.',
          },
          {
            question: 'Aké podmienky musím splniť, aby som si mohol vyplatiť rastúci cashback?',
            answer:
              'Na to aby ste mohli mať vyplatený rastúci cashback alebo odmeny z vernostného programu je potrebné aby ste boli zaregistrovaní a mali doplnené údaje v profile. Následne si môžete požiadať o výplatu z elektronickej peňaženky.',
          },
          {
            question: 'Ako si vyplatím rastúci cashback?',
            answer:
              'Na to aby ste mohli mať vyplatený rastúci cashback alebo odmeny z vernostného programu je potrebné aby ste boli zaregistrovaní a mali doplnené údaje v profile. Následne si môžete požiadať o výplatu z elektronickej peňaženky.',
          },
          {
            question: 'Čo mám robiť, ak mi nebol pripísaný cashback z nákupu?',
            answer:
              'V prípade, ak vám nebol pripísaný cashback z nákupu je potrebné urobiť reklamáciu v sekcii Moja kariéra - Nákupy a kliknúť na tlačidlo Reklamovať. Zároveň je vhodné, aby ste zaslali doklady o kúpe (faktúru, objednávku) na helpdesk@bilionbuy.com.',
          },
          {
            question: 'Čo je rastúci cashback?',
            answer: 'Rastúci cashback je fixný cashback z nákupu a odmena z vernostného programu.',
          },
        ]}
      />

      <BlogLanding />
      <WhatPeopleSay />
      <YouCouldSeeUs />
      <InPageRegistration />
      <Footer />
    </div>
  );
}
