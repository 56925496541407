import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Divider,
  TextField,
  Button,
  Grid,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import { Save as SaveIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import api from "../../../services/api";

const AdminCategoryDetail = ({ categoryId, onClose, onUpdate }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(null);
  const [mappings, setMappings] = useState([]);
  const [editedName, setEditedName] = useState("");
  const [editedOrder, setEditedOrder] = useState(0);
  const [savingCategory, setSavingCategory] = useState(false);

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      if (!categoryId) return;

      setLoading(true);
      try {
        const response = await api.get("admin-category-detail/", {
          params: { target: categoryId },
        });

        if (response.data) {
          setCategory(response.data.category);
          setMappings(response.data.mappings || []);
          setEditedName(response.data.category.category_name || "");
          setEditedOrder(response.data.category.order || 0);
        }
      } catch (error) {
        console.error("Error fetching category details:", error);
        toast.error(t("Failed to load category details"));
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryDetails();
  }, [categoryId, t]);

  const handleSaveCategory = async () => {
    if (!editedName.trim()) {
      toast.error(t("Category name is required"));
      return;
    }

    setSavingCategory(true);
    try {
      await api.post("update-category/", {
        id: categoryId,
        category_name: editedName,
        order: editedOrder,
      });

      toast.success(t("Category updated successfully"));

      // Update the local state
      setCategory({
        ...category,
        category_name: editedName,
        order: editedOrder,
      });

      // Notify parent component
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error("Error updating category:", error);
      toast.error(t("Failed to update category"));
    } finally {
      setSavingCategory(false);
    }
  };

  const handleDeleteMapping = async (mappingId) => {
    try {
      await api.post("delete-category-mapping/", { id: mappingId });
      setMappings(mappings.filter((mapping) => mapping.id !== mappingId));
      toast.success(t("Mapping deleted successfully"));
    } catch (error) {
      console.error("Error deleting mapping:", error);
      toast.error(t("Failed to delete mapping"));
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (!category) {
    return (
      <Box p={4}>
        <Typography variant="h6" color="error">
          {t("Category not found")}
        </Typography>
      </Box>
    );
  }

  return (
    <Paper sx={{ p: 3, maxHeight: "80vh", overflow: "auto" }}>
      {/* Category Information Section */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5" gutterBottom sx={{ mb: 0 }}>
          {t("Edit Category Details")}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={handleSaveCategory}
          disabled={savingCategory}
          size="medium"
        >
          {savingCategory ? t("Saving...") : t("Save Changes")}
        </Button>
      </Box>

      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t("Category Name")}
            variant="outlined"
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t("Order")}
            type="number"
            variant="outlined"
            value={editedOrder}
            onChange={(e) => setEditedOrder(parseInt(e.target.value, 10) || 0)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t("Full Path")}
            variant="outlined"
            value={category.category_fullname || ""}
            InputProps={{ readOnly: true }}
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }} />

      {/* Category Mappings Section */}
      <Typography variant="h5" gutterBottom>
        {t("Category Mappings")}
      </Typography>

      {mappings.length === 0 ? (
        <Typography variant="body1" sx={{ py: 2 }}>
          {t("No mappings found for this category.")}
        </Typography>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t("ID")}</TableCell>
                <TableCell>{t("Language")}</TableCell>
                <TableCell>{t("Category Name")}</TableCell>
                <TableCell>{t("Full Path")}</TableCell>
                <TableCell>{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mappings.map((mapping) => (
                <TableRow key={mapping.id}>
                  <TableCell>{mapping.id}</TableCell>
                  <TableCell>{mapping.language}</TableCell>
                  <TableCell>{mapping.category_name}</TableCell>
                  <TableCell>{mapping.category_fullname}</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDeleteMapping(mapping.id)}
                      title={t("Delete Mapping")}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default AdminCategoryDetail;
