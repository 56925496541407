import { useEffect } from "react";
import { useTranslation } from "react-i18next";

/**
 * Component that initializes and synchronizes language settings
 * This component doesn't render anything visible
 */
function LanguageInitializer() {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Get stored language preferences
    const savedLanguage = localStorage.getItem("i18nextLng");
    const locale = localStorage.getItem("locale");
    const supportedLanguages = ["en", "sk", "cs", "pl", "de", "hu"];

    // Log browser language information for debugging
    console.log("Navigator language:", navigator.language);
    console.log("Navigator languages:", navigator.languages);

    // Enhanced browser language detection
    // First try navigator.languages (array of preferred languages)
    // Then fall back to navigator.language (primary language)
    let detectedLanguage = null;

    // Check navigator.languages array first (better for Safari)
    if (navigator.languages && navigator.languages.length) {
      // Go through each browser language preference
      for (const lang of navigator.languages) {
        const langCode = lang.split("-")[0].toLowerCase();
        if (supportedLanguages.includes(langCode)) {
          detectedLanguage = langCode;
          console.log(
            `Detected supported language from navigator.languages: ${langCode}`
          );
          break;
        }
      }
    }

    // If no match in navigator.languages, try navigator.language
    if (!detectedLanguage && navigator.language) {
      const langCode = navigator.language.split("-")[0].toLowerCase();
      if (supportedLanguages.includes(langCode)) {
        detectedLanguage = langCode;
        console.log(
          `Detected supported language from navigator.language: ${langCode}`
        );
      }
    }

    // Handle language selection
    let finalLanguage = "en"; // Default fallback

    // Check if we have a saved preference
    if (savedLanguage) {
      console.log(`Using saved language: ${savedLanguage}`);
      finalLanguage = savedLanguage;
    } else if (detectedLanguage) {
      // If no saved language, use detected browser language
      console.log(`Using detected browser language: ${detectedLanguage}`);
      finalLanguage = detectedLanguage;
      localStorage.setItem("i18nextLng", detectedLanguage);
    } else {
      // Default to English if no other language is determined
      console.log(`No language detected, using default English`);
      localStorage.setItem("i18nextLng", "en");
    }

    // Set locale based on language ONLY if locale is not already set
    if (!locale) {
      console.log(
        `No locale set, using language ${finalLanguage} to set locale`
      );
      // Most locales match language codes directly, except for Czech
      const localeValue = finalLanguage === "cs" ? "cs" : finalLanguage;
      localStorage.setItem("locale", localeValue);
    }

    // Ensure language is applied in i18n
    if (i18n.language !== finalLanguage) {
      i18n.changeLanguage(finalLanguage);
    }

    // Set HTML lang attribute for accessibility
    document.documentElement.lang = i18n.language;
  }, [i18n]);

  return null; // This component doesn't render anything
}

export default LanguageInitializer;
