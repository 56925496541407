import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@nextui-org/react";

export default function YouCouldSeeUs() {
  const { t } = useTranslation();

  // Placeholder data based on the image
  const articles = [
    {
      id: 1,
      logo: "/images/uploading-sk-logo.png",
      title:
        "Začať získavať cashback je vskutku jednoduché – stačí sa zaregistrovať na portáloch ako BilionBuy. Tieto platformy ponúkajú navrátenie peňazí za nákupy realizované cez ich odkazy u tisícov obchodníkov na internete. Po registrácii a realizácii nákupu prostredníctvom takýchto ...",
      source: "Uploading.sk",
    },
    {
      id: 2,
      logo: "/images/refresher-logo.png",
      title:
        "V súčasnosti sprostredkovatelia cashbacku, ako napríklad bilionbuy.com spolupracujú s množstvom stránok a v rôznych odvetviach, ako je napríklad oblečenie, kozmetika, cestovanie a IT. Ak sa chceš uistiť, že e-shop ponúka cashback, jednoducho prejdi na webovú stránku ...",
      source: "Refresher",
    },
    {
      id: 3,
      logo: "/images/uploading-sk-logo.png",
      title:
        "Začať získavať cashback je vskutku jednoduché – stačí sa zaregistrovať na portáloch ako BilionBuy. Tieto platformy ponúkajú navrátenie peňazí za nákupy realizované cez ich odkazy u tisícov obchodníkov na internete. Po registrácii a realizácii nákupu prostredníctvom takýchto ...",
      source: "Uploading.sk",
    },
    {
      id: 4,
      logo: "/images/refresher-logo.png",
      title:
        "V súčasnosti sprostredkovatelia cashbacku, ako napríklad bilionbuy.com spolupracujú s množstvom stránok a v rôznych odvetviach, ako je napríklad oblečenie, kozmetika, cestovanie a IT. Ak sa chceš uistiť, že e-shop ponúka cashback, jednoducho prejdi na webovú stránku ...",
      source: "Refresher",
    },
  ];

  return (
    <>
      {/* <div className="max-w-screen-2xl mx-auto py-16 px-4 md:px-6">
        <h2 className="text-center text-3xl font-semibold mb-12">
          {t("youCouldSeeUs.title")}
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {articles.map((article) => (
            <div
              key={article.id}
              className="flex flex-col max-w-lg rounded-lg overflow-hidden p-4"
            >
              <div className="p-4 bg-black">
                <img
                  src={article.logo}
                  alt={article.source}
                  className="h-10 object-contain"
                />
              </div>
              <div className="p-4 flex-1">
                <p className="text-sm mb-4">"{article.title}"</p>
              </div>
              <div className="mt-auto p-4 mx-auto">
                <Button className="px-12 bg-black text-white  py-2 rounded-3xl">
                  {t("Článok")}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div> */}
    </>
  );
}
