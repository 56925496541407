import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "@nextui-org/react";
import api from "../../services/api";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { electronicFormatIBAN, isValidIBAN, validateBIC } from "ibantools";
import { IoIosArrowBack } from "react-icons/io";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export default function Edit({ onMenuToggle, t }) {
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [ibanValid, setIbanValid] = useState(true);
  const [bicValid, setBicValid] = useState(true);

  const checkIban = (i) => {
    const formattedIban = electronicFormatIBAN(i);
    const valid = isValidIBAN(formattedIban);
    setIbanValid(valid);
  };

  const checkBic = (bic) => {
    const valid = validateBIC(bic);
    setBicValid(valid);
  };

  const [userInfo, setUserInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    country: "",
    phone_number: "",
    address: "",
    city: "",
    zip_code: "",
    state: "",
    avatar: "",
    referral_code: "",
    referer: "",
    communication_language: "",
    billing_data: {
      name: "",
      email: "",
      country: "",
      phone: "",
      address: "",
      city: "",
      zip: "",
      state: "",
      company: "",
      company_id: "",
      tax_id: "",
      vat_id: "",
      vat_payer: false,
      iban: "",
      swift: "",
      currency: "EUR",
      is_company: false, // New state to track if buying for business
    },
  });

  const [originalUserInfo, setOriginalUserInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchUserData = async () => {
    try {
      const response = await api.get("get-user-data/");
      setUserInfo(response.data);
      setOriginalUserInfo(response.data);
    } catch (error) {
      console.error("fetchUserData -> error", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    checkIban(userInfo.billing_data.iban);
  }, [userInfo.billing_data.iban]);

  useEffect(() => {
    checkBic(userInfo.billing_data.swift);
  }, [userInfo.billing_data.swift]);

  useEffect(() => {
    // Automatically check "Nakupujem na firmu" if the company field has a value
    if (userInfo.billing_data.company) {
      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        billing_data: {
          ...prevUserInfo.billing_data,
          is_company: true,
        },
      }));
    }
  }, [userInfo.billing_data.company]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith("billing_data.")) {
      const billingField = name.split(".")[1];
      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        billing_data: {
          ...prevUserInfo.billing_data,
          [billingField]: type === "checkbox" ? checked : value,
        },
      }));
    } else {
      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Define required fields based on active tab
    let requiredFields = {};

    if (tabValue === 0) {
      // Personal information tab
      requiredFields = {
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        phone_number: userInfo.phone_number,
        country: userInfo.country,
        city: userInfo.city,
        address: userInfo.address,
        zip_code: userInfo.zip_code,
      };
    } else if (tabValue === 1) {
      // Billing information tab
      requiredFields = {
        "billing_data.name": userInfo.billing_data?.name,
        "billing_data.email": userInfo.billing_data?.email,
        "billing_data.country": userInfo.billing_data?.country,
        "billing_data.phone": userInfo.billing_data?.phone,
        "billing_data.address": userInfo.billing_data?.address,
        "billing_data.city": userInfo.billing_data?.city,
        "billing_data.zip": userInfo.billing_data?.zip,
        "billing_data.iban": userInfo.billing_data?.iban,
        "billing_data.swift": userInfo.billing_data?.swift,
      };

      // Add company-related fields only if is_company is checked
      if (userInfo.billing_data?.is_company) {
        Object.assign(requiredFields, {
          "billing_data.company": userInfo.billing_data?.company,
          "billing_data.company_id": userInfo.billing_data?.company_id,
          "billing_data.tax_id": userInfo.billing_data?.tax_id,
        });

        if (userInfo.billing_data?.vat_payer) {
          requiredFields["billing_data.vat_id"] = userInfo.billing_data?.vat_id;
        }
      }
    }

    // Check for empty required fields
    const emptyFields = Object.entries(requiredFields)
      .filter(([_, value]) => !value || String(value).trim() === "")
      .map(([key]) => key);

    if (emptyFields.length > 0) {
      const fieldNames = emptyFields
        .map((field) => {
          const friendlyName = field.replace("billing_data.", "");
          return t(friendlyName);
        })
        .join(", ");

      toast.error(`${t("Prosím vyplňte povinná pole")}: ${fieldNames}`);
      return;
    }

    // Validate IBAN and BIC only if we're on billing tab
    if (tabValue === 1) {
      if (!ibanValid || !bicValid) {
        toast.error(t("IBAN alebo SWIFT/BIC nie je platný"));
        return;
      }
    }

    setLoading(true);
    try {
      await api.post("update-user-data/", userInfo);
      toast.success(t("Profil úspešne aktualizovaný!"));
    } catch (error) {
      toast.error(t("Aktualizácia profilu zlyhala. Skúste to znova."));
      console.error("handleSubmit -> error", error);
    } finally {
      setLoading(false);
    }
  };

  const resetChanges = () => {
    if (originalUserInfo) {
      setUserInfo(originalUserInfo);
    }
  };

  // Add this helper function to show required field indicator
  const RequiredLabel = ({ text }) => (
    <span className="flex items-center gap-1">
      {text} <span className="text-red-500">*</span>
    </span>
  );

  return (
    <div className="pb-12 ">
      <ToastContainer />
      <div className="lg:block hidden uppercase font-bold pt-12 text-3xl">
        {t("UPRAVIŤ PROFIL")}
      </div>
      <div
        className="lg:hidden uppercase font-bold py-12 text-2xl flex items-center gap-2 cursor-pointer"
        onClick={onMenuToggle}
      >
        <IoIosArrowBack className="h-6 w-6 cursor-pointer text-gray-700" />
        {t("UPRAVIŤ PROFIL")}
      </div>

      <div className="flex xl:flex-row flex-col">
        <div className="max-w-lg">
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs">
            <Tab label={t("osobné údaje")} />
            <Tab label={t("fakturačné údaje")} />
          </Tabs>
          <Box hidden={tabValue !== 0} p={3}>
            <div className="max-w-lg">
              <div className="font-semibold mb-2 text-2xl uppercase">
                {t("osobné údaje")}
              </div>

              <hr />
              <TextField
                label={<RequiredLabel text={t("meno")} />}
                name="first_name"
                value={userInfo.first_name}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={loading}
                required
                error={tabValue === 0 && !userInfo.first_name}
                helperText={
                  tabValue === 0 && !userInfo.first_name
                    ? t("Toto pole je povinné")
                    : ""
                }
              />
              <TextField
                label={t("priezvisko")}
                name="last_name"
                value={userInfo.last_name}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={loading}
                required
              />
              <TextField
                label="E-mail"
                name="email"
                value={userInfo.email}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                disabled
                margin="normal"
              />
              <TextField
                label={t("telefón")}
                name="phone_number"
                value={userInfo.phone_number}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={loading}
                required
              />
              <div className="mt-4 max-w-xl">
                <div className="font-semibold mb-2 text-2xl uppercase">
                  {t("adresa")}
                </div>
                <hr />
                <TextField
                  label={t("krajina")}
                  name="country"
                  value={userInfo.country}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled={loading}
                  required
                />
                <TextField
                  label={t("mesto")}
                  name="city"
                  value={userInfo.city}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled={loading}
                  required
                />
                <TextField
                  label={t("adresa")}
                  name="address"
                  value={userInfo.address}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled={loading}
                  required
                />
                <TextField
                  label={t("PSČ")}
                  name="zip_code"
                  value={userInfo.zip_code}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled={loading}
                  required
                />
                <TextField
                  label={t("kraj")}
                  name="state"
                  value={userInfo.state}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled={loading}
                />
              </div>
            </div>
          </Box>

          <Box hidden={tabValue !== 1} p={3} className="max-w-xl">
            <div className="max-w-xl">
              <div className="font-semibold mb-2 text-2xl uppercase">
                {t("fakturačné údaje")}
              </div>
              <hr />

              <TextField
                label={t("meno")}
                name="billing_data.name"
                value={userInfo.billing_data.name}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={loading}
                required
              />
              <TextField
                label="E-mail"
                name="billing_data.email"
                value={userInfo.billing_data.email}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={loading}
                required
              />
              <TextField
                label={t("krajina")}
                name="billing_data.country"
                value={userInfo.billing_data.country}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={loading}
                required
              />
              <TextField
                label={t("telefón")}
                name="billing_data.phone"
                value={userInfo.billing_data.phone}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={loading}
                required
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={userInfo.billing_data.is_company}
                    onChange={handleChange}
                    name="billing_data.is_company"
                    color="primary"
                    disabled={loading}
                  />
                }
                label={t("nakupujem na firmu")}
              />
              {userInfo.billing_data.is_company && (
                <>
                  <TextField
                    label={t("spolocnot")}
                    name="billing_data.company"
                    value={userInfo.billing_data.company}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    disabled={loading}
                    required={userInfo.billing_data.is_company}
                  />
                  <TextField
                    label={t("IČO")}
                    name="billing_data.company_id"
                    value={userInfo.billing_data.company_id}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    disabled={loading}
                    required={userInfo.billing_data.is_company}
                  />
                  <TextField
                    label={t("DIČ")}
                    name="billing_data.tax_id"
                    value={userInfo.billing_data.tax_id}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    disabled={loading}
                    required={userInfo.billing_data.is_company}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={userInfo.billing_data.vat_payer}
                        onChange={handleChange}
                        name="billing_data.vat_payer"
                        color="primary"
                        disabled={loading}
                      />
                    }
                    label={t("platca dph")}
                  />
                  {userInfo.billing_data.vat_payer && (
                    <TextField
                      label={t("IČ DPH")}
                      name="billing_data.vat_id"
                      value={userInfo.billing_data.vat_id}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      disabled={loading}
                      required={
                        userInfo.billing_data.is_company &&
                        userInfo.billing_data.vat_payer
                      }
                    />
                  )}
                </>
              )}
              <TextField
                label={t("adresa")}
                name="billing_data.address"
                value={userInfo.billing_data.address}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={loading}
                required
              />
              <TextField
                label={t("mesto")}
                name="billing_data.city"
                value={userInfo.billing_data.city}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={loading}
                required
              />
              <TextField
                label={t("PSČ")}
                name="billing_data.zip"
                value={userInfo.billing_data.zip}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={loading}
              />
              <TextField
                label={t("kraj")}
                name="billing_data.state"
                value={userInfo.billing_data.state}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={loading}
              />
              <TextField
                label={t("IBAN")}
                name="billing_data.iban"
                value={userInfo.billing_data.iban}
                onChange={(e) => handleChange(e)}
                onBlur={() => checkIban(userInfo.billing_data.iban)}
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={loading}
                error={!ibanValid}
                helperText={!ibanValid ? "IBAN je neplatný" : ""}
              />
              <TextField
                label={t("SWIFT/BIC")}
                name="billing_data.swift"
                value={userInfo.billing_data.swift}
                onChange={(e) => handleChange(e)}
                onBlur={() => checkBic(userInfo.billing_data.swift)}
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={loading}
                error={!bicValid}
                helperText={!bicValid ? "SWIFT/BIC je neplatný" : ""}
              />
              <FormControl
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={loading}
                required
              >
                <InputLabel>{t("mena")}</InputLabel>
                <Select
                  name="billing_data.currency"
                  value={userInfo.billing_data.currency}
                  onChange={handleChange}
                  label={t("mena")}
                >
                  <MenuItem value="EUR">EUR</MenuItem>
                  <MenuItem value="CZK">CZK</MenuItem>
                  <MenuItem value="PLN">PLN</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={loading}
                required
              >
                <InputLabel>{t("Komunikačný jazyk")}</InputLabel>
                <Select
                  name="communication_language"
                  value={userInfo.communication_language}
                  onChange={handleChange}
                  label={t("Komunikačný jazyk")}
                >
                  <MenuItem value="sk">SK</MenuItem>
                  <MenuItem value="cz">CZ</MenuItem>
                  <MenuItem value="pl">PL</MenuItem>
                  <MenuItem value="de">DE</MenuItem>
                  <MenuItem value="hu">HU</MenuItem>
                  <MenuItem value="en">EN</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Box>
        </div>
        <div>
          <div className="p-16 max-w-xl">
            <div className="font-semibold mb-2 text-2xl uppercase">
              {t("ďalšie informácie")}
            </div>
            <hr />
            <TextField
              label={t("odporúčateľský kód")}
              name="referral_code"
              value={
                "https://www.bilionbuy.com/register/?ref=" +
                userInfo.referral_code
              }
              onChange={handleChange}
              variant="outlined"
              fullWidth
              disabled
              margin="normal"
            />
            <TextField
              label={t("váš odporúčateľ")}
              name="referredBy"
              value={userInfo.referer ? userInfo.referer : "-"}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              disabled
              margin="normal"
            />
          </div>
        </div>
      </div>
      <div className="mt-4  xl:mr-24 flex flex-row gap-4 xl:justify-end justify-center">
        <Button disabled={loading} onClick={resetChanges}>
          {t("zahodiť zmeny")}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? <Spinner size="sm" /> : t("uložiť")}
        </Button>
      </div>
    </div>
  );
}
