import React from "react";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import TextImageComponent from "../components/TextImageComponent";
import HowItWorks from "../components/HowItWorks";
import FAQ from "../components/FAQ";
import WhatPeopleSay from "../components/WhatPeopleSay";
import ContactUs from "../components/ContactUs";
import BlogLanding from "../components/BlogLanding";
import YouCouldSeeUs from "../components/YouCouldSeeUs";
import InPageRegistration from "../components/InPageRegistration";
import HeaderBanner from "../components/HeaderBanner";
import BuyBilionbuy from "../components/BuyBilionbuy";
export default function RewardsFromEarnings() {
  const faqQuestions = [
    {
      question:
        "Kedy je najvýhodnejšie poskytnúť finančné prostriedky na Bilionbuy Start-up?",
      answer:
        "Suma a jej zodpovedajúci podiel sa zvyšuje po poskytnutí každých 500 násobkov (kusov), preto je najvýhodnejšie si ich zakúpiť čo najskôr za najvýhodnejšiu cenu.",
    },
    {
      question: "Aká je aktuálna suma za 1 násobok (kus) Bilionbuy start-up?",
      answer:
        "Aktuálnu sumu nájdete na webovej platforme Bilionbuy v časti Odmeny z celého Bilionbuy.",
    },
    {
      question: "Odmeny z obratov Bilionbuy sa týkajú iba premium členov?",
      answer: "Nie. Sú v ponuke pre všetkých registrovaných užívateľov.",
    },
    {
      question: "Bude na webe zverejnené moje meno ako “spolumajiteľ” firmy?",
      answer:
        "Nie. Poskytnutím finančných prostriedkov do Bilionbuy Start-up sa nestávate spolumajiteľom spoločnosti.",
    },
    {
      question: "Ako sa vypočítava zhodnotenie z obratu celého Bilionbuy?",
      answer:
        "Úrok sa vypočítava nasledovne. V rozsahu každého násobku minimálnej sumy, ktorú je možné poskytnúť na Bilionbuy start-up bude užívateľovi pripísaný finančný kredit do elektronickej peňaženky vo výške súčinu podielu (poskytnutých násobkov na Bilionbuy start-up z celkovo možných 30 000 násobkov Bilionbuy start-up) a sumy na prerozdelenie užívateľom, ktorá tvorí súčin 1 EUR a počtu bodov všetkých nákupov na Bilionbuy vo všetkých dostupných štátoch a za všetky dostupné produkty a služby na trhovisku.",
    },
    {
      question: "Kde nájdem výšku mesačného bodového obratu celého Bilionbuy?",
      answer:
        "Každý užívateľ si môže sledovať svoje zárobky po prihlásení sa na webovej platforme v sekcii Moja kariéra - Podielové odmeny. V tejto sekcii sa zverejňujú aj bodové obraty celého Bilionbuy, avšak len tým užívateľom, ktorí poskytli finančné prostriedky do Bilionbuy Start-up.",
    },
    {
      question:
        "Ako požiadam o vrátanie poskytnutých finančných prostriedkov do Bilionbuy Start-up?",
      answer:
        "Požiadať o vrátenie poskytnutých finančných prostriedkov vo výške 100% môžete po prihlásení sa na webovej platforme v sekcii Moja kariéra - Podielové odmeny.",
    },
    {
      question: "Ako často sa vypláca zhodnotenie?",
      answer:
        "Za poskytnuté finančné prostriedky sa užívateľovi vypláca ročný úrok najneskôr do 60 dní nasledujúceho roka za kalendárny rok, ktorého sa úrok týka.",
    },
  ];

  return (
    <div className="overflow-x-clip">
      <BasicNavbar />

      <HeaderBanner
        title="Finančné kredity z celkového bodového obratu Bilionbuy"
        imageSrc="https://bbuy.fra1.cdn.digitaloceanspaces.com/img/man4.png"
        heading="Finančné kredity z celkového bodového obratu Bilionbuy"
        accordionItems={[
          {
            title:
              "doživotné a dedičné podiely vo forme finančných kreditov na obrate Bilionbuy",
            content:
              "Doživotný a dedičný podiel na zárobkoch Bilionbuy získa užívateľ, ktorý poskytol finančné prostriedky do Bilionbuy Start-up. Užívateľ môže poskytnúť finančné prostriedky maximálne v rozsahu 300 násobkov (kusov). Za každý násobok (kus) získa užívateľ 1 podiel na odmenách zo zárobkov Bilionbuy. Celkovo je v obehu 30.000 násobkov kusov. Na odmeny zo zárobkov je vyčlených 1 EUR * celkový bodový obrat, teda z dosiahnutého obratu spoločnosti Bilionbuy, teda všetkých zárobkov zo všetkých aktuálnych, ale aj budúcich podnikateľských aktivít Bilionbuy, zo všetkých štátov.",
          },
          {
            title:
              "sledovanie finančných kreditov v moja kariéra a v e-peňaženke",
            content:
              "Každý užívateľ si môže sledovať svoje zárobky po prihlásení sa na webovej platforme v sekcii Moja kariéra - Podielové odmeny.",
          },
          {
            title: "garancia spätného vrátenia 100% sumy",
            content:
              "Bilionbuy garantuje užívateľovi, ktorý poskytol finančné prostriedky na Bilionbuy start-up spätné vrátenie poskytnutých finančných prostriedkov vo výške 100% najneskôr do 90 dní od jeho žiadosti v prípade, ak užívateľ bude chcieť vrátiť finančné prostriedky.",
          },
          {
            title: "garantované zhodnotenie",
            content:
              "Bilionbuy garantuje v prípade uplatnenia garancie spätného vrátenia finančných prostriedkov, ročné zhodnotenie vo výške 4% ročne v prípade, ak vypočítané zhodnotenie podľa obratov nebolo vyššie.",
          },
        ]}
      />
      <HowItWorks
        title="Ako získam finančné kredity z celkového bodového obratu Bilionbuy?"
        subtitle="Ako získam finančné kredity z celkového bodového obratu Bilionbuy?"
        steps={[
          {
            number: "1",
            titleKey: "Nákup Bilionbuy Start-up",
            descriptionKey:
              "Bilionbuy Start-up je možné zakúpiť priamo na webovej platforme. 1 užívateľ môže zakúpiť maximálne 300 kusov. Za každý kus zakúpeného Bilionbuy Start-up získa užívateľ podiel na bodovom obrate",
            bgColor: "bg-[#4A90E2]",
          },
          {
            number: "2",
            titleKey: "Prístup k marketingovým materiálom",
            descriptionKey:
              "Užívateľ má prístup k marketingovým materiálom po prihlásení v sekcii Moja kariéra - Materiály",
            bgColor: "bg-[#E57697]",
          },
          {
            number: "3",
            titleKey: "Pripisovanie finančných kreditov do e-peňaženky",
            descriptionKey:
              "Finančné kredity sa pripisujú každý mesiac do e-peňaženky podľa skutočného dosiahnutého bodového obratu Bilionbuy",
            bgColor: "bg-[#A6CF4A]",
          },
          {
            number: "4",
            titleKey: "Vyplácanie finančných kreditov na bankový účet",
            descriptionKey:
              "O vyplatenie finančných kreditov je možné požiadať v e-peňaženke. Pred vyplatením si musíte upraviť profil a zadať údaje potrebné na vyplatenie odmeny",
            bgColor: "bg-[#FB6238]",
          },
          {
            number: "5",
            titleKey: "Garancia spätného odkupu",
            descriptionKey:
              "V prípade nespokojnosti, Bilionbuy garantuje spätný odkup a vrátenie zaplatených peňazí za nákup Bilionbuy Start-up za kúpnu cenu navýšenú v prospech užívateľa o 4% ročne",
            bgColor: "bg-[#B8CC2E]",
          },
        ]}
      />
      <TextImageComponent
        imagePosition="left" // or "right"
        heading="Zakúpte si Bilionbuy Start-up a staňte sa “spolumajiteľom” platformy Bilionbuy"
        fulltext="Bilionbuy každého užívateľa, ktorý si zakúpi Bilionbuy start-up, označuje za „spolumajiteľa“. Týmto krokom získava podiel na zárobkoch platformy. Spolumajiteľ má nárok na marketingové materiály a podiel zo zárobkov, no nedisponuje rozhodovacími ani inými právami. Platforma Bilionbuy funguje na princípe samospotrebiteľstva, čím vytvára trhovisko „nás všetkých“. Každý zákazník, ktorý tu nakupuje, sa môže cítiť ako spolumajiteľ tohto obchodu – nášho spoločného trhoviska, kde profitujeme spolu."
        imageSrc="https://bbuy.fra1.cdn.digitaloceanspaces.com/img/cashback_work.png"
        imageAlt="Illustration of how cashback works"
        auth={false}
        ctaConfig={{
          primaryButton: {
            text: "Registrácia zdarma",
            href: "/register",
          },
          secondaryButton: {
            text: "Viac informácií",
            href: "/blog#vyhody-cashbacku/",
          },
        }}
      />

      <BuyBilionbuy />
      <FAQ title="Najčastejšie otázky" questions={faqQuestions} />
      <BlogLanding />
      <WhatPeopleSay />
      <YouCouldSeeUs />
      <InPageRegistration />
      <Footer />
    </div>
  );
}
