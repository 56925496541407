import { Input } from "@nextui-org/react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { logo_url } from "../data/common";
import { ALL_COUNTRIES } from "../data/countries";
import ReactCountryFlag from "react-country-flag";

export default function Footer() {
  const { t } = useTranslation();

  // Filter to show only specific countries in the footer
  const footerCountries = ALL_COUNTRIES.filter((country) =>
    [
      "DE",
      "FR",
      "PL",
      "IT",
      "CZ",
      "SK",
      "HR",
      "NL",
      "PT",
      "LU",
      "GR",
      "EE",
      "BG",
      "ES",
      "AT",
      "HU",
      "LT",
      "FI",
      "SI",
    ].includes(country.code)
  );

  const navigation = {
    o_bilionbuy: [
      { name: t("Kontakt"), href: "/contact" },
      { name: t("Podmienky užívania"), href: "/terms-and-conditions" },
      { name: t("Blog"), href: "/blog" },
    ],
    support: [
      { name: t("Registrácia"), href: "/register" },
      { name: t("Trhovisko"), href: "/marketplace" },
    ],
    company: [{ name: t("Registrovať nový shop"), href: "/register-shop" }],
    registered: [
      { name: t("Vernostný program"), href: "/loyalty-program" },
      { name: t("Rastúci cashback"), href: "/growing-cashback" },
      { name: t("Delenie obratov"), href: "/rewards-from-earnings" },
    ],
    social: [
      {
        name: "Facebook",
        href: "https://www.facebook.com/bilionbuy",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "Instagram",
        href: "https://www.instagram.com/bilionbuy/",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "YouTube",
        href: "#",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
    ],
  };

  return (
    <footer className="bg-lightblue" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-16 ">
        <div className="flex flex-col md:flex-row justify-between">
          {/* Left side - Logo and newsletter */}
          <div className="space-y-8 max-w-md">
            <img
              src={logo_url}
              alt="Bilionbuy Logo"
              className="max-w-[200px]"
            />
            {/* <p className="text-sm text-gray-700">
              {t(
                "Prihláste sa do nášho newslettera a získajte aktuálne informácie o funkciách a novinkách"
              )}
            </p>
            <div>
              <form
                method="post"
                target="_self"
                action="https://app.smartemailing.cz/public/web-forms-v2/display-form/674615-cuky56x1yg2r4coh6eaxxfkvh6yfmqajhp0onaq6o35vaifi6ujkdgfllwet7gkwt73c454s4bzc502pnncuf2fwokfotf2lejl8"
                id="se20-webform-674615-cuky56x1yg2r4coh6eaxxfkvh6yfmqajhp0onaq6o35vaifi6ujkdgfllwet7gkwt73c454s4bzc502pnncuf2fwokfotf2lejl8"
                className="flex flex-row gap-2"
              >
                <label htmlFor="newsletter-email" className="sr-only">
                  Email
                </label>
                <input
                  className="py-2 px-4 rounded-lg text-sm border border-gray-300"
                  type="email"
                  placeholder="E-mail"
                  required
                  aria-label="Enter your email"
                  name="fields[df_emailaddress]"
                  id="frm-webFormHtmlRenderer-webFormForm-fields-df_emailaddress"
                  data-emailaddress="1"
                />
                <button
                  type="submit"
                  className="bg-primary text-white text-sm py-2 px-4 rounded-lg"
                  aria-label="Subscribe to the newsletter"
                  name="_submit"
                >
                  {t("odoberať")}
                </button>
                <input
                  type="hidden"
                  name="referrer"
                  id="se-ref-field-id"
                  value=""
                />
                <input
                  type="hidden"
                  name="sessionid"
                  id="se-sessionid-field"
                  value=""
                />
                <input
                  type="hidden"
                  name="sessionUid"
                  id="se-sessionUid-field"
                  value=""
                />
                <input
                  type="hidden"
                  name="_do"
                  value="webFormHtmlRenderer-webFormForm-submit"
                />
              </form> 
              </div> */}
          </div>

          {/* Right side - Country selection */}
          <div className="mt-8 md:mt-0">
            <h3 className="text-xl font-bold mb-4">{t("Kde sme?")}</h3>
            <div className="grid xl:grid-cols-10  grid-cols-5 gap-4">
              {footerCountries.map((country) => (
                <a
                  key={country.code}
                  // href={`/${country.code.toLowerCase()}`}
                  href="#"
                  className="block"
                  aria-label={country.name}
                >
                  <div className="w-12 h-12 rounded-full flex items-center justify-center overflow-hidden border border-gray-200">
                    <ReactCountryFlag
                      countryCode={country.code}
                      svg
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      title={country.name}
                    />
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Navigation links */}
        <div className="mt-16 grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-sm font-semibold leading-6 text-black">
              Bilionbuy
            </h3>
            <ul role="list" className="mt-6 space-y-4">
              {navigation.o_bilionbuy.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    className="text-sm leading-6 text-gray-600 hover:text-black"
                    aria-label={item.name}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-sm font-semibold leading-6 text-black">
              {t("Pre partnerov")}
            </h3>
            <ul role="list" className="mt-6 space-y-4">
              {navigation.company.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    className="text-sm leading-6 text-gray-600 hover:text-black"
                    aria-label={item.name}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-sm font-semibold leading-6 text-black">
              {t("Pre zákazníkov")}
            </h3>
            <ul role="list" className="mt-6 space-y-4">
              {navigation.support.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    className="text-sm leading-6 text-gray-600 hover:text-black"
                    aria-label={item.name}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-sm font-semibold leading-6 text-black">
              {t("Pre registrovaných")}
            </h3>
            <ul role="list" className="mt-6 space-y-4">
              {navigation.registered.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    className="text-sm leading-6 text-gray-600 hover:text-black"
                    aria-label={item.name}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Copyright and social links */}
      <div className="border-t border-gray-200 bg-white">
        <div className="flex justify-between mx-auto max-w-7xl items-center py-8 px-6">
          <p className="text-xs leading-5 text-gray-500">
            &copy; Bilionbuy, All rights reserved
          </p>
          <div className="flex space-x-2">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-white bg-primary p-2 rounded-lg"
                aria-label={`Visit our ${item.name} page`}
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}
