import { Image, Button } from "@nextui-org/react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import CustomCarousel from "./CustomCarousel";

// Define TypeScript interface for the step structure
const defaultSteps = [
  {
    number: "1",
    titleKey: "howItWorks.step1.title",
    descriptionKey: "howItWorks.step1.description",
    bgColor: "bg-[#4A90E2]",
  },
  {
    number: "2",
    titleKey: "howItWorks.step2.title",
    descriptionKey: "howItWorks.step2.description",
    bgColor: "bg-[#E57697]",
  },
  {
    number: "3",
    titleKey: "howItWorks.step3.title",
    descriptionKey: "howItWorks.step3.description",
    bgColor: "bg-[#A6CF4A]",
  },
  {
    number: "4",
    titleKey: "howItWorks.step4.title",
    descriptionKey: "howItWorks.step4.description",
    bgColor: "bg-[#FB6238]",
  },
  {
    number: "5",
    titleKey: "howItWorks.step5.title",
    descriptionKey: "howItWorks.step5.description",
    bgColor: "bg-[#B8CC2E]",
  },
];

export default function HowItWorks({
  title = "howItWorks.title",
  subtitle = "howItWorks.subtitle",
  steps = defaultSteps,
  className = "",
  containerClassName = "",
}) {
  const { t } = useTranslation();
  return (
    <motion.div
      className={`py-16 w-full bg-lightblue ${className}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div
        className={`flex flex-col gap-8 max-w-screen-2xl mx-auto ${containerClassName}`}
      >
        <motion.div
          className="text-center text-blue font-semibold"
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 300 }}
        >
          {/* {t(subtitle)} */}
        </motion.div>
        <motion.div
          className="text-center text-5xl font-bold"
          whileHover={{ scale: 1.05 }}
          transition={{ type: "spring", stiffness: 300 }}
        >
          {t(title)}
        </motion.div>
        <motion.div
          className="mx-4"
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <CustomCarousel
            itemWidth="280px"
            itemMaxWidth="280px"
            breakpoints={{
              "(max-width: 639px)": {
                mode: "snap",
                slides: { perView: 2, spacing: 16 },
                origin: "center",
              },
              "(min-width: 640px)": {
                mode: "snap",
                slides: { perView: 2, spacing: 16 },
                origin: "center",
              },
              "(min-width: 768px)": {
                mode: "snap",
                slides: { perView: 3, spacing: 16 },
                origin: "center",
              },
              "(min-width: 1024px)": {
                mode: "snap",
                slides: { perView: 4, spacing: 16 },
                origin: "center",
              },
              "(min-width: 1280px)": {
                mode: "snap",
                slides: { perView: 5, spacing: 16 },
                origin: "center",
              },
            }}
          >
            {steps.map((step, index) => (
              <div
                key={index}
                className="flex flex-col items-center p-4 max-w-[250px]"
              >
                <div
                  className={`${step.bgColor} w-20 h-20 rounded-full flex items-center justify-center mb-4`}
                >
                  <span className="text-white text-3xl font-bold">
                    {step.number}
                  </span>
                </div>
                <h3 className="text-center font-semibold mb-2">
                  {t(step.titleKey)}
                </h3>
                <p className="text-center text-sm text-lightgray">
                  {t(step.descriptionKey)}
                </p>
              </div>
            ))}
          </CustomCarousel>
        </motion.div>

        {/* Registration Button Section */}
        <motion.div
          className="flex justify-center mt-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          <Button
            as="a"
            href="/register"
            className="bg-blue text-white rounded-3xl font-bold py-3 md:py-4 px-8 md:px-10 text-base md:text-lg"
          >
            {t("Registrácia zdarma")} &rarr;
          </Button>
        </motion.div>
      </div>
    </motion.div>
  );
}
