import { useLayoutEffect, useRef, useState, useEffect } from "react";
import EditButton from "./EditButton";
import api from "../services/api";
import MagnifyingButton from "./MagnifyingButton";
import { debounce } from "lodash";
import { Button, Skeleton } from "@nextui-org/react";
import DeleteButton from "./DeleteButton";
import AddButton from "./AddButton";
import ReactCountryFlag from "react-country-flag";
import ShopProcessing from "./ShopProcessing";
import { FaCheck, FaStar, FaCog } from "react-icons/fa";
import DownloadButton from "./DownloadButton";
import { Select, SelectSection, SelectItem } from "@nextui-org/react";
import classNames from "classnames";
import { ToastContainer, toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Popover from "@mui/material/Popover";

import "react-toastify/dist/ReactToastify.css";

export default function AdminTable() {
  const [isLoading, setIsLoading] = useState(false);
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);

  // Filter variables
  const [shopname, setShopname] = useState("");
  const [processing, setProcessing] = useState("all");
  const [visibility, setVisibility] = useState("all");
  const [origin, setOrigin] = useState("all");
  const [state, setState] = useState("all");
  const [cashback, setCashback] = useState("all");
  const [logo, setLogo] = useState("all");
  const [campaingLink, setCampaingLink] = useState("all");
  const [agentStatus, setAgentStatus] = useState("all");
  const [shopUser, setShopUser] = useState("all");

  const [shops, setShops] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isDisabled, setIsDisabled] = useState(false);

  const [itemsCount, setItemsCount] = useState(0);

  // Updated country options
  const countryOptions = [
    { value: "all", label: "Všetky" },
    { value: "sk", label: "SK" },
    { value: "cz", label: "CZ" },
    { value: "hu", label: "HU" },
    { value: "pl", label: "PL" },
    { value: "ro", label: "RO" },
    { value: "bg", label: "BG" },
    { value: "at", label: "AT" },
    { value: "de", label: "DE" },
    { value: "it", label: "IT" },
    { value: "fr", label: "FR" },
    { value: "es", label: "ES" },
    { value: "hr", label: "HR" },
    { value: "rs", label: "RS" },
    { value: "si", label: "SI" },
    { value: "nl", label: "NL" },
    { value: "be", label: "BE" },
    { value: "pt", label: "PT" },
    { value: "gr", label: "GR" },
    { value: "se", label: "SE" },
    { value: "dk", label: "DK" },
    { value: "fi", label: "FI" },
    { value: "no", label: "NO" },
    { value: "ch", label: "CH" },
    { value: "ie", label: "IE" },
    { value: "uk", label: "UK" },
    { value: "cn", label: "CN" },
    { value: "ca", label: "CA" },
    { value: "us", label: "US" },
    { value: "pl", label: "PL" },
    { value: "lt", label: "LT" },
    { value: "lv", label: "LV" },
  ];

  const updateShopData = (updatedShop) => {
    setShops((prevShops) =>
      prevShops.map((shop) => (shop.id === updatedShop.id ? updatedShop : shop))
    );
  };

  const fetchFilteredData = async () => {
    setIsDisabled(true);

    const baseUrl = "shop-list/";
    let query = `?shopname=${shopname || ""}&processing=${
      processing || "all"
    }&visible=${visibility || "all"}&origin=${
      origin || "all"
    }&page_size=${pageSize}&page=${currentPage}&state=${
      state || "all"
    }&cashback=${cashback || "all"}&logo=${logo || "all"}&campaign_link=${
      campaingLink || "all"
    }&status=${agentStatus || "all"}&shop_user=${shopUser || "all"}`;

    try {
      setIsLoading(true);
      const response = await api.get(baseUrl + query);
      setShops(response.data.results); // Set shop data
      setTotalPages(response.data.total_pages); // Set total page count
      setCurrentPage(response.data.current_page); // Update current page
      setItemsCount(response.data.total_items);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsDisabled(false);
    setIsLoading(false);
  };
  const debouncedFetchFilteredData = debounce(fetchFilteredData, 300);

  // Button click handler
  const handleClick = () => {
    setIsDisabled(true);
    debouncedFetchFilteredData();
    setTimeout(() => {
      setIsDisabled(false);
    }, 2000);
  };

  const handleSetCurrentPage = (page) => {
    setIsDisabled(true);
    setCurrentPage(page);
    setTimeout(() => {
      setIsDisabled(false);
    }, 300);
  };

  // Function to fetch shops
  const fetchShops = async (page) => {
    try {
      const response = await api.get(`shop-list/?page=${page}`);
      setShops(response.data.results); // Set shop data
      setTotalPages(response.data.total_pages); // Set total page count
      setCurrentPage(response.data.current_page); // Update current page
    } catch (error) {
      console.error("There was an error fetching the shops", error);
    }
  };

  // Fetch shops when component mounts or page changes
  useEffect(() => {
    debouncedFetchFilteredData();
  }, [currentPage, pageSize]);

  const handleCheckboxChange = async (shop) => {
    const newVisibility = !shop.visible;
    try {
      const response = await api.post("change-shop-visibility/", {
        visible: newVisibility,
        shop_id: shop.id,
      });
      if (response.status === 200) {
        toast.success("Visibility updated successfully!");
        updateShopData({ ...shop, visible: newVisibility });
        fetchFilteredData();
      } else {
        toast.error("Failed to update visibility.");
      }
    } catch (error) {
      console.error("Error updating visibility:", error);
      toast.error("Error updating visibility.");
    }
  };

  const handleMostPopularChange = async (shop) => {
    const newMostPopular = !shop.most_popular;
    try {
      const response = await api.post("change-shop-most-popular/", {
        most_popular: newMostPopular,
        shop_id: shop.id,
      });
      if (response.status === 200) {
        toast.success("Most Popular status updated successfully!");
        updateShopData({ ...shop, most_popular: newMostPopular });
        fetchFilteredData();
      } else {
        toast.error("Failed to update Most Popular status.");
      }
    } catch (error) {
      console.error("Error updating Most Popular status:", error);
      toast.error("Error updating Most Popular status.");
    }
  };

  // Add new state for column visibility
  const [visibleColumns, setVisibleColumns] = useState(() => {
    const saved = localStorage.getItem("tableColumns");
    return saved
      ? JSON.parse(saved)
      : {
          name: true,
          visibility: true,
          webUrl: true,
          logo: true,
          origin: true,
          campaignLink: true,
          partner: true,
          cashback: true,
          xmlFeed: true,
          tags: true,
          actions: true,
        };
  });

  useEffect(() => {
    localStorage.setItem("tableColumns", JSON.stringify(visibleColumns));
  }, [visibleColumns]);

  const columns = {
    name: { label: "Názov", key: "name" },
    visibility: { label: "Viditeľné/Najpopulárnejšie", key: "visibility" },
    webUrl: { label: "Web URL", key: "webUrl" },
    logo: { label: "Logo", key: "logo" },
    origin: { label: "Pôvod", key: "origin" },
    campaignLink: { label: "Kamp. link", key: "campaignLink" },
    partner: { label: "Partner", key: "partner" },
    cashback: { label: "Cashback", key: "cashback" },
    xmlFeed: { label: "XML Feed", key: "xmlFeed" },
    tags: { label: "Tagy", key: "tags" },
    actions: { label: "Akcie", key: "actions" },
  };

  // Add state for popover
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-bold leading-6 text-gray-900">Shopy</h1>
        </div>
      </div>

      <div className="mt-4 border-1">
        <div className="mt-2 mx-4">
          <div className="text-lightgray font-semibold pt-4 justify-between flex">
            <div className="flex items-center gap-2">
              Filter &uarr;
              <IconButton
                aria-describedby={id}
                onClick={handlePopoverClick}
                size="small"
                className="hover:bg-gray-100"
              >
                <FaCog className="w-4 h-4 text-gray-500" />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <div className="p-4 w-64">
                  <h3 className="text-sm font-medium mb-2">Zobraziť stĺpce</h3>
                  <FormGroup>
                    {Object.entries(columns).map(([key, { label }]) => (
                      <FormControlLabel
                        key={key}
                        control={
                          <Checkbox
                            checked={visibleColumns[key]}
                            onChange={(e) => {
                              setVisibleColumns((prev) => ({
                                ...prev,
                                [key]: e.target.checked,
                              }));
                            }}
                            size="small"
                          />
                        }
                        label={<span className="text-sm">{label}</span>}
                      />
                    ))}
                  </FormGroup>
                </div>
              </Popover>
            </div>
            <div>
              <AddButton />
            </div>
          </div>
          <div className="flex items-center flex-wrap gap-6 text-sm font-semibold text-lightgray pt-2 ">
            <div className="flex items-center gap-2 ">
              Názov:{" "}
              <input
                className="block ring-1 py-2 ring-lightgray w-full pl-3 pr-10 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                type="text"
                value={shopname}
                onChange={(e) => setShopname(e.target.value)}
                name="shopname"
              />
            </div>
            <div className="flex items-center gap-2 ">
              Spracovanosť:{" "}
              <select
                className="block w-full pl-3 pr-10 py-2 text-base ring-lightgray border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={processing}
                onChange={(e) => setProcessing(e.target.value)}
                name="processing"
              >
                <option value="all">Všetky</option>
                <option value="true">Spracované</option>
                <option value="false">Nespracované</option>
              </select>
            </div>
            <div className="flex items-center gap-2">
              Viditeľnosť:{" "}
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={visibility}
                onChange={(e) => setVisibility(e.target.value)}
                name="visibility"
              >
                <option value="all">Všetky</option>
                <option value="true">Viditeľné</option>
                <option value="false">Skryté</option>
              </select>
            </div>
            <div className="flex items-center gap-2">
              Pôvod:{" "}
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={origin}
                onChange={(e) => setOrigin(e.target.value)}
                name="origin"
              >
                <option value="all">Všetky</option>
                <option value="ehub">ehub</option>
                <option value="affial">affial</option>
                <option value="dognet">dognet</option>
                <option value="cj">cj</option>
                <option value="affiliateport">affiliateport</option>
                <option value="mylead">mylead</option>
                <option value="bilionbuy">bilionbuy</option>
              </select>
            </div>
            <div className="flex items-center gap-2">
              Krajina:{" "}
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={state}
                onChange={(e) => setState(e.target.value)}
                name="state"
              >
                {countryOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center gap-2">
              Cashback:{" "}
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={cashback}
                onChange={(e) => setCashback(e.target.value)}
                name="cashback"
              >
                <option value="all">Vetky</option>
                <option value="true">Áno</option>
                <option value="false">Nie</option>
              </select>
            </div>
            <div className="flex items-center gap-2">
              Logo:{" "}
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={logo}
                onChange={(e) => setLogo(e.target.value)}
                name="logo"
              >
                <option value="all">Všetky</option>
                <option value="true">Áno</option>
                <option value="false">Nie</option>
              </select>
            </div>
            <div className="flex items-center text-nowrap gap-2">
              Kampaňový link:{" "}
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={campaingLink}
                onChange={(e) => setCampaingLink(e.target.value)}
                name="campaign_link"
              >
                <option value="all">Všetky</option>
                <option value="true">Áno</option>
                <option value="false">Nie</option>
              </select>
            </div>
            {/* status filter */}
            <div className="flex items-center text-nowrap gap-2">
              Status:{" "}
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={agentStatus}
                onChange={(e) => setAgentStatus(e.target.value)}
                name="agent_status"
              >
                <option value="all">Všetky</option>
                <option value="1">Chceme osloviť</option>
                <option value="2">Oslovený</option>
                <option value="3">Požiadané - affiliate</option>
                <option value="4">Dohodnuté - zaregistrované</option>
                <option value="5">Dohodnuté - affiliate</option>
              </select>
            </div>
            <div className="flex items-center gap-2">
              Priradené používateľovi:{" "}
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={shopUser}
                onChange={(e) => setShopUser(e.target.value)}
                name="shop_user"
              >
                <option value="all">Všetky</option>
                <option value="true">Áno</option>
                <option value="false">Nie</option>
              </select>
            </div>
            <div>
              <button
                onClick={handleClick}
                className="relative inline-flex items-center rounded-md bg-white px-12 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
              >
                Vyhľadať
              </button>
            </div>
          </div>
        </div>
        <div className="text-lightgray px-4 font-semibold mt-4 text-sm"></div>
        <div className="mt-8 flow-root ">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="relative">
                {selectedPeople.length > 0 && (
                  <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                    <button
                      type="button"
                      className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    >
                      Bulk edit
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    >
                      Delete all
                    </button>
                  </div>
                )}
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="relative px-7 sm:w-12 sm:px-6"
                      ></th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="flex items-center gap-2">Názov</div>
                      </th>
                      {Object.entries(columns).map(
                        ([key, { label }]) =>
                          key !== "name" && // Skip name column since we rendered it separately
                          visibleColumns[key] && (
                            <th
                              key={key}
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              {label}
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {isLoading ? (
                      // Display skeletons when loading
                      <>
                        {Array.from({ length: pageSize }).map((_, index) => (
                          <tr key={index} className="animate-pulse">
                            <td className="relative px-7 sm:w-12 sm:px-6">
                              <Skeleton className="h-6 w-6" />
                            </td>
                            <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium">
                              <Skeleton className="h-6 w-full" />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <Skeleton className="h-6 w-20" />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <Skeleton className="h-6 w-20" />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <Skeleton className="h-6 w-20" />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-green-500">
                              <Skeleton className="h-6 w-20" />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-green-500">
                              <Skeleton className="h-6 w-20" />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-green-500">
                              <Skeleton className="h-6 w-20" />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-green-500">
                              <Skeleton className="h-6 w-20" />
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                              <Skeleton className="h-6 w-20" />
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        {" "}
                        {shops.map((shop) => (
                          <tr
                            key={shop.id}
                            className={
                              selectedPeople.includes(shop)
                                ? "bg-gray-50"
                                : undefined
                            }
                          >
                            <td className="relative px-7 sm:w-12 sm:px-6">
                              {selectedPeople.includes(shop) && (
                                <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                              )}
                            </td>
                            {/* Name */}
                            {visibleColumns.name && (
                              <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium">
                                <div className="flex flex-row gap-4 items-center">
                                  <ReactCountryFlag
                                    countryCode={shop.country}
                                  />
                                  {shop.name}
                                </div>
                              </td>
                            )}
                            {/* Visibility */}
                            {visibleColumns.visibility && (
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <div className="flex flex-col">
                                  <div className="flex items-center">
                                    <input
                                      type="checkbox"
                                      checked={shop.visible}
                                      onChange={() =>
                                        handleCheckboxChange(shop)
                                      }
                                      className="form-checkbox mr-2"
                                    />
                                    <span>Viditeľné</span>
                                  </div>
                                  <div className="flex items-center">
                                    <input
                                      type="checkbox"
                                      checked={shop.most_popular}
                                      onChange={() =>
                                        handleMostPopularChange(shop)
                                      }
                                      className="form-checkbox mr-2"
                                    />
                                    <span>Najpopulárnejšie</span>
                                    {shop.most_popular && (
                                      <FaStar className="ml-1 text-yellow-400" />
                                    )}
                                  </div>
                                </div>
                              </td>
                            )}
                            {/* Web URL */}
                            {visibleColumns.webUrl && (
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-green-500">
                                {shop.web_url ? (
                                  <div className="flex justify-start max-w-[250px] text-wrap">
                                    <a
                                      href={shop.web_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="hover:underline"
                                    >
                                      {shop.web_url}
                                    </a>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                            )}
                            {/* Logo URL */}
                            {visibleColumns.logo && (
                              <td className="px-3 py-4 text-sm text-green-500">
                                {shop.logo_url ? (
                                  <div className="max-w-[250px] break-words">
                                    <a
                                      href={shop.logo_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="hover:underline"
                                    >
                                      {shop.logo_url}
                                    </a>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                            )}
                            {/* Origin */}
                            {visibleColumns.origin && (
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {shop.origin}
                              </td>
                            )}
                            {/* Campaign Link */}
                            {visibleColumns.campaignLink && (
                              <td className="px-3 py-4 text-sm text-green-500">
                                {shop.campaing_link ? (
                                  <div className="max-w-[250px] break-words">
                                    <a
                                      href={shop.campaing_link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="hover:underline"
                                    >
                                      {shop.campaing_link}
                                    </a>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                            )}
                            {/* Partner */}
                            {visibleColumns.partner && (
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-green-500">
                                {shop.shop_user ? (
                                  <div className="flex justify-start">
                                    <FaCheck className="" />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                            )}
                            {/* Cashback */}
                            {visibleColumns.cashback && (
                              <td className="mx-auto text-center">
                                {shop.default_cashback
                                  ? `${shop.default_cashback}%`
                                  : ""}
                              </td>
                            )}
                            {/* XML Feed */}
                            {visibleColumns.xmlFeed && (
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {shop.feed_processed
                                  ? "Spracované"
                                  : shop.feed_url
                                    ? "Vyplnené"
                                    : shop.has_feed === false
                                      ? "Nemá"
                                      : shop.has_feed === null
                                        ? ""
                                        : shop.has_feed === true
                                          ? "Nevyplnené"
                                          : ""}
                              </td>
                            )}
                            {/* Tags */}
                            {visibleColumns.tags && (
                              <td className="px-3 py-4 text-sm text-green-500 min-w-[400px]">
                                <div className="flex flex-wrap gap-2 max-h-[100px] overflow-y-auto">
                                  {shop.shoptags.map((tag) => (
                                    <span
                                      key={tag.id}
                                      className="px-2 py-1  bg-primary font-semibold text-white rounded-full text-xs"
                                    >
                                      {tag.name}
                                    </span>
                                  ))}
                                </div>
                              </td>
                            )}
                            {/* Actions */}
                            {visibleColumns.actions && (
                              <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3 flex justify-end items-center gap-2">
                                {/* <ShopProcessing data={shop} /> */}
                                <DeleteButton data={shop} />
                                <MagnifyingButton shopId={shop.id} />
                                <EditButton
                                  data={shop}
                                  onUpdate={updateShopData}
                                />
                              </td>
                            )}
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
                <nav
                  className="w-full flex items-center justify-between border-t border-gray-200   px-4 py-3 sm:px-6 "
                  aria-label="Pagination"
                >
                  <div className="hidden sm:block">
                    <p className="text-sm text-gray-700">
                      Stránka <span className="font-medium">{currentPage}</span>{" "}
                      z <span className="font-medium">{totalPages}</span>
                    </p>
                  </div>
                  <div className="mt-4">
                    {/* Dropdown for selecting page size */}
                    <select
                      value={pageSize}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                      className="ml-6 mb-4 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="5">5 na stránku</option>
                      <option value="10">10 na stránku</option>
                      <option value="50">50 na stránku</option>
                      <option value="100">100 na stránku</option>
                      <option value="250">250 na stránku</option>
                      <option value="500">500 na stránku</option>
                      <option value="1000">1000 na stránku</option>
                    </select>
                    {/* Table and other UI elements below */}
                    {/* ... */}
                  </div>
                  &nbsp; &nbsp;{" "}
                  <span className="text-sm">
                    Počet nájdených shopov: {itemsCount}
                  </span>
                  <div className="flex flex-1 gap-4 justify-between sm:justify-end">
                    <Button
                      className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                      onClick={() => handleSetCurrentPage((prev) => prev - 1)}
                      disabled={currentPage === 1 || isDisabled}
                    >
                      Späť
                    </Button>
                    <Button
                      className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                      onClick={() => handleSetCurrentPage((prev) => prev + 1)}
                      disabled={currentPage === totalPages || isDisabled}
                    >
                      Ďalej
                    </Button>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
