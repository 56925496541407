import axios from "axios";
import { isTokenExpired, logoutUser } from "./auth";
import { v4 as uuidv4 } from "uuid";

// Map to store AbortController instances
const abortControllers = new Map();

const getSessionId = () => {
  let sessionId = localStorage.getItem("session_id");
  if (!sessionId) {
    sessionId = uuidv4();
    localStorage.setItem("session_id", sessionId);
  }
  return sessionId;
};

// Function to get the base URL based on the type
const getBaseURL = (isAdmin = false) => {
  return isAdmin
    ? process.env.REACT_APP_API_ADMIN_URL
    : process.env.REACT_APP_API_URL;
};

// Helper to determine if the current path is an admin path
const isAdminPath = () => {
  return (
    window.location.pathname.includes("/dashboard") ||
    window.location.pathname.includes("/blog-admin")
  );
};

// Create an instance of axios with a custom config
const api = axios.create({
  baseURL: getBaseURL(isAdminPath()), // Default based on current path
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to switch the base URL
export const switchBaseURL = (isAdmin) => {
  api.defaults.baseURL = getBaseURL(isAdmin);
};

api.cancelToken = axios.CancelToken;
api.isCancel = axios.isCancel;

// Interceptor to add the auth token and custom headers to requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("jwt");
    const locale = localStorage.getItem("locale") || "sk";
    const selected_language = localStorage.getItem("i18nextLng") || "sk";
    const sessionId = getSessionId();

    // For each request, check if we're on an admin path and adjust the baseURL
    if (
      isAdminPath() &&
      (!config.baseURL ||
        !config.baseURL.includes(process.env.REACT_APP_API_ADMIN_URL))
    ) {
      config.baseURL = getBaseURL(true);
    }

    if (sessionId) {
      config.headers["Session"] = sessionId;
    }
    if (locale) {
      config.headers["Destination"] = locale;
    }

    if (selected_language) {
      config.headers["Selected-Language"] = selected_language;
    }

    if (token) {
      if (isTokenExpired(token)) {
        logoutUser();
        window.location.href = "/login"; // Redirect to login page
        return Promise.reject(new Error("Token expired"));
      }
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    // Create and store an AbortController for this request
    const controller = new AbortController();
    config.signal = controller.signal;
    abortControllers.set(config.url, controller);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor to handle responses
api.interceptors.response.use(
  (response) => {
    // Remove the AbortController for this request
    abortControllers.delete(response.config.url);
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      logoutUser();
      window.location.href = "/login"; // Redirect to login page
    }
    // Remove the AbortController for this request
    if (error.config) {
      abortControllers.delete(error.config.url);
    }
    return Promise.reject(error);
  }
);

// Function to cancel a request
export const cancelRequest = (url) => {
  const controller = abortControllers.get(url);
  if (controller) {
    controller.abort();
    abortControllers.delete(url);
  }
};

export default api;
