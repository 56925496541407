import React, { useState, useEffect, useCallback } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

export default function CustomCarousel({
  children,
  itemWidth = "280px",
  itemMaxWidth = "320px",
  hideArrows = false,
  breakpoints = {
    "(max-width: 767px)": {
      mode: "free-snap",
      slides: {
        perView: 1.6,
        spacing: 0,
      },
    },
    "(min-width: 700px)": {
      mode: "snap",
      slides: { perView: 3, spacing: 12 },
    },
    "(min-width: 905px)": {
      mode: "snap",
      slides: { perView: 4, spacing: 12 },
    },
    "(min-width: 1079px)": {
      mode: "snap",
      slides: { perView: 5, spacing: 12 },
    },
    "(min-width: 1280px)": {
      mode: "snap",
      slides: { perView: 5, spacing: 12 },
    },
    "(min-width: 1536px)": {
      mode: "snap",
      slides: { perView: 5, spacing: 12 },
    },
  },
}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      initial: 0,
      slides: {
        perView: "auto",
        spacing: 16,
        origin: "auto",
      },
      breakpoints,
      loop: true,
      dragSpeed: 1,
      created: (slider) => {
        setLoaded(true);
        setTimeout(() => {
          slider.update();
        }, 200);
      },
    },
    [
      (slider) => {
        let observer;
        let resizeTimeout;

        slider.on("created", () => {
          observer = new ResizeObserver(() => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
              slider.update();
            }, 100);
          });

          observer.observe(slider.container);
          if (slider.container.parentElement) {
            observer.observe(slider.container.parentElement);
          }
        });

        slider.on("destroyed", () => {
          observer?.disconnect();
          clearTimeout(resizeTimeout);
        });
      },
    ]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      instanceRef.current?.update();
    }, 200);

    return () => clearTimeout(timer);
  }, [children]);

  const getTotalPages = useCallback(() => {
    if (!instanceRef.current?.track?.details) return 0;
    const totalSlides = instanceRef.current.track.details.slides.length;
    const slidesPerView = instanceRef.current.options.slides.perView;
    return Math.ceil(totalSlides / slidesPerView);
  }, [instanceRef]);

  useEffect(() => {
    if (instanceRef.current) {
      instanceRef.current.on("slideChanged", (s) => {
        if (s.track?.details) {
          const slidesPerView = instanceRef.current.options.slides.perView;
          setCurrentSlide(Math.floor(s.track.details.rel / slidesPerView));
        }
      });
    }
  }, [instanceRef]);

  const handlePrevClick = useCallback(() => {
    if (instanceRef.current && instanceRef.current.track?.details) {
      const slidesPerView = instanceRef.current.options.slides.perView;
      const currentSlide = instanceRef.current.track.details.abs;
      instanceRef.current.moveToIdx(Math.max(currentSlide - slidesPerView, 0));
    }
  }, [instanceRef]);

  const handleNextClick = useCallback(() => {
    if (instanceRef.current && instanceRef.current.track?.details) {
      const slidesPerView = instanceRef.current.options.slides.perView;
      const currentSlide = instanceRef.current.track.details.abs;
      const totalSlides = instanceRef.current.track.details.slides.length;
      instanceRef.current.moveToIdx(
        Math.min(currentSlide + slidesPerView, totalSlides - 1)
      );
    }
  }, [instanceRef]);

  return (
    <div className="relative w-full">
      <button
        onClick={handlePrevClick}
        className={`absolute -left-4 lg:-left-12 top-1/2 -translate-y-1/2 
            hidden md:flex items-center hover:cursor-pointer z-10 
            bg-white/80 rounded-r-lg p-2 shadow-lg
            ${hideArrows ? "2xl:hidden" : ""}`}
        aria-label="Previous slide"
      >
        <svg
          width="46"
          height="46"
          viewBox="0 0 46 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M23 34.5L11.5 23L23 11.5" stroke="black" strokeWidth="5" />
          <path d="M34.5 34.5L23 23L34.5 11.5" stroke="black" strokeWidth="5" />
        </svg>
      </button>

      <div
        ref={sliderRef}
        className="keen-slider overflow-visible"
        style={{ minHeight: "100px" }}
      >
        {React.Children.map(children, (child) => (
          <div
            className="keen-slider__slide"
            style={{
              flex: `0 0 ${itemWidth}`,
              minWidth: itemWidth,
              maxWidth: itemMaxWidth,
              width: itemWidth,
              height: "auto",
            }}
          >
            {child}
          </div>
        ))}
      </div>

      <button
        onClick={handleNextClick}
        className={`absolute -right-4 lg:-right-12 top-1/2 -translate-y-1/2 
            hidden md:flex items-center hover:cursor-pointer z-10 
            bg-white/80 rounded-l-lg p-2 shadow-lg
            ${hideArrows ? "2xl:hidden" : ""}`}
        aria-label="Next slide"
      >
        <svg
          width="46"
          height="46"
          viewBox="0 0 46 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M23 34.5L34.5 23L23 11.5" stroke="black" strokeWidth="5" />
          <path d="M11.5 34.5L23 23L11.5 11.5" stroke="black" strokeWidth="5" />
        </svg>
      </button>

      {loaded && sliderRef.current && (
        <div className="flex justify-center gap-2 mt-4 ">
          {Array.from({ length: getTotalPages() }).map((_, idx) => (
            <button
              key={idx}
              onClick={() => {
                const slidesPerView = sliderRef.current.options.slides.perView;
                sliderRef.current?.moveToIdx(idx * slidesPerView);
              }}
              className={`w-2 h-2 rounded-full ${
                currentSlide === idx ? "bg-primary" : "bg-gray-300"
              }`}
            />
          ))}
        </div>
      )}
    </div>
  );
}
