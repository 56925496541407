import React from "react";
import { Box, Typography, Paper, Divider } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { formatPrice } from "../../utils/formatters";

export const OrderSuccess = ({ orderNumber, total }) => {
  return (
    <div className="max-w-screen-xl mx-auto py-12">
      <Paper sx={{ p: 4 }}>
        <Box display="flex" alignItems="center" gap={2} mb={4}>
          <CheckCircleIcon color="success" sx={{ fontSize: 40 }} />
          <Typography variant="h4">
            Vaša objednávka č. {orderNumber} čaká na zaplatenie
          </Typography>
        </Box>

        <Typography variant="h6" gutterBottom>
          Platobné údaje
        </Typography>
        <Box sx={{ mb: 4 }}>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography>Suma:</Typography>
            <Typography>{total} EUR</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography>Číslo účtu v tvare IBAN:</Typography>
            <Typography>SK7411000000002940169470</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography>Variabilný symbol:</Typography>
            <Typography>{orderNumber}</Typography>
          </Box>
        </Box>

        <Typography variant="h6" gutterBottom>
          Údaje spoločnosti
        </Typography>
        <Box sx={{ mb: 4 }}>
          <Typography>Bilionbuy International s.r.o.</Typography>
          <Typography>Farbiarska 53/29</Typography>
          <Typography>064 01 Stará Ľubovňa</Typography>
          <Typography>Slovenská republika</Typography>
          <Typography>IČO: 56190999</Typography>
          <Typography>DIČ: 2122237392</Typography>
          <Typography>IČ DPH: SK2122237392</Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Typography color="error" sx={{ mb: 4 }}>
          V prípade neuhradenia tejto objednávky do 3 dní, sa táto objednávka
          automaticky stornuje.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Garancia spokojnosti
        </Typography>
        <Typography>
          Vaša spokojnosť je pre nás prvoradá, preto, ak nedodržíme dobu
          dodania, získate od nás ako ospravedlnenie 5 EUR bonus na ďalší nákup
        </Typography>
      </Paper>
    </div>
  );
};
