// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

// Configure more options for language detector
const languageDetectorOptions = {
  // Order of detection methods
  order: ["localStorage", "navigator", "queryString", "cookie", "htmlTag"],

  // Case insensitive lookups
  lookupFromNavigatorLanguage: (lng) => {
    // Try to extract base language code for matching
    if (!lng) return undefined;
    return lng.split("-")[0].toLowerCase();
  },

  // Cache results in localStorage
  caches: ["localStorage"],

  // Override the check for navigator.languages
  checkNavigatorLanguage: true,
};

i18n
  .use(HttpApi) // load translations using http (default public/assets/locales)
  .use(LanguageDetector) // detect user language with custom options
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    supportedLngs: ["en", "sk", "cs", "pl", "de", "hu"],
    fallbackLng: "en",
    detection: languageDetectorOptions,
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
    react: {
      useSuspense: false,
    },
    debug: true, // Enable debug mode to see more logs
  });

export default i18n;
