const ALL_COUNTRIES = [
  { code: "BE", name: "Belgium" },
  { code: "GR", name: "Greece" },
  { code: "HR", name: "Croatia" },
  { code: "LT", name: "Lithuania" },
  { code: "FR", name: "France" },
  { code: "SK", name: "Slovakia" },
  { code: "DE", name: "Germany" },
  { code: "AT", name: "Austria" },
  { code: "NL", name: "Netherlands" },
  { code: "FI", name: "Finland" },
  { code: "IT", name: "Italy" },
  { code: "SI", name: "Slovenia" },
  { code: "ES", name: "Spain" },
  { code: "PT", name: "Portugal" },
  { code: "PT-20", name: "Azores & Madeira" },
  { code: "EE", name: "Estonia" },
  { code: "LU", name: "Luxembourg" },
  { code: "CZ", name: "Czech Republic" },
  { code: "PL", name: "Poland" },
  { code: "HU", name: "Hungary" },
  { code: "RO", name: "Romania" },
  { code: "BG", name: "Bulgaria" },
  { code: "DK", name: "Denmark" },
  { code: "CY", name: "Cyprus" },
  { code: "MT", name: "Malta" },
  { code: "LV", name: "Latvia" },
  { code: "IE", name: "Ireland" },
  { code: "SE", name: "Sweden" },
  { code: "TR", name: "Turkey" },
  { code: "UA", name: "Ukraine" },
  { code: "MD", name: "Moldova" },
  { code: "BY", name: "Belarus" },
  { code: "RU", name: "Russia" },
  { code: "AL", name: "Albania" },
  { code: "MK", name: "North Macedonia" },
  { code: "RS", name: "Serbia" },
  { code: "ME", name: "Montenegro" },
  { code: "BA", name: "Bosnia and Herzegovina" },
  { code: "XK", name: "Kosovo" },
  { code: "GB", name: "United Kingdom" },
  { code: "NO", name: "Norway" },
  { code: "IS", name: "Iceland" },
  { code: "CH", name: "Switzerland" },
  { code: "LI", name: "Liechtenstein" },
];

const SHOP_COUNTRIES = [{ code: "SK", name: "Slovakia" }];

const CASHBACK_COUNTRIES = [
  { code: "SK", name: "Slovakia" },
  { code: "CZ", name: "Czech Republic" },
];

export { ALL_COUNTRIES, SHOP_COUNTRIES, CASHBACK_COUNTRIES };
