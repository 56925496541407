import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@nextui-org/react";
import api from "../services/api"; // Import the API service if needed

const SignOut = ({ t }) => {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      // Optionally call your sign-out API if needed
      // await api.post("/sign-out");

      // Remove specific user-related data from local storage
      localStorage.removeItem("jwt");
      localStorage.removeItem("refcode");
      localStorage.removeItem("user_image");
      localStorage.removeItem("user_permissions");
      localStorage.removeItem("username");

      // Dispatch custom event for admin context
      window.dispatchEvent(new Event("user-logout"));

      console.log("Signed out successfully.");

      // Redirect to login page after clearing storage
      navigate("/login");
    } catch (error) {
      console.error("Failed to sign out:", error);
    }
  };

  return (
    <div role="button" onClick={handleSignOut}>
      {t("Odhlásiť sa")}
    </div>
  );
};

export default SignOut;
