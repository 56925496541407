import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import api from "../../services/api";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const PaymentCancel = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  // Retrieve data passed in via query parameters (make sure your Stripe cancel_url sends these!)
  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("orderId");
  const amount = queryParams.get("amount");
  const orderNumber = queryParams.get("orderNumber");
  const customerEmail = queryParams.get("email");

  const reinitiatePayment = async () => {
    // If required order data isn't available, send the user to the cart
    if (!orderId || !amount || !orderNumber || !customerEmail) {
      console.error("Missing required parameters:", {
        orderId,
        amount,
        orderNumber,
        customerEmail,
      });
      enqueueSnackbar(t("Chýbajúce údaje pre opätovnú platbu"), {
        variant: "error",
      });
      return;
    }

    try {
      setIsLoading(true);
      const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
      );
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error("Stripe failed to load");
      }

      const response = await api.post("eshop/create-payment-session/", {
        order_id: orderId,
        amount: parseFloat(amount),
        order_number: orderNumber,
        customer_email: customerEmail,
      });

      if (!response.data.sessionId) {
        throw new Error("No session ID received from server");
      }

      const { error } = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      });

      if (error) {
        console.error("Stripe redirect error:", error);
        throw error;
      }
    } catch (error) {
      console.error("Error reinitializing payment:", error);
      // Show error to user
      alert(t("Nepodarilo sa obnoviť platbu. Prosím, skúste to znova neskôr."));
      navigate("/cart");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        mx: "auto",
        p: 4,
        textAlign: "center",
        bgcolor: "white",
        borderRadius: 2,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        mt: 8,
      }}
    >
      <Box
        sx={{
          width: 60,
          height: 60,
          borderRadius: "50%",
          bgcolor: "#FF3B30",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mx: "auto",
          mb: 3,
        }}
      >
        <ErrorIcon sx={{ color: "white", fontSize: 32 }} />
      </Box>
      <Typography
        variant="h4"
        sx={{ fontWeight: "bold", mb: 1, fontSize: "2rem" }}
      >
        {t("Platba bola zrušená")}
      </Typography>
      <Typography sx={{ mb: 3 }}>
        {t(
          "Vaša platba sa nepodarila. Mohlo dôjsť k chybe alebo ste platbu zrušili."
        )}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={reinitiatePayment}
        disabled={isLoading}
      >
        {isLoading ? t("Spracovávam...") : t("Skúsiť znova")}
      </Button>
    </Box>
  );
};

export default PaymentCancel;
