import React, { useState, useEffect } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Paper,
  Button,
  Divider,
  IconButton,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  Grid,
  Link,
  Chip,
  CircularProgress,
  MenuItem,
  Fade,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PersonIcon from "@mui/icons-material/Person";
import PaymentIcon from "@mui/icons-material/Payment";
import VerifiedIcon from "@mui/icons-material/Verified";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import { useFormik } from "formik";
import * as Yup from "yup";
import { OrderSuccess } from "./OrderSuccess";
import InventoryIcon from "@mui/icons-material/Inventory";
import { cartService } from "../../services/cartService";
import { useSnackbar } from "notistack";
import api from "../../services/api";
import { loadStripe } from "@stripe/stripe-js";
import PaymentConfirmation from "./PaymentConfirmation";
import OrderReview from "./OrderReview";
import ErrorIcon from "@mui/icons-material/Error";
import { ALL_COUNTRIES } from "../../data/countries";
import { useTranslation } from "react-i18next";
import { formatPrice, getDeliveryText } from "../../utils/formatters";
import { isAuthenticated } from "../../contexts/Authentication";
import LoginForm from "../auth/LoginForm";
import RegisterForm from "../auth/RegisterForm";

const steps = [
  { label: "Košík", icon: <ShoppingCartIcon /> },
  { label: "Dodacie údaje", icon: <PersonIcon /> },
  { label: "Doprava", icon: <LocalShippingIcon /> },
  { label: "Platba", icon: <PaymentIcon /> },
  { label: "Kontrola", icon: <FactCheckIcon /> },
];

const paymentMethods = [
  {
    id: "card",
    name: "Platba kartou",
    price: 0,
    icons: ["visa", "mastercard"],
  },
  { id: "transfer", name: "Platba prevodom", price: 0 },
  { id: "paypal", name: "PayPal", price: 0 },
  { id: "gpay", name: "Google Pay", price: 0 },
  { id: "applepay", name: "Apple Pay", price: 0 },
  { id: "tatrapay", name: "Tatra Pay", price: 0 },
];

const deliveryValidationSchema = Yup.object({
  firstName: Yup.string().required("Povinné pole"),
  lastName: Yup.string().required("Povinné pole"),
  phone: Yup.string()
    .matches(/^[0-9+\s-]{9,}$/, "Neplatné telefónne číslo")
    .required("Povinné pole"),
  street: Yup.string().required("Povinné pole"),
  city: Yup.string().required("Povinné pole"),
  postalCode: Yup.string()
    .matches(/^\d{3}\s?\d{2}$/, "Neplatné PSČ")
    .required("Povinné pole"),
  email: Yup.string().email("Neplatný email").required("Povinné pole"),
  country: Yup.string().required("Povinné pole"),
  isCompany: Yup.boolean(),
  companyName: Yup.string().when("isCompany", {
    is: true,
    then: () => Yup.string().required("Povinné pole"),
    otherwise: () => Yup.string(),
  }),
  ico: Yup.string().when("isCompany", {
    is: true,
    then: () =>
      Yup.string()
        .matches(/^\d{8}$/, "Neplatné IČO")
        .required("Povinné pole"),
    otherwise: () => Yup.string(),
  }),
  dic: Yup.string().when("isCompany", {
    is: true,
    then: () =>
      Yup.string()
        .matches(/^\d{10}$/, "Neplatné DIČ")
        .required("Povinné pole"),
    otherwise: () => Yup.string(),
  }),
  ic_dph: Yup.string().when("isCompany", {
    is: true,
    then: () =>
      Yup.string().matches(
        /^SK\d{10}$/,
        "Neplatné IČ DPH (formát: SK0123456789)"
      ),
    otherwise: () => Yup.string(),
  }),
  additional_message: Yup.string(),
});

const DeliveryDetailsForm = ({ onFormChange }) => {
  const { t } = useTranslation();
  const [profileData, setProfileData] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  // Authentication state
  const isUserAuthenticated = isAuthenticated();
  // Track which auth option the user has selected
  const [authOption, setAuthOption] = useState(
    isUserAuthenticated ? "authenticated" : null
  );

  // Animation states
  const [showOptions, setShowOptions] = useState(true);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [showDeliveryForm, setShowDeliveryForm] = useState(false);

  // Function to handle option selection with animation
  const handleOptionSelect = (option) => {
    setShowOptions(false);
    setTimeout(() => {
      setAuthOption(option);
      if (option === "login") {
        setShowLoginForm(true);
      } else if (option === "register") {
        setShowRegisterForm(true);
      } else if (option === "continue") {
        setShowDeliveryForm(true);
      }
    }, 300); // Wait for fade out to complete
  };

  // Initialize the correct form visibility based on authentication state
  useEffect(() => {
    if (isUserAuthenticated) {
      setShowDeliveryForm(true);
    }
  }, [isUserAuthenticated]);

  // Get locale from localStorage
  const locale =
    typeof window !== "undefined" ? localStorage.getItem("locale") : null;

  // Map locale to country code (special case for Czech)
  const localeToCountry = (locale) => {
    if (!locale) return "SK";
    if (locale.toLowerCase() === "cs") return "CZ";
    return locale.toUpperCase();
  };

  // Determine the default country from locale
  const defaultCountry = localeToCountry(locale);

  // Load saved delivery details from localStorage
  const savedDeliveryDetails = JSON.parse(
    localStorage.getItem("deliveryDetails") || "null"
  );

  // Check if saved country matches locale-derived country
  const shouldUseSavedDetails =
    savedDeliveryDetails && savedDeliveryDetails.country === defaultCountry;

  useEffect(() => {
    // Only fetch profile data if user is authenticated
    if (isUserAuthenticated) {
      const fetchProfile = async () => {
        try {
          setLoadingProfile(true);
          const response = await api.get("/get-user-data/");
          const userData = response.data;

          const hasCompanyData = Boolean(
            userData.billing_data?.company &&
              userData.billing_data?.company_id &&
              userData.billing_data?.tax_id
          );

          setProfileData({
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email,
            phone: userData.phone_number,
            street: userData.address,
            city: userData.city,
            postal_code: userData.zip_code,
            ...(hasCompanyData && {
              company_name: userData.billing_data.company,
              ico: userData.billing_data.company_id,
              dic: userData.billing_data.tax_id,
              ic_dph: userData.billing_data.vat_id || "",
            }),
          });
        } catch (error) {
          console.error("Failed to fetch profile data:", error);
          setProfileData(null);
          enqueueSnackbar(t("Nepodarilo sa načítať údaje profilu"), {
            variant: "error",
          });
        } finally {
          setLoadingProfile(false);
        }
      };

      fetchProfile();
    }
  }, [enqueueSnackbar, isUserAuthenticated, t]);

  const formik = useFormik({
    initialValues: {
      firstName: shouldUseSavedDetails ? savedDeliveryDetails?.firstName : "",
      lastName: shouldUseSavedDetails ? savedDeliveryDetails?.lastName : "",
      phone: shouldUseSavedDetails ? savedDeliveryDetails?.phone : "",
      email: shouldUseSavedDetails ? savedDeliveryDetails?.email : "",
      street: shouldUseSavedDetails ? savedDeliveryDetails?.street : "",
      city: shouldUseSavedDetails ? savedDeliveryDetails?.city : "",
      postalCode: shouldUseSavedDetails ? savedDeliveryDetails?.postalCode : "",
      country: defaultCountry,
      isCompany: shouldUseSavedDetails
        ? savedDeliveryDetails?.isCompany
        : false,
      companyName: shouldUseSavedDetails
        ? savedDeliveryDetails?.companyName
        : "",
      ico: shouldUseSavedDetails ? savedDeliveryDetails?.ico : "",
      dic: shouldUseSavedDetails ? savedDeliveryDetails?.dic : "",
      ic_dph: shouldUseSavedDetails ? savedDeliveryDetails?.ic_dph : "",
      useProfileData: false,
      additional_message: shouldUseSavedDetails
        ? savedDeliveryDetails?.additional_message
        : "",
    },
    validationSchema: deliveryValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      // Save delivery details to localStorage
      localStorage.setItem("deliveryDetails", JSON.stringify(values));
      onFormChange(values);
      setSubmitting(false);
    },
  });

  const handleUseProfileData = (event) => {
    if (event.target.checked) {
      formik.setValues({
        ...formik.values,
        firstName: profileData?.first_name || "",
        lastName: profileData?.last_name || "",
        phone: profileData?.phone || "",
        email: profileData?.email || "",
        street: profileData?.street || "",
        city: profileData?.city || "",
        postalCode: profileData?.postal_code || "",
        country: formik.values.country, // preserve the default country
        isCompany: Boolean(profileData?.company_name),
        companyName: profileData?.company_name || "",
        ico: profileData?.ico || "",
        dic: profileData?.dic || "",
        ic_dph: profileData?.ic_dph || "",
        useProfileData: true,
        additional_message: formik.values.additional_message,
      });
    } else {
      formik.setValues({
        ...formik.initialValues,
        isCompany: formik.values.isCompany,
        additional_message: formik.values.additional_message,
      });
    }
  };

  // Handle successful login/registration
  const handleAuthSuccess = () => {
    // Update auth state to reflect that user is now authenticated
    setAuthOption("authenticated");
    // Refresh the page to update authentication state throughout the app
    window.location.reload();
  };

  // If we're showing a login or register form, render just that
  if (authOption === "login") {
    return (
      <Fade in={showLoginForm} timeout={500}>
        <div>
          <LoginForm onSuccess={handleAuthSuccess} showComplementary={false} />
        </div>
      </Fade>
    );
  }

  if (authOption === "register") {
    return (
      <Fade in={showRegisterForm} timeout={500}>
        <div>
          <RegisterForm
            onSuccess={handleAuthSuccess}
            showComplementary={false}
          />
        </div>
      </Fade>
    );
  }

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit(e);
      }}
    >
      {/* Authentication options for non-authenticated users */}
      {!isUserAuthenticated && authOption !== "continue" && (
        <Fade in={showOptions} timeout={500}>
          <Paper sx={{ p: 3, mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              {t("Dodacie údaje")}
            </Typography>

            <Box
              display="flex"
              justifyContent="space-between"
              gap={2}
              mb={3}
              className="flex flex-col md:flex-row flex-justify-between"
            >
              <Button
                variant="contained"
                color="primary"
                className="w-full md:w-1/3"
                onClick={() => handleOptionSelect("login")}
              >
                {t("Prihlásiť sa")}
              </Button>
              <Button
                variant="outlined"
                className="w-full md:w-1/3"
                color="primary"
                onClick={() => handleOptionSelect("register")}
              >
                {t("Zaregistrovať sa s údajmi")}
              </Button>
              <Button
                variant="outlined"
                className="w-full md:w-1/3"
                onClick={() => handleOptionSelect("continue")}
              >
                {t("Pokračovať v objednávke bez registrácie")}
              </Button>
            </Box>

            <Typography
              variant="body2"
              color="white"
              sx={{
                bgcolor: "warning.light",
                p: 2,
                borderRadius: 1,
                fontWeight: "medium",
                textAlign: "center",
              }}
            >
              <span className="text-lg">
                {t(
                  "V prípade, ak nie ste zaregistrovaný na Bilionbuy, nebude na váš účet pripísaný rastúci cashback ani body do vernostného programu."
                )}
              </span>
            </Typography>
          </Paper>
        </Fade>
      )}

      {/* The regular form - only shown for authenticated users or when "continue without registration" is selected */}
      {(isUserAuthenticated || authOption === "continue") && (
        <Fade in={isUserAuthenticated || showDeliveryForm} timeout={500}>
          <div>
            {isUserAuthenticated && profileData && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.useProfileData}
                    onChange={handleUseProfileData}
                    disabled={loadingProfile}
                  />
                }
                label={
                  loadingProfile ? (
                    <Box display="flex" alignItems="center" gap={1}>
                      <CircularProgress size={16} />
                      {t("Načítavam profil...")}
                    </Box>
                  ) : (
                    t("Použiť údaje z profilu")
                  )
                }
                sx={{ mb: 3 }}
              />
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.isCompany}
                  onChange={(e) => {
                    formik.setFieldValue("isCompany", e.target.checked);
                  }}
                />
              }
              label={t("Fakturácia na firmu")}
              sx={{ mb: 3, display: "block" }}
            />

            {formik.values.isCompany && (
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="companyName"
                    label={t("spolocnot")}
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.companyName &&
                      Boolean(formik.errors.companyName)
                    }
                    helperText={
                      formik.touched.companyName && formik.errors.companyName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="ico"
                    label={t("IČO")}
                    value={formik.values.ico}
                    onChange={formik.handleChange}
                    error={formik.touched.ico && Boolean(formik.errors.ico)}
                    helperText={formik.touched.ico && formik.errors.ico}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="dic"
                    label={t("DIČ")}
                    value={formik.values.dic}
                    onChange={formik.handleChange}
                    error={formik.touched.dic && Boolean(formik.errors.dic)}
                    helperText={formik.touched.dic && formik.errors.dic}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="ic_dph"
                    label={t("IČ DPH")}
                    value={formik.values.ic_dph}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.ic_dph && Boolean(formik.errors.ic_dph)
                    }
                    helperText={formik.touched.ic_dph && formik.errors.ic_dph}
                    placeholder="SK0123456789"
                  />
                </Grid>
              </Grid>
            )}

            <Typography variant="h6" sx={{ mb: 3 }}>
              {t("Kontaktné údaje")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="firstName"
                  label={t("Meno")}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="lastName"
                  label={t("priezvisko")}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="phone"
                  label={t("telefón")}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="email"
                  label={t("Email")}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
            </Grid>

            <Typography variant="h6" sx={{ mb: 3, mt: 4 }}>
              {t("Adresa dodania")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="street"
                  label={t("Ulica a číslo")}
                  value={formik.values.street}
                  onChange={formik.handleChange}
                  error={formik.touched.street && Boolean(formik.errors.street)}
                  helperText={formik.touched.street && formik.errors.street}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="city"
                  label={t("Mesto")}
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="postalCode"
                  label={t("PSČ")}
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.postalCode &&
                    Boolean(formik.errors.postalCode)
                  }
                  helperText={
                    formik.touched.postalCode && formik.errors.postalCode
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  disabled
                  fullWidth
                  name="country"
                  label={t("Krajina")}
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                >
                  {ALL_COUNTRIES.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Typography variant="h6" sx={{ mb: 3, mt: 4 }}>
              {t("Poznámka k objednávke")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  name="additional_message"
                  label={t("Doplňujúce informácie k objednávke (voliteľné)")}
                  placeholder={t(
                    "Napríklad: Preferovaný čas doručenia, špeciálne inštrukcie k doručeniu, alebo iné poznámky k vašej objednávke..."
                  )}
                  value={formik.values.additional_message}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </div>
        </Fade>
      )}
    </Box>
  );
};

const PaymentMethodSelection = ({
  cart,
  calculateTotal,
  selectedPayment,
  setSelectedPayment,
  termsAccepted,
  setTermsAccepted,
  termsError,
  cartTotals,
  selectedDelivery,
  deliveryOptions,
  currency,
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        {t("Vybrať platobnú metódu")}
      </Typography>

      <RadioGroup
        value={selectedPayment}
        onChange={(e) => setSelectedPayment(e.target.value)}
      >
        <Paper
          sx={{
            mb: 2,
            p: 2,
            border: selectedPayment === "card" ? "2px solid #1976d2" : "none",
          }}
        >
          <FormControlLabel
            value="card"
            control={<Radio />}
            label={
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" gap={2}>
                  <Box display="flex" gap={1}>
                    <img
                      src="https://bbuy.fra1.cdn.digitaloceanspaces.com/img/visa_logo.png"
                      alt="Visa"
                      height="24"
                      width="24"
                    />
                    <img
                      src="https://bbuy.fra1.cdn.digitaloceanspaces.com/img/mastercard_logo.png"
                      alt="Mastercard"
                      height="24"
                      width="24"
                    />
                  </Box>
                  <Box>
                    <Typography>{t("Online platba kartou")}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {t("Rýchla a bezpečná platba")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            }
          />
        </Paper>

        <Paper
          sx={{
            mb: 2,
            p: 2,
            border:
              selectedPayment === "transfer" ? "2px solid #1976d2" : "none",
          }}
        >
          <FormControlLabel
            value="transfer"
            control={<Radio />}
            label={
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" gap={2}>
                  <Box>
                    <Typography>{t("Bankový prevod")}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {t("Platba prevodom na účet")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            }
          />
        </Paper>
      </RadioGroup>

      <Paper sx={{ p: 2, mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          {t("Súhrn objednávky")}
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography>{t("Suma bez DPH:")}</Typography>
            <Typography>
              {formatPrice(cartTotals?.total_amount - cartTotals?.total_vat)}{" "}
              {currency}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography>{t("DPH")}:</Typography>
            <Typography>
              {formatPrice(cartTotals?.total_vat)} {currency}
            </Typography>
          </Box>
          {selectedDelivery && (
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography>{t("Doprava s DPH")}:</Typography>
              <Typography>
                {formatPrice(
                  deliveryOptions.find((opt) => opt.name === selectedDelivery)
                    ?.price
                )}{" "}
                {currency}
              </Typography>
            </Box>
          )}
          <Divider sx={{ my: 1 }} />
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">{t("Celkom na úhradu")}:</Typography>
            <Typography variant="h6" color="primary">
              {formatPrice(calculateTotal())} {currency}
            </Typography>
          </Box>
        </Box>
      </Paper>

      <Box sx={{ mt: 3 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
            />
          }
          label={
            <Box component="span">
              {t("Súhlasím s")}{" "}
              <Link
                href="/terms-and-conditions"
                target="_blank"
                underline="hover"
              >
                {t("obchodnými podmienkami")}
              </Link>
            </Box>
          }
        />
        {termsError && (
          <Typography
            color="error"
            variant="caption"
            sx={{ display: "block", mt: 1 }}
          >
            {t("Pre pokračovanie musíte súhlasiť s obchodnými podmienkami")}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const generateOrderNumber = () => {
  const date = new Date();
  const year = date.getFullYear().toString().slice(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const orderCount = Math.floor(Math.random() * 999999)
    .toString()
    .padStart(6, "0");
  return `${year}${month}${orderCount}`;
};

const DeliveryOptions = ({
  selectedDelivery,
  setSelectedDelivery,
  cart,
  calculateTotal,
  deliveryOptions,
  loading,
  currency,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const cartTotal = calculateTotal();
  console.log("Cart total:", cartTotal);

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: 150 }}
      >
        <CircularProgress size={24} sx={{ mr: 2 }} />
        <Typography variant="body1">
          {t("Načítavam možnosti dopravy...")}
        </Typography>
      </Box>
    );
  }

  return (
    <div className="space-y-4">
      {/* <Paper sx={{ p: 2, bgcolor: "#F8F9FA", mb: 3 }}>
        <Box display="flex" gap={2} alignItems="center">
          <LocalShippingIcon color="primary" />
          <Typography>
            {t("Doručenie do")} {cart[0]?.deliveryDays || 10}{" "}
            {t("pracovných dní")}
          </Typography>
        </Box>
      </Paper> */}

      <RadioGroup
        value={selectedDelivery}
        onChange={(e) => setSelectedDelivery(e.target.value)}
      >
        {deliveryOptions.map((option) => {
          const isFreeDelivery = option.is_free_shipping;
          console.log(
            "Option threshold:",
            option.threshold,
            "Is free:",
            isFreeDelivery
          );

          return (
            <Paper
              key={option.id}
              sx={{
                p: 2,
                mb: 2,
                border:
                  selectedDelivery === option.name
                    ? "2px solid #1976d2"
                    : "none",
              }}
            >
              <FormControlLabel
                value={option.name}
                control={<Radio />}
                label={
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Box display="flex" alignItems="center" gap={2}>
                      <Box>
                        <Typography>{t(option.name)} </Typography>
                        {option.id === 6 ? (
                          // Personal pickup option
                          <Typography variant="caption" color="text.secondary">
                            {t(
                              "Dostupné na adrese: Popradská 17, 064 01 Stará Ľubovňa"
                            )}
                          </Typography>
                        ) : (
                          // Other delivery options
                          <Typography variant="caption" color="text.secondary">
                            {t("Doručenie na vašu adresu")}
                          </Typography>
                        )}
                        {!option.is_free_shipping && option.id !== 6 ? (
                          <Typography
                            variant="caption"
                            color="primary"
                            display="block"
                          >
                            {t("Pri nákupe nad")} {option.threshold_value}{" "}
                            {t("bod")} {t("je doprava zadarmo")}.
                          </Typography>
                        ) : (
                          option.id !== 6 && (
                            <Typography
                              variant="caption"
                              color="primary"
                              display="block"
                            >
                              {t("Pri nákupe nad")} {option.threshold_value}{" "}
                              {t("bod")} {t("je doprava zadarmo")}{" "}
                            </Typography>
                          )
                        )}
                        <Typography variant="caption" color="green">
                          {(() => {
                            // Convert package_info to array if it's not already
                            const packages = Array.isArray(option.package_info)
                              ? option.package_info
                              : [];

                            return packages.map((packageInfo, index) => (
                              <Typography
                                key={`${packageInfo.delivery_time}-${index}`}
                                variant="caption"
                                display="block"
                                color="green"
                              >
                                {option.id !== 6 && packages.length > 1
                                  ? `${index + 1}. ${t("zásielka")}: `
                                  : ""}
                                {option.id === 6
                                  ? t(
                                      `Vyzdvihnutie po prijatí e-mailu s výzvou k vyzdvihnutiu`
                                    )
                                  : t(
                                      `Dodanie do ${packageInfo.delivery_time} pracovných dní`
                                    )}
                                {option.id !== 6 && (
                                  <Box component="ul" sx={{ mt: 0.5, pl: 2 }}>
                                    {packageInfo.products.map(
                                      (product, prodIndex) => (
                                        <Box
                                          component="li"
                                          key={`${product.product_name}-${prodIndex}`}
                                          sx={{
                                            fontSize: "0.75rem",
                                            color: "text.secondary",
                                            listStyleType: "disc",
                                          }}
                                        >
                                          {product.product_name}
                                        </Box>
                                      )
                                    )}
                                  </Box>
                                )}
                              </Typography>
                            ));
                          })()}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                }
              />
            </Paper>
          );
        })}
      </RadioGroup>
    </div>
  );
};

export default function Cart() {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState("card");
  const [cart, setCart] = useState(null);
  const [cartId, setCartId] = useState(null);
  const [deliveryDetails, setDeliveryDetails] = useState(null);
  const [orderComplete, setOrderComplete] = useState(false);
  const [orderNumber, setOrderNumber] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [cartTotals, setCartTotals] = useState({
    total_amount: 0,
    vat_amount: 0,
    subtotal: 0,
  });
  const [currency, setCurrency] = useState("€"); // Default currency as fallback
  const [initialLoading, setInitialLoading] = useState(true);
  const [updatingItemId, setUpdatingItemId] = useState(null);
  const [paymentStep, setPaymentStep] = useState("initial");
  const [orderData, setOrderData] = useState(null);
  const [stripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
  );
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [loadingDeliveryOptions, setLoadingDeliveryOptions] = useState(true);
  const [finalOrderTotal, setFinalOrderTotal] = useState(null);
  const [isProcessingCardPayment, setIsProcessingCardPayment] = useState(false);

  // Add scroll to top effect when step changes
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [activeStep]);

  useEffect(() => {
    fetchCart(true);
  }, []);

  useEffect(() => {
    const fetchDeliveryOptions = async () => {
      if (activeStep !== 2 || !deliveryDetails) return;

      try {
        setLoadingDeliveryOptions(true);

        const params = {
          country: deliveryDetails.country || "SK",
          city: deliveryDetails.city,
          postal_code: deliveryDetails.postalCode,
          address_line: deliveryDetails.street,
        };

        const response = await api.get("eshop/cart-delivery-options/", {
          params,
        });

        const transformedOptions = response.data.map((option) => ({
          ...option,
          price: parseFloat(option.price),
          threshold: parseFloat(option.treshold),
        }));
        setDeliveryOptions(transformedOptions);
      } catch (err) {
        enqueueSnackbar(t("Nepodarilo sa načítať možnosti dopravy"), {
          variant: "error",
        });
      } finally {
        setLoadingDeliveryOptions(false);
      }
    };

    fetchDeliveryOptions();
  }, [activeStep, deliveryDetails, enqueueSnackbar]);

  const fetchCart = async (isInitial = false) => {
    try {
      if (isInitial) {
        setInitialLoading(true);
      }
      const response = await cartService.getCart();
      const activeCart = response[0];
      if (activeCart) {
        setCartId(activeCart.id);
        // Extract currency from response if available
        if (activeCart.currency) {
          setCurrency(activeCart.currency);
        }
        const transformedItems = activeCart.items.map((item) => ({
          id: item.id,
          name: item.product_name,
          price: parseFloat(item.product_price),
          total_price: parseFloat(item.total_price),
          quantity: item.quantity,
          image: item.shopping_product.product_image_url,
          vat_amount: parseFloat(item.vat_amount),
          points: item.points,
          cashback: item.cashback,
          product_id: item.shopping_product.id,
          slug: item.shopping_product.slug,
          delivery_date: item.delivery_date,
        }));
        setCart(transformedItems);
        setCartTotals({
          total_amount: parseFloat(activeCart.total_amount),
          total_vat: parseFloat(activeCart.total_vat),
        });
      } else {
        setCart([]);
        setCartTotals(null);
      }
    } catch (error) {
      enqueueSnackbar(t("Nepodarilo sa načítať košík"), { variant: "error" });
      setCart([]);
      setCartTotals(null);
    } finally {
      if (isInitial) {
        setInitialLoading(false);
      }
    }
  };

  const handleDeliveryFormChange = (values) => {
    setDeliveryDetails(values);
    setActiveStep((prev) => prev + 1);
  };

  const handleNext = () => {
    if (activeStep === 1) {
      const deliveryForm = document.querySelector("form");
      if (deliveryForm) {
        const submitEvent = new Event("submit", {
          bubbles: true,
          cancelable: true,
        });
        deliveryForm.dispatchEvent(submitEvent);
      }
      return;
    }

    if (activeStep === 3 && !termsAccepted) {
      setTermsError(true);
      return;
    }

    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    // Reset delivery selection and options if going back to cart
    if (activeStep === 1) {
      setSelectedDelivery(null);
      setDeliveryOptions([]);
    }
    setActiveStep((prev) => prev - 1);
  };

  const handleQuantityChange = async (id, change) => {
    try {
      setUpdatingItemId(id);
      const item = cart.find((item) => item.id === id);
      const newQuantity = Math.max(1, item.quantity + change);

      await cartService.updateQuantity(id, newQuantity);
      await fetchCart();
    } catch (error) {
      enqueueSnackbar(t("Nepodarilo sa aktualizovať množstvo"), {
        variant: "error",
      });
    } finally {
      setUpdatingItemId(null);
    }
  };

  const handleRemoveItem = async (id) => {
    try {
      setUpdatingItemId(id);
      await cartService.removeItem(id);
      await fetchCart();
    } catch (error) {
      enqueueSnackbar(t("Nepodarilo sa odstrániť položku"), {
        variant: "error",
      });
    } finally {
      setUpdatingItemId(null);
    }
  };

  const calculateTotal = () => {
    if (!cartTotals) return 0;

    let total = cartTotals.total_amount;

    // Only add delivery price if we're past the delivery selection step (step 2)
    if (selectedDelivery && deliveryOptions.length > 0 && activeStep >= 2) {
      const selectedOption = deliveryOptions.find(
        (opt) => opt.name === selectedDelivery
      );
      if (selectedOption) {
        // Use is_free_shipping flag instead of threshold comparison
        if (!selectedOption.is_free_shipping) {
          total += selectedOption.price;
        }
      }
    }

    return total;
  };

  const calculateVAT = () => {
    if (!cartTotals) return { withoutVAT: 0, vatAmount: 0, total: 0 };

    return {
      withoutVAT: cartTotals.total_amount - cartTotals.total_vat,
      vatAmount: cartTotals.total_vat,
      total: cartTotals.total_amount,
    };
  };

  const renderCartItems = () => {
    if (!cart?.length) {
      return (
        <Paper sx={{ p: 4, textAlign: "center" }}>
          <Typography variant="h6" gutterBottom>
            {t("Váš košík je prázdny")}
          </Typography>
          <Typography color="text.secondary">
            {t("Pridajte produkty do košíka pre pokračovanie v nákupe")}
          </Typography>
        </Paper>
      );
    }

    return (
      <div className="space-y-4">
        {/* <Paper sx={{ p: 2, bgcolor: "white", mb: 3 }}>
          <Box display="flex" gap={2} alignItems="center">
            <VerifiedIcon color="primary" />
            <Typography>
              {t("Garancia spokojnosti: Vaša spokojnosť je pre nás prvoradá, preto, ak nedodržíme dobu dodania, získate od nás ako ospravedlnenie 5 EUR bonus na ďalší nákup")}
            </Typography>
          </Box>
        </Paper> */}

        {cart.map((item) => (
          <Paper key={item.id} sx={{ p: 2 }}>
            <Box className="flex flex-col sm:flex-row sm:justify-between gap-4">
              {/* Product info section */}
              <Box className="flex gap-3 items-center">
                <img
                  src={item.image}
                  alt={item.name}
                  style={{ width: 80, height: 80, objectFit: "contain" }}
                />
                <Box>
                  <Typography variant="h6" className="text-base sm:text-lg">
                    {item.name}
                  </Typography>
                  <Box className="flex gap-4 mt-1">
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        {t("Body")}
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        color="primary"
                      >
                        {item.points.toFixed(3)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        {t("Cashback")}
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        color="success.main"
                      >
                        {formatPrice(item.cashback)} {currency}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        {t("Dodanie")}
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        color="text.secondary"
                      >
                        {t(getDeliveryText(item.delivery_date))}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* Quantity and price section */}
              <Box className="flex flex-col sm:items-end justify-center mt-2 sm:mt-0">
                {/* Quantity controls */}
                <Box className="flex justify-between sm:justify-end items-center gap-1 mb-2">
                  <IconButton
                    onClick={() => handleQuantityChange(item.id, -1)}
                    disabled={updatingItemId === item.id}
                    size="small"
                    className="border border-gray-300"
                  >
                    <RemoveIcon />
                  </IconButton>
                  <Typography className="min-w-[30px] text-center">
                    {updatingItemId === item.id ? (
                      <CircularProgress size={20} />
                    ) : (
                      item.quantity
                    )}
                  </Typography>
                  <IconButton
                    onClick={() => handleQuantityChange(item.id, 1)}
                    disabled={updatingItemId === item.id}
                    size="small"
                    className="border border-gray-300"
                  >
                    <AddIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleRemoveItem(item.id)}
                    disabled={updatingItemId === item.id}
                    color="error"
                    size="small"
                    className="border border-gray-300 ml-2"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>

                {/* Price information */}
                <Box className="text-right w-full">
                  <Typography variant="body2" color="text.secondary">
                    {t("Bez DPH:")}{" "}
                    {formatPrice(item.total_price - item.vat_amount)} {currency}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t("DPH:")} {formatPrice(item.vat_amount)} {currency}
                  </Typography>
                  <Typography
                    variant="h6"
                    color="primary"
                    className="text-base sm:text-lg"
                  >
                    {formatPrice(item.price)} {currency} × {item.quantity} ={" "}
                    {formatPrice(item.total_price)} {currency}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {t("Získate:")} {item.points.toFixed(3)} × {item.quantity} ={" "}
                    {(item.points * item.quantity).toFixed(3)} {t("bodov")}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {t("Cashback:")} {formatPrice(item.cashback)} {currency} ×{" "}
                    {item.quantity} ={" "}
                    {formatPrice(item.cashback * item.quantity)} {currency}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        ))}

        <Paper sx={{ p: 2, mt: 3 }}>
          <Typography variant="h6" gutterBottom>
            {t("Súhrn objednávky")}
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Box className="flex justify-between mb-1">
              <Typography>{t("Suma bez DPH:")}</Typography>
              <Typography>
                {formatPrice(cartTotals?.total_amount - cartTotals?.total_vat)}{" "}
                {currency}
              </Typography>
            </Box>
            <Box className="flex justify-between mb-1">
              <Typography>{t("DPH")}:</Typography>
              <Typography>
                {formatPrice(cartTotals?.total_vat)} {currency}
              </Typography>
            </Box>
            {selectedDelivery && (
              <Box className="flex justify-between mb-1">
                <Typography>{t("Doprava s DPH")}:</Typography>
                <Typography>
                  {formatPrice(
                    deliveryOptions.find((opt) => opt.name === selectedDelivery)
                      ?.price
                  )}{" "}
                  {currency}
                </Typography>
              </Box>
            )}
            <Divider sx={{ my: 1 }} />
            <Box className="flex justify-between mb-2">
              <Typography variant="h6">{t("Celkom s DPH:")}</Typography>
              <Typography variant="h6" color="primary">
                {formatPrice(calculateTotal())} {currency}
              </Typography>
            </Box>
            <Box className="bg-gray-100 p-3 rounded">
              <Typography variant="subtitle2" gutterBottom>
                {t("Z tejto objednávky získate:")}
              </Typography>
              <Box className="flex justify-between">
                <Typography>{t("Body:")}</Typography>
                <Typography color="primary" fontWeight="bold">
                  {cart
                    .reduce((sum, item) => sum + item.points * item.quantity, 0)
                    .toFixed(3)}{" "}
                  {t("bodov")}
                </Typography>
              </Box>
              <Box className="flex justify-between">
                <Typography>{t("Cashback:")}</Typography>
                <Typography color="success.main" fontWeight="bold">
                  {formatPrice(
                    cart.reduce(
                      (sum, item) => sum + item.cashback * item.quantity,
                      0
                    )
                  )}{" "}
                  {currency}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </div>
    );
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return renderCartItems();
      case 1:
        return <DeliveryDetailsForm onFormChange={handleDeliveryFormChange} />;
      case 2:
        return (
          <DeliveryOptions
            selectedDelivery={selectedDelivery}
            setSelectedDelivery={setSelectedDelivery}
            cart={cart}
            calculateTotal={calculateTotal}
            deliveryOptions={deliveryOptions}
            loading={loadingDeliveryOptions}
            currency={currency}
          />
        );
      case 3:
        return (
          <PaymentMethodSelection
            cart={cart}
            calculateTotal={calculateTotal}
            selectedPayment={selectedPayment}
            setSelectedPayment={setSelectedPayment}
            termsAccepted={termsAccepted}
            setTermsAccepted={setTermsAccepted}
            termsError={termsError}
            cartTotals={cartTotals}
            selectedDelivery={selectedDelivery}
            deliveryOptions={deliveryOptions}
            currency={currency}
          />
        );
      case 4:
        const selectedOption = deliveryOptions.find(
          (opt) => opt.name === selectedDelivery
        );
        // Use is_free_shipping flag instead of threshold comparison
        const calculatedDeliveryPrice =
          selectedOption && !selectedOption.is_free_shipping
            ? selectedOption.price
            : 0;

        return (
          <OrderReview
            cartTotals={cartTotals}
            deliveryDetails={deliveryDetails}
            selectedDelivery={selectedDelivery}
            selectedPayment={selectedPayment}
            deliveryOptions={deliveryOptions}
            deliveryPrice={calculatedDeliveryPrice}
            cart={cart}
            calculateTotal={calculateTotal}
            currency={currency}
            t={t}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const getStepButton = (step) => {
    switch (step) {
      case 0:
        return t("Pokračovať k dodacím údajom");
      case 1:
        return t("Pokračovať k doprave");
      case 2:
        return t("Pokračovať k platbe");
      case 3:
        return t("Skontrolovať objednávku");
      default:
        return t("Pokračovať");
    }
  };

  const handleSubmitOrder = async () => {
    setLoading(true);
    try {
      console.log("Starting order creation...");

      // Get the delivery option selected by the user.
      // The shipping price is p g\šrovided by the backend, so we simply pull it from the option.
      const selectedDeliveryOption = deliveryOptions.find(
        (opt) => opt.name === selectedDelivery
      );
      // If no option is found, default to 0
      const shippingPrice = selectedDeliveryOption
        ? selectedDeliveryOption.price
        : 0;

      // Use the shipping price directly without any additional threshold computations.
      const formattedDeliveryDetails = {
        shipping_first_name: deliveryDetails.firstName,
        shipping_last_name: deliveryDetails.lastName,
        shipping_address: deliveryDetails.street,
        shipping_city: deliveryDetails.city,
        shipping_postal_code: deliveryDetails.postalCode,
        shipping_country: deliveryDetails.country,
        shipping_email: deliveryDetails.email,
        shipping_phone: deliveryDetails.phone,
        shipping_country: deliveryDetails.country,
        shipping_price: shippingPrice, // Now using the value provided from the backend.
        company_name: deliveryDetails.isCompany
          ? deliveryDetails.companyName
          : null,
        company_id: deliveryDetails.isCompany ? deliveryDetails.ico : null,
        tax_id: deliveryDetails.isCompany ? deliveryDetails.dic : null,
        vat_id: deliveryDetails.isCompany ? deliveryDetails.ic_dph : null,
        additional_message: deliveryDetails.additional_message || "",
      };

      const response = await api.post("/eshop/orders/create_order/", {
        cart: cart,
        ...formattedDeliveryDetails,
        payment_method: selectedPayment === "card" ? "card" : "transfer",
        delivery_method: selectedDeliveryOption?.id,
        shipping_provider:
          selectedDeliveryOption?.id || selectedDeliveryOption?.name,
        status: "pending",
      });

      console.log("Order created successfully:", response.data);
      setOrderData(response.data);

      // Store the final total from the backend response
      const finalTotal =
        parseFloat(response.data.total_amount) +
        parseFloat(response.data.shipping_price);
      setFinalOrderTotal(finalTotal);
      console.log("Final total:", finalTotal);
      setOrderComplete(true);
      setOrderNumber(response.data.order_number);

      if (selectedPayment === "transfer") {
        setPaymentStep("confirmation");
      } else {
        setIsProcessingCardPayment(true);
        console.log("Initializing Stripe payment...");
        const stripe = await stripePromise;
        console.log("Stripe loaded:", !!stripe);

        if (!stripe) {
          throw new Error("Stripe failed to load");
        }

        // Get the total_amount and shipping price as computed by the backend.
        const total_amount = parseFloat(response.data.total_amount);
        const shippingPriceBackend = parseFloat(response.data.shipping_price);
        // Simply add the shipping price to the total.
        const amount_with_delivery = total_amount + shippingPriceBackend;

        console.log({
          total_amount,
          shippingPriceBackend,
          amount_with_delivery,
        });

        const sessionResponse = await api.post(
          "eshop/create-payment-session/",
          {
            order_id: response.data.id,
            amount: amount_with_delivery,
            order_number: response.data.order_number,
            customer_email: deliveryDetails.email,
            currency: currency,
          }
        );

        console.log("Payment session created:", sessionResponse.data);

        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionResponse.data.sessionId,
        });

        if (error) {
          console.error("Stripe redirect error:", error);
          throw error;
        }
      }
    } catch (error) {
      setIsProcessingCardPayment(false);
      console.error("Order creation error:", error);
      console.error("Full error object:", {
        message: error.message,
        stack: error.stack,
        response: error.response?.data,
      });

      let errorMessage = t(
        "Nepodarilo sa vytvoriť objednávku. Prosím, skúste to znova."
      );

      if (error.response) {
        console.log("Error response:", error.response.data);
        errorMessage = error.response.data.message || errorMessage;
      }

      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  // Add cleanup of delivery details when order is complete
  useEffect(() => {
    if (orderComplete) {
      // Clear saved delivery details after successful order
      localStorage.removeItem("deliveryDetails");
    }
  }, [orderComplete]);

  useEffect(() => {
    // Scroll to top when activeStep changes
    window.scrollTo(0, 0);
  }, [activeStep]);

  if (initialLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isProcessingCardPayment) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={2}
        py={4}
      >
        <CircularProgress />
        <Typography>{t("Presmerovanie na platobnú bránu...")}</Typography>
      </Box>
    );
  }

  if (paymentStep === "confirmation" && orderData) {
    return (
      <PaymentConfirmation orderData={orderData} total={finalOrderTotal} />
    );
  }

  if (orderComplete && orderNumber) {
    console.log("Rendering OrderSuccess with total:", finalOrderTotal);
    return <OrderSuccess orderNumber={orderNumber} total={finalOrderTotal} />;
  }

  const canProceed = cart?.length > 0;

  return (
    <>
      <div className="max-w-screen-xl mx-auto pt-6 sm:pt-12 px-3 sm:px-12">
        <Stepper
          activeStep={activeStep}
          sx={{ mb: 2 }}
          className="overflow-x-auto pb-2"
          alternativeLabel
        >
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                StepIconComponent={() => (
                  <Box
                    sx={{
                      color: index === activeStep ? "black" : "#bdbdbd",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {step.icon}
                  </Box>
                )}
                sx={{
                  "& .MuiStepLabel-label": {
                    color: index === activeStep ? "black" : "#bdbdbd",
                    fontSize: { xs: "0.75rem", sm: "0.875rem" },
                    whiteSpace: { xs: "nowrap", sm: "normal" },
                  },
                }}
              >
                {t(step.label)}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <div className="bg-white pb-6 sm:pb-12">
        <div className="max-w-screen-xl mx-auto pt-4 sm:pt-6 px-3 sm:px-12">
          <Box sx={{ mt: { xs: 2, sm: 4 } }}>
            {activeStep === 2 ? (
              <DeliveryOptions
                selectedDelivery={selectedDelivery}
                setSelectedDelivery={setSelectedDelivery}
                cart={cart}
                calculateTotal={calculateTotal}
                deliveryOptions={deliveryOptions}
                loading={loadingDeliveryOptions}
                currency={currency}
              />
            ) : activeStep === 3 ? (
              <PaymentMethodSelection
                cart={cart}
                calculateTotal={calculateTotal}
                selectedPayment={selectedPayment}
                setSelectedPayment={setSelectedPayment}
                termsAccepted={termsAccepted}
                setTermsAccepted={setTermsAccepted}
                termsError={termsError}
                cartTotals={cartTotals}
                selectedDelivery={selectedDelivery}
                deliveryOptions={deliveryOptions}
                currency={currency}
              />
            ) : (
              getStepContent(activeStep)
            )}
          </Box>

          <Box className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-4 gap-4">
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="outlined"
              className="w-full sm:w-auto"
            >
              {t("Späť")}
            </Button>
            <Box className="w-full sm:w-auto text-right">
              <Typography variant="h6" className="mb-2 text-base sm:text-lg">
                {t("Celkom:")} {formatPrice(calculateTotal())} {currency}
              </Typography>
              <Button
                variant="contained"
                onClick={
                  activeStep === steps.length - 1
                    ? handleSubmitOrder
                    : handleNext
                }
                size="large"
                disabled={!canProceed || loading}
                className="w-full sm:w-auto"
              >
                {activeStep === steps.length - 1 ? (
                  loading ? (
                    <>
                      <CircularProgress size={20} sx={{ mr: 1 }} />
                      {t("Spracovávam...")}
                    </>
                  ) : selectedPayment === "card" ? (
                    t("Pokračovať k platbe")
                  ) : (
                    t("Dokončiť objednávku")
                  )
                ) : (
                  getStepButton(activeStep)
                )}
              </Button>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
}
