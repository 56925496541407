import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Chip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Skeleton,
  Grid,
  Alert,
  Tooltip,
} from "@mui/material";
import {
  Visibility as ViewIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import api from "../services/api";
import { ALL_COUNTRIES } from "../data/countries";
import { useTranslation } from "react-i18next";

const STATUS_COLORS = {
  pending: "warning",
  paid: "info",
  shipped: "primary",
  delivered: "success",
  cancelled: "error",
};

const STATUS_LABELS = {
  pending: "Čaká na platbu",
  paid: "Zaplatené",
  shipped: "Odoslané",
  delivered: "Doručené",
  cancelled: "Zrušené",
};

const PAYMENT_METHOD_LABELS = {
  transfer: "Bankový prevod",
  card: "Platobná karta",
};

const TableSkeleton = () => {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper} className="mt-12">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("Order number")}</TableCell>
            <TableCell>{t("Date")}</TableCell>
            <TableCell>{t("Amount")}</TableCell>
            <TableCell>{t("Status")}</TableCell>
            <TableCell>{t("Actions")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(5)].map((_, index) => (
            <TableRow key={index}>
              <TableCell>
                <Skeleton width={100} />
              </TableCell>
              <TableCell>
                <Skeleton width={80} />
              </TableCell>
              <TableCell>
                <Skeleton width={70} />
              </TableCell>
              <TableCell>
                <Skeleton
                  width={100}
                  height={32}
                  variant="rectangular"
                  sx={{ borderRadius: 1 }}
                />
              </TableCell>
              <TableCell>
                <Skeleton variant="circular" width={30} height={30} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default function UserOrdersTable() {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState(null);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [cancelError, setCancelError] = useState(null);
  const [cancelLoading, setCancelLoading] = useState(false);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await api.get("eshop/user-orders/");
      console.log("Orders response:", response.data);
      const ordersData = Array.isArray(response.data)
        ? response.data
        : response.data.results || [];
      console.log("Processed orders:", ordersData);
      setOrders(ordersData);
    } catch (error) {
      console.error("Failed to fetch orders:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleViewDetails = (order) => {
    setSelectedOrder(order);
    setDialogOpen(true);
  };

  const handleCancelOrder = async () => {
    setCancelLoading(true);
    setCancelError(null);
    try {
      await api.post("eshop/user-mark-order-cancelled/", {
        order_id: cancelOrderId,
      });
      await fetchOrders(); // Refresh orders
      setCancelDialogOpen(false);
      setCancelOrderId(null);
    } catch (error) {
      setCancelError(t("Failed to cancel order. Please try again later."));
    } finally {
      setCancelLoading(false);
    }
  };

  if (loading) {
    return <TableSkeleton />;
  }

  const renderActions = (order) => (
    <>
      <Tooltip title={t("View order details")} arrow>
        <IconButton size="small" onClick={() => handleViewDetails(order)}>
          <ViewIcon />
        </IconButton>
      </Tooltip>
      {order.status === "pending" && (
        <Tooltip title={t("Cancel order")} arrow>
          <IconButton
            size="small"
            color="error"
            onClick={() => {
              setCancelOrderId(order.id);
              setCancelDialogOpen(true);
            }}
          >
            <CancelIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );

  return (
    <Box className="mt-12">
      {orders.length > 0 ? (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Order number")}</TableCell>
                  <TableCell>{t("Date")}</TableCell>
                  <TableCell>{t("Amount")}</TableCell>
                  <TableCell>{t("Status")}</TableCell>
                  <TableCell>{t("Actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.order_number}</TableCell>
                    <TableCell>
                      {new Date(order.created_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {(
                        parseFloat(order.total_amount) +
                        parseFloat(order.shipping_price)
                      ).toFixed(2)}
                      €
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={
                          STATUS_LABELS[order.status] || order.status_display
                        }
                        color={STATUS_COLORS[order.status]}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>{renderActions(order)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Typography variant="h6" align="center">
          {t("No orders found")}
        </Typography>
      )}

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        {selectedOrder && (
          <>
            <DialogTitle>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="h6">
                  {t("Order")} #{selectedOrder.order_number}
                </Typography>
                <Chip
                  label={
                    selectedOrder.status || STATUS_LABELS[selectedOrder.status]
                  }
                  color={STATUS_COLORS[selectedOrder.status]}
                  size="small"
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mb: 3 }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      gutterBottom
                    >
                      {t("Shipping details")}
                    </Typography>
                    <Typography variant="body2">
                      {selectedOrder.shipping_first_name}{" "}
                      {selectedOrder.shipping_last_name}
                      <br />
                      {selectedOrder.shipping_address}
                      <br />
                      {selectedOrder.shipping_postal_code}{" "}
                      {selectedOrder.shipping_city}
                      <br />
                      {selectedOrder.shipping_country}
                      <br />
                      {selectedOrder.shipping_email}
                      <br />
                      {selectedOrder.shipping_phone}
                    </Typography>
                  </Box>

                  {selectedOrder.company_name && (
                    <Box sx={{ mb: 3 }}>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        gutterBottom
                      >
                        {t("Billing details")}
                      </Typography>
                      <Typography variant="body2">
                        {selectedOrder.company_name}
                        <br />
                        {t("Company ID")}: {selectedOrder.company_id}
                        <br />
                        {t("Tax ID")}: {selectedOrder.tax_id}
                        {selectedOrder.vat_id && (
                          <>
                            <br />
                            {t("VAT ID")}: {selectedOrder.vat_id}
                          </>
                        )}
                      </Typography>
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ mb: 3 }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      gutterBottom
                    >
                      {t("Items")}
                    </Typography>
                    <TableContainer component={Paper} variant="outlined">
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("Product")}</TableCell>
                            <TableCell align="right">{t("Quantity")}</TableCell>
                            <TableCell align="right">{t("Price/pc")}</TableCell>
                            <TableCell align="right">{t("Total")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedOrder.items.map((item) => (
                            <TableRow key={item.id}>
                              <TableCell>{item.product_name}</TableCell>
                              <TableCell align="right">
                                {item.quantity}
                              </TableCell>
                              <TableCell align="right">
                                {item.product_price}€
                              </TableCell>
                              <TableCell align="right">
                                {(
                                  parseFloat(item.product_price) * item.quantity
                                ).toFixed(2)}
                                €
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    {t("Order summary")}
                  </Typography>
                  <Typography variant="body2">
                    {t("Amount without VAT")}:{" "}
                    {(
                      parseFloat(selectedOrder.total_amount) -
                      parseFloat(selectedOrder.vat_amount)
                    ).toFixed(2)}
                    €
                    <br />
                    {t("Shipping")}: {selectedOrder.shipping_price}€
                    <br />
                    {t("VAT")}: {selectedOrder.vat_amount}€
                    <br />
                    <Box sx={{ mt: 1 }}>
                      <Typography variant="subtitle2" fontWeight="bold">
                        {t("Total with VAT")}:{" "}
                        {(
                          parseFloat(selectedOrder.total_amount) +
                          parseFloat(selectedOrder.shipping_price)
                        ).toFixed(2)}
                        €
                      </Typography>
                    </Box>
                  </Typography>
                </Box>

                {selectedOrder.tracking_number && (
                  <Box sx={{ mb: 3 }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      gutterBottom
                    >
                      {t("Tracking information")}
                    </Typography>
                    {Array.isArray(selectedOrder.tracking_number) ? (
                      selectedOrder.tracking_number.map((tracking, index) => (
                        <Typography key={index} variant="body2">
                          {t("Carrier")}: {tracking.provider}
                          <br />
                          {tracking.tracking_number
                            ? `${t("Tracking number")}: ${tracking.tracking_number}`
                            : `${t("Tracking number")}: ${tracking.tracking_number}`}
                          {tracking.estimated_delivery && (
                            <>
                              <br />
                              {t("Estimated delivery")}:{" "}
                              {tracking.estimated_delivery}
                            </>
                          )}
                        </Typography>
                      ))
                    ) : (
                      <Typography variant="body2">
                        {t("Carrier")}:{" "}
                        {selectedOrder.shipping_provider?.name ||
                          selectedOrder.tracking_number.provider}
                        <br />
                        {t("Tracking number")}:{" "}
                        {selectedOrder.tracking_number.tracking_number ||
                          selectedOrder.tracking_number}
                      </Typography>
                    )}
                  </Box>
                )}

                {selectedOrder.additional_message && (
                  <Box sx={{ mb: 3 }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      gutterBottom
                    >
                      {t("Poznámka k objednávke")}
                    </Typography>
                    <Typography variant="body2">
                      {selectedOrder.additional_message}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDialogOpen(false)}>{t("Close")}</Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <Dialog
        open={cancelDialogOpen}
        onClose={() => {
          setCancelDialogOpen(false);
          setCancelError(null);
        }}
      >
        <DialogTitle>{t("Cancel Order")}</DialogTitle>
        <DialogContent>
          {cancelError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {cancelError}
            </Alert>
          )}
          <Typography>
            {t(
              "Are you sure you want to cancel this order? This action cannot be undone."
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCancelDialogOpen(false);
              setCancelError(null);
            }}
            disabled={cancelLoading}
          >
            {t("No, keep order")}
          </Button>
          <Button
            onClick={handleCancelOrder}
            color="error"
            disabled={cancelLoading}
            variant="contained"
          >
            {cancelLoading ? t("Cancelling...") : t("Yes, cancel order")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
