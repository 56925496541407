import BasicNavbar from "../components/BasicNavbar";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import "../utils/quill.css";
import { CiMail } from "react-icons/ci";
import { motion } from "framer-motion";
import Cart from "../components/eshop/Cart";
import { useAdmin } from "../contexts/AdminContext";

const Contact = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { isAdmin, isAdminView } = useAdmin();

  return (
    <>
      <BasicNavbar />
      <div>
        <Cart />
      </div>
      <Footer />
    </>
  );
};

export default Contact;
