import React, { useState, useEffect, lazy, Suspense } from "react";
import { NextUIProvider, Skeleton } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { NavbarProvider } from "../components/NavbarContext";
import { useAdmin } from "../contexts/AdminContext";

// Lazy Loading Components
const BasicNavbar = lazy(() => import("../components/BasicNavbar"));
const Footer = lazy(() => import("../components/Footer"));
const Hero = lazy(() => import("../components/Hero"));
const HowItWorks = lazy(() => import("../components/HowItWorks"));
const AffiliateBenefits = lazy(() => import("../components/AffiliateBenefits"));
const HowCashbackWorks = lazy(() => import("../components/HowCashbackWorks"));
const WhatPeopleSay = lazy(() => import("../components/WhatPeopleSay"));
const ContactUs = lazy(() => import("../components/ContactUs"));
const CookieConsent = lazy(() => import("../components/CookieConsent"));
const ProductSlider = lazy(() => import("../components/ProductSlider"));
// const ShopsCarousel = lazy(() => import("../components/ShopsCarousel"));
const ShopsCloud = lazy(() => import("../components/ShopsCloud"));
const BlogLanding = lazy(() => import("../components/BlogLanding"));
const SeasonalOffers = lazy(() => import("../components/SeasonalOffers"));
const BilionbuyLandingOffers = lazy(
  () => import("../components/BilionbuyLandingOffers")
);
const CategoryOffers = lazy(() => import("../components/CategoryOffers"));
const BilionbuyHomepageOffers = lazy(
  () => import("../components/BilionbuyHomepageOffers")
);

const InPageRegistration = lazy(
  () => import("../components/InPageRegistration")
);

function Home() {
  const { t } = useTranslation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentLocale, setCurrentLocale] = useState(() => {
    return localStorage.getItem("locale") || "sk";
  });
  const { isAdmin, isAdminView } = useAdmin();

  // Add refs for each section
  const howItWorksRef = React.useRef(null);
  const whatPeopleSayRef = React.useRef(null);
  const contactRef = React.useRef(null);
  const seasonalRef = React.useRef(null);
  const affiliateRef = React.useRef(null);
  const cashbackRef = React.useRef(null);

  useEffect(() => {
    const jwt = localStorage.getItem("jwt");
    if (jwt) {
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    const handleLocaleChange = () => {
      setCurrentLocale(localStorage.getItem("locale") || "sk");
    };

    window.addEventListener("storage", handleLocaleChange);
    return () => window.removeEventListener("storage", handleLocaleChange);
  }, []);

  return (
    <>
      <Helmet>
        <title>BILIONBUY - Compare and Earn for FREE</title>
        <meta
          name="description"
          content="Compare quality, price, and earn cashback with Bilionbuy - a platform that connects you with the best deals and offers online."
        />
        <meta
          name="keywords"
          content="Bilionbuy, Cashback, Compare Prices, Online Shopping, Deals"
        />
        <meta name="author" content="Bilionbuy Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://www.bilionbuy.com/" />
        {/* Open Graph Meta Tags for Social Media */}
        <meta
          property="og:title"
          content="BILIONBUY - Compare and Earn for FREE"
        />
        <meta
          property="og:description"
          content="Join Bilionbuy to compare quality, price, and earn cashback across multiple online stores. Shop smart, save more!"
        />
        <meta property="og:url" content="https://www.bilionbuy.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://xsvyvpimqhfgpibgjpyd.supabase.co/storage/v1/object/public/s_bbuy/img/bb-white.png"
        />
      </Helmet>
      <div className="mx-auto bg-white overflow-x-clip">
        {/* Lazy Loaded Components with Fallbacks */}
        <Suspense fallback={<Skeleton width="100%" height="60px" />}>
          <BasicNavbar />
        </Suspense>

        <Suspense fallback={<Skeleton width="100%" height="300px" />}>
          <Hero className="" auth={isAuthenticated} t={t} />
          <div id="seasonal" ref={seasonalRef}>
            <ShopsCloud />
          </div>
        </Suspense>

        {/* Product Sliders */}
        <Suspense fallback={<Skeleton width="100%" height="200px" />}>
          {(currentLocale === "sk" || currentLocale === "cs") && (
            <>
              {/* Admin-only content */}
              {currentLocale === "sk" && isAdmin && isAdminView && (
                <BilionbuyHomepageOffers />
              )}
              {/* <SeasonalOffers /> */}
              <BilionbuyLandingOffers />
              <CategoryOffers category={3225} />
              <CategoryOffers category={3144} />
              <CategoryOffers category={1574} />
              <CategoryOffers category={2158} />
              {/* <ProductSlider
                title={t("Zažite radosť z nakupovania")}
                text={t(
                  "Bez ohľadu na to, čo hľadáte, máme to! Zháňate nové pneumatiky, moderný nábytok, záhradné náradie, najnovšie technologické vychytávky alebo štýlové oblečenie? Prinášame vám neobmedzený sortiment produktov z celého sveta, všetko na jednom mieste."
                )}
                fetchCall={"get-landing-products/"}
                type={"product"}
              />
              <ProductSlider
                title={t("Zažite spokojnosť z donášky potravín")}
                text={t(
                  "Už žiadne dlhé rady v obchodoch ani hľadanie parkovacieho miesta. Všetky kvalitné potraviny, ktoré si objednáte, vám doručíme rýchlo a spoľahlivo priamo k vašim dverám"
                )}
                fetchCall={"get-landing-food/"}
                type={"food"}
              /> */}
              {/* <ProductSlider
                title={t("Zažite tie najkrajšie zážitky")}
                text={t(
                  "Celý svet na dosah ruky! Nezáleží na tom, či túžite po pokojnom odpočinku alebo vzrušujúcom dobrodružstve, naša online ponuka dovoleniek je navrhnutá tak, aby splnila vaše sny a predstavy. Stačí si len vybrať a začať cestovať"
                )}
                fetchCall={"get-landing-travel/"}
                type={"travel"}
              /> */}
            </>
          )}
        </Suspense>

        {/* Other Components */}
        <Suspense fallback={<Skeleton width="100%" height="200px" />}>
          <div id="how-it-works" ref={howItWorksRef}>
            <HowItWorks
              title="Ako to celé funguje?"
              subtitle="Ako to celé funguje?"
              steps={[
                {
                  number: "1",
                  titleKey: "Registrácia zdarma účet bez poplatkov",
                  descriptionKey:
                    "Zaregistrujte sa zdarma, aby sme vám pripisovali odmeny za nakupovanie na váš účet",
                  bgColor: "bg-[#4A90E2]",
                },
                {
                  number: "2",
                  titleKey: "Nákup produktov na trhovisku",
                  descriptionKey:
                    "Nakupujte produkty na trhovisku priamo v eshope alebo cez partnerské obchody",
                  bgColor: "bg-[#E57697]",
                },
                {
                  number: "3",
                  titleKey: "Pripísanie rastúceho cashbacku do e-peňaženky",
                  descriptionKey:
                    "Rastúci cashback za vlastné nákupy a nákupy siete vám budú pripísané do e-peňaženky",
                  bgColor: "bg-[#A6CF4A]",
                },
                {
                  number: "4",
                  titleKey: "Pripísanie bodov do vernostného programu",
                  descriptionKey:
                    "Body za vlastné nákupy a nákupy siete vám budú pripísané do vernostného programu, čím sa vám postupne zvyšuje rastúci cashback",
                  bgColor: "bg-[#FB6238]",
                },
                {
                  number: "5",
                  titleKey:
                    "Pripísanie odmien z vernostného programu do e-peňaženky",
                  descriptionKey:
                    "Odmeny z vernostného programu sa pripisujú 1x mesačne do vašej e-peňaženky.",
                  bgColor: "bg-[#B8CC2E]",
                },
                {
                  number: "6",
                  titleKey: "Vyplatenie odmienna bankový účet",
                  descriptionKey:
                    "O vyplatenie odmien je možné požiadať v e-peňaženke. Pred vyplatením si musíte upraviť profil a zadať údaje potrebné na vyplatenie odmeny",
                  bgColor: "bg-[#F1762F]",
                },
              ]}
            />
          </div>
          <div id="affiliate" ref={affiliateRef}>
            <AffiliateBenefits className="" auth={isAuthenticated} />
          </div>
          <div id="cashback" ref={cashbackRef}>
            <HowCashbackWorks className="" auth={isAuthenticated} />
          </div>
        </Suspense>

        <Suspense fallback={<Skeleton width="100%" height="300px" />}>
          <BlogLanding className="" />
        </Suspense>

        <Suspense fallback={<Skeleton width="100%" height="200px" />}>
          <div id="what-people-say" ref={whatPeopleSayRef}>
            <WhatPeopleSay className="" auth={isAuthenticated} />
          </div>
          <div>
            <InPageRegistration className="" />
          </div>
          <Footer className="" />
        </Suspense>
      </div>
    </>
  );
}

export default React.memo(Home);
