import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Chip,
  Box,
  CircularProgress,
  Autocomplete,
  Typography,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Assignment as AssignmentIcon,
} from "@mui/icons-material";
import ReactCountryFlag from "react-country-flag";
import { toast } from "react-toastify";
import api from "../../../services/api";
import debounce from "lodash/debounce";
import { ALL_COUNTRIES } from "../../../data/countries";

const VATSettingsTable = () => {
  const [vatSettings, setVatSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedVAT, setSelectedVAT] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("all");
  const [formData, setFormData] = useState({
    country: "",
    type: "",
    vat_rate: "",
    categories: [],
    primary: false,
  });
  const abortControllerRef = useRef(null);
  const [openSetProductsModal, setOpenSetProductsModal] = useState(false);
  const [selectedVATForProducts, setSelectedVATForProducts] = useState(null);
  const [setProductsLoading, setSetProductsLoading] = useState(false);

  // Helper function to render country with flag
  const renderCountryWithFlag = (countryCode) => {
    const name =
      countryCode === "all"
        ? "Všetky krajiny"
        : ALL_COUNTRIES.find((c) => c.code === countryCode)?.name ||
          countryCode;

    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {countryCode === "all" ? (
          <span>🌍</span>
        ) : (
          <ReactCountryFlag
            countryCode={countryCode}
            svg
            style={{
              width: "1.5em",
              height: "1.5em",
            }}
            title={name}
          />
        )}
        <span>{name}</span>
      </Box>
    );
  };

  // Fetch VAT settings and categories
  const fetchVATSettings = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams();
      if (selectedCountry && selectedCountry !== "all") {
        params.append("country", selectedCountry);
      }
      const response = await api.get(`vat-settings/?${params.toString()}`);
      setVatSettings(response.data);
      setError(null);
    } catch (err) {
      setError("Nepodarilo sa načítať nastavenia DPH");
      toast.error("Nepodarilo sa načítať nastavenia DPH");
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (query.length > 2) {
        try {
          if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }

          abortControllerRef.current = new AbortController();

          const response = await api.get(`search-categories/?query=${query}`, {
            signal: abortControllerRef.current.signal,
          });
          const newOptions = Array.isArray(response.data) ? response.data : [];
          setCategoryOptions(newOptions);
        } catch (error) {
          if (error.name === "AbortError") {
            console.log("Request aborted");
          } else {
            console.error("Error searching categories: ", error);
          }
        }
      }
    }, 300),
    []
  );

  useEffect(() => {
    fetchVATSettings();
  }, [selectedCountry]);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  // Modal handlers
  const handleOpenModal = (vat = null) => {
    if (vat) {
      setFormData({
        country: vat.country,
        type: vat.type,
        vat_rate: vat.vat_rate,
        categories: vat.categories,
        primary: vat.primary,
      });
      setSelectedVAT(vat);
    } else {
      setFormData({
        country: "",
        type: "",
        vat_rate: "",
        categories: [],
        primary: false,
      });
      setSelectedVAT(null);
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedVAT(null);
    setFormData({
      country: "",
      type: "",
      vat_rate: "",
      categories: [],
      primary: false,
    });
  };

  // Form handlers
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getCountryFlag = (countryCode) => {
    return (
      <ReactCountryFlag
        countryCode={countryCode}
        svg
        style={{
          width: "1.5em",
          height: "1.5em",
        }}
        title={countryCode}
      />
    );
  };

  // CRUD operations
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const submitData = {
        ...formData,
        categories: formData.categories.map((cat) => cat.id),
      };

      if (selectedVAT) {
        await api.put(`vat-settings/${selectedVAT.id}/update/`, submitData);
        toast.success("Nastavenie DPH bolo úspešne aktualizované");
      } else {
        await api.post("vat-settings/create/", submitData);
        toast.success("Nastavenie DPH bolo úspešne vytvorené");
      }
      fetchVATSettings();
      handleCloseModal();
    } catch (err) {
      toast.error(
        selectedVAT
          ? "Nepodarilo sa aktualizovať nastavenie DPH"
          : "Nepodarilo sa vytvoriť nastavenie DPH"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await api.delete(`vat-settings/${id}/delete/`);
      toast.success("Nastavenie DPH bolo úspešne vymazané");
      fetchVATSettings();
    } catch (err) {
      toast.error("Nepodarilo sa vymazať nastavenie DPH");
    } finally {
      setLoading(false);
      setOpenDeleteDialog(false);
    }
  };

  // Add handler for setting VAT to products
  const handleSetToProducts = async (mode) => {
    setSetProductsLoading(true);
    try {
      await api.post("set-products-vat/", {
        country: selectedVATForProducts.country,
        categories: selectedVATForProducts.categories.map((cat) => cat.id),
        vat_rate: selectedVATForProducts.vat_rate,
        mode: mode, // 'set' or 'overwrite'
      });
      toast.success("DPH bolo úspešne nastavené produktom");
      setOpenSetProductsModal(false);
      setSelectedVATForProducts(null);
    } catch (err) {
      toast.error("Nepodarilo sa nastaviť DPH produktom");
    } finally {
      setSetProductsLoading(false);
    }
  };

  if (loading && !vatSettings.length) {
    return <CircularProgress />;
  }

  if (error && !vatSettings.length) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="h5" component="h1">
            Nastavenia DPH
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Krajina</InputLabel>
            <Select
              value={selectedCountry}
              onChange={handleCountryChange}
              label="Krajina"
            >
              <MenuItem value="all">{renderCountryWithFlag("all")}</MenuItem>
              {ALL_COUNTRIES.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {renderCountryWithFlag(country.code)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => handleOpenModal()}
          >
            Pridať nové nastavenie DPH
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Krajina</TableCell>
              <TableCell>Typ</TableCell>
              <TableCell>Sadzba DPH (%)</TableCell>
              <TableCell>Kategórie</TableCell>
              <TableCell>Primárna</TableCell>
              <TableCell>Akcie</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vatSettings.map((vat) => (
              <TableRow key={vat.id}>
                <TableCell>{renderCountryWithFlag(vat.country)}</TableCell>
                <TableCell>{vat.type}</TableCell>
                <TableCell>{vat.vat_rate}%</TableCell>
                <TableCell>
                  <Box display="flex" gap={1} flexWrap="wrap">
                    {vat.categories.map((category) => (
                      <Chip
                        key={category.id}
                        label={category.category_name}
                        size="small"
                        variant="outlined"
                      />
                    ))}
                  </Box>
                </TableCell>
                <TableCell>{vat.primary ? "Áno" : "Nie"}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleOpenModal(vat)}
                    color="primary"
                    title="Upraviť"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setSelectedVAT(vat);
                      setOpenDeleteDialog(true);
                    }}
                    color="error"
                    title="Vymazať"
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setSelectedVATForProducts(vat);
                      setOpenSetProductsModal(true);
                    }}
                    color="info"
                    title="Nastaviť produktom"
                  >
                    <AssignmentIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add/Edit Modal */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {selectedVAT
            ? "Upraviť nastavenie DPH"
            : "Pridať nové nastavenie DPH"}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box display="flex" flexDirection="column" gap={2}>
              <FormControl fullWidth required>
                <InputLabel>Krajina</InputLabel>
                <Select
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  label="Krajina"
                >
                  {ALL_COUNTRIES.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {renderCountryWithFlag(country.code)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                name="type"
                label="Typ"
                value={formData.type}
                onChange={handleInputChange}
                required
                fullWidth
                multiline
                rows={3}
              />
              <TextField
                name="vat_rate"
                label="Sadzba DPH (%)"
                type="number"
                value={formData.vat_rate}
                onChange={handleInputChange}
                required
                fullWidth
                inputProps={{ step: "0.01", min: "0", max: "100" }}
              />
              <Autocomplete
                multiple
                id="categories-autocomplete"
                options={categoryOptions}
                getOptionLabel={(option) => option?.category_fullname || ""}
                value={formData.categories}
                onChange={(event, newValue) => {
                  setFormData((prev) => ({
                    ...prev,
                    categories: newValue,
                  }));
                }}
                onInputChange={(event, newInputValue) => {
                  debouncedSearch(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Kategórie" variant="outlined" />
                )}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option.category_fullname}
                      {...getTagProps({ index })}
                      size="small"
                    />
                  ))
                }
              />
              <FormControl fullWidth>
                <InputLabel>Primárna sadzba</InputLabel>
                <Select
                  name="primary"
                  value={formData.primary}
                  onChange={handleInputChange}
                  label="Primárna sadzba"
                >
                  <MenuItem value={false}>Nie</MenuItem>
                  <MenuItem value={true}>Áno</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Zrušiť</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Uložiť"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Potvrdiť vymazanie</DialogTitle>
        <DialogContent>
          Naozaj chcete vymazať toto nastavenie DPH?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Zrušiť</Button>
          <Button
            onClick={() => handleDelete(selectedVAT?.id)}
            color="error"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Vymazať"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Set Products Modal */}
      <Dialog
        open={openSetProductsModal}
        onClose={() => {
          setOpenSetProductsModal(false);
          setSelectedVATForProducts(null);
        }}
      >
        <DialogTitle>Nastaviť DPH produktom</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 1 }}>
            <Typography>
              Vyberte spôsob nastavenia DPH ({selectedVATForProducts?.vat_rate}
              %) pre produkty v kategóriách:
            </Typography>
            <Box sx={{ mt: 1, mb: 2 }}>
              {selectedVATForProducts?.categories.map((category) => (
                <Chip
                  key={category.id}
                  label={category.category_name}
                  size="small"
                  variant="outlined"
                  sx={{ m: 0.5 }}
                />
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ flexDirection: "column", gap: 1, p: 3 }}>
          <Button
            onClick={() => handleSetToProducts("set")}
            variant="contained"
            color="primary"
            fullWidth
            disabled={setProductsLoading}
          >
            {setProductsLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Nastaviť len prázdnym"
            )}
          </Button>
          <Button
            onClick={() => handleSetToProducts("overwrite")}
            variant="contained"
            color="warning"
            fullWidth
            disabled={setProductsLoading}
          >
            {setProductsLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Prepísať všetky"
            )}
          </Button>
          <Button
            onClick={() => {
              setOpenSetProductsModal(false);
              setSelectedVATForProducts(null);
            }}
            variant="outlined"
            fullWidth
            disabled={setProductsLoading}
          >
            Zrušiť
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default VATSettingsTable;
