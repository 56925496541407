import React from "react";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import TextImageComponent from "../components/TextImageComponent";
import HeaderBanner from "../components/HeaderBanner";
import HowItWorks from "../components/HowItWorks";
import FAQ from "../components/FAQ";
import WhatPeopleSay from "../components/WhatPeopleSay";
import ContactUs from "../components/ContactUs";
import BlogLanding from "../components/BlogLanding";
import YouCouldSeeUs from "../components/YouCouldSeeUs";
import InPageRegistration from "../components/InPageRegistration";

export default function Marketplace() {
  return (
    <div className="overflow-x-clip">
      <BasicNavbar />

      <HeaderBanner
        imageSrc="https://bbuy.fra1.cdn.digitaloceanspaces.com/img/woman1.png"
        alt="Marketplace"
        title="Trhovisko, ktoré prináša zmenu filozofie nakupovania a výhody pre užívateľa"
        accordionItems={[
          {
            title: "Široká ponuka produktov z eshopu Bilionbuy",
            content:
              "Bilionbuy ponúka viac ako 400.000 produktov, ktoré doručuje do 19 krajín. Tieto produkty je možné nakúpiť prostredníctvom nákupu cez nákupný košík. Tieto produkty sú označené ako “Do košíka”.",
          },
          {
            title: "Široká ponuka produktov z partnerských eshopov",
            content:
              "Na Bilionbuy nájdete viac ako 7.000.000 produktov parnerských eshopov, ktoré je možné nakúpiť prostredníctvom prekliku do partnerského eshopu. Tieto produkty sú označené “Do obchodu”.",
          },
          {
            title: "Partnerské obchody",
            content:
              "Na Bilionbuy nájdete viac ako 3.000 partnerských eshopov, v ktorých je možné nakúpiť prostredníctvom prekliku do partnerského eshopu cez tlačidlo “Do obchodu”.",
          },
          {
            title: "Porovnanie ceny",
            content:
              "Bilionbuy ponúka svojim užívateľom funkciu porovnania ceny produktov. Cenu porovnáva medzi partnermi, ktorí dodali Bilionbuy svoj produktový katalóg s cenami.",
          },
          {
            title: "Dostupné v 19 krajinách",
            content:
              "Bilionbuy je dostupný aktuálne v týchto krajinách: Belgicko, Grécko, Chorvátsko, Litva, Francúzsko, Slovensko, Nemecko, Rakúsko, Holandsko, Fínsko, Taliansko, Slovinsko, Španielsko, Portugalsko, Estónsko, Luxembursko, Česká republika, Poľsko, Maďarsko.",
          },
          {
            title: "Nová filozofia nakupovania",
            content:
              "Princíp vernostného programu je založený na pripojení sa k filozofii nakupovania samospotrebiteľskou formou. Samospotrebiteľská forma nakupovania predstavuje možnosť zarábať na vlastnej spotrebe, na tom, čo potrebujete nakupovať.",
          },
          {
            title: "Výhody pre užívateľa",
            content:
              "Výhodou je vyplácanie užívateľom rastúci cashback za vlastné nákupy a nákupy siete, čím majú možnosť zarábať prostredníctvom vlastných, ale aj cudzích nákupov na trhovisku.",
          },
        ]}
      />

      <TextImageComponent
        imagePosition="left" // or "right"
        heading="Produkty z eshopu Bilionbuy v katalógu na webovej platforme"
        fulltext="V katalógu na webovej platforme Bilionbuy nájdete produkty z eshopu Bilionbuy, ktoré si môžete zakúpiť prostredníctvom internetového nákupu priamo cez Bilionbuy. Tieto produkty z eshopu Bilionbuy sú označené “Do košíka” a “Do obchodu”"
        imageSrc="https://bbuy.fra1.cdn.digitaloceanspaces.com/img/cart1.png"
        imageAlt="Illustration of how cashback works"
        auth={false}
        ctaConfig={{
          primaryButton: {
            text: "Registrácia zdarma",
            href: "/register",
          },
          secondaryButton: {
            text: "Prejsť do katalógu",
            href: "/browse-products/",
          },
        }}
      />

      <HowItWorks
        title="Ako funguje nakupovanie v eshope Bilionbuy?"
        subtitle="Ako funguje vernostný program?"
        steps={[
          {
            number: "1",
            titleKey: "Vyhľadanie produktu",
            descriptionKey:
              'Na trhovisku nájdete v katalógu ponuku produktov Bilionbuy, ktoré sú označené ako "Do košíka"',
            bgColor: "bg-[#4A90E2]",
          },
          {
            number: "2",
            titleKey: "Pridanie produktov do košíka",
            descriptionKey:
              'Tlačidlom "Do košíka" pridáte produkty do nákupného košíka, ktoré chcete nakúpiť z Bilionbuy eshopu',
            bgColor: "bg-[#E57697]",
          },
          {
            number: "3",
            titleKey: "Objednávka, zaplatenie a doručenie",
            descriptionKey:
              "Počas procesu objednávky si vyberiete spôsob zaplatenia a doručenia objednávky.",
            bgColor: "bg-[#A6CF4A]",
          },
          {
            number: "4",
            titleKey: "Pripísanie odmien",
            descriptionKey:
              "Za každý nákup získava užívateľ body do vernostného programu. Zároveň získava rastúci cashback a odmeny z vernostného programu, ktoré sa pripisujú do e-peňaženky.",
            bgColor: "bg-[#FB6238]",
          },
          {
            number: "5",
            titleKey: "Vyplatenie odmien na bankový účet",
            descriptionKey:
              "O vyplatenie odmien je možné požiadať v e-peňaženke. Pred vyplatením si musíte upraviť profil a zadať údaje potrebné na vyplatenie odmeny",
            bgColor: "bg-[#B8CC2E]",
          },
        ]}
      />

      <TextImageComponent
        imagePosition="right" // or "right"
        heading="Produkty z partnerských eshopov v katalógu na webovej platforme"
        fulltext="V katalógu nájdete aj produkty partnerských eshopov, ktoré sú označené “Do obchodu”, ktoré si máte možnosť zakúpiť po prekliku z webu Bilionbuy do partnerského eshopu. Produkty týchto partnerských eshopov sú zaradené v našom katalógu a v porovnaní produktov len v prípade, ak nám partnerský eshop dodal svoj katalóg s aktuálnymi cenami produktov. Ak nám katalóg nedodal, tak jediná možnosť ako nakúpiť s rastúcim cashbackom - je prekliknúť sa do partnerského eshopu a vyhľadať si produkt priamo na  stránke partnera"
        imageSrc="https://bbuy.fra1.cdn.digitaloceanspaces.com/img/cart2.png"
        imageAlt="Illustration of how cashback works"
        auth={false}
        ctaConfig={{
          primaryButton: {
            text: "Registrácia zdarma",
            href: "/register",
          },
          secondaryButton: {
            text: "Prejsť do katalógu",
            href: "/browse-products/",
          },
        }}
      />

      <HowItWorks
        title="Ako funguje nakupovanie cez vyhľadávanie produktov partnerských eshopov v katalógu?"
        subtitle="Ako funguje vernostný program?"
        steps={[
          {
            number: "1",
            titleKey: "Vyhľadanie produktu",
            descriptionKey:
              "Na trhovisku nájdete v katalógu ponuku produktov partnerských eshopov, ktoré sú označené ako “Do obchodu”",
            bgColor: "bg-[#4A90E2]",
          },
          {
            number: "2",
            titleKey: "Preklik do partnerského eshopu",
            descriptionKey:
              "Nákup prebieha v partnerskom eshope po prekliku z webovej platformy Bilionbuy cez tlačidlo “Do obchodu”",
            bgColor: "bg-[#E57697]",
          },
          {
            number: "3",
            titleKey: "Označenie nákupu v “moja kariéra - nákupy”",
            descriptionKey:
              "Prekliky, cez ktoré došlo aj k nákupu produktu, môžete označiť za “nakúpené”, čím zrýchlite proces vyplatenia odmeny. Ostatné označte za “nenakúpené”",
            bgColor: "bg-[#A6CF4A]",
          },
          {
            number: "4",
            titleKey: "Pripísanie odmien",
            descriptionKey:
              "Za každý nákup získava užívateľ body do vernostného programu. Zároveň získava rastúci cashback a odmeny z vernostného programu, ktoré sa pripisujú do e-peňaženky.",
            bgColor: "bg-[#FB6238]",
          },
          {
            number: "5",
            titleKey: "Vyplatenie odmien na bankový účet",
            descriptionKey:
              "O vyplatenie odmien je možné požiadať v e-peňaženke. Pred vyplatením si musíte upraviť profil a zadať údaje potrebné na vyplatenie odmeny",
            bgColor: "bg-[#B8CC2E]",
          },
        ]}
      />

      <TextImageComponent
        imagePosition="left"
        heading="Zoznam partnerských eshopov"
        fulltext="V zozname partnerských eshopov označenom ako “Partnerské cashback obchody” nájdete všetky naše partnerské eshopy, ktoré si môžete odfiltrovať podľa kategórie produktov, ktoré ponúkajú. Produkty týchto partnerských eshopov sú zaradené v našom katalógu produktov a v porovnaní produktov len v prípade, ak nám partnerský eshop dodal svoj katalóg s aktuálnymi cenami produktov. Ak nám katalóg nedodal, tak jediná možnosť pre užívateľa ako nakúpiť s rastúcim cashbackom je prekliknúť sa do partnerského eshopu a vyhľadať si produkt priamo na stránke partnera"
        imageSrc="https://bbuy.fra1.cdn.digitaloceanspaces.com/img/cart2.png"
        imageAlt="Illustration of how cashback works"
        auth={false}
        ctaConfig={{
          primaryButton: {
            text: "Registrácia zdarma",
            href: "/register",
          },
          secondaryButton: {
            text: "Viac informácií",
            href: "/blog#vyhody-cashbacku/",
          },
        }}
      />

      <HowItWorks
        title="Ako funguje nakupovanie cez vyhľadávanie obľúbených partnerských eshopov?"
        subtitle="Ako funguje vernostný program?"
        steps={[
          {
            number: "1",
            titleKey: "Vyhľadanie obľúbeného obchodu",
            descriptionKey:
              "V sekcii “Partnerské cashback obchody” nájdete zoznam partnerských eshopov, ktoré môžete filtrovať podľa kategórii ponúkaných produktov",
            bgColor: "bg-[#4A90E2]",
          },
          {
            number: "2",
            titleKey: "Preklik do partnerského eshopu",
            descriptionKey:
              "Nákup prebieha v partnerskom eshope po prekliku z webovej platformy Bilionbuy cez tlačidlo “Do obchodu”",
            bgColor: "bg-[#E57697]",
          },
          {
            number: "3",
            titleKey: "Označenie nákupu v “moja kariéra - nákupy”",
            descriptionKey:
              "Prekliky, cez ktoré došlo aj k nákupu produktu, môžete označiť za “nakúpené”, čím zrýchlite proces vyplatenia odmeny. Ostatné označte za “nenakúpené”",
            bgColor: "bg-[#A6CF4A]",
          },
          {
            number: "4",
            titleKey: "Pripísanie odmien",
            descriptionKey:
              "Za každý nákup získava užívateľ body do vernostného programu. Zároveň získava rastúci cashback a odmeny z vernostného programu, ktoré sa pripisujú do e-peňaženky.",
            bgColor: "bg-[#FB6238]",
          },
          {
            number: "5",
            titleKey: "Vyplatenie odmien na bankový účet",
            descriptionKey:
              "O vyplatenie odmien je možné požiadať v e-peňaženke. Pred vyplatením si musíte upraviť profil a zadať údaje potrebné na vyplatenie odmeny",
            bgColor: "bg-[#B8CC2E]",
          },
        ]}
      />
      <FAQ
        title="Často kladené otázky"
        questions={[
          {
            question:
              "Môžem získať rastúci cashback za nákupy cez Bilionbuy vo viac ako 3000 eshopov?",
            answer:
              "Bilionbuy spolupracuje s viac ako 3000 eshopmi. Na základe rozhodnutia Bilionbuy, je Bilionbuy oprávnené vyplácať rastúci cashback svojim užívateľom za nákupy v partnerských eshopoch.",
          },
          {
            question:
              "Ako môžem zarábať na vlastných nákupoch a nákupoch siete?",
            answer:
              "Bilionbuy vytvorilo vernostný program, na základe ktorého vypláca registrovaným užívateľom rastúci cashback za vlastné nákupy a za nákupy siete. Viac informácií o vernostnom programe sa dozviete v časti vernostný program.",
          },
          {
            question: "Aké získam výhody nákupom na trhovisku Bilionbuy?",
            answer:
              "Najdôležitejšou výhodou, ktorú ponúka Bilionbuy je získanie rastúceho cashbacku. Tým sa stáva váš nákup vždy lacnejší, pretože získate peniaze späť z každého nákupu vo forme rastúceho cashbacku. Čím dlhšie ste užívateľom trhoviska a čím máte za sebou väčšiu históriu, tým dostávate za nákupy väčší rastúci cashback.",
          },
          {
            question:
              "Nenašiel som požadovaný produkt vo vyhľadávaní, avšak viem, že partnerský ehop takýto produkt ponúka. Prečo?",
            answer:
              "Vo vyhľadávaní nájdete produkty partnerských eshopov, ktoré nám dodali katalóg ich vlastých produktov. Trhovisko Bilionbuy však umožňuje získať rastúci cashback aj z nákupov preklikom do partnerských eshopov. Partnerský eshop si vyhľadáte v sekcii Partnerské cashback obchody.",
          },
          {
            question:
              "Ako môžem zarábať samospotrebiteľskou formou na vlastnej spotrebe?",
            answer:
              "Môžete zarábať na tom, čo by ste si aj tak kúpili – či už ide o potraviny, drogériu, oblečenie alebo elektroniku. Každý nákup sa tak mení na príležitosť, ako získať niečo späť. Už to nie sú len výdavky, ale múdre investície. Kľúčovou výhodou je rastúci cashback, ktorý vám vracia časť peňazí. Tento inovatívny model premieňa bežné nakupovanie na nástroj na budovanie finančnej stability a efektívnejšie hospodárenie s vašimi financiami.",
          },
        ]}
      />

      <BlogLanding />
      <WhatPeopleSay />
      <YouCouldSeeUs />
      <InPageRegistration />
      <Footer />
    </div>
  );
}
