import React from "react";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import PaymentCancel from "../components/eshop/PaymentCancel";

const PaymentCancelPage = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <BasicNavbar />
      <main className="flex-grow">
        <PaymentCancel />
      </main>
      <Footer />
    </div>
  );
};

export default PaymentCancelPage;
