import React, { createContext, useContext, useState, useEffect } from "react";
import { Switch } from "@nextui-org/react";

export const AdminContext = createContext(undefined);

export const AdminProvider = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAdminView, setIsAdminView] = useState(false);
  const currentPath =
    typeof window !== "undefined" ? window.location.pathname : "";

  const checkAdminStatus = () => {
    const jwt = localStorage.getItem("jwt");
    const permissions = localStorage.getItem("user_permissions");

    // Reset admin state if not logged in or no permissions
    if (!jwt) {
      setIsAdmin(false);
      setIsAdminView(false);
      return;
    }

    setIsAdmin(permissions?.includes("administrator") || false);
  };

  // Check admin status when the provider mounts and when localStorage changes
  useEffect(() => {
    // Initial check
    checkAdminStatus();

    // Listen for storage changes (in case permissions change or user logs out)
    window.addEventListener("storage", checkAdminStatus);

    // Listen for custom logout event
    const handleLogout = () => {
      setIsAdmin(false);
      setIsAdminView(false);
    };
    window.addEventListener("user-logout", handleLogout);

    // Listen for custom login event
    const handleLogin = () => {
      checkAdminStatus();
    };
    window.addEventListener("user-login", handleLogin);

    return () => {
      window.removeEventListener("storage", checkAdminStatus);
      window.removeEventListener("user-logout", handleLogout);
      window.removeEventListener("user-login", handleLogin);
    };
  }, []);

  const toggleAdminView = () => {
    setIsAdminView((prev) => !prev);
  };

  // Only render admin panel if user is authenticated, has admin permissions,
  // and the current route is not "/dashboard"
  const showAdminPanel =
    isAdmin && localStorage.getItem("jwt") && currentPath !== "/dashboard";

  return (
    <AdminContext.Provider value={{ isAdmin, isAdminView, toggleAdminView }}>
      {children}
      {/* {showAdminPanel && (
          <div className="fixed bottom-4 left-4 z-50 bg-white shadow-lg rounded-lg p-4 border border-gray-200">
            <div className="flex flex-col gap-2">
              <div className="text-sm font-semibold">Správcovský panel</div>
              <div className="flex items-center gap-2">
                <span className="text-xs">Správcovský režim:</span>
                <Switch
                  isSelected={isAdminView}
                  onValueChange={toggleAdminView}
                  size="sm"
                />
              </div>
              {isAdminView && (
                <div className="text-xs text-green-600">
                  Správcovský režim je aktivovaný
                </div>
              )}
            </div>
          </div>
        )} */}
    </AdminContext.Provider>
  );
};

// Custom hook to use admin context
export const useAdmin = () => {
  const context = useContext(AdminContext);
  if (context === undefined) {
    throw new Error("useAdmin must be used within an AdminProvider");
  }
  return context;
};
