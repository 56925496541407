import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { Add, Remove, Clear, Check, Upload } from "@mui/icons-material";
import { toast } from "react-toastify";
import RichTextEditor from "../RichTextEditor";
import DualAutocomplete from "../DualAutocomplete";
import AutoselectFetchCreate from "../AutoselectFetchCreate";
import api from "../../services/api";
import debounce from "lodash/debounce";
import Chip from "@mui/material/Chip";

const initialProductState = {
  product_name: "",
  product_manufacturer: "",
  product_ean: "",
  product_image_url: "",
  product_description: "",
  parameters: [],
  visibility: true,
  adult_content: false,
  video_url: "",
  video_iframe: [],
  additional_photos: [],
  language: "sk",
};

const languageOptions = [
  { value: "sk", label: "Slovensko" },
  { value: "cz", label: "Česko" },
];

const fetchVariantGroups = async (query) => {
  try {
    const response = await api.get(`get-variant-groups/?query=${query}`);
    return Array.isArray(response.data)
      ? response.data
      : response.data?.results || [];
  } catch (error) {
    console.error("Error fetching variant groups:", error);
    return [];
  }
};

const createVariantGroup = async (name) => {
  try {
    const response = await api.post("create-variant-group/", { name });
    return response.data;
  } catch (error) {
    console.error("Error creating variant group:", error);
    throw error;
  }
};

const ProductEditModal = ({
  isOpen,
  onClose,
  productId,
  onUpdate,
  initialData,
  content: initialContent,
}) => {
  console.log("Modal props:", { isOpen, productId, initialData });
  const [editedProduct, setEditedProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [iframes, setIframes] = useState([]);
  const [additionalPhotos, setAdditionalPhotos] = useState([]);
  const [variantGroup, setVariantGroup] = useState(null);
  const [nameError, setNameError] = useState("");
  const [showParameterInput, setShowParameterInput] = useState(false);
  const [editingParameter, setEditingParameter] = useState(null);
  const [newParameter, setNewParameter] = useState({ name: "", value: "" });
  const [linkedShops, setLinkedShops] = useState([]);
  const [foreignLinkedShops, setForeignLinkedShops] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryInputValue, setCategoryInputValue] = useState("");
  const [variantOptions, setVariantOptions] = useState([]);
  const [vatSettings, setVatSettings] = useState([]);
  const [selectedVat, setSelectedVat] = useState(null);

  useEffect(() => {
    const fetchProductData = async () => {
      if (isOpen) {
        try {
          if (productId) {
            // Editing existing product
            setEditedProduct(null);
            const response = await api.get(
              `get-product-edit-data/${productId}/`
            );
            const productData = response.data;

            setEditedProduct(productData);
            setContent(productData.product_description || "");
            setIframes(productData.video_iframe || []);
            setAdditionalPhotos(productData.additional_photos || []);
            setVariantGroup(productData.variant_group || null);
            setLinkedShops(productData.linked_shops || []);
            setForeignLinkedShops(productData.foreign_linked_shops || []);

            if (productData.product_assigned_category) {
              fetchCategory(productData.product_assigned_category);
            }
          } else if (initialData) {
            // Creating new product from duplicate
            setEditedProduct(initialData);
            setContent(initialContent || "");
            setIframes(initialData.video_iframe || []);
            setAdditionalPhotos(initialData.additional_photos || []);
            setVariantGroup(initialData.variant_group || null);
            setLinkedShops([]);
            setForeignLinkedShops([]);
            if (initialData.product_assigned_category) {
              fetchCategory(initialData.product_assigned_category);
            }
          } else {
            // Creating new product
            setEditedProduct(initialProductState);
            setContent("");
            setIframes([]);
            setAdditionalPhotos([]);
            setVariantGroup(null);
            setLinkedShops([]);
            setForeignLinkedShops([]);
            setSelectedCategory(null);
            setCategoryInputValue("");
          }
        } catch (error) {
          console.error("Error fetching product data:", error);
          toast.error("Failed to load product data");
          onClose();
        }
      }
    };

    fetchProductData();
  }, [isOpen, productId, initialData, initialContent]);

  useEffect(() => {
    console.log("Modal state:", { isOpen, editedProduct });
  }, [isOpen, editedProduct]);

  useEffect(() => {
    // Fetch all categories when the modal opens
    fetchCategories();
  }, [isOpen]);

  useEffect(() => {
    const fetchVatSettings = async () => {
      if (isOpen) {
        try {
          const response = await api.get("get-product-vat/", {
            params: {
              product_id: productId,
            },
          });
          setVatSettings(response.data.all_vat_settings);
          setSelectedVat(response.data.vat || null);
        } catch (error) {
          console.error("Error fetching VAT settings:", error);
          toast.error("Failed to load VAT settings");
        }
      }
    };

    fetchVatSettings();
  }, [isOpen]);

  const fetchCategories = async () => {
    try {
      const response = await api.get("get-admin-categories/");
      setCategories(response.data.results);
    } catch (error) {
      console.error("Error fetching categories:", error);
      toast.error("Failed to load categories");
    }
  };

  const fetchCategory = async (categoryId) => {
    try {
      const response = await api.get(`get-category/?id=${categoryId}`);
      const categoryData = Array.isArray(response.data)
        ? response.data[0]
        : response.data;
      setSelectedCategory(categoryData);
      setCategoryInputValue(categoryData.category_fullname || "");
    } catch (error) {
      console.error("Error fetching category:", error);
      toast.error("Failed to load product category");
    }
  };

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (query.length > 2) {
        try {
          const response = await api.get(`search-categories/?query=${query}`);
          const newOptions = Array.isArray(response.data) ? response.data : [];
          setCategories(newOptions);
        } catch (error) {
          console.error("Error searching categories: ", error);
        }
      }
    }, 300),
    []
  );

  const handleCategoryInputChange = (event, newInputValue) => {
    setCategoryInputValue(newInputValue);
    debouncedSearch(newInputValue);
  };

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
    setEditedProduct((prev) => ({
      ...prev,
      product_assigned_category: newValue ? newValue.id : null,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct((prev) => ({ ...prev, [name]: value }));
    if (name === "product_name") setNameError("");
  };

  const handleContentChange = (newContent) => {
    setContent(newContent);
  };

  const handleVariantGroupChange = (newVariantGroup) => {
    setVariantGroup(newVariantGroup);
  };

  const resetVariantGroup = () => {
    setVariantGroup(null);
  };

  const handleAddPhoto = () => {
    setAdditionalPhotos([...additionalPhotos, ""]);
  };

  const handleRemovePhoto = (index) => {
    setAdditionalPhotos(additionalPhotos.filter((_, i) => i !== index));
  };

  const handlePhotoChange = (index, value) => {
    const updatedPhotos = [...additionalPhotos];
    updatedPhotos[index] = value;
    setAdditionalPhotos(updatedPhotos);
  };

  const handleAddIframe = () => {
    setIframes([...iframes, ""]);
  };

  const handleRemoveIframe = (index) => {
    setIframes(iframes.filter((_, i) => i !== index));
  };

  const handleIframeChange = (index, value) => {
    const updatedIframes = [...iframes];
    updatedIframes[index] = value;
    setIframes(updatedIframes);
  };

  const handleEditParameter = (index) => {
    setEditingParameter(index);
    const param = editedProduct.parameters[index];
    setNewParameter({ name: param.name, value: param.value });
  };

  const confirmParameter = () => {
    if (!newParameter.name || !newParameter.value) {
      toast.error("Názov a hodnota parametra nemôžu byť prázdne");
      return;
    }
    if (editingParameter !== null) {
      setEditedProduct((prev) => {
        const updatedParameters = [...prev.parameters];
        updatedParameters[editingParameter] = newParameter;
        return { ...prev, parameters: updatedParameters };
      });
      setEditingParameter(null);
    } else {
      const exists = editedProduct.parameters.some(
        (param) =>
          param.name === newParameter.name && param.value === newParameter.value
      );
      if (!exists) {
        setEditedProduct((prev) => ({
          ...prev,
          parameters: [...prev.parameters, newParameter],
        }));
      } else {
        toast.info("Parameter už existuje.");
      }
      setShowParameterInput(false);
    }
    setNewParameter({ name: "", value: "" });
  };

  const handleDeleteParameter = (index) => {
    setEditedProduct((prev) => {
      const updatedParameters = [...prev.parameters];
      updatedParameters.splice(index, 1);
      return { ...prev, parameters: updatedParameters };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setNameError("");

    try {
      const productData = {
        product_name: editedProduct.product_name,
        product_description: content,
        product_manufacturer: editedProduct.product_manufacturer,
        product_category: editedProduct.product_category,
        product_price_vat: editedProduct.product_price_vat,
        product_params: editedProduct.parameters,
        product_assigned_category: selectedCategory
          ? selectedCategory.id
          : null,
        product_ean: editedProduct.product_ean || null,
        product_image_url: editedProduct.product_image_url,
        video_url: editedProduct.video_url,
        video_iframe: iframes,
        additional_photos: additionalPhotos,
        variant_group: variantGroup ? variantGroup.id : null,
        language: editedProduct.language || "sk",
        visibility: editedProduct.visibility,
        adult_content: editedProduct.adult_content,
        vat_id: selectedVat?.id || null,
      };

      const response = productId
        ? await api.post("update-product/", {
            ...productData,
            product_id: productId,
          })
        : await api.post("products/create/", productData);

      if (response.status === 200 || response.status === 201) {
        toast.success(
          productId
            ? "Produkt bol úspešne aktualizovaný!"
            : "Produkt bol úspešne vytvorený!"
        );
        onClose();
        if (onUpdate) onUpdate();
      } else {
        throw new Error(
          productId
            ? "Failed to update the product"
            : "Failed to create the product"
        );
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data === "Duplicate product name"
      ) {
        setNameError("The name already exists");
      } else {
        console.error(
          productId
            ? "Chyba pri aktualizácii produktu:"
            : "Chyba pri vytvorení produktu:",
          error
        );
        toast.error("Operácia zlyhala: " + error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Create FormData
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", file.name);
    formData.append("content_type", file.type);
    formData.append("acl", "public-read");

    try {
      const response = await api.post("upload-product-image/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.url) {
        setEditedProduct((prev) => ({
          ...prev,
          product_image_url: response.data.url,
        }));
        toast.success("Obrázok bol úspešne nahraný");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Nepodarilo sa nahrať obrázok");
    }
  };

  const handleVariantGroupSearch = async (query) => {
    if (query.length < 2) {
      setVariantOptions([]);
      return [];
    }
    const results = await fetchVariantGroups(query);
    setVariantOptions(results);
    return results;
  };

  const handleVariantGroupCreate = async (name) => {
    try {
      const newGroup = await createVariantGroup(name);
      setVariantOptions((prev) => [...prev, newGroup]);
      setVariantGroup(newGroup);
      toast.success("Skupina variantov bola úspešne vytvorená");
    } catch (error) {
      toast.error("Nepodarilo sa vytvoriť skupinu variantov");
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: 1000,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    maxHeight: "90vh",
    overflowY: "auto",
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="product-edit-modal"
      aria-describedby="product-edit-form"
    >
      <Box sx={modalStyle}>
        {!editedProduct ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography variant="h6" component="h2">
                {productId
                  ? `Upraviť produkt: ${editedProduct.product_name}`
                  : "Nový produkt"}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Názov produktu"
                  name="product_name"
                  value={editedProduct.product_name || ""}
                  onChange={handleInputChange}
                  error={!!nameError}
                  helperText={
                    nameError || "Názov produktu ako sa zobrazí používateľom"
                  }
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Výrobca"
                  name="product_manufacturer"
                  value={editedProduct.product_manufacturer || ""}
                  onChange={handleInputChange}
                  helperText="Názov výrobcu alebo značky produktu"
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="EAN"
                  name="product_ean"
                  value={editedProduct.product_ean || ""}
                  onChange={handleInputChange}
                  helperText="EAN kód produktu (nepovinné)"
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel id="language-select-label">Jazyk</InputLabel>
                  <Select
                    labelId="language-select-label"
                    name="language"
                    value={editedProduct.language || "sk"}
                    label="Jazyk"
                    onChange={handleInputChange}
                  >
                    {languageOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    Krajina, pre ktorú je produkt určený
                  </FormHelperText>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="vat-select-label">DPH</InputLabel>
                  <Select
                    labelId="vat-select-label"
                    value={selectedVat?.id || ""}
                    label="DPH"
                    onChange={(e) => {
                      const vatId = e.target.value;
                      const selected =
                        vatSettings.find((vat) => vat.id === vatId) || null;
                      setSelectedVat(selected);
                    }}
                  >
                    <MenuItem value="">
                      <em>Žiadna</em>
                    </MenuItem>
                    {vatSettings.map((vat) => (
                      <MenuItem key={vat.id} value={vat.id}>
                        {`${vat.country} - ${vat.type} ${vat.vat_rate} %`}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    Vyberte sadzbu DPH pre produkt
                  </FormHelperText>
                </FormControl>
                <Typography variant="body1" mt={2}>
                  Pôvodná kategória: {editedProduct.product_category}
                </Typography>
                <Autocomplete
                  disablePortal
                  id="category-selector"
                  options={categories || []}
                  getOptionLabel={(option) => option?.category_fullname || ""}
                  renderOption={(props, option) => (
                    <li {...props}>{option?.category_fullname || ""}</li>
                  )}
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  onInputChange={handleCategoryInputChange}
                  inputValue={categoryInputValue}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Priradená kategória"
                      variant="outlined"
                      margin="normal"
                      helperText="Vyberte kategóriu, do ktorej produkt patrí"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                />
                <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
                  Skupina variantov
                </Typography>
                <AutoselectFetchCreate
                  label="Skupina variantov"
                  value={variantGroup}
                  onChange={handleVariantGroupChange}
                  onReset={resetVariantGroup}
                  onSearch={handleVariantGroupSearch}
                  onCreate={handleVariantGroupCreate}
                  options={variantOptions}
                  helperText="Prepojenie s inými variantmi tohto produktu (napr. rôzne farby/veľkosti)"
                  getOptionLabel={(option) => option?.name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                />
                <Box className="flex pt-2 flex-col gap-1 items-start w-full">
                  <Box className="flex flex-row gap-1 items-center w-full">
                    <TextField
                      fullWidth
                      margin="dense"
                      label="URL obrázka"
                      value={editedProduct.product_image_url || ""}
                      onChange={(e) =>
                        setEditedProduct({
                          ...editedProduct,
                          product_image_url: e.target.value,
                        })
                      }
                    />
                    <Button
                      component="label"
                      variant="contained"
                      startIcon={<Upload />}
                      sx={{ height: 52 }}
                    >
                      Nahrať
                      <input
                        type="file"
                        hidden
                        accept="image/jpeg,image/png"
                        onChange={handleImageUpload}
                      />
                    </Button>
                  </Box>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ mt: -1, ml: 1 }}
                  >
                    Podporované formáty: JPG, PNG
                  </Typography>
                </Box>
                {/* {editedProduct.product_image_url && (
                  <img
                    src={editedProduct.product_image_url}
                    alt={editedProduct.product_name}
                    style={{
                      width: "100%",
                      maxHeight: 300,
                      objectFit: "contain",
                      marginTop: 2,
                    }}
                  />
                )} */}
              </Grid>
              <Grid item xs={12} md={6}>
                <img
                  src={editedProduct.product_image_url}
                  alt={editedProduct.product_name}
                  style={{
                    width: "100%",
                    maxHeight: 300,
                    objectFit: "contain",
                  }}
                />
              </Grid>
            </Grid>

            <Typography variant="h6" mt={2} mb={1}>
              Popis produktu
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ mb: 2, display: "block" }}
            >
              Detailný popis produktu s možnosťou formátovania textu
            </Typography>
            <RichTextEditor
              content={content}
              setContent={handleContentChange}
            />

            <Typography variant="h6" mt={2} mb={1}>
              Parametre
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ mb: 2, display: "block" }}
            >
              Technické parametre a špecifikácie produktu (napr. farba, veľkosť,
              materiál)
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
              {editedProduct?.parameters?.map((param, index) => (
                <Box key={index} display="flex" alignItems="center">
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleEditParameter(index)}
                  >
                    {param.name}: {param.value}
                  </Button>
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteParameter(index)}
                  >
                    <Clear />
                  </IconButton>
                </Box>
              ))}
            </Box>
            {showParameterInput ? (
              <Box display="flex" alignItems="center" gap={1}>
                <DualAutocomplete
                  initialName={newParameter.name}
                  initialValue={newParameter.value}
                  onNameChange={(name) =>
                    setNewParameter((prev) => ({ ...prev, name }))
                  }
                  onValueChange={(value) =>
                    setNewParameter((prev) => ({ ...prev, value }))
                  }
                />
                <IconButton onClick={confirmParameter}>
                  <Check />
                </IconButton>
                <IconButton onClick={() => setShowParameterInput(false)}>
                  <Clear />
                </IconButton>
              </Box>
            ) : (
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={() => setShowParameterInput(true)}
              >
                Pridať parameter
              </Button>
            )}

            <Typography variant="h6" mt={2} mb={1}>
              Dodatočné fotky
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ mb: 2, display: "block" }}
            >
              Ďalšie fotografie produktu - každá na novom riadku
            </Typography>
            {additionalPhotos.map((photo, index) => (
              <Grid
                container
                spacing={2}
                alignItems="center"
                key={index}
                mb={1}
              >
                <Grid item xs={9}>
                  <TextField
                    label={`URL fotky ${index + 1}`}
                    variant="outlined"
                    value={photo}
                    onChange={(e) => handlePhotoChange(index, e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    component="label"
                    variant="contained"
                    startIcon={<Upload />}
                    sx={{ height: 52 }}
                  >
                    Nahrať
                    <input
                      type="file"
                      hidden
                      accept="image/jpeg,image/png"
                      onChange={async (e) => {
                        const file = e.target.files[0];
                        if (!file) return;

                        const formData = new FormData();
                        formData.append("file", file);
                        formData.append("file_name", file.name);
                        formData.append("content_type", file.type);
                        formData.append("acl", "public-read");

                        try {
                          const response = await api.post(
                            "upload-product-image/",
                            formData,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );

                          if (response.data.url) {
                            handlePhotoChange(index, response.data.url);
                            toast.success("Obrázok bol úspešne nahraný");
                          }
                        } catch (error) {
                          console.error("Error uploading image:", error);
                          toast.error("Nepodarilo sa nahrať obrázok");
                        }
                      }}
                    />
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => handleRemovePhoto(index)}>
                    <Remove />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Button startIcon={<Add />} onClick={handleAddPhoto}>
              Pridať fotku
            </Button>

            <Typography variant="h6" mt={2} mb={1}>
              Video iFramy
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ mb: 2, display: "block" }}
            >
              Vložené video prehrávače (napr. z YouTube) - každý na novom riadku
            </Typography>
            {iframes.map((iframe, index) => (
              <Grid
                container
                spacing={2}
                alignItems="center"
                key={index}
                mb={1}
              >
                <Grid item xs={10}>
                  <TextField
                    label={`iFrame ${index + 1}`}
                    variant="outlined"
                    value={iframe}
                    onChange={(e) => handleIframeChange(index, e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={() => handleRemoveIframe(index)}>
                    <Remove />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Button startIcon={<Add />} onClick={handleAddIframe}>
              Pridať iFrame
            </Button>

            <Typography variant="h6" mt={2} mb={1}>
              Priradené obchody
            </Typography>
            {linkedShops.map((shop) => (
              <Box
                key={shop.id}
                display="flex"
                justifyContent="space-between"
                p={1}
                mb={1}
                bgcolor="background.paper"
              >
                <a href={shop.url}>{shop.shop_name}</a>
                <Typography>{shop.price_vat}</Typography>
              </Box>
            ))}

            <Typography variant="h6" mt={2} mb={1}>
              Zahraničné obchody
            </Typography>
            {foreignLinkedShops.map((shop) => (
              <Box
                key={shop.id}
                display="flex"
                justifyContent="space-between"
                p={1}
                mb={1}
                bgcolor="background.paper"
              >
                <a href={shop.url}>{shop.shop_name}</a>
                <Typography>{shop.price_vat}</Typography>
              </Box>
            ))}

            <FormControlLabel
              control={
                <Switch
                  checked={editedProduct.visibility || false}
                  onChange={(e) =>
                    setEditedProduct({
                      ...editedProduct,
                      visibility: e.target.checked,
                    })
                  }
                />
              }
              label="Viditeľnosť"
            />
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ ml: 1, display: "block" }}
            >
              Určuje, či bude produkt viditeľný pre používateľov
            </Typography>

            <FormControlLabel
              control={
                <Switch
                  checked={editedProduct.adult_content || false}
                  onChange={(e) =>
                    setEditedProduct({
                      ...editedProduct,
                      adult_content: e.target.checked,
                    })
                  }
                />
              }
              label="Obsah 18+"
            />
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ ml: 1, display: "block" }}
            >
              Označte, ak produkt obsahuje obsah nevhodný pre mladistvých
            </Typography>

            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button onClick={onClose} sx={{ mr: 1 }}>
                Zrušiť
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Uložiť zmeny"}
              </Button>
            </Box>
          </form>
        )}
      </Box>
    </Modal>
  );
};

export default ProductEditModal;
