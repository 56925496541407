const isTokenExpired = (token) => {
  // console.log("Checking if token is expired");
  try {
    const { exp } = JSON.parse(atob(token.split(".")[1]));
    if (!exp) {
      console.error("JWT does not contain 'exp' claim");
      return true;
    }
    // console.log("Current Time (ms):", Date.now());
    // console.log("Token Expiration Time (ms):", exp * 1000);
    return Date.now() >= exp * 1000;
  } catch (error) {
    console.error("Failed to decode JWT", error);
    return true;
  }
};

const logoutUser = () => {
  localStorage.removeItem("jwt");
  // Dispatch custom event for admin context
  window.dispatchEvent(new Event("user-logout"));
};

export { isTokenExpired, logoutUser };
