import { Button, Avatar, AvatarGroup } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FaLink } from "react-icons/fa";
import { MdBusinessCenter } from "react-icons/md";
import { BiSolidCategory } from "react-icons/bi";
import { MdLocalOffer } from "react-icons/md";
import { FaHotjar } from "react-icons/fa";
import { BiSolidOffer } from "react-icons/bi";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { IoPeopleCircleSharp } from "react-icons/io5";
import { RiContactsBook2Line } from "react-icons/ri";
import { ImBlogger } from "react-icons/im";
import { SiCashapp } from "react-icons/si";
import { FaUsers } from "react-icons/fa";
import { FaShoppingBasket } from "react-icons/fa";
import { FaHandHoldingUsd } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa";
import { FaMoneyBillWave } from "react-icons/fa";

import CustomCarousel from "./CustomCarousel";

export default function Hero({ auth }) {
  const { t } = useTranslation();

  // Reduced to 4 cards as requested
  const cards = [
    {
      title: t(
        "Bilionbuy je trhovisko, v ktorom nájdete produkty eshopu Bilionbuy a partnerských eshopov."
      ),
      icon: MdBusinessCenter,
      img: "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/trh1.png",
      link: "/marketplace",
    },
    {
      title: t(
        "Vernostný program ponúka užívateľom doživotné a dedičné možnosti zarábania nákupmi na trhovisku."
      ),
      icon: HiQuestionMarkCircle,
      img: "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/vern2.png",
      link: "/loyalty-program",
    },
    {
      title: t(
        "Rastúci cashback je forma zvyšujúceho sa cashbacku na základe histórie vlastných nákupov a nákupov siete."
      ),
      icon: SiCashapp,
      img: "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/rast3.png",
      link: "/growing-cashback",
    },
    {
      title: t(
        "Bilionbuy ponúka finančné kredity z obratu pre užívateľov, ktorí majú záujem o Bilionbuy Start-up."
      ),
      icon: ImBlogger,
      img: "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/kredity4.png",
      link: "/rewards-from-earnings",
    },
  ];

  // Navigation links with icons
  const navLinks = [
    { title: t("trhovisko"), icon: FaShoppingBasket, href: "/marketplace" },
    { title: t("Vernostný program"), icon: FaUsers, href: "/loyalty-program" },
    {
      title: t("Rastúci cashback"),
      icon: FaChartLine,
      href: "/growing-cashback",
    },
    {
      title: t("odmeny z celého Bilionbuy"),
      icon: FaMoneyBillWave,
      href: "/rewards-from-earnings",
    },
  ];

  const copyRefferalCode = () => {
    navigator.clipboard.writeText(
      "https://www.bilionbuy.com/register?ref=" +
        localStorage.getItem("refcode")
    );
  };

  return (
    <div className="relative lg:mt-12 mt-4">
      <div className="w-full relative">
        {/* Enhanced animated gradient background similar to HeaderBanner */}
        <div className="absolute inset-0 z-0">
          {/* Base gradient - fixed and stable */}
          <div className="absolute inset-0 bg-gradient-to-r from-white to-primary"></div>

          {/* Floating blurred circles */}
          <div className="absolute inset-0 overflow-hidden">
            {/* White circles with animation */}
            <motion.div
              className="absolute h-[500px] w-[500px] rounded-full bg-white/20 blur-2xl right-[60%] top-1/6"
              animate={{
                x: [0, 120, -80, 0],
                y: [0, -100, 60, 0],
              }}
              transition={{
                duration: 10,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
              }}
            ></motion.div>

            <motion.div
              className="absolute h-[550px] w-[550px] rounded-full bg-white/15 blur-3xl right-[55%] top-1/3"
              animate={{
                x: [0, -140, 100, 0],
                y: [0, 120, -80, 0],
              }}
              transition={{
                duration: 12,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 0.5,
              }}
            ></motion.div>

            {/* Pink circles */}
            <motion.div
              className="absolute h-[550px] w-[550px] rounded-full bg-pink/25 blur-3xl right-0 mr-20 top-1/4"
              animate={{
                x: [0, -60, 50, 0],
                y: [0, 70, -50, 0],
              }}
              transition={{
                duration: 14,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 0.6,
              }}
            ></motion.div>

            <motion.div
              className="absolute h-[520px] w-[520px] rounded-full bg-pink/18 blur-3xl right-0 mr-10 center"
              animate={{
                x: [0, -50, 40, 0],
                y: [0, 60, -50, 0],
              }}
              transition={{
                duration: 18,
                repeat: Infinity,
                repeatType: "loop",
                ease: "easeInOut",
                delay: 0.9,
              }}
            ></motion.div>
          </div>
        </div>

        {/* Content container */}
        <div className="relative max-w-screen-2xl mx-auto ">
          <div className="relative xl:px-8 flex lg:flex-row flex-col items-start justify-between lg:min-h-[450px] min-h-[200px] ">
            {/* Text Section */}
            <div className="w-full xl:w-1/2 lg:w-2/3 text-darkblue lg:text-left text-center z-10 pt-8 ">
              {/* Navigation badges similar to "ZADARMO" but in a row */}
              {/* Main heading - updated with new text */}
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="md:text-2xl lg:text-5xl md:max-w-full max-w-sm text-xl text-left font-extrabold mb-6 ml-4 line-clamp-2"
              >
                {t("Bilionbuy prináša zmenu v nakupovacích zvyklostiach")}
              </motion.h1>
              <div className="flex lg:flex-wrap flex-row justify-start lg:justify-start mb-8 xl:max-w-[580px] overflow-x-scroll scrollbar-hide md:ml-4 md:gap-3">
                {navLinks.map((link, index) => (
                  <a
                    key={index}
                    href={link.href}
                    className="inline-flex items-center px-4 py-2 bg-pink rounded-full text-sm text-white uppercase font-bold hover:bg-pink/90 transition-colors whitespace-nowrap ml-4 md:ml-0"
                  >
                    <link.icon className="mr-2 uppercase" />
                    {link.title}
                  </a>
                ))}
              </div>
            </div>

            {/* Image Section - fixed positioning issues */}

            <div className="absolute bottom-0 xl:right-32 md:-right-36 -right-72 max-w-md lg:max-w-full  z-1 overflow-visible">
              <motion.img
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.7, ease: "easeOut" }}
                src="https://bbuy.fra1.cdn.digitaloceanspaces.com/img/woman0.png"
                alt={t("Bilionbuy")}
                className="lg:h-[120%]  w-2/5 lg:w-4/5 max-w-lg object-cover"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Gradient overlay - using multiple layers for better blending */}
      <div className="absolute bottom-0 left-0 right-0 h-60 bg-gradient-to-t from-white via-white/95 to-transparent" />
      <div className="absolute bottom-0 left-0 right-0 h-40 bg-gradient-to-t from-white via-white/90 to-transparent" />

      {/* Cards section - positioned to overlap with increased size */}
      <div className="max-w-screen-2xl mx-auto relative xl:-mt-48 lg:-mt-32 -mt-10 xl:px-8 pb-6 z-20">
        {/* Desktop view - carousel with 4 larger cards */}
        <div className="">
          <CustomCarousel
            itemWidth="360px"
            itemMaxWidth="360px"
            hideArrows={false}
            breakpoints={{
              "(max-width: 767px)": {
                slides: { perView: 1.2, spacing: 4 },
                origin: "center",
                mode: "free-snap",
              },
              "(min-width: 768px)": {
                slides: { perView: 2, spacing: 4 },
                origin: "center",
                mode: "snap",
              },
              "(min-width: 1024px)": {
                slides: { perView: 3, spacing: 4 },
                origin: "center",
                mode: "snap",
              },
              "(min-width: 1280px)": {
                slides: { perView: 4, spacing: 4 },
                origin: "center",
                mode: "snap",
              },
            }}
          >
            {cards.map((card) => (
              <div key={card.title} className="p-2 h-full">
                <CardItem key={card.title} card={card} t={t} />
              </div>
            ))}
          </CustomCarousel>
        </div>
      </div>
    </div>
  );
}

// Updated card item with increased size
const CardItem = ({ card, t }) => {
  return (
    <div className="pb-4 h-full w-full">
      <a
        href={card.link}
        className="rounded-lg bg-white shadow-md transition-shadow duration-200 hover:shadow-lg
      border overflow-hidden h-full flex flex-col w-full"
      >
        <div className="">
          <img
            src={card.img}
            alt={card.title}
            className="w-full h-48 object-cover" /* Increased height */
          />
        </div>
        <div className="p-4 flex flex-row items-center gap-3 font-base text-base w-full min-h-[60px]">
          {" "}
          {/* <card.icon className="flex-shrink-0 text-xl" /> */}
          <div className=" w-full">
            {card.title}{" "}
            <a className="bg-pink px-2 text-white py-1 text-xs rounded-3xl">
              {t("viac")}
            </a>
          </div>
        </div>
      </a>
    </div>
  );
};
