import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useCart } from "../contexts/CartContext";
import { Button, Spinner } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { cartService } from "../services/cartService";
import { formatPrice } from "../utils/formatters";

const CartPreview = () => {
  const { t } = useTranslation();
  const {
    cartItems,
    isCartOpen,
    setIsCartOpen,
    getCartTotal,
    isLoading,
    error,
    fetchCart,
    currency,
  } = useCart();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let mounted = true;

    if (isCartOpen && mounted) {
      fetchCart();
    }

    return () => {
      mounted = false;
    };
  }, [isCartOpen]);

  if (!isCartOpen) return null;

  const handleRemoveItem = async (itemId) => {
    try {
      await cartService.removeItem(itemId);
      await fetchCart();
    } catch (err) {
      console.error("Failed to remove item:", err);
    }
  };

  // Cart content that's shared between both implementations
  const CartContent = () => (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">{t("Košík")}</h3>
        <button
          onClick={() => setIsCartOpen(false)}
          className="text-gray-500 hover:text-gray-700 text-xl"
        >
          ×
        </button>
      </div>

      {error && <div className="text-red-500 mb-4">{error}</div>}

      {isLoading ? (
        <div className="flex justify-center py-4">
          <Spinner size="sm" color="primary" />
        </div>
      ) : cartItems.length === 0 ? (
        <p className="text-gray-500">{t("Košík je prázdny")}</p>
      ) : (
        <>
          <div className="space-y-4 mb-4">
            {cartItems.map((item) => (
              <div key={item.id} className="flex justify-between items-center">
                <div className="flex items-center space-x-4">
                  <img
                    src={item.img_url}
                    alt={item.name}
                    className="w-16 h-16 object-cover rounded"
                  />
                  <div>
                    <p className="font-medium">{item.name}</p>
                    <p className="text-sm text-gray-500">
                      {formatPrice(item.price_vat)} {currency} x {item.quantity}
                    </p>
                    <p className="text-xs text-gray-400">
                      {t("Body")}: {(item.points * item.quantity).toFixed(3)}
                    </p>
                    <p className="text-xs text-green-600">
                      {t("Cashback")}:{" "}
                      {formatPrice(item.cashback * item.quantity)} {currency}
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => handleRemoveItem(item.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  ×
                </button>
              </div>
            ))}
          </div>
          <div className="border-t pt-4">
            <div className="flex justify-between mb-2">
              <span className="text-sm text-gray-600">{t("Bez DPH")}:</span>
              <span className="text-sm">
                {formatPrice(getCartTotal().total - getCartTotal().vat)}{" "}
                {currency}
              </span>
            </div>
            <div className="flex justify-between mb-4">
              <span className="text-sm text-gray-600">{t("DPH")}:</span>
              <span className="text-sm">
                {formatPrice(getCartTotal().vat)} {currency}
              </span>
            </div>
            <div className="flex justify-between mb-4">
              <span className="font-semibold">{t("Celkom")}:</span>
              <span className="font-semibold">
                {formatPrice(getCartTotal().total)} {currency}
              </span>
            </div>
            <Link to="/cart">
              <Button
                color="primary"
                className="w-full"
                onClick={() => setIsCartOpen(false)}
              >
                {t("Prejsť do košíka")}
              </Button>
            </Link>
          </div>
        </>
      )}
    </div>
  );

  // Mobile implementation using portal
  if (isMobile) {
    return ReactDOM.createPortal(
      <>
        <div
          className="fixed inset-0 z-[9990] bg-black bg-opacity-25"
          onClick={() => setIsCartOpen(false)}
        />
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="fixed inset-x-0 mx-4 top-16 z-[9999] w-auto max-h-[80vh] overflow-y-auto bg-white shadow-xl rounded-lg"
        >
          <CartContent />
        </motion.div>
      </>,
      document.body
    );
  }

  // Desktop implementation (original)
  return (
    <>
      <div
        className="fixed inset-0 z-80 sm:hidden"
        onClick={() => setIsCartOpen(false)}
      />
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="absolute right-0 top-12 z-50 w-96 max-h-[80vh] overflow-y-auto bg-white shadow-xl rounded-lg"
      >
        <CartContent />
      </motion.div>
    </>
  );
};

export default CartPreview;
