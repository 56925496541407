import React from 'react';
import BasicNavbar from '../components/BasicNavbar';
import Footer from '../components/Footer';
import TextImageComponent from '../components/TextImageComponent';
import HowItWorks from '../components/HowItWorks';
import FAQ from '../components/FAQ';
import WhatPeopleSay from '../components/WhatPeopleSay';
import ContactUs from '../components/ContactUs';
import BlogLanding from '../components/BlogLanding';
import YouCouldSeeUs from '../components/YouCouldSeeUs';
import InPageRegistration from '../components/InPageRegistration';
import HeaderBanner from '../components/HeaderBanner';

export default function Marketplace() {
  const faqQuestions = [
    {
      question: 'Čo sú to nákupy siete?',
      answer:
        'Vo vernostnom programe sa získavajú body aj za nákupy siete. Do vašej siete patria všetci užívatelia zaregistrovaní vo vašej sieti smerom pod vami bez obmedzenia počtu línií.',
    },
    {
      question: 'Ako si môžem tvoriť sieť a zarábať na nákupoch siete?',
      answer:
        'Sieť sa tvorí takým spôsobom, že pozvete ďalších užívateľov, ktorí sa zaregistrujú a budú nakupovať na trhovisku Bilionbuy. Pozvať užívateľa môžete prostredníctvom pozvánky na platforme Bilionbuy alebo zaslaním odporúčacieho linku.',
    },
    {
      question: 'Kde nájdem tabuľku vernostného programu?',
      answer: `Tabuľka vernostného programu je súčasťou obchodných podmienok Bilionbuy a nájdete ju aj v marketingových materiáloch ako napr. v kariérnom pláne alebo v prezentácii. Historicky získané body - odmena za 1 bod: 0 bodov - 0,5 EUR 100 bodov - 2,5 EUR 300 bodov - 3,5 EUR 1.000 bodov - 4 EUR 5.000 bodov - 4,5 EUR 25.000 bodov - 5 EUR 100.000 bodov - 5,5 EUR 200.000 bodov - 6 EUR Získané body v mesiaci - odmena za 1 bod: 0 bodov - 0,5 EUR 10 bodov - 2,5 EUR 30 bodov - 3,5 EUR 100 bodov - 4 EUR 500 bodov - 4,5 EUR 2.500 bodov - 5 EUR 10.000 bodov - 5,5 EUR 20.000 bodov - 6 EUR 50.000 bodov - 6,5 EUR 100.000 bodov - 7 EUR 200.000 bodov - 7,5 EUR 500.000 bodov - 8 EUR`,
    },
    {
      question:
        'Môžem v jednom mesiaci získať odmenu vo vernostnom programe za historicky získané body a zároveň aj za získané body v mesiaci?',
      answer:
        'V jednom mesiaci môže užívateľ získať odmenu vo vernostnom programe buď za historicky získané body alebo za získané body v mesiaci. Užívateľ získa automaticky tú odmenu, ktorá je pre neho výhodnejšia.',
    },
    {
      question: 'Čo sú historicky získané body?',
      answer:
        'Historicky získané body je súčet všetkých bodov získaných za vlastné nákupy a za nákupy užívateľov v sieti od začiatku registrácie k aktuálnemu dátumu.',
    },
    {
      question: 'Kedy mám nárok na vyplatenie za získané body v mesiaci?',
      answer:
        'Užívateľ získa odmenu podľa tabuľky vernostného programu za získané body namiesto odmeny za historické body vtedy, ak je pre užívateľa odmeny za získané body v mesiaci výhodnejšia.',
    },
    {
      question: 'Nulujú sa získané body vo vernostnom programe?',
      answer:
        'Historicky získané body sa nenulujú. Body získane v danom mesiaci sa každý mesiac nulujú prvý deň kalendárneho mesiaca.',
    },
  ];

  return (
    <div className='overflow-x-clip'>
      <BasicNavbar />

      <HeaderBanner
        title='Vernostný program pre registrovaných užívateľov'
        imageSrc='https://bbuy.fra1.cdn.digitaloceanspaces.com/img/newman.png'
        heading='Ako funguje vernostný program?'
        accordionItems={[
          {
            title: 'za vlastné nákupy získate body do vernostného programu',
            content:
              'Každý užívateľ zbiera vo vernostnom programe body. Každý nákup má svoju bodovú hodnotu v závislosti od výšky odmeny poskytnutej partnerom. Vo vernostnom programe sa získavajú body aj za vlastné nákupy.',
          },
          {
            title: 'za nákupy siete získate body do vernostného programu',
            content:
              'Každý užívateľ zbiera vo vernostnom programe body. Každý nákup má svoju bodovú hodnotu v závislosti od výšky odmeny poskytnutej partnerom. Vo vernostnom programe sa získavajú body aj za nákupy siete. Do vašej siete patria všetci užívatelia zaregistrovaní vo vašej sieti smerom pod vami bez obmedzenia počtu línií.',
          },
          {
            title: 'vyššie odmeny za viac historicky získaných bodov vo vernostnom programe',
            content: `Vernostný program odmeňuje užívateľov podľa histórie vlastných nákupov a nákupov siete. Vernostný program umožňuje vyššie odmeny podľa tabuľky vernostného programu, v ktorej sú odstupňované výšky odmien podľa historicky získaných bodov. Historicky získané body - odmena za 1 bod: 0 bodov - 0,5 EUR 100 bodov - 2,5 EUR 300 bodov - 3,5 EUR 1.000 bodov - 4 EUR 5.000 bodov - 4,5 EUR 25.000 bodov - 5 EUR 100.000 bodov - 5,5 EUR 200.000 bodov - 6 EUR`,
          },
          {
            title:
              'vyššie odmeny za viac získaných bodov vo vernostnom programe v kalendárnom mesiaci',
            content: `Vernostný program odmeňuje užívateľov podľa výsledku získaných počtu bodov za vlastné nákupy a nákupy siete v danom mesiaci. Vernostný program umožňuje získať užívateľovi vyššie odmeny, v prípade, ak v danom mesiaci získa vyššiu odmenu (eurovú hodnotu) za body získane v danom mesiaci, a to podľa tabuľky vernostného programu, v ktorej sú odstupňované výšky odmien podľa získaných bodov v danom mesiaci. Získané body v mesiaci - odmena za 1 bod: 0 bodov - 0,5 EUR 10 bodov - 2,5 EUR 30 bodov - 3,5 EUR 100 bodov - 4 EUR 500 bodov - 4,5 EUR 2.500 bodov - 5 EUR 10.000 bodov - 5,5 EUR 20.000 bodov - 6 EUR 50.000 bodov - 6,5 EUR 100.000 bodov - 7 EUR 200.000 bodov - 7,5 EUR 500.000 bodov - 8 EUR`,
          },
          {
            title: 'rastúci cashback',
            content:
              'Rastúci cashback je označenie pre odmeny z vlastných nákupov a odmeny za nákupy siete, ktoré užívateľom rastú, teda sa zvyšujú na základe vernostného programu. Rastúci cashback nazývame rastúcim z dvoch dôvodov. Prvý dôvod je skutočnosť, že rastie ako cashback za vlastné nákupy na základe historicky získaných bodov vo vernostnom programe. Druhý dôvod je skutočnosť, že finančná čiastka vyplácaná ako rastúci cashback, rastie aj o odmeny vyplácané za nákupy siete. Preto je rastúci cashback výhodnejší pre užívateľa, oproti štandardnému cashbacku.',
          },
          {
            title: 'filozofia nakupovania samospotrebiteľskou formou',
            content:
              'Filozofia nakupovania samospotrebiteľskou formou prináša revolúciu do každodenného nakupovania. Predstavte si, že za vlastné nákupy získavate hodnotné odmeny a váš cashback sa neustále zvyšuje. Navyše, nie ste obmedzení iba na svoje výdavky – stačí si vytvoriť sieť užívateľov, a profitovať môžete aj z ich nákupov. Táto jedinečná forma nakupovania vám umožňuje čerpať výhody nielen z vlastných nákupov, ale aj z celkových obratov vytvorených prostredníctvom platformy BilionBuy. Je to cesta, ako maximalizovať hodnotu každého nákupu a zároveň si budovať pasívny príjem.',
          },
          {
            title: 'podiel na obrate spoločnosti za premium členstvo',
            content:
              'Každý užívateľ, ktorý v danom mesiaci nakúpi produkty Bilionbuy v hodnote minimálne 1 bodu, získava premium členstvo a podiel na 1% obrate Bilionbuy. Výpočet podielu je jednoduchý. Hodnota 1% obratu Bilionbuy bez DPH sa rovným dielom prerozdelí medzi počet užívateľov, ktorí splnili podmienku nákupu produktov Bilionbuy v hodnote 1 bodu v danom mesiaci.',
          },
          {
            title: 'odmeny z obratov Bilionbuy',
            content:
              'Každý registrovaný užívateľ môže ako spolupracovník získať fixný podiel na obrate spoločnosti doživotne, v prípade ak si zakúpi niektorý z ponúkaných marketingových materiálov. Bilionbuy sa rozhodol k tomuto kroku z toho dôvodu, aby svojim užívateľom dal možnosť byť akokeby spolumajiteľom spoločnosti s možnosťou podieľať sa na obrate spoločnosti a považovať tak Bilionbuy právom za vlastný obchod. Viac sa dozviete v časti Odmeny z obratov Bilionbuy.',
          },
          {
            title: 'Bilionbuy vás označuje za “spolumajiteľov”',
            content:
              'Bilionbuy označuje za “spolumajiteľa” každého užívateľa, ktorý si zakúpil Bilionbuy start-up a automaticky tak získal podiel na zárobkoch Bilionbuy. Práva tohto “spolumajiteľa” spočívajú v práve na získanie zakúpených marketingových materiálov a v práve na vyplatenie odmeny zo zárobkov Bilionbuy. Užívateľ nemá rozhodovacie práva, ani žiadne iné práva okrem vyššie uvedených.',
          },
          {
            title: 'Bilionbuy je váš vlastný obchod',
            content:
              'Bilionbuy spustil webovú platformu na princípe samospotrebiteľstva. Preto šíri myšlienku, že Bilionbuy je obchod všetkých nás, ktorí v ňom nakupujeme a získavame časť odmeny z tohto veľkého trhoviska. Preto každý z nás môže považovať Bilionbuy za náš vlastný obchod, naše vlastné trhovisko.',
          },
        ]}
      />
      <HowItWorks
        title='Ako funguje vernostný program?'
        subtitle='Ako funguje vernostný program?'
        steps={[
          {
            number: '1',
            titleKey: 'Body za vlastný nákup',
            descriptionKey: 'Za každý vlastný nákup získate body do vernostného programu',
            bgColor: 'bg-[#4A90E2]',
          },
          {
            number: '2',
            titleKey: 'Body za nákup siete',
            descriptionKey: 'Za každý nákup siete získate body do vernostného programu',
            bgColor: 'bg-[#E57697]',
          },
          {
            number: '3',
            titleKey: 'Výpočet odmeny z vernostného programu',
            descriptionKey:
              'Odmena sa vypočítava na základe historicky získaných bodov alebo za získané body v mesiaci. Užívateľ získa automaticky tú odmenu, ktorá je pre neho výhodnejšia',
            bgColor: 'bg-[#A6CF4A]',
          },
          {
            number: '4',
            titleKey: 'Pripísanie odmien',
            descriptionKey:
              'Za každý nákup získava užívateľ body do vernostného programu. Zároveň získava rastúci cashback a odmeny z vernostného programu, ktoré sa pripisujú do e-peňaženky.',
            bgColor: 'bg-[#FB6238]',
          },
          {
            number: '5',
            titleKey: 'Vyplatenie odmien na bankový účet',
            descriptionKey:
              'O vyplatenie odmien je možné požiadať v e-peňaženke. Pred vyplatením si musíte upraviť profil a zadať údaje potrebné na vyplatenie odmeny.',
            bgColor: 'bg-[#B8CC2E]',
          },
        ]}
      />
      <TextImageComponent
        imagePosition='left' // or "right"
        heading='Princíp vernostného programu je postavený na základoch nakupovania samospotrebiteľskou formou'
        fulltext='Je to nový revolučný pohľad na nakupovanie. Umožňuje vám profitovať z toho, čo by ste si tak či tak kúpili, či už ide o potraviny, drogériu, oblečenie alebo elektroniku. Každý nákup sa stáva príležitosťou na zárobok – už to nie je len výdavok, ale inteligentná investícia. Jedným z hlavných benefitov je rastúci cashback, ktorý vracia časť vašich výdavkov späť. Tento model tak premieňa každodenné nakupovanie na nástroj na budovanie finančnej stability a efektívne využívanie peňazí'
        imageSrc='https://bbuy.fra1.cdn.digitaloceanspaces.com/img/princip.png'
        imageAlt='Illustration of how cashback works'
        auth={false}
        ctaConfig={{
          primaryButton: {
            text: 'Registrácia zdarma',
            href: '/register',
          },
          secondaryButton: {
            text: 'Viac informácií',
            href: '/blog#vyhody-cashbacku/',
          },
        }}
      />

      <TextImageComponent
        imagePosition='right' // or "right"
        heading='Zvyšovanie odmien z vernostného programu viete ovplyvniť zväčšovaním vašej siete a ich nákupmi'
        fulltext='Zvyšovanie odmien z vernostného programu môžete ľahko ovplyvniť rozširovaním svojej siete a ich nákupmi. Okrem cashbacku zohráva kľúčovú úlohu aj  odmena z vernostného programu, ktorá sa odvíja od hodnoty získaných bodov z vašich vlastných nákupov a nákupov siete. Čím viac bodov nazbierate, tým vyššia je vaša odmena, čo vás motivuje nakupovať múdro a strategicky. Každý bod vás približuje k finančným cieľom, takže tento jednoduchý, no efektívny systém vám pomáha stabilne zvyšovať príjem a budovať finančnú istotu.'
        imageSrc='https://bbuy.fra1.cdn.digitaloceanspaces.com/img/zvysovanie-odmien.png'
        imageAlt='Illustration of how cashback works'
        auth={false}
        ctaConfig={{
          primaryButton: {
            text: 'Registrácia zdarma',
            href: '/register',
          },
          secondaryButton: {
            text: 'Viac informácií',
            href: '/blog#vyhody-cashbacku/',
          },
        }}
      />
      <FAQ title='Najčastejšie otázky' questions={faqQuestions} />
      <BlogLanding />
      <WhatPeopleSay />
      <YouCouldSeeUs />
      <InPageRegistration />
      <Footer />
    </div>
  );
}
