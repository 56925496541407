import React, { useState, useEffect, useCallback } from "react";
import {
  Paper,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Collapse,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import api from "../../../services/api";
import AdminCategoryDetail from "./AdminCategoryDetail";

// Styled component for the category row
const CategoryRow = styled(Box)(({ theme, depth = 0, expanded = false }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1.5),
  paddingLeft: theme.spacing(2 + depth * 2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: expanded ? theme.palette.action.hover : "inherit",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  transition: "background-color 0.2s ease",
}));

// Component to display a single category with its children
const CategoryItem = ({ category, depth = 0, onRefresh }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [children, setChildren] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [categoryName, setCategoryName] = useState(
    category.category_name || ""
  );
  const [categoryOrder, setCategoryOrder] = useState(category.order || 0);

  const toggleExpand = async () => {
    if (!expanded && children.length === 0) {
      await fetchChildren();
    }
    setExpanded(!expanded);
  };

  const fetchChildren = async () => {
    setLoading(true);
    try {
      const response = await api.get("get-admin-categories/", {
        params: { target: category.id },
      });
      setChildren(response.data || []);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      toast.error(t("Failed to load subcategories"));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCategory = async () => {
    try {
      await api.post("delete-category/", { id: category.id });
      toast.success(t("Category deleted successfully"));
      setOpenDeleteDialog(false);
      if (onRefresh) onRefresh();
    } catch (error) {
      console.error("Error deleting category:", error);
      toast.error(t("Failed to delete category"));
    }
  };

  const handleAddSubcategory = async (newCategoryName, newCategoryOrder) => {
    try {
      await api.post("create-category/", {
        category_name: newCategoryName,
        parent: category.id,
        order: newCategoryOrder,
      });
      toast.success(t("Subcategory added successfully"));
      await fetchChildren();
      setExpanded(true);
      if (onRefresh) onRefresh();
    } catch (error) {
      console.error("Error adding subcategory:", error);
      toast.error(t("Failed to add subcategory"));
    }
  };

  return (
    <>
      <CategoryRow depth={depth} expanded={expanded}>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={toggleExpand}
        >
          {loading ? (
            <CircularProgress size={24} sx={{ mr: 1 }} />
          ) : (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                toggleExpand();
              }}
              sx={{ mr: 1 }}
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
          <Typography variant="subtitle1">{category.category_name}</Typography>
        </Box>
        <Box>
          <IconButton
            size="small"
            color="primary"
            onClick={() => setOpenDetailDialog(true)}
            title={t("Edit & View")}
          >
            <VisibilityIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            color="error"
            onClick={() => setOpenDeleteDialog(true)}
            title={t("Delete")}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            color="success"
            onClick={() => {
              setOpenAddDialog(true);
            }}
            title={t("Add Subcategory")}
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </Box>
      </CategoryRow>

      <Collapse in={expanded}>
        {children.map((child) => (
          <CategoryItem
            key={child.id}
            category={child}
            depth={depth + 1}
            onRefresh={onRefresh}
          />
        ))}
        {expanded && children.length === 0 && !loading && (
          <Box pl={depth + 5} py={2}>
            <Typography variant="body2" color="textSecondary">
              {t("No subcategories found")}
            </Typography>
          </Box>
        )}
      </Collapse>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>{t("Confirm Delete")}</DialogTitle>
        <DialogContent>
          <Typography>
            {t("Are you sure you want to delete")} "{category.category_name}"?{" "}
            {t("This action cannot be undone.")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>
            {t("Cancel")}
          </Button>
          <Button onClick={handleDeleteCategory} color="error">
            {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Subcategory Dialog */}
      <AddCategoryDialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        onAdd={handleAddSubcategory}
        parentName={category.category_name}
      />

      {/* Category Detail Dialog */}
      <Dialog
        open={openDetailDialog}
        onClose={() => setOpenDetailDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {t("Category Details")}: {category.category_name}
          <IconButton
            aria-label="close"
            onClick={() => setOpenDetailDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            ✕
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <AdminCategoryDetail
            categoryId={category.id}
            onClose={() => setOpenDetailDialog(false)}
            onUpdate={() => {
              onRefresh();
              setCategoryName(category.category_name);
              setCategoryOrder(category.order);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

// Component for the Add Category Dialog
const AddCategoryDialog = ({ open, onClose, onAdd, parentName }) => {
  const { t } = useTranslation();
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newCategoryOrder, setNewCategoryOrder] = useState(0);

  const handleAdd = () => {
    if (!newCategoryName.trim()) {
      toast.error(t("Category name is required"));
      return;
    }
    onAdd(newCategoryName, newCategoryOrder);
    setNewCategoryName("");
    setNewCategoryOrder(0);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {parentName
          ? t("Add Subcategory to") + ` "${parentName}"`
          : t("Add Category")}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={t("Category Name")}
          fullWidth
          variant="outlined"
          value={newCategoryName}
          onChange={(e) => setNewCategoryName(e.target.value)}
        />
        <TextField
          margin="dense"
          label={t("Order")}
          fullWidth
          variant="outlined"
          type="number"
          value={newCategoryOrder}
          onChange={(e) =>
            setNewCategoryOrder(parseInt(e.target.value, 10) || 0)
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Cancel")}</Button>
        <Button onClick={handleAdd} color="primary">
          {t("Add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Main component
const AdminCategories = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAddDialog, setOpenAddDialog] = useState(false);

  const fetchRootCategories = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get("get-admin-categories/");
      setCategories(response.data || []);
    } catch (error) {
      console.error("Error fetching root categories:", error);
      toast.error(t("Failed to load categories"));
    } finally {
      setLoading(false);
    }
  }, [t]);

  useEffect(() => {
    fetchRootCategories();
  }, [fetchRootCategories]);

  const handleAddCategory = async (newCategoryName, newCategoryOrder) => {
    try {
      await api.post("create-category/", {
        category_name: newCategoryName,
        order: newCategoryOrder,
      });
      toast.success(t("Category added successfully"));
      fetchRootCategories();
    } catch (error) {
      console.error("Error adding category:", error);
      toast.error(t("Failed to add category"));
    }
  };

  return (
    <Paper elevation={3} sx={{ my: 3, maxWidth: "100%" }}>
      <Box
        p={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" component="h2">
          {t("Categories Management")}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenAddDialog(true)}
        >
          {t("Add Root Category")}
        </Button>
      </Box>

      <Box sx={{ borderTop: 1, borderColor: "divider" }}>
        {loading ? (
          <Box display="flex" justifyContent="center" p={4}>
            <CircularProgress />
          </Box>
        ) : categories.length === 0 ? (
          <Box p={4} textAlign="center">
            <Typography>{t("No categories found")}</Typography>
          </Box>
        ) : (
          categories.map((category) => (
            <CategoryItem
              key={category.id}
              category={category}
              onRefresh={fetchRootCategories}
            />
          ))
        )}
      </Box>

      {/* Add Root Category Dialog */}
      <AddCategoryDialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        onAdd={handleAddCategory}
      />
    </Paper>
  );
};

export default AdminCategories;
