import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Modal,
  TextField,
  Switch,
  FormControlLabel,
  Typography,
  Grid,
  CircularProgress,
  Pagination,
  Tooltip,
  Popover,
  Checkbox,
  FormGroup,
  MenuItem,
  Autocomplete,
  Skeleton,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility,
  VisibilityOff,
  Search as SearchIcon,
  Add as AddIcon,
  LocalOffer as LocalOfferIcon,
  ContentCopy,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import api, { cancelRequest } from "../../../services/api";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import RichTextEditor from "../../RichTextEditor";
import AutoselectFetchCreate from "../../AutoselectFetchCreate";
import BilionBuyProductEditModal from "./BilionBuyProductEditModal";
import ProductEditModal from "../ProductEditModal";
import BilionBuyOfferModal from "./BilionBuyOfferModal";
import ProductSelectorModal from "./ProductSelectorModal";
import { alpha } from "@mui/material/styles";
import axios from "axios";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto",
};

const dangerBg = {
  backgroundColor: alpha("#f44336", 0.1), // light red background
};

const successBg = {
  backgroundColor: alpha("#4caf50", 0.1),
};

const warningBg = {
  backgroundColor: alpha("#ff9800", 0.1),
};

export default function BilionBuyProductsTable() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageInputValue, setPageInputValue] = useState("1");
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [isAddOfferModalOpen, setIsAddOfferModalOpen] = useState(false);
  const [newProduct, setNewProduct] = useState({
    product_name: "",
    original_name: "",
    product_description: "",
    language: "sk",
    product_manufacturer: "",
    product_assigned_category: "",
    product_image_url: "",
    product_ean: "",
    visibility: true,
    adult_content: false,
    shop_link_visibility: true,
    price_vat: "",
    price_novat: "",
    url: "",
    img_url: "",
    stock: "in_stock",
    stock_count: "",
    delivery_date: "",
    item_id: "",
    gift: "",
    extended_warranty: null,
    special_service: "",
    sales_voucher: null,
    cashback: "",
  });
  const [content, setContent] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryInputValue, setCategoryInputValue] = useState("");
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const [isProductSelectorOpen, setIsProductSelectorOpen] = useState(false);
  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
  const [isEditOfferModalOpen, setIsEditOfferModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [priceOrder, setPriceOrder] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [showMultipleOffers, setShowMultipleOffers] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("SK");
  const [deliveryPrices, setDeliveryPrices] = useState({});
  const abortControllerRef = useRef(null);

  // Add column visibility state
  const [visibleColumns, setVisibleColumns] = useState(() => {
    const saved = localStorage.getItem("bilionBuyTableColumns");
    return saved
      ? JSON.parse(saved)
      : {
          image: true,
          name: true,
          category: true,
          wholesalePrice: true,
          margin: true,
          priceVat: true,
          vatRate: true,
          shippingCost: true,
          grossProfit: true,
          lowestPrice: true,
          stock: true,
          tags: true,
          visibility: true,
          actions: true,
        };
  });

  // Add popover state
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Save column visibility to localStorage when it changes
  useEffect(() => {
    localStorage.setItem(
      "bilionBuyTableColumns",
      JSON.stringify(visibleColumns)
    );
  }, [visibleColumns]);

  // Column definitions
  const columns = {
    image: { label: "Obrázok", key: "image" },
    name: { label: "Názov", key: "name" },
    category: { label: "Kategória", key: "category" },
    wholesalePrice: { label: "Veľkoobchodná cena", key: "wholesalePrice" },
    margin: { label: "Marža", key: "margin" },
    priceVat: { label: "Cena s DPH", key: "priceVat" },
    vatRate: { label: "Sadzba DPH", key: "vatRate" },
    shippingCost: { label: "Náklady na dopravu", key: "shippingCost" },
    grossProfit: { label: "Hrubý zisk", key: "grossProfit" },
    lowestPrice: { label: "Najnižšia cena v porovnávači", key: "lowestPrice" },
    stock: { label: "Skladom", key: "stock" },
    tags: { label: "Tagy", key: "tags" },
    visibility: { label: "Viditeľnosť", key: "visibility" },
    actions: { label: "Akcie", key: "actions" },
  };

  const lastRequestUrlRef = useRef(null);
  const lastRequestIdRef = useRef(0);

  const fetchProducts = useCallback(
    async (silent = false) => {
      if (!silent) setIsLoading(true);
      if (lastRequestUrlRef.current) {
        cancelRequest(lastRequestUrlRef.current);
      }
      const url = "/bilionbuy-products-mv/";
      lastRequestUrlRef.current = url;
      const requestId = ++lastRequestIdRef.current;

      try {
        const response = await api.get(url, {
          params: {
            page,
            page_size: pageSize,
            ...(searchTerm && { search: searchTerm }),
            ...(priceOrder && { price_order: priceOrder }),
            ...(selectedTag && { tag: selectedTag }),
            ...(showMultipleOffers && { multiple_offers: true }),
            ...(selectedCategory?.id && { category: selectedCategory.id }),
            country: selectedCountry,
          },
        });
        if (requestId === lastRequestIdRef.current) {
          if (response.data?.results) {
            setProducts(response.data.results);
            const pagination = response.data.pagination;
            setPage(pagination.current_page);
            setTotalPages(pagination.total_pages);
            setTotalItems(pagination.total_items);
            setPageSize(pagination.page_size);
          } else {
            setProducts([]);
            setTotalPages(1);
            setTotalItems(0);
          }
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (requestId === lastRequestIdRef.current) {
          console.error("Error fetching products:", error);
          toast.error("Failed to fetch products");
          setProducts([]);
          setTotalPages(1);
        }
      } finally {
        if (requestId === lastRequestIdRef.current && !silent) {
          setIsLoading(false);
        }
      }
    },
    [
      page,
      pageSize,
      searchTerm,
      priceOrder,
      selectedTag,
      showMultipleOffers,
      selectedCategory,
      selectedCountry,
    ]
  );

  const debouncedFetch = useCallback(
    debounce((searchValue) => {
      setPage(1);
      const fetchData = async () => {
        setIsLoading(true);

        if (lastRequestUrlRef.current) {
          cancelRequest(lastRequestUrlRef.current);
        }

        const url = "/bilionbuy-products-mv/";
        lastRequestUrlRef.current = url;
        const requestId = ++lastRequestIdRef.current;

        try {
          const response = await api.get(url, {
            params: {
              page: 1,
              page_size: pageSize,
              ...(searchValue && { search: searchValue }),
              country: selectedCountry,
            },
          });

          if (requestId === lastRequestIdRef.current) {
            if (response.data?.results) {
              setProducts(response.data.results);
              const pagination = response.data.pagination;
              setPage(pagination.current_page);
              setTotalPages(pagination.total_pages);
              setTotalItems(pagination.total_items);
              setPageSize(pagination.page_size);
            } else {
              setProducts([]);
              setTotalPages(1);
              setTotalItems(0);
            }
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log("Request canceled:", error.message);
          } else if (requestId === lastRequestIdRef.current) {
            console.error("Error fetching products:", error);
            toast.error("Failed to fetch products");
            setProducts([]);
            setTotalPages(1);
          }
        } finally {
          if (requestId === lastRequestIdRef.current) {
            setIsLoading(false);
          }
        }
      };
      fetchData();
    }, 500),
    [selectedCountry]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedFetch(value);
  };

  useEffect(() => {
    if (!searchTerm) {
      fetchProducts();
    }
  }, [fetchProducts, page, pageSize]);

  useEffect(() => {
    if (selectedProduct) {
      setContent(selectedProduct.product_description || "");
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (isAddProductModalOpen) {
      setContent("");
    }
  }, [isAddProductModalOpen]);

  const fetchCategory = async (categoryId) => {
    try {
      const response = await api.get(`get-category/?id=${categoryId}`);
      const categoryData = Array.isArray(response.data)
        ? response.data[0]
        : response.data;
      setSelectedCategory(categoryData);
      setCategoryInputValue(categoryData.category_fullname || "");
    } catch (error) {
      console.error("Error fetching category:", error);
      toast.error("Failed to load product category");
    }
  };

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setIsEditProductModalOpen(true);
  };

  const handleEditOffer = (product) => {
    setSelectedProduct(product);
    setIsEditOfferModalOpen(true);
  };

  const handleDeleteClick = (product) => {
    setProductToDelete(product);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await api.delete(`/bilionbuy-products/${productToDelete.id}/`);
      toast.success("Offer deleted successfully");
      setIsDeleteModalOpen(false);
      setProductToDelete(null);
      fetchProducts(true);
    } catch (error) {
      toast.error("Failed to delete offer");
      console.error("Error deleting offer:", error);
    }
  };

  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    try {
      await api.patch(`/bilionbuy-products/${selectedProduct.id}/`, {
        ...selectedProduct,
        product_description: content,
      });
      toast.success("Product updated successfully");
      setIsModalOpen(false);
      setContent("");
      fetchProducts(true);
    } catch (error) {
      toast.error("Failed to update product");
      console.error("Error updating product:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedProduct((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleVisibilityToggle = async (id, currentVisibility) => {
    try {
      await api.patch(`/bilionbuy-products/${id}/`, {
        visibility: !currentVisibility,
      });
      fetchProducts();
      toast.success("Shop link visibility updated successfully");
    } catch (error) {
      toast.error("Failed to update shop link visibility");
      console.error("Error updating shop link visibility:", error);
    }
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    try {
      await api.post("/bilionbuy-products/", newProduct);
      toast.success("Product added successfully");
      setIsAddProductModalOpen(false);
      setNewProduct({
        product_name: "",
        original_name: "",
        product_description: "",
        language: "sk",
        product_manufacturer: "",
        product_assigned_category: "",
        product_image_url: "",
        product_ean: "",
        visibility: true,
        adult_content: false,
        shop_link_visibility: true,
        price_vat: "",
        price_novat: "",
        url: "",
        img_url: "",
        stock: "in_stock",
        stock_count: "",
        delivery_date: "",
        item_id: "",
        gift: "",
        extended_warranty: null,
        special_service: "",
        sales_voucher: null,
        cashback: "",
      });
      fetchProducts(true);
    } catch (error) {
      toast.error("Failed to add product");
      console.error("Error adding product:", error);
    }
  };

  const handleCloseAddModal = () => {
    setIsAddProductModalOpen(false);
    setTimeout(() => {
      setNewProduct({
        product_name: "",
        original_name: "",
        product_description: "",
        language: "sk",
        product_manufacturer: "",
        product_assigned_category: "",
        product_image_url: "",
        product_ean: "",
        visibility: true,
        adult_content: false,
        shop_link_visibility: true,
        price_vat: "",
        price_novat: "",
        url: "",
        img_url: "",
        stock: "in_stock",
        stock_count: "",
        delivery_date: "",
        item_id: "",
        gift: "",
        extended_warranty: null,
        special_service: "",
        sales_voucher: null,
        cashback: "",
      });
      setContent("");
      setSelectedCategory(null);
      setCategoryInputValue("");
    }, 100);
  };

  const handleCloseEditModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
    setContent("");
    setSelectedCategory(null);
    setCategoryInputValue("");
  };

  const fetchCategories = useCallback(async () => {
    try {
      const response = await api.get("get-admin-categories/");
      setCategories(response.data?.results || []);
    } catch (error) {
      console.error("Error fetching categories:", error);
      toast.error("Failed to load categories");
      setCategories([]);
    }
  }, []);

  useEffect(() => {
    if (isModalOpen || isAddProductModalOpen) {
      fetchCategories();
    }
  }, [isModalOpen, isAddProductModalOpen, fetchCategories]);

  const handleAddOfferClick = () => {
    setIsProductSelectorOpen(true);
  };

  const handleProductSelect = (product) => {
    setSelectedProduct({
      product: product,
    });
    setIsAddOfferModalOpen(true);
  };

  const handleDuplicate = (product) => {
    console.log("Duplicating product:", product);

    const duplicateData = {
      product_name: product.product_name || "",
      original_name: product.original_name || "",
      product_description: product.product_description || "",
      language: product.language || "sk",
      product_manufacturer: product.product_manufacturer || "",
      product_assigned_category: product.product_assigned_category_id || "",
      product_image_url: product.product_image_url || "",
      product_ean: "",
      visibility: product.visibility ?? true,
      adult_content: product.adult_content ?? false,
      shop_link_visibility: product.shop_link_visibility ?? true,
      price_vat: product.price_vat || "",
      price_novat: product.price_novat || "",
      url: product.url || "",
      img_url: product.img_url || "",
      stock: product.stock || "in_stock",
      stock_count: product.stock_count || "",
      delivery_date: product.delivery_date || "",
      item_id: product.item_id || "",
      gift: product.gift || "",
      extended_warranty: product.extended_warranty || null,
      special_service: product.special_service || "",
      sales_voucher: product.sales_voucher || null,
      cashback: product.cashback || "",
    };

    console.log("Setting new product data:", duplicateData);

    // Set all the states at once
    setNewProduct(duplicateData);
    setContent(product.product_description || "");
    if (product.product_assigned_category_id) {
      fetchCategory(product.product_assigned_category_id);
    }
    setIsAddProductModalOpen(true);
  };

  useEffect(() => {
    console.log("newProduct state updated:", newProduct);
  }, [newProduct]);

  useEffect(() => {
    if (isAddProductModalOpen) {
      console.log("Modal opened with newProduct:", newProduct);
      console.log("Content:", content);
    }
  }, [isAddProductModalOpen, newProduct, content]);

  // Calculate gross profit percentage based on margin
  const calculateGrossProfitPercentage = (margin) => {
    if (!margin) return null;
    const marginDecimal = parseFloat(margin) / 100;
    return ((marginDecimal / (1 + marginDecimal)) * 100).toFixed(2);
  };

  // Calculate price without VAT
  const calculatePriceNoVat = (marginPrice, margin) => {
    if (!marginPrice || !margin) return null;
    const wholesale = parseFloat(marginPrice);
    const marginPercent = parseFloat(margin);
    const marginAmount = wholesale * (marginPercent / 100);
    return (wholesale + marginAmount).toFixed(2);
  };

  // Calculate price with VAT (assuming 23% VAT)
  const calculatePriceVat = (priceNoVat) => {
    if (!priceNoVat) return null;
    return (parseFloat(priceNoVat) * 1.23).toFixed(2);
  };

  // Modify the fetchDeliveryPrice function to add more logging
  const fetchDeliveryPrice = useCallback(async (productId, country) => {
    console.log("Fetching delivery price for:", { productId, country });
    try {
      const response = await api.get("bigbuy/get-delivery-price/", {
        // Added leading slash
        params: {
          product: productId,
          country: country,
        },
      });
      console.log("Delivery price response:", response.data);
      setDeliveryPrices((prev) => ({
        ...prev,
        [`${productId}-${country}`]: response.data.price,
      }));
    } catch (error) {
      console.error("Error fetching delivery price:", error);
      setDeliveryPrices((prev) => ({
        ...prev,
        [`${productId}-${country}`]: null,
      }));
    }
  }, []);

  // Modify the useEffect to add debugging and proper checks
  useEffect(() => {
    if (products.length > 0) {
      console.log("Products for delivery price fetch:", products);
      products.forEach((product) => {
        // Add more specific checks
        if (product?.product_id && product?.language) {
          console.log("Fetching for product:", {
            id: product.product_id,
            language: product.language,
          });
          fetchDeliveryPrice(product.product_id, product.language);
        } else {
          console.log("Skipping product due to missing data:", product);
        }
      });
    }
  }, [products, fetchDeliveryPrice]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (lastRequestUrlRef.current) {
        cancelRequest(lastRequestUrlRef.current);
      }
    };
  }, []);

  const debouncedCategorySearch = useCallback(
    debounce(async (query) => {
      if (query.length > 2) {
        try {
          if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }

          abortControllerRef.current = new AbortController();

          const response = await api.get(`search-categories/?query=${query}`, {
            signal: abortControllerRef.current.signal,
          });
          const newOptions = Array.isArray(response.data) ? response.data : [];
          setCategoryOptions(newOptions);
        } catch (error) {
          if (error.name === "AbortError") {
            console.log("Request aborted");
          } else {
            console.error("Error searching categories: ", error);
          }
        }
      }
    }, 300),
    []
  );

  const debouncedSetPage = useCallback(
    debounce((newPage) => {
      if (newPage >= 1 && newPage <= totalPages) {
        setPage(newPage);
      }
    }, 500),
    [totalPages]
  );

  useEffect(() => {
    setPageInputValue(page.toString());
  }, [page]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          gap: 2,
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <TextField
          size="small"
          placeholder="Vyhľadať produkty..."
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
        />
        <Autocomplete
          disablePortal
          size="small"
          options={categoryOptions}
          getOptionLabel={(option) => option?.category_fullname || ""}
          value={selectedCategory}
          onChange={(event, newValue) => {
            setSelectedCategory(newValue);
            setPage(1);
          }}
          onInputChange={(event, newInputValue) => {
            debouncedCategorySearch(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filtrovať podľa kategórie"
              variant="outlined"
              size="small"
              sx={{
                backgroundColor: "#fff",
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#fff",
                },
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          sx={{
            minWidth: 300,
          }}
          componentsProps={{
            popper: {
              sx: {
                zIndex: 9999,
                "& .MuiPaper-root": {
                  backgroundColor: "#fff !important",
                  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
                },
                "& .MuiAutocomplete-listbox": {
                  backgroundColor: "#fff !important",
                },
              },
            },
          }}
        />
        <TextField
          select
          size="small"
          value={priceOrder}
          onChange={(e) => setPriceOrder(e.target.value)}
          label="Zoradiť podľa ceny"
          sx={{ minWidth: 200 }}
          disabled
        >
          <MenuItem value="">Bez zoradenia</MenuItem>
          <MenuItem value="asc">Viac ako naša ponuka</MenuItem>
          <MenuItem value="desc">Menej ako naša ponuka</MenuItem>
          <MenuItem value="equal">Rovnaká cena</MenuItem>
        </TextField>
        <TextField
          select
          size="small"
          value={selectedTag}
          onChange={(e) => setSelectedTag(e.target.value)}
          label="Filter podľa tagu"
          sx={{ minWidth: 200 }}
        >
          <MenuItem value="">Všetky tagy</MenuItem>
          <MenuItem value="bigbuy">Bigbuy</MenuItem>
          <MenuItem value="bilionbuy">Bilionbuy</MenuItem>
        </TextField>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel id="country-select-label">Krajina</InputLabel>
          <Select
            labelId="country-select-label"
            id="country-select"
            value={selectedCountry}
            label="Krajina"
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            <MenuItem value="SK">Slovensko</MenuItem>
            <MenuItem value="CZ">Česko</MenuItem>
            <MenuItem value="HU">Maďarsko</MenuItem>
            <MenuItem value="PL">Poľsko</MenuItem>
            <MenuItem value="DE">Nemecko</MenuItem>
            <MenuItem value="AT">Rakúsko</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={showMultipleOffers}
              onChange={(e) => setShowMultipleOffers(e.target.checked)}
              size="small"
            />
          }
          label="Len produkty s viacerými ponukami"
          sx={{ ml: 1 }}
        />
        <IconButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          size="small"
          sx={{ ml: 1 }}
        >
          <SettingsIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box sx={{ p: 2, width: 250 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Zobraziť stĺpce
            </Typography>
            <FormGroup>
              {Object.entries(columns).map(([key, { label }]) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      checked={visibleColumns[key]}
                      onChange={(e) => {
                        setVisibleColumns((prev) => ({
                          ...prev,
                          [key]: e.target.checked,
                        }));
                      }}
                      size="small"
                    />
                  }
                  label={<Typography variant="body2">{label}</Typography>}
                />
              ))}
            </FormGroup>
          </Box>
        </Popover>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsAddProductModalOpen(true)}
        >
          Pridať produkt
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<LocalOfferIcon />}
          onClick={handleAddOfferClick}
        >
          Pridať ponuku
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {Object.entries(columns).map(
                ([key, { label }]) =>
                  visibleColumns[key] && (
                    <TableCell key={key}>{label}</TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              [...Array(pageSize)].map((_, index) => (
                <TableRow key={`skeleton-${index}`}>
                  {visibleColumns.image && (
                    <TableCell>
                      <Skeleton variant="rectangular" width={50} height={50} />
                    </TableCell>
                  )}
                  {visibleColumns.name && (
                    <TableCell>
                      <Skeleton variant="text" width={200} />
                      <Skeleton variant="text" width={150} sx={{ mt: 0.5 }} />
                    </TableCell>
                  )}
                  {visibleColumns.category && (
                    <TableCell>
                      <Skeleton variant="text" width={120} />
                    </TableCell>
                  )}
                  {visibleColumns.wholesalePrice && (
                    <TableCell>
                      <Skeleton variant="text" width={80} />
                    </TableCell>
                  )}
                  {visibleColumns.margin && (
                    <TableCell>
                      <Skeleton variant="text" width={60} />
                    </TableCell>
                  )}
                  {visibleColumns.priceVat && (
                    <TableCell>
                      <Skeleton variant="text" width={80} />
                    </TableCell>
                  )}
                  {visibleColumns.vatRate && (
                    <TableCell>
                      <Skeleton variant="text" width={60} />
                    </TableCell>
                  )}
                  {visibleColumns.shippingCost && (
                    <TableCell>
                      <Skeleton variant="text" width={80} />
                    </TableCell>
                  )}
                  {visibleColumns.grossProfit && (
                    <TableCell>
                      <Skeleton variant="text" width={60} />
                    </TableCell>
                  )}
                  {visibleColumns.lowestPrice && (
                    <TableCell>
                      <Skeleton variant="text" width={150} height={40} />
                    </TableCell>
                  )}
                  {visibleColumns.stock && (
                    <TableCell>
                      <Skeleton variant="text" width={60} />
                    </TableCell>
                  )}
                  {visibleColumns.tags && (
                    <TableCell>
                      <Box sx={{ display: "flex", gap: 0.5 }}>
                        <Skeleton variant="rounded" width={60} height={24} />
                        <Skeleton variant="rounded" width={60} height={24} />
                      </Box>
                    </TableCell>
                  )}
                  {visibleColumns.visibility && (
                    <TableCell>
                      <Skeleton variant="circular" width={32} height={32} />
                    </TableCell>
                  )}
                  {visibleColumns.actions && (
                    <TableCell>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Skeleton variant="circular" width={32} height={32} />
                        <Skeleton variant="circular" width={32} height={32} />
                        <Skeleton variant="circular" width={32} height={32} />
                        <Skeleton variant="circular" width={32} height={32} />
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : products?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={
                    Object.keys(visibleColumns).filter(
                      (key) => visibleColumns[key]
                    ).length
                  }
                >
                  Nenašli sa žiadne produkty
                </TableCell>
              </TableRow>
            ) : (
              products.map((product) => {
                // Only calculate if wholesale price and margin are available
                let calculatedValues = null;
                if (product.margin_price && product.margin) {
                  const grossProfit = calculateGrossProfitPercentage(
                    product.margin
                  );
                  const priceNoVat = calculatePriceNoVat(
                    product.margin_price,
                    product.margin
                  );
                  const priceVat = calculatePriceVat(priceNoVat);
                  calculatedValues = { grossProfit, priceNoVat, priceVat };
                }

                return (
                  <TableRow key={product.id}>
                    {visibleColumns.image && (
                      <TableCell>
                        <img
                          src={product.product_image_url}
                          alt={product.product_name}
                          style={{ width: 50, height: 50, objectFit: "cover" }}
                        />
                      </TableCell>
                    )}
                    {visibleColumns.name && (
                      <TableCell>
                        <Typography variant="body1">
                          {product.product_name}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {product.product_manufacturer}
                        </Typography>
                      </TableCell>
                    )}
                    {visibleColumns.category && (
                      <TableCell>
                        {product.assigned_category_fullname || "-"}
                      </TableCell>
                    )}
                    {visibleColumns.wholesalePrice && (
                      <TableCell>
                        <Typography noWrap>
                          {product.margin_price
                            ? `${product.margin_price} €`
                            : "-"}
                        </Typography>
                      </TableCell>
                    )}
                    {visibleColumns.margin && (
                      <TableCell>
                        <Typography noWrap>
                          {product.margin ? `${product.margin} %` : "-"}
                        </Typography>
                      </TableCell>
                    )}
                    {visibleColumns.priceVat && (
                      <TableCell>
                        {product.price_vat ? (
                          <>
                            <Typography noWrap>
                              {product.price_vat} €
                            </Typography>
                            {product.conversion_price && (
                              <Typography noWrap>
                                {product.conversion_price} {product.currency}
                              </Typography>
                            )}
                          </>
                        ) : calculatedValues?.priceVat ? (
                          <Typography noWrap>
                            {calculatedValues.priceVat} €
                          </Typography>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    )}
                    {visibleColumns.vatRate && (
                      <TableCell>
                        {product.vat_rate !== undefined &&
                        product.vat_rate !== null ? (
                          <Typography noWrap>{product.vat_rate} %</Typography>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    )}
                    {visibleColumns.shippingCost && (
                      <TableCell>
                        {product?.product_id && product?.language ? (
                          deliveryPrices[
                            `${product.product_id}-${product.language}`
                          ] !== undefined ? (
                            typeof deliveryPrices[
                              `${product.product_id}-${product.language}`
                            ] === "number" ? (
                              <Tooltip
                                title={`Najnižia cena dopravy pre tento produkt, s doručením do krajiny ${product.language}`}
                              >
                                <span>
                                  {
                                    deliveryPrices[
                                      `${product.product_id}-${product.language}`
                                    ]
                                  }{" "}
                                  €
                                </span>
                              </Tooltip>
                            ) : (
                              "N/A"
                            )
                          ) : (
                            <CircularProgress size={20} />
                          )
                        ) : (
                          "Missing product data"
                        )}
                      </TableCell>
                    )}
                    {visibleColumns.grossProfit && (
                      <TableCell>
                        {product.margin ? (
                          <Typography noWrap>
                            {calculateGrossProfitPercentage(product.margin)} %
                          </Typography>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    )}
                    {visibleColumns.lowestPrice && (
                      <TableCell>
                        <a
                          href={"/view-product/" + product.product_slug}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {product.lowest_price === null ||
                          product.lowest_price === undefined ? (
                            "Žiadne iné ponuky"
                          ) : (
                            <Box
                              sx={() => {
                                const currentPrice = calculatedValues?.priceVat
                                  ? parseFloat(calculatedValues.priceVat)
                                  : product.price_vat
                                    ? parseFloat(product.price_vat)
                                    : null;

                                if (!currentPrice) return {};

                                const lowestPrice = parseFloat(
                                  product.lowest_price
                                );

                                if (currentPrice > lowestPrice) {
                                  return { ...dangerBg, p: 1 };
                                } else if (currentPrice < lowestPrice) {
                                  return { ...successBg, p: 1 };
                                } else {
                                  return { ...warningBg, p: 1 };
                                }
                              }}
                            >
                              <Typography>
                                {`${product.lowest_price} €`}
                              </Typography>
                              <Typography variant="caption" display="block">
                                {(() => {
                                  const currentPrice =
                                    calculatedValues?.priceVat
                                      ? parseFloat(calculatedValues.priceVat)
                                      : product.price_vat
                                        ? parseFloat(product.price_vat)
                                        : null;

                                  if (!currentPrice) return "";

                                  const lowestPrice = parseFloat(
                                    product.lowest_price
                                  );

                                  if (currentPrice > lowestPrice) {
                                    return "Naša cena je vyššia";
                                  } else if (currentPrice < lowestPrice) {
                                    return "Naša cena je nižšia";
                                  } else {
                                    return "Naša cena je rovnaká";
                                  }
                                })()}
                              </Typography>
                            </Box>
                          )}
                        </a>
                      </TableCell>
                    )}
                    {visibleColumns.stock && (
                      <TableCell>{product.stock_count || "-"}</TableCell>
                    )}
                    {visibleColumns.tags && (
                      <TableCell>
                        {product.tags
                          ? Object.entries(product.tags).map(([key, value]) => (
                              <Typography
                                key={key}
                                variant="caption"
                                sx={{
                                  display: "inline-block",
                                  bgcolor: "primary.light",
                                  color: "white",
                                  px: 1,
                                  py: 0.5,
                                  borderRadius: 1,
                                  mr: 0.5,
                                  mb: 0.5,
                                }}
                              >
                                {`${value}`}
                              </Typography>
                            ))
                          : "-"}
                      </TableCell>
                    )}
                    {visibleColumns.visibility && (
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            handleVisibilityToggle(
                              product.id,
                              product.visibility
                            )
                          }
                        >
                          {product.visibility ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </TableCell>
                    )}
                    {visibleColumns.actions && (
                      <TableCell>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <IconButton onClick={() => handleDuplicate(product)}>
                            <ContentCopy />
                          </IconButton>
                          <IconButton
                            onClick={() => handleEditProduct(product)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => handleEditOffer(product)}>
                            <LocalOfferIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteClick(product)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body2" color="text.secondary">
          Zobrazené {(page - 1) * pageSize + 1} -{" "}
          {Math.min(page * pageSize, totalItems)} z {totalItems} položiek
        </Typography>

        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="5">5 na stránku</option>
            <option value="10">10 na stránku</option>
            <option value="50">50 na stránku</option>
            <option value="100">100 na stránku</option>
            <option value="250">250 na stránku</option>
            <option value="500">500 na stránku</option>
            <option value="1000">1000 na stránku</option>
          </select>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <TextField
              size="small"
              type="number"
              inputProps={{
                min: 1,
                max: totalPages,
                style: { width: "60px" },
              }}
              value={pageInputValue}
              onChange={(e) => {
                const value = e.target.value;
                setPageInputValue(value);
                const newPage = parseInt(value);
                if (!isNaN(newPage)) {
                  debouncedSetPage(newPage);
                }
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  const newPage = parseInt(pageInputValue);
                  if (newPage >= 1 && newPage <= totalPages) {
                    setPage(newPage);
                  }
                }
              }}
              onBlur={() => {
                const newPage = parseInt(pageInputValue);
                if (newPage >= 1 && newPage <= totalPages) {
                  setPage(newPage);
                } else {
                  setPageInputValue(page.toString());
                }
              }}
            />
            <Typography variant="body2" color="text.secondary">
              z {totalPages}
            </Typography>
          </Box>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(e, newPage) => setPage(newPage)}
            color="primary"
          />
        </Box>
      </Box>

      <ProductEditModal
        isOpen={isEditProductModalOpen}
        onClose={() => {
          setIsEditProductModalOpen(false);
          setSelectedProduct(null);
        }}
        productId={selectedProduct?.product_id}
        onUpdate={() => fetchProducts(true)}
      />

      <BilionBuyOfferModal
        isOpen={isEditOfferModalOpen}
        onClose={() => {
          setIsEditOfferModalOpen(false);
          setSelectedProduct(null);
        }}
        product={selectedProduct}
        onUpdate={() => fetchProducts(true)}
      />

      <ProductSelectorModal
        isOpen={isProductSelectorOpen}
        onClose={() => setIsProductSelectorOpen(false)}
        onProductSelect={handleProductSelect}
      />

      <ProductEditModal
        isOpen={isAddProductModalOpen}
        onClose={handleCloseAddModal}
        onUpdate={() => fetchProducts(true)}
        initialData={newProduct}
        content={content}
      />

      <BilionBuyOfferModal
        isOpen={isAddOfferModalOpen}
        onClose={() => {
          setIsAddOfferModalOpen(false);
          setSelectedProduct(null);
        }}
        product={selectedProduct}
        onUpdate={() => fetchProducts(true)}
      />

      <Modal open={isModalOpen} onClose={handleCloseEditModal}>
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" mb={2}>
            Upraviť produkt
          </Typography>
          <form onSubmit={handleUpdateProduct}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                Náhľad obrázka produktu
              </Typography>
              {selectedProduct?.product_image_url && (
                <Box
                  sx={{
                    width: "100%",
                    height: "200px",
                    border: "1px solid #ddd",
                    borderRadius: 1,
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <img
                    src={selectedProduct.product_image_url}
                    alt={selectedProduct.product_name}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                    }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "placeholder-image-url";
                    }}
                  />
                </Box>
              )}
            </Box>

            <Typography
              variant="h6"
              sx={{ mt: 2, mb: 1, color: "primary.main" }}
            >
              Detaily produktu
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Kategória
                </Typography>
                <AutoselectFetchCreate
                  fetchCall="search-categories"
                  createCall="categories"
                  value={selectedCategory}
                  setValue={(newValue) => {
                    setSelectedCategory(newValue);
                    setNewProduct((prev) => ({
                      ...prev,
                      product_assigned_category: newValue?.id || null,
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Názov produktu"
                  name="product_name"
                  value={newProduct.product_name}
                  onChange={(e) =>
                    setNewProduct((prev) => ({
                      ...prev,
                      product_name: e.target.value,
                      original_name: e.target.value,
                    }))
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Výrobca"
                  name="product_manufacturer"
                  value={newProduct.product_manufacturer}
                  onChange={(e) =>
                    setNewProduct((prev) => ({
                      ...prev,
                      product_manufacturer: e.target.value,
                    }))
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="EAN"
                  name="product_ean"
                  value={newProduct.product_ean}
                  onChange={(e) =>
                    setNewProduct((prev) => ({
                      ...prev,
                      product_ean: e.target.value,
                    }))
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Product Image URL"
                  name="product_image_url"
                  value={newProduct.product_image_url}
                  onChange={(e) =>
                    setNewProduct((prev) => ({
                      ...prev,
                      product_image_url: e.target.value,
                    }))
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Description
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <RichTextEditor
                    content={newProduct.product_description || ""}
                    setContent={(newContent) =>
                      setNewProduct((prev) => ({
                        ...prev,
                        product_description: newContent,
                      }))
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={newProduct.visibility}
                      onChange={(e) =>
                        setNewProduct((prev) => ({
                          ...prev,
                          visibility: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Produkt viditeľný"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={newProduct.adult_content}
                      onChange={(e) =>
                        setNewProduct((prev) => ({
                          ...prev,
                          adult_content: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Obsah pre dospelých"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{ mt: 2, mb: 1, color: "primary.main" }}
                >
                  Detaily obchodnej ponuky
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Price (VAT)"
                  name="price_vat"
                  type="number"
                  value={newProduct.price_vat}
                  onChange={(e) =>
                    setNewProduct((prev) => ({
                      ...prev,
                      price_vat: e.target.value,
                      price_novat: (parseFloat(e.target.value) / 1.2).toFixed(
                        2
                      ),
                    }))
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Stock Count"
                  name="stock_count"
                  type="number"
                  value={newProduct.stock_count}
                  onChange={(e) =>
                    setNewProduct((prev) => ({
                      ...prev,
                      stock_count: e.target.value,
                    }))
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Delivery Date"
                  name="delivery_date"
                  value={newProduct.delivery_date}
                  onChange={(e) =>
                    setNewProduct((prev) => ({
                      ...prev,
                      delivery_date: e.target.value,
                    }))
                  }
                  margin="normal"
                  placeholder="e.g., 2-3 business days"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Item ID"
                  name="item_id"
                  value={newProduct.item_id}
                  onChange={(e) =>
                    setNewProduct((prev) => ({
                      ...prev,
                      item_id: e.target.value,
                    }))
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Cashback"
                  name="cashback"
                  type="number"
                  value={newProduct.cashback}
                  onChange={(e) =>
                    setNewProduct((prev) => ({
                      ...prev,
                      cashback: e.target.value,
                    }))
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={newProduct.shop_link_visibility}
                      onChange={(e) =>
                        setNewProduct((prev) => ({
                          ...prev,
                          shop_link_visibility: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Shop Link Visible"
                />
              </Grid>
            </Grid>

            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button onClick={handleCloseAddModal} sx={{ mr: 1 }}>
                Zrušiť
              </Button>
              <Button type="submit" variant="contained">
                Aktualizovať produkt
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>

      <Modal
        open={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setProductToDelete(null);
        }}
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" mb={2}>
            Potvrdenie vymazania
          </Typography>
          <Typography variant="body1" mb={3}>
            Naozaj chcete vymazať ponuku produktu "
            {productToDelete?.product_name}"? Túto akciu nie je možné vrátiť
            späť.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button
              onClick={() => {
                setIsDeleteModalOpen(false);
                setProductToDelete(null);
              }}
            >
              Zrušiť
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteConfirm}
            >
              Vymazať
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
