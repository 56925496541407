import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TextField,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const BuyBilionbuy = () => {
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState(10);
  const price = 12000; // Base price in EUR

  const handleQuantityChange = (e) => {
    setQuantity(parseInt(e.target.value));
  };

  // Generate array of numbers from 1 to 20
  const quantityOptions = Array.from({ length: 20 }, (_, i) => i + 1);

  return (
    <div className="bg-lightblue">
      <div className="max-w-6xl mx-auto">
        <div className="flex flex-col md:flex-row items-center justify-between gap-8 rounded-lg ">
          <div className="w-full md:w-1/2 space-y-8 ">
            <h1 className="text-4xl font-bold text-center pt-8">
              {t(
                "Zakúpte si Bilionbuy Start-up",
                "Zakúpte si Bilionbuy Start-up"
              )}
            </h1>

            <div className="flex flex-col space-y-6 pb-8 items-center relative">
              '
              <FormControl fullWidth className="w-full max-w-xs">
                {/* <InputLabel id='quantity-label'>{t('Počet', 'Počet')}</InputLabel> */}
                <TextField
                  select
                  labelId="quantity-label"
                  fullWidth
                  value={quantity}
                  onChange={handleQuantityChange}
                  variant="outlined"
                  disabled
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#e0e0e0",
                      },
                      "&:hover fieldset": {
                        borderColor: "#bdbdbd",
                      },
                    },
                    "& .MuiSelect-select": {
                      padding: "14px",
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#666",
                    },
                  }}
                  SelectProps={{
                    IconComponent: ArrowDropDownIcon,
                  }}
                >
                  {quantityOptions.map((num) => (
                    <MenuItem key={num.toString()} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl fullWidth className="w-full max-w-xs">
                {/* <InputLabel>{t('Cena', 'Cena')}</InputLabel> */}
                <TextField
                  disabled
                  fullWidth
                  value={`${price.toLocaleString()} €`}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#e0e0e0",
                      },
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000",
                      fontWeight: 500,
                      fontSize: "1.25rem",
                    },
                  }}
                />
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled
                sx={{
                  marginTop: 2,
                  padding: "16px 24px",
                  fontSize: "1.125rem",
                  fontWeight: 500,
                  textTransform: "none",
                  width: "100%",
                  maxWidth: "20rem",
                  borderRadius: "8px",
                }}
              >
                {t("Čoskoro dostupné", "Kúpiť Bilionbuy Start-up")}
              </Button>
            </div>
          </div>

          <div className="w-full md:w-1/2">
            <div className="rounded-lg overflow-hidden">
              <img
                src="https://bbuy.fra1.cdn.digitaloceanspaces.com/img/newman.png"
                alt={t("buy.imageAlt", "Happy Bilionbuy customer")}
                className="w-full h-[120%] object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyBilionbuy;
