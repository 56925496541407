import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import RegisterForm from "./auth/RegisterForm";
import { Image } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

export default function InPageRegistration() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const jwt = localStorage.getItem("jwt");
    if (jwt) {
      setIsAuthenticated(true);
    }

    // Listen for login/logout events
    const handleAuthChange = () => {
      setIsAuthenticated(!!localStorage.getItem("jwt"));
    };

    window.addEventListener("user-login", handleAuthChange);
    window.addEventListener("user-logout", handleAuthChange);

    return () => {
      window.removeEventListener("user-login", handleAuthChange);
      window.removeEventListener("user-logout", handleAuthChange);
    };
  }, []);

  if (isAuthenticated) {
    return null;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeOut" }}
    >
      <div className="pt-16 bg-white">
        <div className="flex flex-col gap-8 max-w-screen-2xl mx-auto">
          <div className="text-center px-4">
            <h2 className="font-bold lg:text-5xl text-3xl leading-tight">
              {t("Registrujte sa do BILIONBUY a získajte registračný bonus")}
            </h2>
          </div>
          <div className=" flex flex-row max-w-[1000px] mx-auto ">
            <div className="relative lg:w-1/2 w-full flex justify-center mb-12">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="max-w-[350px]"
              >
                <RegisterForm showComplementary={false} />
              </motion.div>
              <div className="absolute lg:top-0 -top-16 lg:-right-32 -right-20 w-[200px] h-[200px] rounded-full bg-pink lg:scale-100 scale-50">
                <div className="flex flex-col justify-center items-center h-full text-center scale-125">
                  <div className="text-white font-bold text-5xl">
                    {t("10")}
                    <span className="text-3xl align-top">€</span>
                  </div>
                  <div className="text-white text-lg font-bold max-w-[100px] leading-tight">
                    {t("za nákupy v hodnote 5 bodov")}
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:flex hidden w-1/2  justify-end items-end">
              <img
                src="https://bbuy.fra1.cdn.digitaloceanspaces.com/img/newman.png"
                alt="Illustration of a person pointing"
                className="h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
