import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Tooltip,
} from "@mui/material";
import {
  Close as CloseIcon,
  FileDownload as FileDownloadIcon,
  Print as PrintIcon,
  Description as DescriptionIcon,
  CheckCircle as CheckCircleIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Warning as WarningIcon,
} from "@mui/icons-material";
import api from "../../services/api";
import { toast } from "react-toastify";

// Function to format date from YYYY-MM-DD to DD.MM.YYYY
function formatDate(dateString) {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("sk-SK");
}

// Add Row component for expandable rows
function Row({ commission }) {
  return (
    <>
      <TableRow>
        {/* <TableCell>{commission.id}</TableCell> */}
        <TableCell>{commission.invoice_number || "-"}</TableCell>
        <TableCell>{formatDate(commission.date_created)}</TableCell>
        <TableCell>{formatDate(commission.commision_date)}</TableCell>

        <TableCell>{commission.commisions.length}</TableCell>
      </TableRow>
      {commission.commisions.length > 0 && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="subtitle2"
                gutterBottom
                sx={{ mt: 2, fontWeight: 500 }}
              >
                Detail provízií pre doklad {commission.invoice_number || "-"}
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Transakcia</TableCell>
                    <TableCell>Dátum</TableCell>
                    <TableCell>Hodnota</TableCell>
                    <TableCell>Používateľ</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {commission.commisions.map((comm) => (
                    <TableRow key={comm.id}>
                      <TableCell>{comm.transaction}</TableCell>
                      <TableCell>{formatDate(comm.original_date)}</TableCell>
                      <TableCell>
                        <Chip
                          label={comm.value}
                          size="small"
                          sx={{
                            bgcolor: comm.is_green
                              ? "success.light"
                              : "warning.light",
                            color: "white",
                            fontWeight: 500,
                          }}
                        />
                      </TableCell>
                      <TableCell>{comm.original_user}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export default function AdminCommissionsDetails({
  open,
  onClose,
  uzavierkaId,
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [details, setDetails] = useState(null);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [confirmPublishOpen, setConfirmPublishOpen] = useState(false);

  useEffect(() => {
    if (open && uzavierkaId) {
      fetchDetails();
    }
  }, [open, uzavierkaId]);

  const fetchDetails = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/admin-commission-lists/${uzavierkaId}/`);
      setDetails(response.data);
      setError(null);
    } catch (err) {
      console.error("Error fetching details:", err);
      setError("Failed to load commission details");
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (documentId) => {
    try {
      const response = await api.get(
        `/admin-commission-lists/${uzavierkaId}/documents/${documentId}/`,
        {
          responseType: "blob",
        }
      );

      // Create a blob from the response data
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `document-${documentId}`; // You might want to get the actual filename from the response
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      toast.success("Dokument bol úspešne stiahnutý");
    } catch (err) {
      console.error("Chyba pri sťahovaní dokumentu:", err);
      toast.error("Nepodarilo sa stiahnuť dokument");
    }
  };

  const handlePrint = async () => {
    setIsPdfLoading(true);

    // Open a blank window immediately (must be done in direct response to user action)
    const newWindow = window.open("about:blank", "_blank");

    // Set some content to show while loading
    if (newWindow) {
      newWindow.document.write(`
        <html>
          <head>
            <title>Načítavam PDF...</title>
            <style>
              body { 
                font-family: Arial, sans-serif; 
                display: flex; 
                flex-direction: column;
                align-items: center; 
                justify-content: center; 
                height: 100vh; 
                margin: 0;
                background-color: #f5f5f5;
              }
              .loader {
                border: 5px solid #f3f3f3;
                border-top: 5px solid #3498db;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                animation: spin 1s linear infinite;
                margin-bottom: 20px;
              }
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            </style>
          </head>
          <body>
            <div class="loader"></div>
            <h2>Načítavam PDF dokument...</h2>
            <p>Prosím, počkajte...</p>
          </body>
        </html>
      `);
    }

    try {
      const response = await api.get(
        `/admin-commission-lists/${uzavierkaId}/pdf/`
      );
      console.log("PDF response:", response.data);

      // Check for url or file_url property in the response
      const pdfUrl = response.data.url || response.data.file_url;

      if (pdfUrl && newWindow) {
        // Redirect the already-opened window to the PDF URL
        newWindow.location.href = pdfUrl;
      } else {
        if (newWindow) {
          // Close the window if we couldn't get a URL
          newWindow.close();
        }
        toast.error("PDF URL nebola nájdená v odpovedi");
        console.error("Missing PDF URL in response:", response.data);
      }
    } catch (err) {
      if (newWindow) {
        // Close the window if there was an error
        newWindow.close();
      }
      console.error("Error fetching PDF:", err);
      toast.error("Nepodarilo sa načítať PDF");
    } finally {
      setIsPdfLoading(false);
    }
  };

  const handlePublishClick = () => {
    setConfirmPublishOpen(true);
  };

  const handleConfirmPublish = async () => {
    setConfirmPublishOpen(false);
    setIsPublishing(true);
    try {
      await api.post(`/admin-commission-lists/${uzavierkaId}/publish/`);
      toast.success("Uzávierka bola úspešne započítaná do peňaženky");
      // Update status in local state
      setDetails((prev) => ({
        ...prev,
        status: "published",
        publishedDate: new Date().toLocaleDateString("sk"),
      }));
      // Refresh data to get updated published status
      fetchDetails();
    } catch (err) {
      console.error("Chyba pri započítaní uzávierky do peňaženky:", err);
      toast.error("Nepodarilo sa započítať uzávierku do peňaženky");
    } finally {
      setIsPublishing(false);
    }
  };

  const handleCancelPublish = () => {
    setConfirmPublishOpen(false);
  };

  // Check if all commissions are already published
  const areAllCommissionsPublished = React.useMemo(() => {
    if (!details?.commission_lists) return false;

    for (const commissionList of details.commission_lists) {
      if (!commissionList.commisions) continue;

      for (const commission of commissionList.commisions) {
        if (!commission.published) {
          return false;
        }
      }
    }

    // All commissions in all lists are published
    return details.commission_lists.length > 0;
  }, [details]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="uzavierky-details-dialog"
    >
      <DialogTitle
        id="uzavierky-details-dialog"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {details && (
          <>
            <Typography variant="h6">
              Uzávierka č. {details.uzavierkaNr}
            </Typography>
            <IconButton
              aria-label="zavrieť"
              onClick={onClose}
              sx={{ top: 8, right: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </>
        )}
      </DialogTitle>
      <DialogContent dividers>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Box sx={{ p: 3, textAlign: "center", color: "error.main" }}>
            <Typography>{error}</Typography>
            <Button variant="contained" onClick={fetchDetails} sx={{ mt: 2 }}>
              Skúsiť znova
            </Button>
          </Box>
        ) : details ? (
          <Box sx={{ py: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <Typography variant="subtitle1" sx={{ mr: 1 }}>
                    Status:
                  </Typography>
                  <Chip
                    label={
                      details.status === "published"
                        ? "Započítaná"
                        : details.status === "closed"
                          ? "Uzavretá"
                          : "Vytvorená"
                    }
                    color={
                      details.status === "published"
                        ? "success"
                        : details.status === "closed"
                          ? "error"
                          : "default"
                    }
                    size="small"
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  Obdobie
                </Typography>
                <Typography variant="body1">
                  {formatDate(details.startDate)} -{" "}
                  {formatDate(details.endDate)}
                </Typography>
              </Grid>
              {details.closeDate && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Dátum uzavretia
                  </Typography>
                  <Typography variant="body1">
                    {formatDate(details.closeDate)}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  ID používateľa
                </Typography>
                <Typography variant="body1">{details.userId}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  Meno používateľa
                </Typography>
                <Typography variant="body1">{details.userName}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  Email používateľa
                </Typography>
                <Typography variant="body1">{details.userEmail}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  Level
                </Typography>
                <Typography variant="body1">{details.level}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  Koeficient
                </Typography>
                <Typography variant="body1">{details.coefficient}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  Vlastné body
                </Typography>
                <Typography variant="body1">
                  {details.own_points.toFixed(4)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  Štruktúrne body
                </Typography>
                <Typography variant="body1">
                  {details.structure_points.toFixed(4)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  Celkové body
                </Typography>
                <Typography variant="body1" fontWeight={500}>
                  {details.totalPoints}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  Celková odmena
                </Typography>
                <Typography variant="body1" fontWeight={500}>
                  {details.totalRewardClaim} €
                </Typography>
              </Grid>
              {details.additionalInfo && (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Dodatočné informácie
                  </Typography>
                  <Typography variant="body2">
                    {details.additionalInfo}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Divider sx={{ my: 3 }} />

            {/* <Typography variant="h6" sx={{ mb: 2 }}>
              Dokumenty
            </Typography>

            {details.documents && details.documents.length > 0 ? (
              <Paper
                variant="outlined"
                sx={{ borderRadius: 1, overflow: "hidden" }}
              >
                {details.documents.map((doc) => (
                  <Box
                    key={doc.id}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      p: 2,
                      "&:not(:last-child)": {
                        borderBottom: "1px solid",
                        borderColor: "divider",
                      },
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="body1">{doc.name}</Typography>
                    </Box>
                    <Box>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => handleDownload(doc.id)}
                        aria-label="stiahnuť dokument"
                      >
                        <FileDownloadIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </Paper>
            ) : (
              <Typography variant="body2" color="textSecondary">
                Žiadne dokumenty nie sú dostupné
              </Typography>
            )} */}

            {/* <Divider sx={{ my: 3 }} /> */}

            <Typography variant="h6" sx={{ mb: 2 }}>
              Zoznam provízií
            </Typography>

            <TableContainer component={Paper} variant="outlined" sx={{ mb: 3 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {/* <TableCell>ID</TableCell> */}
                    <TableCell>Číslo dokladu</TableCell>
                    <TableCell>Dátum vytvorenia</TableCell>
                    <TableCell>Dátum provízie</TableCell>

                    <TableCell>Počet provízií</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {details.commission_lists?.map((commission) => (
                    <Row key={commission.id} commission={commission} />
                  ))}
                  {!details.commission_lists?.length && (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        Žiadne provízie neboli nájdené
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        {details && (
          <>
            <Button
              startIcon={
                isPdfLoading ? (
                  <CircularProgress size={16} />
                ) : (
                  <PictureAsPdfIcon />
                )
              }
              onClick={handlePrint}
              disabled={isPdfLoading}
            >
              {isPdfLoading ? "Načítavam PDF..." : "Zobraziť PDF"}
            </Button>
            <Box sx={{ flex: 1 }} />
            <Button onClick={onClose} color="inherit">
              Zavrieť
            </Button>
            {details.status !== "published" && (
              <Tooltip
                title={
                  areAllCommissionsPublished
                    ? "Všetky provízie sú už započítané do peňaženky"
                    : ""
                }
                placement="top"
              >
                <span>
                  {" "}
                  {/* Wrapper needed for disabled buttons */}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePublishClick}
                    disabled={areAllCommissionsPublished || isPublishing}
                    startIcon={
                      isPublishing ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : null
                    }
                  >
                    {isPublishing ? "Spracovávam..." : "Započítať do peňaženky"}
                  </Button>
                </span>
              </Tooltip>
            )}
          </>
        )}
      </DialogActions>
      <Dialog
        open={confirmPublishOpen}
        onClose={handleCancelPublish}
        aria-labelledby="confirm-publish-dialog"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="confirm-publish-dialog">
          Potvrdenie započítania do peňaženky
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <WarningIcon color="warning" sx={{ mr: 2, fontSize: 30 }} />
            <Typography variant="subtitle1" fontWeight="medium">
              Toto je nezvratná akcia!
            </Typography>
          </Box>
          <Typography paragraph>
            Chystáte sa započítať uzávierku{" "}
            <strong>{details?.uzavierkaNr}</strong> do peňaženky používateľa{" "}
            <strong>{details?.userName}</strong>.
          </Typography>
          <Typography>Po započítaní:</Typography>
          <Box component="ul" sx={{ mt: 1 }}>
            <Typography component="li">
              Uzávierka bude označená ako "Započítaná"
            </Typography>
            <Typography component="li">
              Provízie budú pripísané používateľovi
            </Typography>
            <Typography component="li">
              Stav uzávierky už nebude možné vrátiť späť
            </Typography>
          </Box>
          <Typography color="warning.main" sx={{ mt: 2 }}>
            Chcete pokračovať?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button onClick={handleCancelPublish} color="inherit">
            Zrušiť
          </Button>
          <Button
            onClick={handleConfirmPublish}
            variant="contained"
            color="primary"
            startIcon={
              isPublishing ? (
                <CircularProgress size={20} color="inherit" />
              ) : null
            }
            disabled={isPublishing}
          >
            {isPublishing ? "Spracovávam..." : "Započítať do peňaženky"}
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}
