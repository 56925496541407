import api from "../services/api";
// import { useAuth } from "react-auth-verification-context";

const log_in = async (email, password, setIsLoading, setAuth) => {
  try {
    setIsLoading(true);

    const username = email;
    const response = await api.post("jwt-login/", { username, password });
    const token = response.data.token;
    const user_permissions = response.data.user_permissions;

    localStorage.setItem("jwt", token);
    localStorage.setItem("user_permissions", user_permissions);
    localStorage.setItem("username", username);

    // Dispatch login event after setting localStorage items
    window.dispatchEvent(new Event("user-login"));

    setAuth(token);
    setIsLoading(false);

    return true;
  } catch (error) {
    console.log(error);
    setIsLoading(false);
    return false;
  }
};

export default log_in;
