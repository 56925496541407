import React from "react";
import { Accordion, AccordionItem, Button } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

/**
 * FAQ Component
 * @param {Object} props
 * @param {string} props.title - The title of the FAQ section
 * @param {Array<{question: string, answer: string}>} props.questions - Array of FAQ items
 */
export const FAQ = ({ title, questions }) => {
  const { t } = useTranslation();

  const PlusIcon = ({ isOpen }) => (
    <div
      className={`w-6 h-6 md:w-4 md:h-4 rounded-full bg-darkblue flex items-center justify-center`}
    >
      <div
        className={`text-white transition-transform duration-300 ${
          isOpen ? "rotate-45" : ""
        }`}
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 2V10M2 6H10"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );

  return (
    <div className="w-full max-w-screen-2xl mx-auto px-4 py-16">
      <h2 className="text-3xl font-bold text-center text-[#1a56db] mb-10">
        {t(title)}
      </h2>
      <Accordion
        showDivider={true}
        className="gap-1"
        selectionMode="single"
        itemClasses={{
          base: "py-0 w-full",
          title:
            "font-semibold text-base text-darkblue hover:text-darkblue pl-2 w-full",
          trigger:
            "flex flex-row items-center py-6 w-full cursor-pointer touch-manipulation",
          content: "text-base pb-6 text-darkblue",
          indicator: "hidden",
          startContent: "flex items-center mr-3",
        }}
      >
        {questions.map((item, index) => (
          <AccordionItem
            key={index}
            aria-label={t(item.question)}
            title={t(item.question)}
            startContent={<PlusIcon />}
            disableAnimation={false}
            classNames={{
              base: "w-full",
              heading: "w-full",
              trigger: "w-full min-h-[44px]",
            }}
          >
            <div className="whitespace-pre-line">{t(item.answer)}</div>
          </AccordionItem>
        ))}
      </Accordion>

      <div className="flex md:flex-row flex-col gap-2 items-center justify-center mt-12">
        <Button
          as="a"
          href="/register"
          className="bg-blue text-white rounded-3xl font-bold py-3 md:py-4 px-8 md:px-10 min-h-[44px] w-full md:w-auto"
        >
          {t("Registrácia zdarma")} &rarr;
        </Button>
        <Button
          as="a"
          href="/blog#vyhody-cashbacku"
          className="bg-transparent text-blue border-blue border rounded-3xl font-bold py-3 md:py-4 px-8 md:px-10 min-h-[44px] w-full md:w-auto"
        >
          {t("Viac informácií")}
        </Button>
      </div>
    </div>
  );
};

export default FAQ;
